import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import '../App.css';
import { Container } from 'react-bootstrap';
import MapNavbar from '../components/MapNavbar';
import { privacyStatementContent } from '../components/internationalization/Translations';

function PrivacyStatement(props) {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? privacyStatementContent.English :
        language === "NL" ? privacyStatementContent.Dutch :
            privacyStatementContent.English;


    return (
        <div style={{backgroundColor: "#f2f2f2"}}>
            <MapNavbar page="privacystatement" />
            <Container className='mt-4'>
                <br />
                <h4 style={{ textAlign: 'center' }}><b>{content.title}</b></h4>
                <br />
                <p>{content.subtitle_content}</p>
                <br />
                <br />
                <h6><b>{content.security_title}</b></h6>
                <br />
                <p>{content.security_content}</p>
                <br />
                <br />
                <h6><b>{content.dataProcessing_title}</b></h6>
                <br />
                <p>{content.dataProcessing_content_p1}</p>
                <p>{content.dataProcessing_content_p2}</p>
                <p>{content.dataProcessing_content_p3}</p>

                <p><b>{content.dataProcessing_content_p4}</b></p>
                <p>{content.dataProcessing_content_emaildata}</p>
                <p>{content.dataProcessing_content_emailcontent}</p>
                <br />

                <p>{content.dataProcessing_content_telephonedata}</p>
                <p>{content.dataProcessing_content_telephonecontent}</p>
                <br />

                <p>{content.dataProcessing_content_cardata}</p>
                <p>{content.dataProcessing_content_carcontent}</p>

                <br />
                <p>{content.dataProcessing_content_incidenttypedata}</p>
                <p>{content.dataProcessing_content_incidenttypecontent}</p>

                <br />
                <p>{content.dataProcessing_content_incidentdescriptiondata}</p>
                <p>{content.dataProcessing_content_incidentdescriptioncontent}</p>

                <br />
                <p>{content.dataProcessing_content_incidentlocationdata}</p>
                <p>{content.dataProcessing_content_incidentlocationcontent}</p>

                <br />
                <p>{content.dataProcessing_content_incidentcommentsdata}</p>
                <p>{content.dataProcessing_content_incidentcommentscontent}</p>

                <br />
                <p><b>{content.dataProcessing_content_otherusage_title}</b></p>
                <p>{content.dataProcessing_content_otherusage_accountdata}</p>
                <p>{content.dataProcessing_content_otherusage_accountcontent}</p>

                <br />
                <br />
                <h6><b>{content.dataProcessing_content_cookiessettings_title}</b></h6>
                <p>{content.dataProcessing_content_cookiessettings_subtitle}</p>
                <p><b><u>{content.dataProcessing_content_cookiessettings_necessary_title}</u></b></p>
                <p>{content.dataProcessing_content_cookiessettings_necessary_content}</p>
                <p><b><u>{content.dataProcessing_content_cookiessettings_tracking_title}</u></b></p>
                <p>{content.dataProcessing_content_cookiessettings_tracking_content}</p>
                <p><b><u>{content.dataProcessing_content_cookiessettings_ads_title}</u></b></p>
                <p>{content.dataProcessing_content_cookiessettings_ads_content}</p>

                <br />
                <br />
                <h6><b>{content.dataProcessing_content_amendments_title}</b></h6>
                <p>{content.dataProcessing_content_amendments_content}</p>
                <br />
                <br />
                <br />
                <br />

            </Container>
        </div>
    )
}

export default PrivacyStatement;