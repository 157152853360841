import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { searchHereContent } from '../internationalization/Translations';


function NoMarkersFoundToast(props) {

    const content = props.language === "EN" ? searchHereContent.English :
        props.language === "NL" ? searchHereContent.Dutch :
            searchHereContent.English;

    return (
        <ToastContainer className="p-2 loginToast" position='middle-center' id='noMarkersFoundToastContainer'>
            <Toast onClose={props.closeNoMarkersFoundToast} show={props.showNoMarkersFoundToast} delay={4000} autohide>
                <Toast.Header closeButton={false} style={{ color: 'white' }}>
                    <strong className="mx-auto">{content.noChargersFoundTitle}</strong>
                    <CustomCloseButton handleClose={props.closeNoMarkersFoundToast} />
                </Toast.Header>
                <Toast.Body>{content.noChargersFoundBody}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default NoMarkersFoundToast;