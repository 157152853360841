import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import EVSEConnectorInfo from './EVSEConnectorInfo';

function EVSEStatusInfo(props) {

    const [showConnectorsDetails, setShowConnectorsDetails] = useState(false);
    const evse = props.evse;
    const tot_connectors = props.evse && props.evse.evse_connectors ? props.evse.evse_connectors.length : null;
    const tot_connectors_text = tot_connectors ?
        tot_connectors === 1 ? tot_connectors + " connector"
            : tot_connectors + " connectors"
        : null;
    //const report_date = new Date(Date.parse(userReport.report_start_time)).toLocaleString();

    const evse_name = evse ? evse.evse_name ? evse.evse_name : "Unknown name" : "Unknown name";
    const evse_status = evse ? evse.status ? evse.status : "Unknown" : "Unknown";
    const last_status_update = evse ? evse.last_updated_str ? evse.last_updated_str : "Unknown" : "Unknown";
    const parking_restrictions = evse ? evse.parking_restrictions ? evse.parking_restrictions : "No" : "No";
    const evse_capabilities = evse ? evse.capabilities ? evse.capabilities.replace(/,/g, ", ") : "Unknown" : "Unknown";

    const evse_status_classname = evse_status === "AVAILABLE" ? "green-text"
        : evse_status === "OUTOFORDER" || evse_status === "INOPERATIVE" ? "unavailable-text"
            : "black-text";

    const hideOrShowConnectorDetails = () => {
        if (showConnectorsDetails) setShowConnectorsDetails(false);
        else setShowConnectorsDetails(true);
    }

    return (
        <Card border="dark" className="offcanvas-cards">
            <Card.Header as="h5">
                {evse_name}
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    Status: <span className={evse_status_classname} style={{fontSize: "1rem"}}><b>{evse_status}</b></span>
                    <br />
                    <span style={{ 'fontSize': '0.8rem' }}>Updated at: {last_status_update}</span>
                </Card.Text>
                {props.evse && props.evse.evse_connectors && tot_connectors && tot_connectors > 0 ?
                    <div>
                        <b><hr className='chargeprob-green' /></b>
                        <Stack direction="horizontal" gap={1} onClick={() => hideOrShowConnectorDetails()}>
                            <div><span className='evConnectorIcon chargeprob-green'></span></div>
                            <div className='chargeprob-green'><b>{tot_connectors_text}</b></div>
                            <FontAwesomeIcon icon={faAngleDown} className='chargeprob-green' style={{ fontSize: '1.1rem' }} />
                        </Stack>
                        {showConnectorsDetails ?
                            <div>
                                {props.evse && props.evse.evse_connectors && props.evse.evse_connectors.map((connector) => {
                                    return (
                                        <React.Fragment key={connector.id}>
                                            <EVSEConnectorInfo connector={connector} tot_connectors={tot_connectors} />
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            : null}
                    </div>
                    : null}
                <b><hr className='chargeprob-green' /></b>
                <Stack direction="horizontal" gap={1}>
                    <div>
                        <span className='parkingIcon'></span>
                    </div>
                    <div className='chargeprob-green'><b>Parking restrictions</b></div>
                </Stack>
                {parking_restrictions.includes("EV_ONLY") ?
                    <Stack direction="horizontal" gap={1}>
                        <div className='chargeprob-green'>
                            <span className='evcarIcon'></span>
                        </div>
                        <div>Only EV cars</div>
                        <br /><br />
                    </Stack>
                    :
                    <Card.Text>
                        {parking_restrictions}
                    </Card.Text>
                }
                <b><hr className='chargeprob-green' /></b>
                <Stack direction="horizontal" gap={1}>
                    <div className='chargeprob-green'>
                        <span className='capabilitiesIcon'></span>
                    </div>
                    <div className='chargeprob-green'><b>Options</b></div>
                </Stack>
                {/* <Card.Text>
                    {evse_capabilities}
                </Card.Text> */}
                {evse_capabilities.includes("RFID_READER") ?
                    <Stack direction="horizontal" gap={1}>
                        <div className='chargeprob-green'>
                            <span className='rfidIcon'></span>
                        </div>
                        <div>
                            RFID READER
                        </div>
                        <br /><br />
                    </Stack>
                    : null}
                {evse_capabilities.includes("CREDIT_CARD") ?
                    <Stack direction="horizontal" gap={1}>
                        <div className='chargeprob-green'>
                            <span className='creditcardIcon'></span>
                        </div>
                        <div>
                            CREDIT CARD
                        </div>
                        <br /><br />
                    </Stack>
                    : null}
                {evse_capabilities.includes("RESERVABLE") ?
                    <Stack direction="horizontal" gap={1}>
                        <div className='chargeprob-green'>
                            <span className='evsereservableIcon'></span>
                        </div>
                        <div>
                            RESERVABLE
                        </div>
                        <br /><br />
                    </Stack>
                    : null}
                {evse_capabilities.includes("REMOTE_START_STOP") ?
                    <Stack direction="horizontal" gap={1}>
                        <div className='chargeprob-green'>
                            <span className='evseremotestartstopIcon'></span>
                        </div>
                        <div>
                            START/STOP REMOTE
                        </div>
                    </Stack>
                    : null}




            </Card.Body>
        </Card>
    )
}

export default EVSEStatusInfo;