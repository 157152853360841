import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import AuthContext from '../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from '@fortawesome/free-solid-svg-icons';

function UserProfileCarType(props) {

    let { userProfile } = useContext(AuthContext);
    const buttonscontent = props.buttonscontent;
    const content = props.content;

    return (
        <>
            <h6 className='chargeprob-green'><FontAwesomeIcon icon={faCar} size="lg" /> {content.userProfileCarType}</h6>
            {userProfile && userProfile.car_type ?
                <>
                    <p>{userProfile.car_type}</p>
                    {props.showUserChangeCarType ?
                        <Stack direction="vertical" gap={1}>
                            <Col xs={10} md={7}>
                                <Form>
                                    <Form.Group className="mb-0" controlId="userprofile.ControlInputChangeCarType" >
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            className='offcanvasButtons'
                                            style={{color: "white"}}
                                            placeholder={userProfile.car_type}
                                            onChange={evt => props.setUserCarType(evt.target.value)}
                                        />
                                        <p className='red'>{props.userCarTypeError}</p>
                                    </Form.Group>
                                </Form>
                            </Col>
                            {props.userUpdateCarTypeLoading ?
                                <Button size="sm" variant="success" className='offcanvasButtons'>
                                    {buttonscontent.saveUserInfo}
                                </Button>
                                :
                                <Stack direction="horizontal" gap={1}>
                                    <Button size="sm" variant="success" className='offcanvasButtons'
                                        onClick={() => props.updateCarType("update")}>
                                        {buttonscontent.userChangeCarType}
                                    </Button>
                                    <Button size="sm" variant="success" className='offcanvasButtons'
                                        onClick={() => props.updateCarType("delete")}>
                                        {buttonscontent.userRemoveCarType}
                                    </Button>
                                    <Button size="sm" variant="danger" className='offcanvasButtons'
                                        onClick={() => props.setShowUserChangeCarType(false)}>
                                        {buttonscontent.close}
                                    </Button>
                                </Stack>
                            }
                        </Stack>
                        :
                        <>
                            <Button
                                variant="outline-warning" size="sm" className='offcanvasButtons' onClick={() => props.showAndResetUserChangeCarType(true)}>
                                {buttonscontent.userChangeCarType}
                            </Button>
                            <p>{props.userUpdateCarTypeError}</p>
                        </>
                    }
                </>
                :
                <>
                    <p>{content.userProfileNoCarTypeProvided}</p>
                    {props.showUserAddCarType ?
                        <Stack direction="vertical" gap={1}>
                            <Col xs={8}>
                                <Form>
                                    <Form.Group className="mb-0" controlId="userprofile.ControlInputAddCarType" >
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            className='offcanvasButtons'
                                            style={{color: "white"}}
                                            placeholder="manufacturer model"
                                            onChange={evt => props.setUserCarType(evt.target.value)}
                                        />
                                        <p className='red'>{props.userCarTypeError}</p>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Stack direction="horizontal" gap={1}>
                                <Button size="sm" variant="success" className='offcanvasButtons'
                                    onClick={() => props.updateCarType("add")}>
                                    {buttonscontent.userAddCarType}
                                </Button>
                                <Button size="sm" variant="danger" className='offcanvasButtons'
                                    onClick={() => props.setShowUserAddCarType(false)}>
                                    {buttonscontent.close}
                                </Button>
                            </Stack>
                        </Stack>
                        :
                        <>
                            <Button
                                variant="outline-warning" size="sm" className='offcanvasButtons' onClick={() => props.showAndResetUserAddCarType(true)}>
                                {buttonscontent.userAddCarType}
                            </Button>
                            <p>{props.userUpdateCarTypeError}</p>
                        </>
                    }
                </>
            }
        </>
    )
}

export default UserProfileCarType;