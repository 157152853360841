import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import AuthContext from '../../context/AuthContext';


function LanguagePicker(props) {
    let {language, setLanguage} = useContext(AuthContext);
    const languageIconClassname = "select-language-" + props.variant + " language" + language;

    return(
        <div>
            <Form.Select size="sm" aria-label="new incident type" className={languageIconClassname}
                onChange={ evt => setLanguage(evt.target.value)}
                defaultValue={language}>
                <option value={language} disabled hidden>{language}</option>
                <option value="NL">NL</option>
                <option value="EN">EN</option>
            </Form.Select>
        </div>
    )
}

export default LanguagePicker;
