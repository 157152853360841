import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faExclamationTriangle, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { energieOnderbrekingContent } from '../internationalization/Translations';

function UserFavoriteCharger(props) {
    const [showConfirmRemoveFromFavChargers, setShowConfirmRemoveFromFavChargers] = useState(false);
    const userFavCharger = props.userFavCharger;
    const tot_sockets_text = userFavCharger && userFavCharger.totevses === 1 ?
        userFavCharger.totevses + " socket"
        : userFavCharger.totevses + " sockets";

    const reportsOnCharger = userFavCharger ? userFavCharger["usersreports"] : null;
    const openReports = reportsOnCharger ? reportsOnCharger.filter(report => report.report_status !== "CLOSE") : null;

    const last_report_date = openReports && openReports.length > 0 ?
        userFavCharger.lastreporttime : null; // check in backed if this is correct
    //new Date(Date.parse(userFavCharger.lastreporttime.report_start_time)).toLocaleString() : null;

    const eoContent = props.language === "EN" ? energieOnderbrekingContent.English :
        props.language === "NL" ? energieOnderbrekingContent.Dutch :
            energieOnderbrekingContent.English;

    const hasEoDisruption = userFavCharger && userFavCharger.disruption ? true : false;

    const eoDisruption_translation = hasEoDisruption ?
        userFavCharger.disruption.planned ? eoContent.warningPlannedMaint
            : eoContent.warningEnergyFailure
        : null;

    const charger_status_color = userFavCharger ?
        userFavCharger["status"] === "Available" ? "#1dbf73"
            : userFavCharger["status"] === "Partially available" ? "#1dbf73"
                : userFavCharger["status"] === "Warning" ? "#FFAE00"
                    : userFavCharger["status"] === "Unavailable" ? "#BF2D32"
                        : "black" : "black";

    const bringmethere_clicked = (charger) => {
        props.setBringmethereLocation({
            'id': charger["id"],
            'latitude': charger["latitude"],
            'longitude': charger["longitude"]
        });
    }

    const showChargerStatus = (charger) => {
        let back = "userFavoriteChargers";
        props.showChargerStatusDetails(charger, back);
    }

    const showSocketsOnCharger = (charger) => {
        let back = "userFavoriteChargers";
        props.showSocketsDetails(charger, back);
    }

    const checkRemoveFromFavorites = (charger) => {
        setShowConfirmRemoveFromFavChargers(true);
    }

    const confirmRemoveFromFavorites = (charger) => {
        props.addOrRemoveFromFavoriteChargers(charger);
    }

    const abortRemoveFromFavorites = (charger) => {
        setShowConfirmRemoveFromFavChargers(false);
    }

    return (
        <Card border="dark" className="offcanvas-cards">
            <Card.Header as="h5">
                <Stack direction='horizontal' gap={3}>
                    <div>
                        {userFavCharger["address"] + ","}
                        <br />
                        {userFavCharger["postal_code"] + " " + userFavCharger["city"]}
                    </div>
                    <div className="ms-auto">
                        <h5 style={{ 'fontSize': '1.6rem', cursor: 'pointer' }} onClick={() => props.showShareOptions(userFavCharger)}>
                            <FontAwesomeIcon icon={faShareNodes} style={{ 'color': 'white' }} />
                        </h5>
                    </div>
                </Stack>
            </Card.Header>

            <Card.Body>
                {/* <Card.Title>{report_date}</Card.Title> */}
                <Card.Text>
                    Operator: <b>{userFavCharger["cpo_name"]}</b>
                </Card.Text>
                <Card.Text>
                    Status: <b style={{ 'color': charger_status_color, 'fontSize': '1.1rem' }}>{userFavCharger["status"]}</b>
                </Card.Text>
                {hasEoDisruption ?
                    <Stack direction='vertical' gap={2}>
                        <Stack direction='horizontal' gap={2} style={{ 'color': "#FFAE00" }}>
                            <div>
                                <b><FontAwesomeIcon icon={faExclamationTriangle} style={{ 'fontSize': '1.3rem' }} /></b>
                            </div>
                            <div><b>{eoDisruption_translation}</b></div>
                        </Stack>
                        <div></div>
                    </Stack>
                    : null}
                <hr className='chargeprob-green' />
                {openReports && openReports.length === 1 ?
                    <Card.Text>
                        <FontAwesomeIcon icon={faCircleExclamation} style={{ 'color': '#BF2D32' }} />
                        &nbsp;&nbsp;{props.content.userFavoriteChargersOneReport}
                    </Card.Text>
                    : openReports && openReports.length > 1 ?
                        <Card.Text>
                            <FontAwesomeIcon icon={faCircleExclamation} style={{ 'color': '#BF2D32' }} />
                            &nbsp;&nbsp;{props.content.userFavoriteChargersMultipleReports_p1} {openReports.length} {props.content.userFavoriteChargersMultipleReports_p2}
                        </Card.Text>
                        :
                        <Card.Text>
                            <FontAwesomeIcon icon={faThumbsUp} style={{ 'color': '#1dbf73' }} />
                            &nbsp;&nbsp;{props.content.userFavoriteChargersNoReports}
                        </Card.Text>
                }
                {openReports && openReports.length > 0 ?
                    <Card.Text>
                        {props.content.userFavoriteChargersLastReport} <b>{userFavCharger.lastreport.report}</b>
                        <br />
                        <span style={{ fontSize: '0.8rem' }}>{last_report_date}</span>
                        <br />
                    </Card.Text>
                    : null
                }
                <Button size="sm" className='offcanvasButtons chargeprob-button-outline-green shadow-none'
                    onClick={() => showChargerStatus(userFavCharger)}>
                    {props.buttonscontent.showChargerStatus}
                </Button>
                <hr className='chargeprob-green' />
                <Stack direction="horizontal" gap={2} >
                    <div><span className='socketIcon chargeprob-green'></span></div>
                    <div>{tot_sockets_text}</div>
                </Stack>
                <Card.Text>
                    <br />
                    <Button size="sm" className='offcanvasButtons chargeprob-button-outline-green shadow-none'
                        onClick={() => showSocketsOnCharger(userFavCharger)}>
                        {props.buttonscontent.showSockets}
                    </Button>
                </Card.Text>
                <hr className='chargeprob-green' />
                <Row>
                    <Col xs={6}>
                        <Button size="sm" variant="outline-primary" className='offcanvasButtons'
                            onClick={() => bringmethere_clicked(userFavCharger)}>
                            {props.buttonscontent.bringMeThere}
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button size="sm" variant="outline-dark" className='offcanvasButtons'
                            onClick={() => checkRemoveFromFavorites(userFavCharger)}>
                            {props.buttonscontent.removeFromFavoriteChargers}
                        </Button>
                    </Col>
                </Row>
                {showConfirmRemoveFromFavChargers ?
                    <Row>
                        <Col xs={12}>
                            <Card.Text>
                                {props.content.userFavoriteChargersConfirmRemoveFromFavorites}
                            </Card.Text>
                            <Stack direction="horizontal" gap={1}>
                                <Button size="sm" variant="success"
                                    onClick={() => confirmRemoveFromFavorites(userFavCharger)}>
                                    Yes
                                </Button>
                                <Button size="sm" variant="danger"
                                    onClick={() => abortRemoveFromFavorites(userFavCharger)}>
                                    No
                                </Button>
                            </Stack>
                        </Col>
                    </Row>
                    : null}
            </Card.Body>
        </Card>
    )
}

export default UserFavoriteCharger;