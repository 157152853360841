import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import CustomCloseButton from '../buttons/CustomCloseButton';


function ModalUserReportConfirmSolved(props) {

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{props.content.userReportIsIssueSolvedModalTitle}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <Row>
                    <Col xs={12} md={{ span: '10', offset: '1' }} lg={{ span: '8', offset: '2' }}>
                        {props.sendingReportFeedback ?
                            <Stack direction="horizontal" gap={3}>
                                <Button className="chargeprob-button-green">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="visually-hidden">Loading...</span>
                                    {props.content.userReportIsIssueSolvedConfirm}
                                </Button>
                                <Button variant="danger">
                                    {props.content.userReportIsIssueSolvedNotConfirm}
                                </Button>
                            </Stack>
                            :
                            <Stack direction="horizontal" gap={3}>
                                <Button className="chargeprob-button-green" onClick={() => props.confirmReportIsSolved(props.report)}>
                                    {props.content.userReportIsIssueSolvedConfirm}
                                </Button>
                                <Button variant="danger" onClick={() => props.confirmReportIsNotSolved(props.report)}>
                                    {props.content.userReportIsIssueSolvedNotConfirm}
                                </Button>
                            </Stack>
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    )
}

export default ModalUserReportConfirmSolved;