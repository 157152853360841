import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import { navbarContent } from './internationalization/Translations';
import AuthContext from '../context/AuthContext';

function MapFooter(props) {

    let { user, language, userProfile } = useContext(AuthContext);
    const content = language === "EN" ? navbarContent.English :
        language === "NL" ? navbarContent.Dutch :
            navbarContent.English;

    const isUserLoggedIn = () => {
        if (user) return true;
        return false;
    }

    const totOpenReports = userProfile && userProfile.tot_open_reports ? userProfile.tot_open_reports : 0;

    return (
        <>
            {isUserLoggedIn() ?
                <Navbar variant="dark" fixed="bottom" className='py-0 map-navbar-lg'>
                    <Container>
                        <Navbar.Collapse className="justify-content-center">
                            <div className='d-none d-md-block'>
                                <Stack direction='horizontal' gap={5}>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewUserReports}>
                                        <span className='userReportIcon' style={{ position: "relative" }}>
                                            {totOpenReports && totOpenReports > 0 ?
                                                <>
                                                    <Badge pill className="chargeprob-background-green"
                                                        style={{ position: "absolute", left: "-2px", color: "black", fontSize: "0.6rem" }}>
                                                        {totOpenReports}
                                                    </Badge>
                                                    <span className="visually-hidden">{totOpenReports} reports</span>
                                                </>
                                                : null}
                                        </span>
                                        <Navbar.Text>{content.viewReports}</Navbar.Text>
                                    </Stack>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewUserFavoriteChargers}>
                                        <span className='userFavChargerIcon'></span>
                                        <Navbar.Text>{content.viewMyFavoriteChargers}</Navbar.Text>
                                    </Stack>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewUserProfile}>
                                        <span className='userProfileIcon'></span>
                                        <Navbar.Text>{content.viewMyProfile}</Navbar.Text>
                                    </Stack>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewLeaveFeedbackModal}>
                                        <span className='userFeedbackIcon'></span>
                                        <Navbar.Text>{content.leaveFeedback}</Navbar.Text>
                                    </Stack>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }}>
                                        <span className='CPIcon'></span>
                                        <Navbar.Text>
                                            <Link
                                                to="/aboutchargeprob"
                                                style={{ textDecoration: 'none' }}>
                                                {content.aboutChargeProb}
                                            </Link>
                                        </Navbar.Text>
                                    </Stack>
                                </Stack>
                            </div>
                            <div className='d-block d-md-none'>
                                <Stack direction='horizontal' gap={5}>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewUserReports}>
                                        <Navbar.Text>
                                            <span className='userReportIcon' style={{ position: "relative" }}>
                                                {totOpenReports && totOpenReports > 0 ?
                                                    <>
                                                        <Badge pill className="chargeprob-background-green"
                                                            style={{ position: "absolute", left: "-2px", color: "black", fontSize: "0.6rem" }}>
                                                            {totOpenReports}
                                                        </Badge>
                                                        <span className="visually-hidden">{totOpenReports} reports</span>
                                                    </>
                                                    : null}
                                            </span>
                                        </Navbar.Text>
                                    </Stack>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewUserFavoriteChargers}>
                                        <Navbar.Text><span className='userFavChargerIcon'></span></Navbar.Text>
                                    </Stack>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewUserProfile}>
                                        <Navbar.Text><span className='userProfileIcon'></span></Navbar.Text>
                                    </Stack>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewLeaveFeedbackModal}>
                                        <Navbar.Text><span className='userFeedbackIcon'></span></Navbar.Text>
                                    </Stack>
                                    <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }}>
                                        <Navbar.Text>
                                            <Link
                                                to="/aboutchargeprob"
                                                style={{ textDecoration: 'none' }} >
                                                <span className='CPIcon'></span>
                                            </Link>
                                        </Navbar.Text>
                                    </Stack>
                                </Stack>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                :
                <Navbar variant="dark" fixed="bottom" className='py-0 map-navbar-lg'>
                    <Container>
                        <Navbar.Collapse className="justify-content-center">
                            <Stack direction='horizontal' gap={5}>
                                <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }} onClick={props.viewLeaveFeedbackModal}>
                                    <span className='userFeedbackIcon'></span>
                                    <Navbar.Text>{content.leaveFeedback}</Navbar.Text>
                                </Stack>
                                <Stack direction='horizontal' gap={2} style={{ cursor: "pointer" }}>
                                    <span className='CPIcon'></span>
                                    <Navbar.Text>
                                        <Link
                                            to="/aboutchargeprob"
                                            style={{ textDecoration: 'none' }}>
                                            {content.aboutChargeProb}
                                        </Link>
                                    </Navbar.Text>
                                </Stack>
                            </Stack>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            }
        </>
    )

}

export default MapFooter;