export class API {

    static submitContactDetails(body) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/chargeprob/contactus/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
    }

    static getGeneralStats() {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/generalstatistics/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(resp => resp.json())
    }

    static addOrRemoveFromFavoriteChargers(body, userProfId, token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/userprofile/favoritechargers/${userProfId}/update/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
    }

    static getEnergyLocationsByZipcode(southWestBound, northEastBound) {
        if (southWestBound) {
            return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/searchbyzipcode/?` + new URLSearchParams({
                'min_lat': southWestBound.lat,
                'min_lng': southWestBound.lng,
                'max_lat': northEastBound.lat,
                'max_lng': northEastBound.lng,
            }),
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(resp => resp.json())
        }
        return null;
    }

    static getEnergyLocationDetail(location_id) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/${location_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(resp => resp.json())
    }

    static postNewIncident(body, token) {
        //const boundary = `----WebKitFormBoundary${new Date().getTime()}-${Math.random().toString().slice(2)}`;
        let headers = {};
        if (typeof token === 'undefined') { 
            headers = {};
        }
        else {
            headers = {
                //'Content-Type': `multipart/form-data; boundary=${boundary}`,
                'Authorization': `Bearer ${token}`
            }
        }
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/reports/create/`, {
            method: 'POST',
            headers: headers,
            body: body
        })
    }

    static postMissingCharger(body) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/evcharger/missing/`, {
            method: 'POST',
            body: body
        })
    }

    static getUserReports(token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/reports/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(resp => resp.json())
    }

    static getUserReportsOnCharger(location_id) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/reports/oncharger/${location_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(resp => resp.json())
    }

    static postNewComment(body, token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/comments/create/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
    }

    static sendUserFeedbackOnOwnReport(reportId, feedback, token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/reports/user_feedback/${reportId}/update/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({feedback})
        })
    }

    static sendUserFeedbackOnReport(chargerId, feedback, token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/reports/user_feedback/charger_status/${chargerId}/update/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({feedback})
        })
    }

    static sendUserFeedbackOnReportAnon(chargerId, feedback) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/reports/user_feedback/charger_status/${chargerId}/update/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({feedback})
        })
    }

    static postFeedback(body) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/feedback/create/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
    }

    static updatePhonenumber(body, userProfId, token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/userprofile/phonenumber/${userProfId}/update/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (response.ok) {
                    return response;
                }
                else return { 'status': 0 };
            })
            .catch((error) => {
                return { 'status': 0 };
            });
    }

    static updateCarType(body, userProfId, token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/userprofile/car/${userProfId}/update/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (response.ok) {
                    return response;
                }
                else return { 'status': 0 };
            })
            .catch((error) => {
                return { 'status': 0 };
            });
    }

    static updateFavoriteLanguage(body, userProfId, token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/userprofile/favoritelanguage/${userProfId}/update/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (response.ok) {
                    return response;
                }
                else return { 'status': 0 };
            })
            .catch((error) => {
                return { 'status': 0 };
            });
    }

    static updateUserNotificationsConsent(body, userProfId, token) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/userprofile/emailconsent/${userProfId}/update/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        }).then((response) => {
            if (response.ok) {
                return response;
            }
            else return { 'status': 0 };
        }).catch((error) => {
            return { 'status': 0 };
        });
    }

    static getMapboxDetails() {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/chargeprob/mapbox/token/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(resp => resp.json())
    }

    static getEOdisruptions() {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/eo/disruptions/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(resp => resp.json())
    }

    static sendNewActivationLink(body) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/activate/newactivationlink/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(resp => resp)
    }

    static sendRestorePasswordEmail(body) {
        return fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/password/restore/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(resp => resp)
    }
}