import React, { useState, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { API } from '../../api-service';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { offcanvasContent } from '../internationalization/Translations';

function ModalProblemSolvedOrNot(props) {

    let { user, userProfile, getUserProfile, authTokens } = useContext(AuthContext);

    const content = props.language === "EN" ? offcanvasContent.English :
        props.language === "NL" ? offcanvasContent.Dutch :
            offcanvasContent.English;

    const [sendingReportFeedback, setSendingReportFeedback] = useState(false);
    const [feedback, setFeedback] = useState(null);

    const confirmReportIsSolvedOrNot = async (charger, feedback) => {
        setFeedback(feedback);
        setSendingReportFeedback(true);
        if (user && userProfile) {
            const resp = await API.sendUserFeedbackOnReport(charger.id, feedback, authTokens.access);
            if (resp.status === 200 || resp.status === 204) {
                props.setFeedbackResult(feedback);
            }
            else if (resp.status === 429) {
                props.setFeedbackResult("too many requests");
            }
            else {
                props.setFeedbackResult("error");
            }
            //refresh user profile
            getUserProfile(authTokens.access);
        }
        else {
            const resp = await API.sendUserFeedbackOnReportAnon(charger.id, feedback);
            if (resp.status === 200 || resp.status === 204) {
                props.setFeedbackResult(feedback);
            }
            else if (resp.status === 429) {
                props.setFeedbackResult("too many requests");
            }
            else {
                props.setFeedbackResult("error");
            }
        }
        props.searchHereClicked();
        props.setFeedbackGiven(true);
        setSendingReportFeedback(false);
        props.closeModalProblemSolvedOrNot();
        props.openModalReportFeedbackSent();
    }

    const confirmReportUnknownStatus = () => {
        setFeedback(null);
        props.setFeedbackGiven(true);
        props.setFeedbackResult(null);
        props.closeModalProblemSolvedOrNot();
    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{content.chargerPopupIsIssueSolvedModalTitle}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <Row>
                    <Col xs={12} md={{ span: '10', offset: '1' }} lg={{ span: '10', offset: '1' }}>
                        {sendingReportFeedback ?
                            <Stack direction="horizontal" gap={3}>
                                {feedback === "solved" ?
                                    <Button className="chargeprob-button-green" style={{fontSize: '0.9rem'}}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                        {content.userReportIsIssueSolvedConfirm}
                                    </Button>
                                    :
                                    <Button size="sm" style={{fontSize: '0.9rem'}} className="chargeprob-button-green">
                                        {content.userReportIsIssueSolvedConfirm}
                                    </Button>
                                }
                                {feedback === "not solved" ?
                                    <Button variant="danger" style={{fontSize: '0.9rem'}}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                        {content.userReportIsIssueSolvedNotConfirm}
                                    </Button>
                                    :
                                    <Button size="sm" style={{fontSize: '0.9rem'}} variant="danger">
                                        {content.userReportIsIssueSolvedNotConfirm}
                                    </Button>
                                }
                                <Button size="sm" style={{fontSize: '0.9rem'}} variant="secondary">
                                    {content.userReportIsIssueSolvedUnknown}
                                </Button>
                            </Stack>
                            :
                            <Stack direction="horizontal" gap={3}>
                                <Button size="sm" className="chargeprob-button-green" style={{fontSize: '0.9rem'}} onClick={() => confirmReportIsSolvedOrNot(props.markerInfo, "solved")}>
                                    {content.userReportIsIssueSolvedConfirm}
                                </Button>
                                <Button size="sm" variant="danger" style={{fontSize: '0.9rem'}} onClick={() => confirmReportIsSolvedOrNot(props.markerInfo, "not solved")}>
                                    {content.userReportIsIssueSolvedNotConfirm}
                                </Button>
                                <Button size="sm" variant="secondary" style={{fontSize: '0.9rem'}} onClick={() => confirmReportUnknownStatus()}>
                                    {content.userReportIsIssueSolvedUnknown}
                                </Button>
                            </Stack>
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    )
}

export default ModalProblemSolvedOrNot;