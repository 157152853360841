import React, { useContext } from 'react';
import MapNavbar from '../components/MapNavbar';
import '../App.css';
import Container from 'react-bootstrap/Container';
import { loginContent } from '../components/internationalization/Translations';
import AuthContext from '../context/AuthContext';

function PasswordResetSuccess() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? loginContent.English :
        language === "NL" ? loginContent.Dutch :
            loginContent.English;

    return (
        <>
            <MapNavbar page="resetpassword_success" />
            <Container fluid className='resetpassword'>
                <br /><br /><br /><br />
                <h2>{content.changePasswordSucccess_p1}</h2>
                <h2>&nbsp; {content.changePasswordSucccess_p2} <a href='/'>ChargeProb</a></h2>
            </Container>
        </>
    )

}

export default PasswordResetSuccess;