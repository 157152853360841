import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { incidentSentContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

function ModalReportImage(props) {

    const content = props.language === "EN" ? incidentSentContent.English :
        props.language === "NL" ? incidentSentContent.Dutch :
            incidentSentContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const address = props.report && props.report.evchargerdetails ? props.report.evchargerdetails[0]["address"]
        : props.charger && props.charger.address ? props.charger.address
            : null;

    const city = props.report && props.report.evchargerdetails ? props.report.evchargerdetails[0]["city"]
        : props.charger && props.charger.city ? props.charger.city
            : null;

    const modalTitle = address ?
        city ?
            address + ", " + city
            : address
        : city ?
            city :
            "";

    const imageFormat = props.report.report_image_extension ?
        props.report.report_image_extension === ".jpg" ? "jpeg"
            : props.report.report_image_extension === ".png" ? "png"
                : "jpeg" : "jpeg";

    const imageSrc = props.report.report_image_base64 ? `data:image/${imageFormat};base64,${props.report.report_image_base64}` : null;

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{modalTitle}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <div>
                    <p style={{ fontSize: '0.7rem' }}>{props.report.report_start_time_str}</p>
                    <p style={{ fontSize: '0.85rem' }}>
                        <FontAwesomeIcon icon={faCircleExclamation} style={{ 'color': '#BF2D32' }} />
                        &nbsp;&nbsp;Problem: &nbsp;
                        {props.report.affected_charger && props.report.affected_charger !== "" && props.report.affected_charger !== "Not sure" ?
                            <span><b>{props.report.report}</b> ({props.report.affected_charger})</span>
                            : <b>{props.report.report}</b>}
                    </p>
                </div>
                <img src={imageSrc} alt="Incident picture" className='report-image-modal' />
            </Modal.Body>
        </Modal>
    )
}

export default ModalReportImage;