import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import '../App.css';
import { Container } from 'react-bootstrap';
import MapNavbar from '../components/MapNavbar';
import { termsAndConditionsContent } from '../components/internationalization/Translations';

function TermsAndConditions(props) {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? termsAndConditionsContent.English :
        language === "NL" ? termsAndConditionsContent.Dutch :
            termsAndConditionsContent.English;


    return (
        <div style={{ backgroundColor: "#f2f2f2" }}>
            <MapNavbar page="termsandconditions" />
            <Container className='mt-4'>
                <br />
                <h4 style={{ textAlign: 'center' }}><b>{content.title}</b></h4>
                <br />
                <p>{content.subtitle_content_p1}</p>
                <p>{content.subtitle_content_p2}</p>
                <p>{content.subtitle_content_p3}</p>
                <br />
                <br />
                <h6><b>{content.cookies_title}</b></h6>
                <br />
                <p>{content.cookies_content_p1}</p>
                <p>{content.cookies_content_p2}</p>
                <p>{content.cookies_content_p3}</p>
                <br />
                <br />
                <h6><b>{content.license_title}</b></h6>
                <br />
                <p>{content.license_content_p1}</p>
                <p>{content.license_content_p2}</p>
                <ul>
                    <li>{content.license_youmaynot_content_l1}</li>
                    <li>{content.license_youmaynot_content_l2}</li>
                    <li>{content.license_youmaynot_content_l3}</li>
                    <li>{content.license_youmaynot_content_l4}</li>
                </ul>

                <p>{content.license_content_p3}</p>
                <p>{content.license_content_p4}</p>
                <p>{content.license_content_p5}</p>
                <p>{content.license_content_p6}</p>
                <ul>
                    <li>{content.license_warrant_content_l1}</li>
                    <li>{content.license_warrant_content_l2}</li>
                    <li>{content.license_warrant_content_l3}</li>
                    <li>{content.license_warrant_content_l4}</li>
                </ul>
                <p>{content.license_content_p7}</p>
                <br />
                
                <h6><b>{content.hyperlinks_title}</b></h6>
                <p>{content.hyperlinks_content_p1}</p>
                <ul>
                    <li>{content.hyperlinks_nopermission_content_l1}</li>
                    <li>{content.hyperlinks_nopermission_content_l2}</li>
                    <li>{content.hyperlinks_nopermission_content_l3}</li>
                    <li>{content.hyperlinks_nopermission_content_l4}</li>
                    <li>{content.hyperlinks_nopermission_content_l5}</li>
                </ul>
                <p>{content.hyperlinks_content_p2}</p>
                <p>{content.hyperlinks_content_p3}</p>
                <ul>
                    <li>{content.hyperlinks_linkrequests_content_l1}</li>
                    <li>{content.hyperlinks_linkrequests_content_l2}</li>
                    <li>{content.hyperlinks_linkrequests_content_l3}</li>
                    <li>{content.hyperlinks_linkrequests_content_l4}</li>
                    <li>{content.hyperlinks_linkrequests_content_l5}</li>
                    <li>{content.hyperlinks_linkrequests_content_l6}</li>
                    <li>{content.hyperlinks_linkrequests_content_l7}</li>
                </ul>
                <p>{content.hyperlinks_content_p4}</p>
                <p>{content.hyperlinks_content_p5}</p>
                <p>{content.hyperlinks_content_p6}</p>
                <p>{content.hyperlinks_content_p7}</p>
                <ul>
                    <li>{content.hyperlinks_contactus_content_l1}</li>
                    <li>{content.hyperlinks_contactus_content_l2}</li>
                    <li>{content.hyperlinks_contactus_content_l3}</li>
                </ul>
                <p>{content.hyperlinks_content_p8}</p>

                <br />
                <h6><b>{content.liability_title}</b></h6>
                <p>{content.liability_content_p1}</p>

                <br />

                <h6><b>{content.rights_title}</b></h6>
                <p>{content.rights_content_p1}</p>

                <br />

                <h6><b>{content.linkremovals_title}</b></h6>
                <p>{content.linkremovals_content_p1}</p>
                <p>{content.linkremovals_content_p2}</p>

                <br />

                <h6><b>{content.disclaimer_title}</b></h6>
                <p>{content.disclaimer_content_p1}</p>
                <ul>
                <li>{content.disclaimer_list_content_l1}</li>
                <li>{content.disclaimer_list_content_l2}</li>
                <li>{content.disclaimer_list_content_l3}</li>
                <li>{content.disclaimer_list_content_l4}</li>
                </ul>
                <p>{content.disclaimer_content_p2}</p>
                <p>{content.disclaimer_content_p3}</p>
                
                <br />
                <br />
                <br />
                <br />

            </Container>
        </div>
    )
}

export default TermsAndConditions;