import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { loginContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { Stack } from 'react-bootstrap';
import { GoogleLoginButton } from "react-social-login-buttons";
import { useGoogleLogin } from '@react-oauth/google';
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
//import FacebookLogin from 'react-facebook-login';


function ModalUserLogin(props) {

    const [googleLoginError, setGoogleLoginError] = useState('');

    const content = props.language === "EN" ? loginContent.English :
        props.language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const googleLoginErrorMsg = content.googleLoginError;

    // const signInWithFacebook = (response) => {
    //     console.log(response);
    // }

    const loginWithGoogleToken = async (token) => {
        setGoogleLoginError("");
        const resp = await props.loginWithGoogleToken(token);
        if (resp && resp === "ERROR") {
            setGoogleLoginError(googleLoginErrorMsg);
        }
    }

    const signInWithGoogle = useGoogleLogin({
        onSuccess: resp => loginWithGoogleToken(resp.access_token),
        onError: error => setGoogleLoginError(googleLoginErrorMsg),
        flow: 'implicit',
    });

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody" >
                <Modal.Title className='w-100 text-center'>
                    {props.parent === "report" ?
                        content.loginReportTitle
                        :
                        content.loginTitle
                    }
                </Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                {props.parent === "report" ?
                    <div>
                        <Row className="justify-content-center">
                            <Col xs={{ span: '9' }}>
                                <div className="d-flex justify-content-center">
                                    <h6><b>{props.incidentTypeTranslation}</b></h6>
                                </div>
                                {props.incidentExtraInfo && props.incidentExtraInfo !== "" ?
                                    <div className="d-flex justify-content-center">
                                        <p style={{ fontSize: '0.7rem' }}><b>{props.incidentExtraInfo}</b></p>
                                    </div>
                                    : <br />}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: '10', offset: '1' }} md={{ span: '8', offset: '2' }}>
                                <Stack direction='horizontal' gap={2}>
                                    <Col xs={{ span: '3' }}>
                                        <div className="d-grid gap-2">
                                            <Button variant="danger" onClick={props.handleClose}>
                                                {buttonscontent.close}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: '9' }}>
                                        <div className="d-grid gap-2">
                                            {/* <Button className='chargeprob-button-green' onClick={() => props.continueWithoutLogin()}>
                                                {buttonscontent.sendReportAnonymous}
                                            </Button> */}
                                            {props.sendIncidentLoading ?
                                                <Button className="chargeprob-button-green">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="sendincidentloading"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="visually-hidden">Loading...</span>
                                                    <b>{buttonscontent.sendingReport}</b>
                                                </Button>
                                                :
                                                <Button className='chargeprob-button-green sendReport-button-glowing' onClick={() => props.postNewIncident(props.markerInfo)}>
                                                    <b>{buttonscontent.sendReportAnonymous}</b>
                                                </Button>
                                            }
                                        </div>
                                    </Col>
                                </Stack>
                            </Col>
                        </Row>
                        <hr className='chargeprob-green' />
                        <br />
                        <Row className="justify-content-center">
                            <Col xs={{ span: '12' }}>
                                <div className="d-flex justify-content-center text-center">
                                    <h5><b>{content.loginToSendReportTitle}</b></h5>
                                </div>
                                <div className="d-flex justify-content-center text-center">
                                    <p style={{ fontSize: '0.8rem' }}>{content.loginToSendReport_p1}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    : null
                }
                <Row>
                    <Col xs={{ span: '10', offset: '1' }} md={{ span: '8', offset: '2' }}>
                        <Form>
                            <Form.Group className="mb-3" controlId="userlogin.ControlInputEmail" >
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    autoFocus
                                    onChange={evt => props.setUserEmail(evt.target.value)}
                                />
                                <p className='red'>{props.userEmailError}</p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="userlogin.ControlInputPassword" >
                                <Form.Control
                                    type="password"
                                    placeholder='password'
                                    onChange={evt => props.setUserPassword(evt.target.value)}
                                />
                                <p className='red'>{props.userLoginError}</p>
                            </Form.Group>
                            <div style={{ 'display': 'flex', 'justifyContent': 'flex-end' }}>
                                <p
                                    onClick={() => props.goToForgotPasswordView()}
                                    className='chargeprob-green'
                                    style={{ 'cursor': 'pointer' }}>
                                    <u>{content.forgotPasswordLink}</u>
                                </p>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Stack direction='vertical' gap={3}>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }}>
                            <div className="d-grid gap-2">
                                <Button className='chargeprob-button-green' onClick={() => props.checkLoginCredentials()}>
                                    {buttonscontent.login}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs='auto'>
                            <p>
                                <b>
                                    {content.goToSignup}
                                    <span className='chargeprob-green clickable'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.goToSignupView()}>
                                        {content.goToSignupLink}
                                    </span>
                                </b>
                            </p>
                        </Col>
                    </Row>
                </Stack>
                {/* {props.parent === "report" ?
                    <Row className="justify-content-center">
                        <Col xs='auto'>
                            <p>
                                <b>
                                    {content.stayAnonymous}
                                    <span className='chargeprob-green clickable'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.continueWithoutLogin()}>
                                        {content.stayAnonymousLink}
                                    </span>
                                </b>
                            </p>
                        </Col>
                    </Row>
                    : null} */}
                <Row>
                    <Col xs={{ span: '8', offset: '2' }} md={{ span: '6', offset: '3' }}>
                        <h6 className='horizontal-line'>{buttonscontent.or}</h6>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: '10', offset: '1' }} md={{ span: '8', offset: '2' }}>
                        <Row>
                            <Col xs={{ span: '10', offset: '1' }} md={{ span: '8', offset: '2' }}>
                                <div className="d-flex justify-content-center">
                                    <GoogleLoginButton
                                        onClick={() => signInWithGoogle()}
                                        iconSize='18px'
                                        style={{ fontSize: "0.9rem" }}>
                                        <span>Sign in with Google</span>
                                    </GoogleLoginButton>
                                </div>
                            </Col>
                            {/* <Col xs={6} md={6}>
                                <div className="d-flex justify-content-center">
                                    <FacebookLogin
                                        appId=""
                                        //autoLoad
                                        //fields="name,email"
                                        callback={signInWithFacebook}
                                        size="small"
                                        icon="fa-facebook"
                                        textButton="Sign in with Facebook"
                                        //cssClass="facebook-login-button-custom"
                                        // render={renderProps => (
                                        //     <FacebookLoginButton
                                        //         //onClick={renderProps.signInWithFacebook}
                                        //         iconSize='18px'
                                        //         style={{ fontSize: "0.7rem" }}>
                                        //         <span>Sign in with<br />Facebook</span>
                                        //     </FacebookLoginButton>

                                        // )} 
                                        /> */}
                            {/* <LoginSocialFacebook
                                        //isOnlyGetToken
                                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                                        fieldsProfile={"first_name"}
                                        //onLoginStart={onLoginStart}
                                        onResolve={(provider, data) => {
                                            console.log(provider);
                                            console.log(data);
                                        }}
                                        onReject={(err) => {
                                            console.log(err)
                                        }}>
                                        <FacebookLoginButton />
                                    </LoginSocialFacebook> 
                                </div>
                            </Col>*/}
                        </Row>
                    </Col>
                </Row>
                <p className='red'>{googleLoginError}</p>
            </Modal.Body>
        </Modal>
    )
}

export default ModalUserLogin;
