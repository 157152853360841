import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import UserFavoriteCharger from './UserFavoriteCharger';
import { offcanvasContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';


function UserFavoriteChargersOffcanvas(props){

    const content = props.language === "EN" ? offcanvasContent.English :
                    props.language === "NL" ? offcanvasContent.Dutch :
                    offcanvasContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
                    props.language === "NL" ? buttonsContent.Dutch :
                    buttonsContent.English;
    
    return(
        <Offcanvas show={props.show} onHide={props.handleClose} scroll={true} backdrop={true} className="offCanvasUserFavoriteChargers chargeprob-background-darkgreen" >
            <Offcanvas.Header className='chargeprob-green chargeprob-background-darkgreen'>
                <Offcanvas.Title>{content.userFavoriteChargersTitle}</Offcanvas.Title>
                <CustomCloseButton handleClose={props.handleClose}/>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <br />
                { props.loadingUserFavChargers ?
                    <p className='chargeprob-green'>{content.userFavoriteChargersLoading}</p>
                : null }
                { props.loadingUserFavChargersError ?
                    <p className='chargeprob-green'>{content.userFavoriteChargerError_p1}<br/><br/>{content.userFavoriteChargerError_p2}</p>
                : null }
                { props.userFavoriteChargers && props.userFavoriteChargers.length === 0 ? 
                    <p className='chargeprob-green'>{content.userFavoriteChargersNoChargers_p1}<br/><br/>{content.userFavoriteChargersNoChargers_p2}</p>
                : null }
                { props.userFavoriteChargers && props.userFavoriteChargers.length > 0 && 
                    props.userFavoriteChargers.map((userFavCharger) => {
                    return (
                        <React.Fragment key={userFavCharger.id}>
                            <UserFavoriteCharger 
                                userFavCharger={userFavCharger} 
                                setBringmethereLocation={props.setBringmethereLocation}
                                showChargerStatusDetails={props.showChargerStatusDetails}
                                showSocketsDetails={props.showSocketsDetails}
                                addOrRemoveFromFavoriteChargers={props.addOrRemoveFromFavoriteChargers}
                                showShareOptions={props.showShareOptions}
                                content={content}
                                buttonscontent={buttonscontent} />
                            <br />
                        </React.Fragment>
                    )
                })}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default UserFavoriteChargersOffcanvas;