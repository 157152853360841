import { useEffect } from "react";
import { GeoSearchControl } from 'leaflet-geosearch';
import { useMap } from 'react-leaflet';
import 'leaflet-geosearch/dist/geosearch.css';


function MapSearchBox(props) {
    const map = useMap();

    async function searchEventHandler(result) {
      map.closePopup();
      await new Promise(r => setTimeout(r, 700));
      map.setZoom(16);
      props.searchOnLocationFound(map);
    }

    map.on('geosearch/showlocation', searchEventHandler);
    // @ts-ignore
    const searchControl = new GeoSearchControl({
      provider: props.provider,
      notFoundMessage: 'Sorry, we can not find this address.',
      autoComplete: true, // optional: true|false  - default true
      autoCompleteDelay: 250, // optional: number  - default 250
      style: 'bar', // optional: bar|button  - default button
      retainZoomLevel: true // optional: true|false  - default false
    });
  
    useEffect(() => {
      map.addControl(searchControl);
      return () => map.removeControl(searchControl);
    }, [props.provider]);
  
    return null;
  }

export default MapSearchBox;