import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { missingChargerContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import { Utils } from '../../utils/utils';

function ModalConfirmMissingCharger(props) {

    const content = props.language === "EN" ? missingChargerContent.English :
        props.language === "NL" ? missingChargerContent.Dutch :
            missingChargerContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [contactEmail, setContactEmail] = useState("");
    const [contactEmailError, setContactEmailError] = useState(null);

    const postMissingCharger = () => {
        setContactEmailError(null);
        if (contactEmail && !Utils.emailValidation(contactEmail)) {
            setContactEmailError(content.contactEmailErrorMsg);
        }
        else {
            props.postMissingCharger(contactEmail);
        }
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody" >
                <Modal.Title className='w-100 text-center'>{content.confirmTitle}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <div>
                    <Row className="justify-content-center" style={{ fontSize: '0.8rem' }}>
                        <Col xs={11}>
                            {props.missingChargerFormData["address"] && props.missingChargerFormData["address"] != "" ?
                                <div>
                                    <p className='mb-0'><b>{content.address}</b></p>
                                    <p>{props.missingChargerFormData["address"]}</p>
                                </div>
                                : null
                            }
                            {props.missingChargerFormData["cpo"] && props.missingChargerFormData["cpo"] != "" ?
                                <div>
                                    <p className='mb-0'><b>{content.cpo}</b></p>
                                    <p>{props.missingChargerFormData["cpo"]}</p>
                                </div>
                                : null
                            }
                            {props.missingChargerFormData["chargerId"] && props.missingChargerFormData["chargerId"] != "" ?
                                <div>
                                    <p className='mb-0'><b>{content.chargerNumber}</b></p>
                                    <p>{props.missingChargerFormData["chargerId"]}</p>
                                </div>
                                : null
                            }
                            {props.missingChargerFormData["extraInfo"] && props.missingChargerFormData["extraInfo"] !== "" ?
                                <div>
                                    <p className='mb-0'><b>{content.extraDetails}</b></p>
                                    <p style={{ fontSize: '0.9rem' }}>{props.missingChargerFormData["extraInfo"]}</p>
                                </div>
                                : null}
                            {props.missingChargerFormData["incidentType"] && props.missingChargerFormData["incidentType"] != "" ?
                                <div>
                                    <p className='mb-0'><b>{content.confirmIssue}</b></p>
                                    <p>{props.missingChargerFormData["incidentTypeTranslation"]}</p>
                                </div>
                                : null
                            }
                            {props.missingChargerFormData["reportImagePreview"] ?
                                <div>
                                    <img src={props.missingChargerFormData["reportImagePreview"]} alt="Picture preview" className='report-image-preview' />
                                </div>
                                : null
                            }
                        </Col>
                    </Row>
                    <Row className="justify-content-center" >
                        <Col xs={11}>
                            <Form>
                                <Form.Group className="mb-3" controlId="missingCharger.ControlInputEmail" >
                                    <Form.Label>
                                        {content.contactEmail}
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                        onChange={evt => setContactEmail(evt.target.value)}
                                    />
                                    <p className='red'>{contactEmailError}</p>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={{ span: '10', offset: '1' }} md={{ span: '8', offset: '2' }}>
                            <Stack direction='horizontal' gap={2}>
                                <Col xs={{ span: '4' }}>
                                    <div className="d-grid gap-2">
                                        <Button variant="secondary" onClick={props.handleBack}>
                                            {buttonscontent.back}
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs={{ span: '8' }}>
                                    {props.postMissingChargerLoading ?
                                        <Button className="chargeprob-button-green w-100">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="sendincidentloading"
                                                aria-hidden="true"
                                            />
                                            <span className="visually-hidden">Submit...</span>
                                            <b>{buttonscontent.submit}...</b>
                                        </Button>
                                        :
                                        <Button className="chargeprob-button-green sendReport-button-glowing w-100" onClick={() => postMissingCharger()}>
                                            <b>{buttonscontent.submit}</b>
                                        </Button>
                                    }
                                </Col>
                            </Stack>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default ModalConfirmMissingCharger;