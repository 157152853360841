import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import MapNavbar from '../components/MapNavbar';

function LatestReports(props) {

    const [latestReports, setLatestReports] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchLatestReports() {
            setLoading(true);
            setError(null);

            const data = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/evchargers/reports/latest/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(resp => resp.json())
                .catch(error => setError(error));

            setLatestReports(data);
            setLoading(false);
        }
        fetchLatestReports();
    }, []);

    const possibleReports = {
        "Charger does not work": "Laadpaal werkt niet",
        "Slow charging": "Laadsnelheid langzaam",
        "Charging does not start": "Laadproces start/stopt niet",
        "Other issue": "Ander probleem"
    };

    return (
        <div>
            <MapNavbar page="latestreports" />
            <Container>
                <br />
                <Row className="justify-content-center">
                    <Col xs={12} md={10}>
                        <h2 className='text-center'>Laatst gemelde laadpaal storingen door ev-rijders</h2>
                    </Col>
                </Row>
                <br />
                {error ?
                    <Row className="justify-content-center">
                        <Col xs={12} md={10}>
                            <h4 className='text-center'>Fout. Probeer het later opnieuw.</h4>
                        </Col>
                    </Row>
                    : loading ?
                        <Row className="justify-content-center">
                            <Col xs={12} md={10}>
                                <Table bordered hover responsive style={{fontSize: '0.75rem'}}>
                                    <thead>
                                        <tr>
                                            <th style={{wordBreak: "break-all"}}>Stad</th>
                                            <th style={{wordBreak: "break-all"}}>Adres</th>
                                            <th style={{wordBreak: "break-all"}}>Postcode</th>
                                            <th style={{wordBreak: "break-all"}}>Gemelde storing</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{fontSize: '0.8rem'}}>
                                        <tr>
                                            <td style={{wordBreak: "break-all"}}>Loading...</td>
                                            <td style={{wordBreak: "break-all"}}>Loading...</td>
                                            <td style={{wordBreak: "break-all"}}>Loading...</td>
                                            <td style={{wordBreak: "break-all"}}>Loading...</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        : latestReports ?
                            <Row className="justify-content-center">
                                <Col xs={12} md={10}>
                                    <Table bordered hover responsive style={{fontSize: '0.75rem'}}>
                                        <thead>
                                            <tr>
                                                <th style={{wordBreak: "break-all"}}>Stad</th>
                                                <th style={{wordBreak: "break-all"}}>Adres</th>
                                                <th style={{wordBreak: "break-all"}}>Postcode</th>
                                                <th style={{wordBreak: "break-all"}}>Gemelde storing</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {latestReports.map((report) => {
                                                return (
                                                    <tr key={report.id}>
                                                        <td key="city" style={{wordBreak: "break-all"}}>{report.evchargerdetails[0].city}</td>
                                                        <td key="address" style={{wordBreak: "break-all"}}>{report.evchargerdetails[0].address}</td>
                                                        <td key="postcode" style={{wordBreak: "break-all"}}>{report.evchargerdetails[0].postal_code}</td>
                                                        <td key="report" style={{wordBreak: "break-all"}}>
                                                            {possibleReports[report.report] ?
                                                                possibleReports[report.report]
                                                                : "Ander probleem"
                                                            }
                                                        </td>
                                                        <td key="gotocp" style={{wordBreak: "break-all"}}>
                                                            <a href={'/' + report.evcharger}>
                                                                <Button size='sm' className="chargeprob-button-green" style={{fontSize: '0.65rem'}}>Bekijk het op ChargeProb!</Button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            : null}
                <br />
                {error ? null
                    : <Row className="justify-content-center">
                        <Col xs={12} md={10} className='text-center'>
                            <h3>Ervaar jij ook een storing met een laadpaal?</h3>
                            <a href='/'>
                                <Button size='lg' className="chargeprob-button-green">Meld hier je storing!</Button>
                            </a>
                        </Col>
                    </Row>
                }
                <br />
                <br />
            </Container>
        </div >
    )
}

export default LatestReports;