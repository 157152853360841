import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Routes, Route, BrowserRouter, HashRouter, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from './context/AuthContext';
import ActivateAccountSuccess from './pages/ActivateAccountSuccess';
import ActivateAccountFail from './pages/ActivateAccountFail';
import ActivateAccountAlreadyActive from './pages/ActivateAccountAlreadyActive';
import ResetPassword from './pages/ResetPassword';
import ErrorPage from './pages/ErrorPage';
import PasswordResetSuccess from './pages/PasswordResetSuccess';
import ERidersHomePage from './pages/ERidersHomePage';
import CPOHomePage from './pages/CPOHomepage';
import AboutChargeProbHome from './pages/AboutChargeProbHome';
import PrivacyStatement from './pages/PrivacyStatement';
import { GoogleOAuthProvider } from '@react-oauth/google';
import TermsAndConditions from './pages/TermsAndConditions';
import VattenfallStoring from './pages/storing_cpo/VattenfallStoring';
import AllegoStoring from './pages/storing_cpo/AllegoStoring';
import FastnedStoring from './pages/storing_cpo/FastnedStoring';
import EcotapStoring from './pages/storing_cpo/EcotapStoring';
import EnecoEMobilityStoring from './pages/storing_cpo/EnecoEMobilityStoring';
import EquansStoring from './pages/storing_cpo/EquansStoring';
import EvnetNLStoring from './pages/storing_cpo/EvnetNLStoring';
import GreenfluxStoring from './pages/storing_cpo/GreenfluxStoring';
import IonityStoring from './pages/storing_cpo/IonityStoring';
import ShellRechargeStoring from './pages/storing_cpo/ShellRechargeStoring';
import CpoStoringHome from './pages/storing_cpo/CpoStoringHome';
import LatestReports from './pages/LatestReports';
//import TestPage from './pages/TestPage';


function Router() {

  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<AuthProvider><App page="map" /></AuthProvider>} />
          <Route path="/aboutchargeprob" element={<AuthProvider><AboutChargeProbHome page="aboutchargeprob" /></AuthProvider>} />
          <Route path="/for-e-driver" element={<AuthProvider><ERidersHomePage page="eriders" /></AuthProvider>} />
          <Route path="/for-cpo" element={<AuthProvider><CPOHomePage page="cpos" /></AuthProvider>} />
          <Route path="/storing/cpo" element={<AuthProvider><CpoStoringHome page="storing-cpo" /></AuthProvider>} />
          <Route path="/storing/cpo/vattenfall" element={<AuthProvider><VattenfallStoring page="storing-vattenfall" /></AuthProvider>} />
          <Route path="/storing/cpo/allego" element={<AuthProvider><AllegoStoring page="storing-allego" /></AuthProvider>} />
          <Route path="/storing/cpo/fastned" element={<AuthProvider><FastnedStoring page="storing-fastned" /></AuthProvider>} />
          <Route path="/storing/cpo/ecotap" element={<AuthProvider><EcotapStoring page="storing-ecotap" /></AuthProvider>} />
          <Route path="/storing/cpo/enecoemobility" element={<AuthProvider><EnecoEMobilityStoring page="storing-enecoemobility" /></AuthProvider>} />
          <Route path="/storing/cpo/equans" element={<AuthProvider><EquansStoring page="storing-equans" /></AuthProvider>} />
          <Route path="/storing/cpo/evnetnl" element={<AuthProvider><EvnetNLStoring page="storing-evnetnl" /></AuthProvider>} />
          <Route path="/storing/cpo/greenflux" element={<AuthProvider><GreenfluxStoring page="storing-greenflux" /></AuthProvider>} />
          <Route path="/storing/cpo/ionity" element={<AuthProvider><IonityStoring page="storing-ionity" /></AuthProvider>} />
          <Route path="/storing/cpo/shellrecharge" element={<AuthProvider><ShellRechargeStoring page="storing-shellrecharge" /></AuthProvider>} />
          <Route path="/privacystatement" element={<AuthProvider><PrivacyStatement page="privacystatement" /></AuthProvider>} />
          <Route path="/termsandconditions" element={<AuthProvider><TermsAndConditions page="termsandconditions" /></AuthProvider>} />
          <Route path="/welcome/success/:str" element={<AuthProvider><ActivateAccountSuccess page="activateaccount_success" /></AuthProvider>} />
          <Route path="/welcome/alreadyactive" element={<AuthProvider><ActivateAccountAlreadyActive page="activateaccount_alreadyactive" /></AuthProvider>} />
          <Route path="/welcome/notactive/:str" element={<AuthProvider><ActivateAccountFail page="activateaccount_fail" /></AuthProvider>} />
          <Route path="/user/password/reset/:uid/:user_token" element={<AuthProvider><ResetPassword page="resetpassword" /></AuthProvider>} />
          <Route path="/user/password/reset/success" element={<AuthProvider><PasswordResetSuccess page="resetpassword_success" /></AuthProvider>} />
          <Route path="/:id" element={<AuthProvider><App page="map" /></AuthProvider>} />
          <Route path="/reports/latestreports" element={<AuthProvider><LatestReports page="latestreports" /></AuthProvider>} />
          {/* <Route path="/test/testpage" element={<TestPage page="testpage" />} /> */}
          <Route path="*" element={<AuthProvider><ErrorPage /></AuthProvider>} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>

  )
}

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  //<React.StrictMode>
  <GoogleOAuthProvider clientId="440968760122-6gld0psbmov5d0gmd7pdin2gflh3buib.apps.googleusercontent.com">
    <Router />
  </GoogleOAuthProvider>
  //</React.StrictMode>,
  //document.getElementById('root')
);