import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { loginContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';


function ModalUserRegister(props) {

    const content = props.language === "EN" ? loginContent.English :
        props.language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{content.registerTitle}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <h6 className='text-center'>{content.registerText}</h6>
                <br />
                <Row>
                    <Col xs={{ span: '10', offset: '1' }} md={{ span: '8', offset: '2' }}>
                        <Form>
                            <Form.Group className="mb-3" controlId="userlogin.ControlInputEmail" >
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    autoFocus
                                    onChange={evt => props.setUserEmail(evt.target.value)}
                                />
                                <p className='red'>{props.userEmailError}</p>
                            </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="userlogin.ControlInputPhoneNumber" >
                        <Form.Control
                            type="tel"
                            placeholder="phone number (e.g.: +31612345678)"
                            onChange={ evt => props.setUserPhoneNumber(evt.target.value)}
                        /> 
                        <p className='red'>{props.userPhoneNumberError}</p>
                    </Form.Group> */}
                            <Form.Group className="mb-0" controlId="userlogin.ControlInputPassword" >
                                <Form.Control
                                    type="password"
                                    placeholder='password'
                                    onChange={evt => props.setUserPassword(evt.target.value)}
                                />
                                <p style={{ 'fontSize': '0.7rem' }}>{content.passwordRequirements}</p>
                                <p className='red'>{props.userPasswordError}</p>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="userlogin.ControlInputRepeatPassword" >
                                <Form.Control
                                    type="password"
                                    placeholder='repeat password'
                                    onChange={evt => props.setUserRepeatPassword(evt.target.value)}
                                />
                                <p className='red'>{props.userRepeatPasswordError}</p>
                                <p className='red'>{props.userRegisterError}</p>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Stack direction='vertical' gap={3}>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }}>
                            <div className="d-grid gap-2">
                                <Button className='chargeprob-button-green' onClick={() => props.checkSignupCredentials()}>
                                    {buttonscontent.register}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs='auto'>
                            <p>
                                <b>
                                    {content.goToLogin}
                                    <span className='chargeprob-green clickable'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.goToLoginView()}>
                                        {content.goToLoginLink}
                                    </span>
                                </b>
                            </p>
                        </Col>
                    </Row>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}

export default ModalUserRegister;

/*
<Button variant="secondary" onClick={props.handleClose}>
    Close
</Button>
*/
