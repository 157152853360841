import React, { useState, useEffect, useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import ModalConfirmUnsubscribe from '../modals/ModalConfirmUnsubscribe';
import { offcanvasContent, buttonsContent, loginContent } from '../internationalization/Translations';
import AuthContext from '../../context/AuthContext';
import { API } from '../../api-service';
import { Utils } from '../../utils/utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faUser, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import ModalUnsubscribedConfirmation from '../modals/ModalUnsubscribedConfirmation';
import UserProfileCarType from './UserProfileCarType';
import UserProfilePhonenumber from './UserProfilePhonenumber';
import UserProfileLanguage from './UserProfileLanguage';
import CustomCloseButton from '../buttons/CustomCloseButton';
import ModalChangePassword from '../modals/ModalChangePassword';
import ModalPasswordChanged from '../modals/ModalPasswordChanged';
import UserProfileNotification from './UserProfileNotification';
import ModalGetNewActivationLink from '../modals/ModalGetNewActivationLink';


function UserProfileOffcanvas(props) {

    let { user, userProfile, getUserProfile, authTokens, unsubscribeUser, changeUserPassword, language } = useContext(AuthContext);
    const [showUserAddPhonenumber, setShowUserAddPhonenumber] = useState(false);
    const [showUserChangePhonenumber, setShowUserChangePhonenumber] = useState(false);
    const [userPhonenumber, setUserPhonenumber] = useState('');
    const [userPhonenumberError, setUserPhonenumberError] = useState('');
    const [userUpdatePhonenumberLoading, setUserUpdatePhonenumberLoading] = useState(false);
    const [userUpdatePhonenumberError, setUserUpdatePhonenumberError] = useState('');
    const [userCarType, setUserCarType] = useState('');
    const [showUserAddCarType, setShowUserAddCarType] = useState(false);
    const [showUserChangeCarType, setShowUserChangeCarType] = useState(false);
    const [userCarTypeError, setUserCarTypeError] = useState('');
    const [userUpdateCarTypeLoading, setUserUpdateCarTypeLoading] = useState(false);
    const [userUpdateCarTypeError, setUserUpdateCarTypeError] = useState('');

    const [showConfirmUnsubscribeModal, setShowConfirmUnsubscribeModal] = useState(false);
    const [showUnsubscribedConfirmationModal, setShowUnsubscribedConfirmationModal] = useState(false);
    const [unsubscribedSuccess, setUnsubscribedSuccess] = useState("");

    const [userOldPassword, setUserOldPassword] = useState('');
    const [userNewPassword, setUserNewPassword] = useState('');
    const [userRepeatPassword, setUserRepeatPassword] = useState('');
    const [userOldPasswordError, setUserOldPasswordError] = useState('');
    const [userNewPasswordError, setUserNewPasswordError] = useState('');
    const [userRepeatPasswordError, setUserRepeatPasswordError] = useState('');
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showPasswordChangedModal, setShowPasswordChangedModal] = useState(false);

    const [showGetNewActivationLinkModal, setShowGetNewActivationLinkModal] = useState(false);

    const content = language === "EN" ? offcanvasContent.English :
        language === "NL" ? offcanvasContent.Dutch :
            offcanvasContent.English;

    const logincontent = language === "EN" ? loginContent.English :
        language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const buttonscontent = language === "EN" ? buttonsContent.English :
        language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const unsubscribe = async () => {
        const resp = await unsubscribeUser();
        if (resp === "OK") {
            setUnsubscribedSuccess("OK");
            goToUnsubscribedConfirmationModal();
        }
        else {
            setUnsubscribedSuccess("ERROR");
            goToUnsubscribedConfirmationModal();
        }
    }

    const checkPhonenumber = (action) => {
        setUserUpdatePhonenumberError('');
        if (action === "delete") {
            setUserPhonenumberError('');
            updatePhonenumber();
        }
        else if (!Utils.phoneNumberValidation(userPhonenumber)) {
            setUserPhonenumberError(content.userProfilePhoneNumberError);
        }
        else {
            setUserPhonenumberError('');
            updatePhonenumber();
        }
    }

    const updatePhonenumber = async () => {
        setUserUpdatePhonenumberLoading(true);
        const phonenumber = userPhonenumber.replace(/\s/g, '');
        const body = {
            'phonenumber': phonenumber
        }

        const resp = await API.updatePhonenumber(body, userProfile.id, authTokens.access);
        if (resp.status === 200) {
            setUserUpdatePhonenumberLoading(false);
            getUserProfile(authTokens.access);
            setShowUserAddPhonenumber(false);
            setShowUserChangePhonenumber(false);
        }
        else {
            setUserUpdatePhonenumberLoading(false);
            setShowUserAddPhonenumber(false);
            setShowUserChangePhonenumber(false);
            setUserUpdatePhonenumberError(content.userProfileUpdatePhonenumberError);
        }
    }

    const updateCarType = async (action) => {
        const car_type = userCarType.trim();
        if (action !== "delete" && car_type.length < 4) {
            setUserCarTypeError(content.userProfileCarTypeError);
        }
        else {
            setUserUpdateCarTypeLoading(true);
            const body = {
                'car_type': car_type
            }

            const resp = await API.updateCarType(body, userProfile.id, authTokens.access);
            if (resp.status === 200) {
                getUserProfile(authTokens.access);
                setUserUpdateCarTypeLoading(false);
                setShowUserAddCarType(false);
                setShowUserChangeCarType(false);
            }
            else {
                setUserUpdateCarTypeError(content.userProfileUpdateCarTypeError);
                setUserUpdateCarTypeLoading(false);
                setShowUserAddCarType(false);
                setShowUserChangeCarType(false);
            }
        }
    }

    const updateFavoriteLanguage = async () => {
        const body = {
            'favorite_language': language
        }

        const resp = await API.updateFavoriteLanguage(body, userProfile.id, authTokens.access);
        if (resp.status === 200) {
            getUserProfile(authTokens.access);
        }
        else {
            //setUserUpdateCarTypeError(content.userProfileUpdateCarTypeError);
        }

    }

    const checkChangePasswordCredentials = async () => {
        setUserNewPasswordError('');
        setUserRepeatPasswordError('');
        setUserOldPasswordError('');
        const pwd_validation = Utils.passwordValidation(userNewPassword, logincontent);
        const repeat_pwd_validation = Utils.repeatPasswordValidation(userNewPassword, userRepeatPassword);
        if (pwd_validation !== "") {
            setUserNewPasswordError(pwd_validation);
        }
        else if (!repeat_pwd_validation) {
            setUserRepeatPasswordError(logincontent.passwordsAreDifferent);
        }
        else {
            const resp = await changeUserPassword({
                'username': user.username,
                'old_password': userOldPassword,
                'new_password': userNewPassword,
                'repeat_password': userRepeatPassword,
            });

            if (resp === "OK") {
                goToPasswordChangedModal();
            }
            else if (resp === "old password is not correct") {
                setUserNewPasswordError(logincontent.changePasswordWrongOldPassword);
            }
            else if (resp === "passwords are different") {
                setUserNewPasswordError(logincontent.changePasswordWrongNewPassword);
            }
            else {
                setUserNewPasswordError(logincontent.changePasswordServerError);
            }
        }
    }

    const updateNotificationConsent = async (new_consent) => {
        const resp = await API.updateUserNotificationsConsent(new_consent, userProfile.id, authTokens.access);
        if (resp.status === 200) {
            return "OK"
        }
        else {
            return "ERROR"
        }
    }

    const goToConfirmUnsubscribeModal = () => {
        setShowUnsubscribedConfirmationModal(false);
        setShowChangePasswordModal(false);
        setShowPasswordChangedModal(false);
        setShowGetNewActivationLinkModal(false);
        setShowConfirmUnsubscribeModal(true);
    }

    const goToUnsubscribedConfirmationModal = () => {
        setShowConfirmUnsubscribeModal(false);
        setShowChangePasswordModal(false);
        setShowPasswordChangedModal(false);
        setShowGetNewActivationLinkModal(false);
        setShowUnsubscribedConfirmationModal(true);
    }

    const goToChangePasswordModal = () => {
        setShowConfirmUnsubscribeModal(false);
        setShowUnsubscribedConfirmationModal(false);
        setShowPasswordChangedModal(false);
        setShowGetNewActivationLinkModal(false);
        setShowChangePasswordModal(true);
    }

    const goToPasswordChangedModal = () => {
        setShowConfirmUnsubscribeModal(false);
        setShowUnsubscribedConfirmationModal(false);
        setShowChangePasswordModal(false);
        setShowGetNewActivationLinkModal(false);
        setShowPasswordChangedModal(true);
    }

    const goTosendNewActivationLinkModal = () => {
        setShowConfirmUnsubscribeModal(false);
        setShowUnsubscribedConfirmationModal(false);
        setShowChangePasswordModal(false);
        setShowPasswordChangedModal(false);
        setShowGetNewActivationLinkModal(true);
    }

    const closeAllModals = () => {
        setShowConfirmUnsubscribeModal(false);
        setShowUnsubscribedConfirmationModal(false);
        setShowChangePasswordModal(false);
        setShowPasswordChangedModal(false);
        setShowGetNewActivationLinkModal(false);
    }

    const showAndResetUserAddPhonenumber = () => {
        setUserPhonenumber("");
        setShowUserAddPhonenumber(true);
        setUserPhonenumberError("");
        setUserUpdatePhonenumberError("");
    }

    const showAndResetUserChangePhonenumber = () => {
        setUserPhonenumber("");
        setShowUserChangePhonenumber(true);
        setUserPhonenumberError("");
        setUserUpdatePhonenumberError("");
    }

    const showAndResetUserAddCarType = () => {
        setUserCarType("");
        setShowUserAddCarType(true);
        setUserCarTypeError("");
        setUserUpdateCarTypeError("");
    }

    const showAndResetUserChangeCarType = () => {
        setUserCarType("");
        setShowUserChangeCarType(true);
        setUserCarTypeError("");
        setUserUpdateCarTypeError("");
    }

    return (
        <>
            {user && user.username ?
                <Offcanvas show={props.show} onHide={props.handleClose} scroll={true} backdrop={true} className="offCanvasUserProfile chargeprob-background-darkgreen" >
                    <Offcanvas.Header className='chargeprob-green chargeprob-background-darkgreen'>
                        <Offcanvas.Title>{content.userProfileTitle}</Offcanvas.Title>
                        <CustomCloseButton handleClose={props.handleClose} />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <h6 className='chargeprob-green'><FontAwesomeIcon icon={faUser} size="lg" /> {content.userProfileLoggedInAs}</h6>
                        <p>{user.username}</p>
                        <Stack direction="horizontal" gap={1}>
                            <Button
                                variant="outline-danger" size="sm" className='offcanvasButtons' onClick={() => props.logoutUser()}>
                                {buttonscontent.logout}
                            </Button>
                            {userProfile && !userProfile.is_social_login ?
                                <Button
                                    variant="outline-warning" size="sm" className='offcanvasButtons' onClick={() => goToChangePasswordModal()}>
                                    {buttonscontent.userChangePassword}
                                </Button>
                                : null}
                        </Stack>
                        <br />
                        <br />
                        <UserProfilePhonenumber
                            setUserPhonenumber={setUserPhonenumber}
                            checkPhonenumber={checkPhonenumber}
                            userPhonenumberError={userPhonenumberError}
                            userUpdatePhonenumberLoading={userUpdatePhonenumberLoading}
                            userUpdatePhonenumberError={userUpdatePhonenumberError}
                            showUserAddPhonenumber={showUserAddPhonenumber}
                            setShowUserAddPhonenumber={setShowUserAddPhonenumber}
                            showAndResetUserAddPhonenumber={showAndResetUserAddPhonenumber}
                            showUserChangePhonenumber={showUserChangePhonenumber}
                            setShowUserChangePhonenumber={setShowUserChangePhonenumber}
                            showAndResetUserChangePhonenumber={showAndResetUserChangePhonenumber}
                            content={content}
                            buttonscontent={buttonscontent}
                        />
                        <br />
                        <UserProfileCarType
                            setUserCarType={setUserCarType}
                            updateCarType={updateCarType}
                            userCarTypeError={userCarTypeError}
                            userUpdateCarTypeLoading={userUpdateCarTypeLoading}
                            userUpdateCarTypeError={userUpdateCarTypeError}
                            showUserAddCarType={showUserAddCarType}
                            setShowUserAddCarType={setShowUserAddCarType}
                            showAndResetUserAddCarType={showAndResetUserAddCarType}
                            showUserChangeCarType={showUserChangeCarType}
                            setShowUserChangeCarType={setShowUserChangeCarType}
                            showAndResetUserChangeCarType={showAndResetUserChangeCarType}
                            content={content}
                            buttonscontent={buttonscontent}
                        />
                        <br />
                        <UserProfileLanguage
                            content={content}
                            updateFavoriteLanguage={updateFavoriteLanguage}
                        />
                        <br /><br />
                        <UserProfileNotification
                            content={content}
                            buttonscontent={buttonscontent}
                            goTosendNewActivationLinkModal={goTosendNewActivationLinkModal}
                            updateNotificationConsent={updateNotificationConsent}
                        />
                        <hr />
                        {userProfile && userProfile.tot_reports ?
                            <h6><FontAwesomeIcon icon={faCircleExclamation} style={{ 'color': '#BF2D32' }} /> {userProfile.tot_reports} {content.userProfileTotReports}</h6>
                            :
                            <h6><FontAwesomeIcon icon={faCircleExclamation} style={{ 'color': '#BF2D32' }} /> 0 {content.userProfileTotReports}</h6>
                        }
                        {userProfile && userProfile.favoritechargers ?
                            <h6><FontAwesomeIcon icon={faHeart} style={{ 'color': '#BF2D32' }} /> {userProfile.favoritechargers.length} {content.userProfileTotFavChargers}</h6>
                            :
                            <h6><FontAwesomeIcon icon={faHeart} style={{ 'color': '#BF2D32' }} /> 0 {content.userProfileTotFavChargers}</h6>
                        }
                        <br />
                        <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                            <Button
                                variant="outline-info"
                                onClick={() => goToConfirmUnsubscribeModal()}>
                                {buttonscontent.unsubscribe}
                            </Button>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                : null}
            <ModalConfirmUnsubscribe
                show={showConfirmUnsubscribeModal}
                handleClose={closeAllModals}
                unsubscribe={unsubscribe}
                content={content}
                buttonscontent={buttonscontent}
            />
            <ModalUnsubscribedConfirmation
                show={showUnsubscribedConfirmationModal}
                handleClose={closeAllModals}
                unsubscribedSuccess={unsubscribedSuccess}
                content={content}
                buttonscontent={buttonscontent}
            />
            <ModalChangePassword
                show={showChangePasswordModal}
                handleClose={closeAllModals}
                language={language}
                setUserOldPassword={setUserOldPassword}
                setUserNewPassword={setUserNewPassword}
                setUserRepeatPassword={setUserRepeatPassword}
                userOldPasswordError={userOldPasswordError}
                userNewPasswordError={userNewPasswordError}
                userRepeatPasswordError={userRepeatPasswordError}
                checkChangePasswordCredentials={checkChangePasswordCredentials}
            />
            <ModalPasswordChanged
                show={showPasswordChangedModal}
                handleClose={closeAllModals}
                language={language}
            />
            <ModalGetNewActivationLink
                show={showGetNewActivationLinkModal}
                handleClose={closeAllModals}
                language={language}
            />
        </>
    )
}

export default UserProfileOffcanvas;