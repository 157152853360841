import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { shareOnSocialsContent } from '../internationalization/Translations';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon, LinkedinShareButton, EmailShareButton, EmailIcon, LinkedinIcon } from 'react-share';


function ModalShareOnSocials(props) {

    const content = props.language === "EN" ? shareOnSocialsContent.English :
        props.language === "NL" ? shareOnSocialsContent.Dutch :
            shareOnSocialsContent.English;

    const charger_id = props.selectedCharger ? props.selectedCharger.id : '';
    const chargeprob_url = `https://www.chargeprob.com/${charger_id}`;

    return (
        <Modal onClose={props.closeShareOptionsModal} show={props.showShareOptionsModal} centered size="sm">
            <Modal.Header closeButton={false}>
                <Modal.Title style={{ fontSize: '0.9rem' }} className="mx-auto">
                    <b>{content.title}</b>
                </Modal.Title>
                <CustomCloseButton handleClose={props.closeShareOptionsModal} />
            </Modal.Header>
            <Modal.Body>
                <Stack direction='horizontal' gap={3} className='justify-content-center'>
                    <div>
                        <FacebookShareButton
                            url={chargeprob_url}
                            quote={content.facebookText}
                            hashtag="#chargeprob #laadpaal" >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                    </div>
                    <div>
                        <TwitterShareButton
                            // url={`https://twitter.com/intent/tweet?text=${encodeURIComponent(content.twitterTitle)}&url=${encodeURIComponent(chargeprob_url)}`}
                            title={content.twitterTitle}
                            url={chargeprob_url}
                            hashtags={["chargeprob", "laadpaal"]} >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                    </div>
                    <div>
                        <WhatsappShareButton
                            title={content.whatsappTitle}
                            url={chargeprob_url} >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </div>
                    <div>
                        <TelegramShareButton
                            title={content.telegramTitle}
                            url={chargeprob_url} >
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </div>
                    <div>
                        <LinkedinShareButton
                            title={content.linkedinTitle}
                            summary={content.linkedinSummary}
                            url={chargeprob_url} >
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                    </div>
                    <div>
                        <EmailShareButton
                            subject={content.emailSubject}
                            body={content.emailBody}
                            url={chargeprob_url} >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </div>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}

export default ModalShareOnSocials;