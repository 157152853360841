import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from '@fortawesome/free-solid-svg-icons';

function ChargerOperStatusInfo(props) {

    const oper_status_color = props.selectedCharger ?
        props.selectedCharger.status === "Available" || props.selectedCharger.status === "Partially available" ? "#1dbf73" :
            props.selectedCharger.status === "Unavailable" ? "#BF2D32" :
                props.selectedCharger.status === "Warning" ? "#FFAE00" :
                    "white" : "white";

    return (
        <div>
            {/* <h5 className='white-text'>Operational Status:
                <span style={{'color': oper_status_color, 'fontSize': '1.5rem'}}> {props.selectedCharger.status}</span>
            </h5> */}
            <h5 className='white-text text-center'>
                <span style={{ 'color': oper_status_color, 'fontSize': '1.5rem' }}> {props.selectedCharger.status}</span>
            </h5>
            <br />
            <div className='text-center white-text'>
                {props.selectedCharger.status === "Unavailable" ?
                    <h6>{props.content.chargerStatusUnavailableText}</h6>
                    : props.selectedCharger.status === "Warning" ?
                        <h6>{props.content.chargerStatusWarningText}</h6>
                        : <h5 className='white-text text-center'>{props.content.noReportsToShow}</h5> 
                }
                <br />
            </div>
            {props.selectedCharger.status === "Unavailable" || props.selectedCharger.status === "Warning" ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <Card border="dark" className="offcanvas-cards text-center">
                        <Card.Header as="h5">
                            {props.content.userReportsUpdateFromOwner}
                        </Card.Header>
                        <Card.Body>
                            {props.selectedCharger.status_notes && props.selectedCharger.status_notes !== "" ?
                                <Card.Text>
                                    <FontAwesomeIcon icon={faPen} /> &nbsp;&nbsp;
                                    <b>{props.selectedCharger.status_notes}</b>
                                </Card.Text>
                                :
                                <Card.Text>
                                    <FontAwesomeIcon icon={faPen} /> &nbsp;&nbsp;
                                    {props.content.userReportsNoUpdateFromOwner}
                                </Card.Text>
                            }
                        </Card.Body>
                    </Card> */}
                    {props.selectedCharger.status_notes && props.selectedCharger.status_notes !== "" ?
                        <div className='text-center white-text'>
                            <br />
                            <h6><FontAwesomeIcon icon={faPen} /> &nbsp;&nbsp; {props.content.userReportsUpdateFromOwner}</h6>
                            <br />
                            <h6><b>{props.selectedCharger.status_notes}</b></h6>
                        </div>
                        :
                        <div className='text-center white-text'>
                            <br />
                            <h6><FontAwesomeIcon icon={faPen} /> &nbsp;&nbsp; {props.content.userReportsNoUpdateFromOwner}</h6>
                        </div>
                    }
                </div>
                : null}
        </div>
    )

}

export default ChargerOperStatusInfo;