import React from 'react';
import { chargerSocketsContent } from '../internationalization/Translations';
import { Stack, Button } from 'react-bootstrap';


function ChargerEvses(props) {

    const content = props.language === "EN" ? chargerSocketsContent.English :
        props.language === "NL" ? chargerSocketsContent.Dutch :
            chargerSocketsContent.English

    const markerInfo = props.markerInfo ? props.markerInfo : null;
    const totSockets = props.markerInfo ? props.markerInfo.totevses ? props.markerInfo.totevses : 0 : 0;
    const socketText = totSockets === 0 ? "Unknown" : totSockets === 1 ? content.socket : content.sockets;

    const totAvailableSockets = props.markerInfo ? props.markerInfo.tot_available_evses ? props.markerInfo.tot_available_evses : 0 : 0;
    const totAvailableSocketsText = totAvailableSockets === 0 ? content.notAvailableNow : content.availableNow;

    return (
        <div className='popup-element-div popup-element-div-bordered popup-element-div-white' 
            onClick={() => props.showChargerEvsesStatusDetails(markerInfo)}>
            <Stack direction='horizontal' gap={3}>
                <div><span className='socketIcon-white' ></span></div>
                <Stack direction='vertical' gap={1}>
                    <div><b>{totSockets} {socketText}</b></div>
                    <div>
                        {totAvailableSockets} {totAvailableSocketsText}
                    </div>
                </Stack>
                <div><span className='rightArrowWhiteIcon' ></span></div>
            </Stack>
        </div>
    )

}

export default ChargerEvses;
