import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMapEvents, useMap } from 'react-leaflet';
//import L from 'leaflet';
import MapSearchBox from "./map_components/MapSearchBox";
import UserLocation from "./map_components/UserLocation";
import BringMeThere from "./map_components/BringMeThere";
import { mapContent } from "./internationalization/Translations";
import UserLocationMarker from "./map_components/UserLocationMarker";
import 'leaflet-geosearch/dist/geosearch.css';
import '../App.css';
import ChargerMarker from "./markers/ChargerMarker";
import { Stack } from "react-bootstrap";


function MapEvents(props) {
  const map = useMap();

  useEffect(() => {
    props.setMap(map);
  }, []);

  useEffect(() => {
    map.setZoom(props.mapZoom);
  }, [props.mapZoom]);

  useMapEvents({
    click: (e) => {
      //const { lat, lng } = e.latlng;
      //L.marker([lat, lng], { icon }).addTo(map);
      props.setShowSearchHere(true);
    },
    move: (e) => {
      props.setShowSearchHere(false);
    },
    moveend: (e) => {
      //map.closePopup();
      props.setShowSearchHere(true);
    },
    zoomend: (e) => {
      props.setMapZoom(map.getZoom());
    }
  });

  // useEffect( () => {
  //   const legend = L.control({ position: "bottomright" });
  //   const legendContent = props.legendContent.legendContent;

  //   legend.onAdd = () => {
  //     const div = L.DomUtil.create("div", "info legend");
  //     div.innerHTML = legendContent;
  //     return div;
  //   };

  //   legend.addTo(map);

  // }, [map])

  return null;
}

/* function AttributionControl(props){
  const map = useMap();

  const [show, setShow] = useState(false);
  const showAttribution = () => {
    setShow(true);
  }

  //map.attributionControl.addAttribution('Licensed by &copy; <a href="some_link", class="your_class">yoyoyoyoyo</a>');
  const attribution = <div>&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a></div>;

  return (
    const newAttributionControl = <div className="leaflet-bottom leaflet-right">
      {show ?
        <div className="leaflet-control">{attribution}</div>
      :
        <div onClick={showAttribution}>Yo0000000000000000000</div>
      }
    </div>
  )

  //map.attributionControl.addAttribution(newAttributionControl);
  //return null;
} */

function LocateMeControl(props) {
  const map = useMap();

  useEffect(() => {
    if (props.userLocationActive && props.loadUserLocation) {
      let currentZoom = map.getZoom();
      navigator.geolocation.getCurrentPosition(function (position) {
        map.setView([position.coords.latitude, position.coords.longitude], Math.max(currentZoom, 16));
        props.searchOnLocationFound(map);
      });
    }
  }, [props.userLocationActive]);

  const locationClicked = () => {
    if (props.userLatLng && props.userLocationActive) {
      let currentZoom = map.getZoom();
      map.flyTo(props.userLatLng, Math.max(currentZoom, 16));
    }
    else {
      props.setShowNoLocationAccessAlert(true);
    }
  }

  const locatemebutton = <button className="locatemeIcon" onClick={locationClicked}></button>;

  return (
    <div className="leaflet-top leaflet-left">
      <div className="leaflet-control locateme">{locatemebutton}</div>
    </div>
  )
}

function LegendControl(props) {

  return (
    <div className="leaflet-bottom leaflet-right">
      <div className="leaflet-control">
        <div className="legend mb-2">
        <div style={{ 'cursor': 'pointer' }} onClick={() => props.openMissingChargerModal()}>
            <b>{props.legendContent.legendMissingCharger}</b>
          </div>
        {/* <Stack direction="horizontal" gap={1} style={{ 'cursor': 'pointer' }} onClick={() => props.openMissingChargerModal()}>
            <b>{props.legendContent.legendMissingCharger}</b>
            <span className="notfoundIcon-xs" ></span>
          </Stack> */}
        </div>
        <div className="legend mb-4">
          <Stack direction="vertical" gap={1}>
            <Stack direction="horizontal" gap={2}>
              <b>{props.legendContent.legendContentTitle}</b>
              <span className="infoIcon-xs" style={{ 'cursor': 'pointer' }} onClick={() => props.openLegendInfoModal()}></span>
            </Stack>
            {/* <li className="legendAvailable"><b> {props.legendContent.legendContentAvailable} </b></li>
          <li className="legendWarning"><b> {props.legendContent.legendContentWarning} </b></li>
          <li className="legendUnavailable"><b> {props.legendContent.legendContentUnavailable} </b></li>
          <li className="legendUnknown"><b> {props.legendContent.legendContentUnknown} </b></li> */}
            <Stack direction="vertical" gap={1}>
              <Stack direction="horizontal" gap={2}>
                <div><span className="legendAvailable"></span></div>
                <div><b>{props.legendContent.legendContentAvailable}</b></div>
              </Stack>
              <Stack direction="horizontal" gap={2}>
                <div><span className="legendHalfAvailable"></span></div>
                <Stack direction="vertical" gap={1}>
                  <div><b>{props.legendContent.legendContentHalfAvailable_1}</b></div>
                  <div><b>{props.legendContent.legendContentHalfAvailable_2}</b></div>
                </Stack>
              </Stack>
              <Stack direction="horizontal" gap={2}>
                <div><span className="legendWarning"></span></div>
                <div><b>{props.legendContent.legendContentWarning}</b></div>
              </Stack>
              <Stack direction="horizontal" gap={2}>
                <div><span className="legendUnavailable"></span></div>
                <div><b>{props.legendContent.legendContentUnavailable}</b></div>
              </Stack>
              <Stack direction="horizontal" gap={2}>
                <div><span className="legendUnknown"></span></div>
                <div><b>{props.legendContent.legendContentUnknown}</b></div>
              </Stack>
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  )
}

function Map(props) {
  const legendContent = props.language === "EN" ? mapContent.English :
    props.language === "NL" ? mapContent.Dutch :
      mapContent.English;

  const [userLocationActive, setUserLocationActive] = useState(true);
  const [userLatLng, setUserLatLng] = useState(null);

  return (
    <>
      <MapContainer
        style={{ width: "100%" }}
        center={[props.latitude, props.longitude]} zoom={props.mapZoom} zoomControl={false}>
        {process.env.REACT_APP_MAP_PROVIDER === 'mapbox' ?
          props.mapboxToken ?
            <TileLayer
              url={"https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=" + props.mapboxToken}
              tileSize={512}
              zoomOffset={-1}
              attribution='© <a href="https://www.mapbox.com/contribute/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
              maxZoom={20}
            />
            :
            null
          :
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            subdomains="abcd"
            maxZoom={20}
          />
        }
        {props.showSearch && props.mapSearchBoxProvider ?
          <MapSearchBox
            provider={props.mapSearchBoxProvider}
            searchOnLocationFound={props.searchOnLocationFound} />
          : null}
        {props.markers && props.markers.map((markerInfo) => {
          return (
            <ChargerMarker
              key={"energy-marker-" + markerInfo.id}
              markerInfo={markerInfo}
              mapZoom={props.mapZoom}
              showChargerStatusDetails={props.showChargerStatusDetails}
              showChargerEvsesStatusDetails={props.showChargerEvsesStatusDetails}
              showChargerComments={props.showChargerComments}
              setShowSearchHere={props.setShowSearchHere}
              popupIsOpen={props.popupIsOpen}
              setPopupIsOpen={props.setPopupIsOpen}
              showShowSearch={props.showShowSearch}
              hideShowSearch={props.hideShowSearch}
              setShowLoginToast={props.setShowLoginToast}
              setShowSignupToast={props.setShowSignupToast}
              language={props.language}
              addOrRemoveFromFavoriteChargers={props.addOrRemoveFromFavoriteChargers}
              searchHereClicked={props.searchHereClicked}
              openUserLoginModal={props.openUserLoginModal}
              showShareOptions={props.showShareOptions}
            />)
        })}
        <LocateMeControl
          userLatLng={userLatLng}
          loadUserLocation={props.loadUserLocation}
          userLocationActive={userLocationActive}
          setShowNoLocationAccessAlert={props.setShowNoLocationAccessAlert}
          searchOnLocationFound={props.searchOnLocationFound} />
        <LegendControl
          userLatLng={userLatLng}
          userLocationActive={userLocationActive}
          setShowNoLocationAccessAlert={props.setShowNoLocationAccessAlert}
          legendContent={legendContent}
          openLegendInfoModal={props.openLegendInfoModal}
          openMissingChargerModal={props.openMissingChargerModal} />
        <UserLocation
          setUserLatLng={setUserLatLng}
          setLoadingUserLocation={props.setLoadingUserLocation}
          setUserLocationActive={setUserLocationActive}
          setUserLocationError={props.setUserLocationError}
        />
        {userLocationActive && userLatLng ? <UserLocationMarker userLatLng={userLatLng} /> : null}
        <MapEvents
          setMap={props.setMap}
          setShowSearchHere={props.setShowSearchHere}
          mapZoom={props.mapZoom}
          setMapZoom={props.setMapZoom}
          legendContent={legendContent} />
        <BringMeThere
          bringmethereLocation={props.bringmethereLocation}
          goToLocation={props.goToLocation} />
      </MapContainer>
    </>
  );
}

export default Map;
