import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { loginContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';

function ModalPasswordChanged(props) {

    const content = props.language === "EN" ? loginContent.English :
        props.language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{content.changePasswordSuccessTitle}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <p>{content.changePasswordSuccess_p1}</p>
                <Row>
                    <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                        <Button variant="secondary" onClick={props.handleClose}>
                            {buttonscontent.close}
                        </Button>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPasswordChanged;