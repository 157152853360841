import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { loginContent, accountActivationContent, buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { Utils } from '../../utils/utils';
import { API } from '../../api-service';

function ModalGetNewActivationLink(props) {

    const content = props.language === "EN" ? loginContent.English :
        props.language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const activatecontent = props.language === "EN" ? accountActivationContent.English :
        props.language === "NL" ? accountActivationContent.Dutch :
            accountActivationContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [userEmail, setUserEmail] = useState(false);
    const [userEmailError, setUserEmailError] = useState('');
    const [linkSent, setLinkSent] = useState(false);
    const [linkSentError, setLinkSentError] = useState(false);
    const [sendingLink, setSendingLink] = useState(false);

    const sendNewActivationLink = async () => {
        setUserEmailError('');
        if (!Utils.emailValidation(userEmail)) {
            setUserEmailError(activatecontent.activateFail_emailError);
        }
        else {
            setSendingLink(true);
            setLinkSent(false);
            setLinkSentError(false);
            const resp = await API.sendNewActivationLink({ 'email': userEmail });
            if (resp.status === 200) {
                setLinkSent(true);
                setLinkSentError(false);
            } else {
                setLinkSent(true);
                setLinkSentError(true);
            }
            setSendingLink(false);
        }
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                {linkSent ?
                    linkSentError ?
                        <Modal.Title className='w-100 text-center'>{activatecontent.activateFail_newLinkSentTitle_error}</Modal.Title>
                        :
                        <Modal.Title className='w-100 text-center'>{activatecontent.activateSuccess_newLinkSentTitle}</Modal.Title>
                    :
                    <Modal.Title className='w-100 text-center'>{activatecontent.activateSuccess_sendNewLinkTitle}</Modal.Title>
                }
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            {linkSent ?
                linkSentError ?
                    <Modal.Body className="modalsBody">
                        <p>{activatecontent.activateFail_newLinkSent_error_p1}</p>
                        <p>{activatecontent.activateFail_newLinkSent_error_p2}</p>
                        <Row>
                            <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                <Button variant="success" onClick={props.handleClose}>
                                    {buttonscontent.close}
                                </Button>
                            </div>
                        </Row>
                    </Modal.Body>
                    :
                    <Modal.Body className="modalsBody">
                        <p>{activatecontent.activateSuccess_newLinkSent_p2}</p>
                        <p>{activatecontent.activateSuccess_newLinkSent_p3}</p>
                        <Row>
                            <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                <Button variant="success" onClick={props.handleClose}>
                                    {buttonscontent.close}
                                </Button>
                            </div>
                        </Row>
                    </Modal.Body>
                :
                <Modal.Body className="modalsBody">
                    <Form>
                        <Form.Group className="mb-3" controlId="sendactivationlink.ControlInputEmail" >
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                style={{ 'color': 'black' }}
                                autoFocus
                                onChange={evt => setUserEmail(evt.target.value)}
                            />
                            <p className='red' style={{ fontSize: '1rem' }}><b>{userEmailError}</b></p>
                        </Form.Group>
                    </Form>
                    {sendingLink ?
                        <Row>
                            <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                <Button variant="success">
                                    {buttonscontent.activateFail_sendingNewLink_btn}
                                </Button>
                            </div>
                        </Row>
                        :
                        <Row>
                            <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                <Stack direction='horizontal' gap={2}>
                                    <Button variant="secondary" onClick={props.handleClose}>
                                        {buttonscontent.close}
                                    </Button>
                                    <Button
                                        variant='success'
                                        onClick={() => sendNewActivationLink()}>
                                        {buttonscontent.sendActivationLink}
                                    </Button>
                                </Stack>
                            </div>
                        </Row>
                    }
                </Modal.Body>
            }
        </Modal>
    )
}

export default ModalGetNewActivationLink;
