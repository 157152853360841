export const cookiesConsentContent = {
  English: {
    consentText: "We use cookies to enhance the user experience.",
    consentButtonText: "OK",
  },
  Dutch: {
    consentText: "We gebruiken cookies om de gebruikerservaring te verbeteren.",
    consentButtonText: "OK",
  }
};

export const accountActivationContent = {
  English: {
    activateSuccess_p1: "Your account has been successfully activated!",
    activateSuccess_p2: "You can now login in ",
    activateAlreadyActive_p1: "Your account has already been confirmed.",
    activateAlreadyActive_p2: "You can login in ",
    activateSuccess_sendNewLinkTitle: "Send new activation link",
    activateSuccess_newLinkSentTitle: "Thank you!",
    activateSuccess_newLinkSent_p1: "Thank you!",
    activateSuccess_newLinkSent_p2: "If the email you entered is registered at ChargeProb, you will receive a new confirmation email shortly.",
    activateSuccess_newLinkSent_p3: "Please follow the instructions in the email to activate your account.",
    activateFail_p1: "Sorry, your activation link is not valid anymore.",
    activateFail_p2: "Enter your email and click on the button to receive a new link.",
    activateFail_emailError: "Please enter a valid email.",
    activateFail_newLinkSentTitle_error: "Something did not work",
    activateFail_newLinkSent_error_p1: "Sorry, we are having troubles processing your request.",
    activateFail_newLinkSent_error_p2: "Please try again later.",
  },
  Dutch: {
    activateSuccess_p1: "Je account is succesvol geactiveerd!",
    activateSuccess_p2: "Je kunt nu inloggen in ",
    activateAlreadyActive_p1: "Uw account is al bevestigd.",
    activateAlreadyActive_p2: "Je kunt inloggen in ",
    activateFail_p1: "Sorry, je activatielink is niet meer geldig.",
    activateFail_p2: "Voer je e-mailadres in en klik op de knop om een nieuwe link te ontvangen.",
    activateSuccess_sendNewLinkTitle: "Stuur een nieuwe activeringslink!",
    activateSuccess_newLinkSentTitle: "Thanks!",
    activateSuccess_newLinkSent_p1: "Thanks!",
    activateSuccess_newLinkSent_p2: "Als je e-mailadres is geregistreerd bij ChargeProb, ontvangt je binnenkort een nieuwe bevestigingsmail.",
    activateSuccess_newLinkSent_p3: "Volg de instructies in de e-mail om je account te activeren.",
    activateFail_emailError: "Gelieve een geldig e-mailadres in te vullen.",
    activateFail_newLinkSentTitle_error: "Iets werkte niet",
    activateFail_newLinkSent_error_p1: "Sorry, we hebben problemen met het verwerken van je verzoek.",
    activateFail_newLinkSent_error_p2: "Probeer het later opnieuw a.u.b.",
  }
};

export const errorPageContent = {
  English: {
    wrongPage: "Wrong page!",
    goBackToCP: "Go back to ",
  },
  Dutch: {
    wrongPage: "Verkeerde pagina!",
    goBackToCP: "Terug naar ",
  }
};

export const homePageContent = {
  English: {
    moreInfo: "More information",
    learnMore: "Learn more",
    submit: "Submit",
    contact: "Contact",
    incidentOnCharger: "Incident on a public charging station?",
    contactText: "We make transparent the most frustrating problems for an e-driver!",
    incident: "Incident",
    forEDrivers: "For e-drivers",
    forEDriversImgText: "Incident on a public charging station? Easily check or report your incident with ChargeProb!",
    forCPOs: "For Charge Point operators",
    forCPOsImgText: "Too many calls about incidents with charging stations? Digitalize your incident process with ChargeProb!",
    readMore: "Read more",
    aboutUs: "About us",
    aboutUs_text: "The idea for ChargeProb was born out of personal frustration with faulty ev charging stations. Our experience within the telecom sector has made it possible to develop this national incident management platform. Want to know more?",
    unavailableChargersOverview: "Charging stations technically unavailable",
    problemsReportedOverview: "Incidents reported by e-drivers",
    updatesFromCPOOverview: "Updates from charging stations operators",
    availableChargersOverview: "Technically available charging stations",
    warningChargersOverview: "Tot chargers 'warning'",
    chargingChargersOverview: "Tot chargers 'charging'",
    blockedChargersOverview: "Tot chrgers 'blocked'",
    reportIncident: "Report an incident",
    reportIncidentHere: "Report incident here",
    reportIncidentEasy: "Report an incident easily",
    reportIncidentEasy_text_1: "Reporting an incident with a public charging station is very easy with ",
    reportIncidentEasy_text_2: " The reported incident is directly visible for other e-drivers. And we keep you updated on the progress of the incident!",
    reportIncidentEasy_chargerStatus: "Charging station status",
    reportIncidentEasy_chargerStatusCheckStatus: "Check the status of the charging station. If an incident has already been reported, it will be immediately visible!",
    reportIncidentEasy_chargerStatusStayUpdated: "Stay updated",
    reportIncidentEasy_chargerStatusStayUpdated_text: "As soon as we have any new information, we will keep you updated!",
    reportIncidentEasy_chargerStatusReportProblem_text: "Is there no incident reported yet? Then report it quickly and easily! By doing this, you will also help other e-drivers.",
    chargerPossibileStatuses: "Which statuses can a charging station have?",
    chargerPossibileStatuses_available: "Available",
    chargerPossibileStatuses_available_text: "The charging station is currently working without problems, we see normal activity. There is no incident reported by the charging station operator or by any e-driver.",
    chargerPossibileStatuses_halfavailable: "Partially available",
    chargerPossibileStatuses_halfavailable_text: "This means that one or more sockets in the charging station has a malfunction. This has been confirmed by the charging station operator. But one or more charging sockets in the charging station are still working.",
    chargerPossibileStatuses_unavailable: "Unavailable",
    chargerPossibileStatuses_unavailable_text: "This means that the charging station has a malfunction. This has been confirmed by the charging station operator. We will let you know as soon as the charging station is available again.",
    chargerPossibileStatuses_warning: "Incident reported",
    chargerPossibileStatuses_warning_text: "An e-driver has reported an incident on this charging station. We have not yet received confirmation from the charging station operator that the charging station is no longer working. It is also possible that the charging station is involved in a local energy failure.",
    chargerPossibileStatuses_unknown: "Unknown",
    chargerPossibileStatuses_unknown_text: "This means that unfortunately we don't have any insight into the status of the charging station.",
    commonProblems: "Common problems",
    commonProblems_notWorking: "The charging station does not work",
    commonProblems_notWorking_text: "This can have different reasons. If the charging station does not respond at all, the cause may be an energy failure. There may also be a technical problem with the charging station itself. In this case, make a report via ChargeProb. In this way, you will also inform the next e-driver. And we will keep you informed!",
    commonProblems_slow: "The charging station is slow",
    commonProblems_slow_text: " This can have different reasons. For example the battery level of your car or the temperature outside. The current load on the electricity grid can also be the cause. If several cars are charging, the charging speed may decrease. Make a report via ChargeProb if you experience slow charging.",
    commonProblems_cableStuck: "The charging cable is stuck",
    commonProblems_cableStuck_text: "If you stop the charging session, the car should automatically unlock the charging cable, but very occasionally this does not happen. Particularly during cold periods, it can happen that the automatic unlocking fails. There is actually always a backup in the form of a mechanical release. Call the operator for help on how to disconnect the cable.",
    commonProblems_passNotWorking: "The charge card does not work",
    commonProblems_passNotWorking_text: "The charge card may be defective, which means that the chip in the charge card is broken or damaged. Try starting the charging session with a second pass. Does the second pass not work either? Then there is a good chance that the card reader in the charging station is defective. Report via ChargeProb!",
    commonProblems_chargerDamaged: "The charging station is damaged",
    commonProblems_chargerDamaged_text: "It is possible that the charging station still functions, but it is skewed or damaged. We would also like to receive your report in these cases so that the operator can quickly schedule a recovery action.",
    commonProblems_blocked: "The charging station is unaccessible",
    commonProblems_blocked_text: "If the charging station is blocked by a non-electric car, this is of course very inconvenient. Report this via ChargeProb!",
    faq: "Frequently asked questions",
    faq_reportIncident: "Reporting an incident",
    faq_reportIncident_q1: "How do I stay informed about an incident if I did not report it myself?",
    faq_reportIncident_r1: "If you don't have an account with ChargeProb yet, create one. After you have created an account, you can add the charging station to your favourites. You do this by clicking on the charging station and clicking on the heart at the top right. As soon as something changes in the status of the charging station, you will receive an email from us.",
    faq_reportIncident_q2: "What should I do if my charging station is not on the ChargeProb map?",
    faq_reportIncident_r2: "ChargeProb works with the most current charging point data available. Every night we provide our map with the latest charging stations. If your charging station is not listed, unfortunately we cannot help you with this.",
    faq_reportIncident_q3: "How can I make a report?",
    faq_reportIncident_r3: "Select the charging station with which you are experiencing a malfunction. Then click on 'Report incident' and create an account or log in to your existing account. Then fill in all the details to make your report as complete as possible. Finally, check whether everything is correct and press 'Send incident'.",
    faq_notifications: "Notifications",
    faq_notifications_q1: "What should I do if the incident lasts for a very long time?",
    faq_notifications_r1: "If you reported an incident or a charging station been unavailable for a long time without any update from the operator, then contact the the charging station operator by telephone.",
    faq_notifications_q2: "Why am I not receiving updates from the operator about my reported outage?",
    faq_notifications_r2: "ChargeProb is working hard to connect all operators within the Netherlands to our national platform. It is possible that the charging station operator is not yet connected to our platform. If this is the case, we will let you know as soon as the charging station is working again. You can also see whether other e-drivers have already reported problems with the charging station.",
    faq_account: "ChargeProb account",
    faq_account_q1: "Do I need an account to report a problem?",
    faq_account_r1: "You do not need an account to see the status of a charging station or reports from other e-drivers. However, to report an incident yourself or receive status updates, you need to create an account.",
    cpo_title_1: "Digitalize the incident management process?",
    cpo_title_2: "It's possible with ChargeProb!",
    cpo_digitalizeproccess: "We digitalize the incident management process!",
    cpo_digitalizeproccess_text_1: "With the ",
    cpo_digitalizeproccess_text_2: " platform we make it possible to digitize the incident process for charging stations. As a result, you will receive less calls for every malfunction. This saves costs and gives you time to focus on the construction of new charging stations!",
    cpo_proactive: "Proactive",
    cpo_proactive_text: "You are able to inform all e-drivers about malfunctions via one central platform. We then keep them informed of progress and status!",
    cpo_reactive: "Reactive",
    cpo_reactive_text: "By digitizing the reporting process, you have direct insight into what is happening on the charger locations. Reports from e-drivers are immediately visible to all users and to you, which prevents repeated reports on the same malfunction.",
    cpo_automation: "Automation",
    cpo_automation_text: "Is one of the charging stations involved in an energy power failure? We show this to the e-drivers. As a result, a lot of reports are avoided. This process is completely automatic!",
    cpo_central_control: "Central control",
    cpo_central_control_text: "Manage all your malfunctions and reports from e-drivers digitally from one central location.",
    cpo_demo: "Demo",
    cpo_demo_text_1: "Want to know more about what ",
    cpo_demo_text_2: " can do for your organization? Request a demo here!",
    cpo_foc_text: "During the last edition of the Future of Charging, ChargeProb was present at the event organized by MRA-E. Want to know more about our story?",
    cpo_placeholder_name: "Name",
    cpo_placeholder_company: "Company",
    cpo_placeholder_email: "Email",
    cpo_placeholder_phone: "Phone",
    contactEmailError: "Please provide a valid email.",
    sendContactDetailsSuccess: "Thank you! We will contact you as soon as possible.",
    sendContactDetailsError: "Sorry, we are experiencing some troubles. Please try again later.",
  },
  Dutch: {
    moreInfo: "Meer Informatie",
    learnMore: "Learn more",
    submit: "Stuur",
    contact: "Contact",
    contactText: "Wij maken het meest frustrerende probleem van de e-rijder transparant!",
    incident: "Storing",
    incidentOnCharger: "Storing met een publieke laadpaal?",
    forEDrivers: "Voor e-rijders",
    forEDriversImgText: "Storing met een publieke laadpaal? Met ChargeProb check of meld je dit eenvoudig en snel!",
    forCPOs: "Voor laadpaal beheerders",
    forCPOsImgText: "Veel telefoontjes over storingen? Met ChargeProb helpen wij jou het storingsproces te digitaliseren!",
    readMore: "Lees hier meer",
    aboutUs: "Over ons",
    aboutUs_text: "Uit persoonlijke frustratie met een defecte laadpaal is het idee voor ChargeProb ontstaan. Onze ervaring binnen de telecom sector heeft het mogelijk gemaakt dit landelijke storingsplatform te ontwikkelen. Meer weten?",
    unavailableChargersOverview: "Aantal laadpalen technisch onbeschikbaar",
    problemsReportedOverview: "Aantal gemelde storingen e-rijders",
    updatesFromCPOOverview: "Aantal status updates laadpaal beheerders",
    availableChargersOverview: "Aantal laadpalen operationeel beschikbaar",
    warningChargersOverview: "Aantal laadpunten 'warning'",
    chargingChargersOverview: "Aantal laadpunten 'charging'",
    blockedChargersOverview: "Aantal laadpunten 'blocked'",
    reportIncident: "Meld een storing",
    reportIncidentHere: "Meld hier je storing",
    reportIncidentEasy: "Meld eenvoudig je storing",
    reportIncidentEasy_text_1: "Een probleem met een publieke laadpaal melden is met het ",
    reportIncidentEasy_text_2: " platform heel eenvoudig. De storing is direct inzichtelijk voor andere e-rijders. Wij houden jou op de hoogte van de afhandeling!",
    reportIncidentEasy_chargerStatus: "Laadpaal status",
    reportIncidentEasy_chargerStatusCheckStatus: "Check eenvoudig de status van de laadpaal. Is er reeds een storing gemeld dan is dit direct zichtbaar!",
    reportIncidentEasy_chargerStatusStayUpdated: "Blijf op de hoogte",
    reportIncidentEasy_chargerStatusStayUpdated_text: "Zodra wij een status update hebben houden wij jou hiervan op de hoogte!",
    reportIncidentEasy_chargerStatusReportProblem_text: "Is er nog geen storing bekend? Meld deze dan snel en eenvoudig! Met deze melding help je ook andere e-rijders.",
    chargerPossibileStatuses: "Welke status kan de laadpaal hebben?",
    chargerPossibileStatuses_available: "Beschikbaar",
    chargerPossibileStatuses_available_text: "De laadpaal werkt op dit moment zonder problemen, we zien normale activiteit. Er is geen storing gemeld door de beheerder van de laadpaal of e-rijders.",
    chargerPossibileStatuses_halfavailable: "Gedeeltelijk beschikbaar",
    chargerPossibileStatuses_halfavailable_text: "Dit geeft aan dat een deel van de laadpaal een storing heeft. Dit is bevestigd door de beheerder. Maar één of meerdere oplaadpunten in de laadpaal werken nog.",
    chargerPossibileStatuses_unavailable: "Onbeschikbaar",
    chargerPossibileStatuses_unavailable_text: "Dit geeft aan dat deze laadpaal een storing heeft. Dit is bevestigd door de beheerder. Wij laten het je weten zodra de laadpaal weer beschikbaar is.",
    chargerPossibileStatuses_warning: "Storing gemeld",
    chargerPossibileStatuses_warning_text: "Een e-rijder heeft een storing gemeld over deze laadpaal. Vanuit de beheerder hebben we nog geen bevestiging dat de laadpaal niet meer werkt. Ook kan het zijn dat de laadpaal betrokken is bij een lokale stroomstoring.",
    chargerPossibileStatuses_unknown: "Onbekend",
    chargerPossibileStatuses_unknown_text: "Dit betekent dat we helaas geen inzicht hebben in de status van de laadpaal.",
    commonProblems: "Veel voorkomende problemen",
    commonProblems_notWorking: "De laadpaal werkt niet",
    commonProblems_notWorking_text: "Dit kan verschillende oorzaken hebben. Als de laadpaal helemaal niet reageerd kan de oorzaak hiervan een stroomstoring zijn. Ook kan er een technisch probleem zijn met de laadpaal. Maak in dit geval een melding via ChargeProb dan informeer je ook de volgende e-rijder. Wij houden je op de hoogte!",
    commonProblems_slow: "Laadpaal laad langzaam",
    commonProblems_slow_text: " Dit kan verschillende oorzaken hebben. Denk aan het batterijniveau van je auto of bijvoorbeeld de temperatuur. Ook kan de belasting van het elektriciteitsnet de oorzaak zijn. Indien er meerdere auto's aan het laden zijn kan de laadsnelheid omlaag gaan. Maak een melding via ChargeProb.",
    commonProblems_cableStuck: "Laadkabel komt niet los",
    commonProblems_cableStuck_text: "Als je de laadsessie stopt, dan moet de auto de laadkabel automatisch ontgrendelen, maar heel soms gebeurt dat niet. Vooral bij vorst kan het voorkomen dat de automatische ontgrendeling hapert. Er is eigenlijk altijd een back-up in de vorm van een mechanische ontgrendeling. Bel de beheerder om de kabel los te maken.",
    commonProblems_passNotWorking: "Laadpas werkt niet",
    commonProblems_passNotWorking_text: "De laadpas kan defect zijn, wat betekent dat de chip in de laadpas gebroken of beschadigd is. Probeer de laadsessie te starten met een tweede pas. Werkt de tweede pas ook niet? Dan is de kans groot dat de paslezer in de laadpaal defect is. Maak een melding viaChargeProb!",
    commonProblems_chargerDamaged: "Laadpaal is beschadigd",
    commonProblems_chargerDamaged_text: "Het kan zijn dat de laadpaal nog wel functioneert maar dat hij bijvoorbeeld scheef staat of is beschadigd. Ook in deze gevallen ontvangen wij graag uw melding zodat de beheerder snel een herstelactie kan inplannen.",
    commonProblems_blocked: "Laadpaal is geblokkeerd",
    commonProblems_blocked_text: "Indien de laadpaal wordt geblokkeerd door een niet elektrische auto is dit natuurlijk erg onhandig. Geef deze melding ook door via ChargeProb!",
    faq: "Veel gestelde vragen",
    faq_reportIncident: "Melding maken",
    faq_reportIncident_q1: "Hoe blijf ik op de hoogte van een storing als ik deze niet zelf heb gemeld?",
    faq_reportIncident_r1: "Indien je nog geen account hebt bij ChargeProb maak deze dan aan. Nadat je een account hebt aangemaakt en bent ingelogd zet je de laadpaal in je favorieten. Dit doe je door op de laadpaal te klikken en rechts boven op het hartje te klikken. Zodra er iets verranderd in de status van de laadpaal ontvang je hierover een email.",
    faq_reportIncident_q2: "Wat moet ik doen als mijn laadpaal niet op de ChargeProb map staat?",
    faq_reportIncident_r2: "ChargeProb werkt met de meest actuele laadpaal data welke er beschikbaar is. Elke nacht voorzien wij onze kaart van de nieuwste laadpalen. Staat jou laadpaal er niet tussen kunnen wij u hier helaas niet bij helpen.",
    faq_reportIncident_q3: "Hoe kan ik een melding maken?",
    faq_reportIncident_r3: "Selecteer de laadpaal waarmee je een storing ervaart. Klik vervolgens op 'Meld een storing' en maak een account aan of log-in op je reeds bestaande account. Vul daarna alle velden in om je melding zo compleet mogelijk te maken. Tot slot check je of alles klopt en druk je op 'Stuur melding'.",
    faq_notifications: "Afhandeling van melding",
    faq_notifications_q1: "Wat moet ik doen als de storing heel lang duurt?",
    faq_notifications_r1: "Heb je een melding gemaakt of staat er een laadpaal al heel lang op de status onbeschikbaar zonder updates van de beheerder. Neem dan telefonisch contact op met de beheerder van de laadpaal.",
    faq_notifications_q2: "Waarom ontvang ik geen updates vanuit de beheerder over mijn gemelde storing?",
    faq_notifications_r2: "ChargeProb werkt hard om alle beheerders binnen Nederland aan te sluiten op het landelijk platform. Het kan zijn dat de beheerder van de laadpaal nog niet is aangesloten op ons platform. Is dit het geval laten we je wel weten zodra de laadpaal weer werkt. Ook kun je zien of andere e-rijders het probleem al hebben gemeld.",
    faq_account: "ChargeProb account",
    faq_account_q1: "Heb ik altijd een account nodig voor een melding?",
    faq_account_r1: "Om de status van een laadpaal of meldingen van andere e-rijders te zien heb je geen account nodig. Echter om zelf een melding te maken of status updates te ontvangen dien je een account aan te maken.",
    cpo_title_1: "Het storingproces digitaliseren?",
    cpo_title_2: "Het kan met ChargeProb!",
    cpo_digitalizeproccess: "Wij digitaliseren het storingsproces!",
    cpo_digitalizeproccess_text_1: "Met het ",
    cpo_digitalizeproccess_text_2: " platform maken we het mogelijk het storingsproces voor laadpalen te digitaliseren. Hierdoor word je niet voor elke storing gebeld. Dit bespaart kosten en geeft je de tijd om te focussen op de aanleg van nieuwe laadpalen!",
    cpo_proactive: "Proactief",
    cpo_proactive_text: "Via één centrale plek ben je in staat alle e-rijders te informeren over storingen. Wij houden deze vervolgens op de hoogte van voortgang en status, wel zo makkelijk!",
    cpo_reactive: "Reactief",
    cpo_reactive_text: "Door het meldingproces te digitaliseren heb je direct inzicht wat er op locatie gebeurd. Meldingen van e-rijders zijn direct inzichtelijk voor alle gebruikers, dit voorkomt herhaalverkeer.",
    cpo_automation: "Automation",
    cpo_automation_text: "Is één van de laadpalen betrokken bij een stroomstoring? Wij laten dit zien aan de e-rijders. Hierdoor worden een hoop meldingen op voorhand afgevangen. Dit proces verloopt volledig automatisch!",
    cpo_central_control: "Centrale besturing",
    cpo_central_control_text: "Bestuur al je storingen en meldingen van e-rijders digitaal vanaf één centrale plek.",
    cpo_demo: "Demo",
    cpo_demo_text_1: "Meer weten over wat ",
    cpo_demo_text_2: " voor jullie organisatie kan betekenen? Vraag hier een demo aan!",
    cpo_foc_text: "Tijdens de laatste editie van de Future of Charging was ChargeProb aanwezig op uitnodiging van MRA-E. Meer weten over ons verhaal?",
    cpo_placeholder_name: "Naam",
    cpo_placeholder_company: "Bedrijf",
    cpo_placeholder_email: "Email",
    cpo_placeholder_phone: "Telefoon",
    contactEmailError: "Gelieve een geldig e-mailadres in te vullen.",
    sendContactDetailsSuccess: "Bedankt! We nemen zo snel mogelijk contact met je op.",
    sendContactDetailsError: "Sorry, we hebben wat problemen. Probeer het later opnieuw a.u.b.",
  }
};


export const storingCPOPageContent = {
  English: {
    headerTitle: "Ever been at a broken charging station? ",
    headerSubTitle: "With the ChargeProb platform you can easily report an incident on a public charging station. The reported incident is immediately visible to other e-drivers!",
    reportProblemButton: "Report an incident!",
    infoCpo: "Quick help to resolve a malfunction at a charging station of:",
    infoCpoWebsite: "Website",
    infoCpoStoringWebsite: "Incidents info website",
    infoCpoPhonenumber: "Phonenumber",
  },
  Dutch: {
    headerTitle: "Nooit meer bij een defecte laadpaal staan?",
    headerSubTitle: "Een probleem met een publieke laadpaal melden is met het ChargeProb platform heel eenvoudig. De storing is direct inzichtelijk voor andere e-rijders!",
    reportProblemButton: "Meld een storing!",
    infoCpo: "Snel hulp bij het verhelpen met een storing aan een laadpaal van:",
    infoCpoWebsite: "Website",
    infoCpoStoringWebsite: "Storingspagina website",
    infoCpoPhonenumber: "Telefoonnummer",
  }
}

export const privacyStatementContent = {
  English: {
    title: "PRIVACY STATEMENT CHARGEPROB",
    subtitle_content: "We consider it very important to handle personal data carefully. Therefore your personal data is carefully processed and secured. In doing so, we comply with the laws and regulations in the field of the protection of personal data, such as the General Data Protection Regulation (GDPR).",
    security_title: "SECURITY DATA",
    security_content: "We use security procedures to prevent unauthorized access to this data. These safety procedures are continuously improved.",
    dataProcessing_title: "DATA PROCESSING",
    dataProcessing_content_p1: "ChargeProb is an application for making malfunction reports with public charging stations. In the context of this service, ChargeProb records personal data. These are specified below.",
    dataProcessing_content_p2: "Based on the location of the charging station, the report is sent to our client, who will handle the report. The client is the responsible party in the context of the processing of your data. This can be the manager of the charging station, but also another party who is responsible of managing the charging station. The client uses your data to resolve the report and to keep you informed about the progress. The client will have its own privacy policy on how it handles your data. It is possible that they make this information available to third parties such as contractors or specialized companies.",
    dataProcessing_content_p3: "ChargeProb processes your data in case we need to provide support to our client. In that case we have access to your data. We will never provide this information to third parties (excluding administrators or specialized companies).",
    dataProcessing_content_p4: "It concerns the following data:",
    dataProcessing_content_emaildata: "Email address",
    dataProcessing_content_emailcontent: "Your e-mailaddress is required. If you provide an e-mail address, you will be informed by e-mail about the progress of the report and reactions that are added. This data is never shown on the public ChargeProb map, but is only available to ChargeProb and the client.",
    dataProcessing_content_telephonedata: "Telephone number",
    dataProcessing_content_telephonecontent: "Your telephone number is optional to enter in your profile. If you provide your telephone number, the client or a third party can contact you about the progress of the report. ChargeProb will not use this phone number. This data is never shown on the public ChargeProb map, but is only available to ChargeProb and the client.",
    dataProcessing_content_carata: "Car model",
    dataProcessing_content_carcontent: "Your type of car is not required, but you can specify it. This information is used to gain insight into which types of cars experience the most charging problems. This data will be shown on the public ChargeProb map after the report has been made.",
    dataProcessing_content_incidenttypedata: "Type of incident / socket",
    dataProcessing_content_incidenttypecontent: "The type of incident you choose is important for the handling by the client. This data is visible on the public ChargeProb map.",
    dataProcessing_content_incidentdescriptiondata: "Incident description",
    dataProcessing_content_incidentdescriptioncontent: "The description of the report is important for the handling by the client. This data is visible on the public ChargeProb map. Be aware that the description can lead to the identification of the reporter, if it contains text such as 'My neighbour...' , 'right in front of me...', 'My name is...', etc.",
    dataProcessing_content_incidentlocationdata: "Location of the incident",
    dataProcessing_content_incidentlocationcontent: "The location of the incident is important for handling by the client. This data is available on the public map based on the location of the charging station.",
    dataProcessing_content_incidentcommentsdata: "Comments",
    dataProcessing_content_incidentcommentscontent: "The comments you post on the charging station are available and visible on the public ChargeProb map.",
    dataProcessing_content_otherusage_title: "We also use your data for:",
    dataProcessing_content_otherusage_accountdata: "Account",
    dataProcessing_content_otherusage_accountcontent: "It is possible to create a ChargeProb account. We store your e-mail address for this. The password is stored encrypted, and we don't have access to it.",
    dataProcessing_content_cookiessettings_title: "COOKIE SETTINGS",
    dataProcessing_content_cookiessettings_subtitle: "The ChargeProb website uses cookies for the following purposes:",
    dataProcessing_content_cookiessettings_necessary_title: "Necessary and functional Cookies",
    dataProcessing_content_cookiessettings_necessary_content: "These cookies are used to provide functionality to use the ChargeProb website. Think of the possibility to let the ChargeProb website use the location. You cannot disable these cookies.",
    dataProcessing_content_cookiessettings_tracking_title: "Tracking and Performance Cookies",
    dataProcessing_content_cookiessettings_tracking_content: "ChargeProb uses cookies to analyze how visitors use our website. This analytical data cannot be traced back to a user. For this we use the settings as indicated by the Dutch Data Protection Authority.",
    dataProcessing_content_cookiessettings_ads_title: "Targeting and Advertising Cookies",
    dataProcessing_content_cookiessettings_ads_content: "These cookies make it possible to log in via the Facebook and Google+ channels. You can refuse these cookies. With the exception of the login options via social media, the website will continue to function normally.",
    dataProcessing_content_amendments_title: "AMENDMENTS",
    dataProcessing_content_amendments_content: "ChargeProb reserves the right to make changes to this privacy statement. We will always observe the applicable laws and regulations with regard to privacy.",
  },
  Dutch: {
    title: "PRIVACYVERKLARING CHARGEPROB",
    subtitle_content: "Wij vinden het van groot belang om zorgvuldig met persoonsgegevens om te gaan. Persoonlijke gegevens worden dan ook zorgvuldig verwerkt en beveiligd. Hierbij houden wij ons aan de wet - en regelgeving op het gebied van de bescherming van persoonsgegevens, zoals de Algemene Verordening Gegevensbescherming (AVG). ",
    security_title: "BEVEILIGING GEGEVENS",
    security_content: "Wij maken gebruik van veiligheidsprocedures, onder meer om te voorkomen dat onbevoegden toegang krijgen tot deze gegevens. Deze veiligheidsprocedures worden continu verbeterd.",
    dataProcessing_title: "VERWERKING GEGEVENS",
    dataProcessing_content_p1: "ChargeProb is een applicatie voor het maken van storingsmeldingen met publieke laadpalen. In het kader van deze dienstverlening legt ChargeProb persoonsgegevens vast. Deze zijn hieronder gespecificeerd.", 
    dataProcessing_content_p2: "Op basis van de locatie van de laadpaal wordt de melding naar onze opdrachtgever gestuurd, die de melding zal behandelen. De opdrachtgever is de verantwoordelijke partij in het kader van de verwerking van uw gegevens. Dit kan een de beheerder van de laadpaal zijn maar ook een andere instantie waar het beheer van de laadpaal is neergelegd. De opdrachtgever gebruikt uw gegevens om de melding op te lossen en u op de hoogte te houden over de voortgang. De opdrachtgever zal een eigen privacybeleid hebben hoe zij met uw gegevens omgaat. Het is mogelijk dat zij deze gegevens ter beschikking stellen aan derden zoals aannemers of gespecialiseerde bedrijven.",
    dataProcessing_content_p3: "ChargeProb verwerkt uw gegevens in het geval dat wij onze opdrachtgever support moeten leveren. In dat geval hebben wij inzage in uw gegevens. Wij zullen deze gegevens nooit aan derden (exclusief de beheerders of gespecialiseerde bedrijven) verschaffen.",
    dataProcessing_content_p4: "Het gaat om de volgende gegevens:",
    dataProcessing_content_emaildata: "E-mailadres",
    dataProcessing_content_emailcontent: "Uw e-mailadres is verplicht. Indien u een e-mailadres opgeeft, dan wordt u via e-mail geïnformeerd over de voortgang van de melding en reacties die worden toegevoegd. Deze gegevens worden nooit op de openbare ChargeProb kaart getoond, maar zijn alleen voor ChargeProb en de opdrachtgever beschikbaar.",
    dataProcessing_content_telephonedata: "Telefoonnummer",
    dataProcessing_content_telephonecontent: "Uw telefoonnummer is optioneel in te geven in uw profiel. Indien je uw telefoonnummer opgeeft kan de opdrachtgever of een derde partij contact met u opnemen over de voortgang van de melding. ChargeProb zal dit telefoonnummer niet gebruiken. Deze gegevens worden nooit op de openbare ChargeProb kaart getoond, maar zijn alleen voor ChargeProb en de opdrachtgever beschikbaar.",
    dataProcessing_content_cardata: "Type Auto",
    dataProcessing_content_carcontent: "Uw type auto is niet verplicht maar u kunt deze wel doorgeven. Dit gegeven wordt gebruikt om inzicht te krijgen in welke type auto's het meeste laadproblemen ervaart. Deze gegevens worden op de openbare ChargeProb kaart getoond nadat de melding is gemaakt.",
    dataProcessing_content_incidenttypedata: "Type storing / Socket",
    dataProcessing_content_incidenttypecontent: "De type storing die u kiest is van belang voor de afhandeling door de opdrachtgever. Dit gegeven is zichtbaar op de openbare ChargeProb kaart.",
    dataProcessing_content_incidentdescriptiondata: "Omschrijving van de melding",
    dataProcessing_content_incidentdescriptioncontent: "De omschrijving van de melding is van belang voor de afhandeling door de opdrachtgever. Dit gegeven is zichtbaar op de openbare ChargeProb kaart. Wees ervan bewust dat de omschrijving kan leiden tot identificatie van de melder, als hier tekst in wordt vermeld zoals 'Mijn buurman...' , 'recht tegenover mij...', 'Mijn naam is...', enz.",
    dataProcessing_content_incidentlocationdata: "Locatie van de melding",
    dataProcessing_content_incidentlocationcontent: "De locatie van de melding is van belang voor de afhandeling door de opdrachtgever. Dit gegeven is beschikbaar op de openbare kaart op basis van de locatie van de laadpaal.",
    dataProcessing_content_incidentcommentsdata: "Reacties",
    dataProcessing_content_incidentcommentscontent: "De reacties welke u plaatst op de laadpaal zijn beschikbaar en zichtbaar op de openbare ChargeProb kaart.",
    dataProcessing_content_otherusage_title: "Verder gebruiken wij uw gegevens voor:",
    dataProcessing_content_otherusage_accountdata: "Account",
    dataProcessing_content_otherusage_accountcontent: "Het is mogelijk om een ChargeProb account aan te maken. We bewaren hiervoor uw e-mailadres. Het wachtwoord wordt versleuteld opgeslagen, hier hebben wij geen inzage in.",
    dataProcessing_content_cookiessettings_title: "COOKIE INSTELLINGEN",
    dataProcessing_content_cookiessettings_subtitle: "De ChargeProb website gebruikt cookies voor de volgende doeleinden:",
    dataProcessing_content_cookiessettings_necessary_title: "Noodzakelijke en functionele Cookies",
    dataProcessing_content_cookiessettings_necessary_content: "Deze cookies worden gebruikt voor het leveren van functionaliteit om gebruik te kunnen maken van de website ChargeProb. Denk hierbij aan de mogelijkheid om de ChargeProb website gebruik te laten maken van de locatie. Deze cookies kunt u niet uitzetten.",
    dataProcessing_content_cookiessettings_tracking_title: "Tracking en prestatie Cookies",
    dataProcessing_content_cookiessettings_tracking_content: "ChargeProb gebruikt cookies om te kunnen analyseren hoe bezoekers onze website gebruiken. Deze analytische data is niet terug te herleiden naar een gebruiker. Wij maken hiervoor gebruik van de instellingen zoals aangegeven door de Autoriteit Persoonsgegevens.",
    dataProcessing_content_cookiessettings_ads_title: "Targeting - en advertentie Cookies",
    dataProcessing_content_cookiessettings_ads_content: "Deze cookies maken het mogelijk om in te loggen via de kanalen Facebook en Google+. U kunt deze cookies weigeren. Met uitzondering van de inlogmogelijkheden via sociale media blijft de website gewoon functioneren.",
    dataProcessing_content_amendments_title: "WIJZIGINGEN",
    dataProcessing_content_amendments_content: "ChargeProb behoudt zich het recht voor om wijzigingen aan te brengen in dit privacy statement. Hierbij zullen wij altijd de geldende wet - en regelgeving met betrekking tot privacy in acht nemen.",
  }
};

export const termsAndConditionsContent = {
  English: {
    title: "TERMS AND CONDITIONS",
    subtitle_content_p1: "Welcome at www.chargeprob.com!",
    subtitle_content_p2: "These general terms and conditions describe the rules and regulations for the use of the ChargeProb website, hosted at www.chargeprob.com.",
    subtitle_content_p3: "By visiting this website, we assume that you accept these terms and conditions. Do not continue to use www.chargeprob.com if you do not agree to all the terms and conditions stated on this page.",
    cookies_title: "COOKIES",
    cookies_content_p1: "The website uses cookies to personalize your online experience. By accessing www.chargeprob.com you agree to use the required cookies.",
    cookies_content_p2: "A cookie is a text file that is placed on your hard drive by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.",
    cookies_content_p3: "We may use cookies to collect, store and track information for statistical or marketing purposes to make our website work. You have the option to accept or decline optional cookies. There are some required cookies that are necessary for the operation of our website. These cookies do not require your consent, as they always work. Please note that by accepting required cookies, you also accept third-party cookies, which may be used through services provided by third parties if you use such services on our website, for example a third-party video display window integrated into our website.",
    license_title: "LICENSE",
    license_content_p1: "Unless otherwise stated, ChargeProb and/or its licensors own the intellectual property rights to all data on www.chargeprob.com. All intellectual property rights are reserved. You may access it from www.chargeprob.com for your own personal use, subject to the restrictions set out in these terms and conditions.",
    license_content_p2: "You may not:",
    license_youmaynot_content_l1: "Copy or republish material from www.chargeprob.com",
    license_youmaynot_content_l2: "Sell, rent or sublicense material from www.chargeprob.com",
    license_youmaynot_content_l3: "Reproduce, duplicate or copy material from www.chargeprob.com",
    license_youmaynot_content_l4: "Redistribute content from www.chargeprob.com",
    license_content_p3: "This agreement begins on this date.",
    license_content_p4: "Parts of this website allow users to post and exchange opinions and information on certain parts of the website. ChargeProb does not filter, edit, publish or review comments prior to their presence on the website. Comments do not reflect the views and opinions of ChargeProb, its agents and/or affiliates. Comments reflect the views and opinions of the person posting their views and opinions. To the fullest extent permitted by applicable law, ChargeProb shall not be liable for the comments or any liability, damages or costs caused and/or suffered as a result of the use of, and/or the posting of, and/or the appearance of the comments on this website.",
    license_content_p5: "ChargeProb reserves the right to monitor all comments and to remove any comments that may be considered inappropriate, offensive or that violate these Terms and Conditions.",
    license_content_p6: "You warrant and represent that:",
    license_warrant_content_l1: "You have the right to post the comments on our website and have all necessary licenses and permissions to do so.",
    license_warrant_content_l2: "The comments do not infringe any intellectual property right, including without limitation any copyright, patent or trademark of any third party.",
    license_warrant_content_l3: "The comments do not contain libelous, defamatory, abusive, indecent or otherwise unlawful material, which is an invasion of privacy.",
    license_warrant_content_l4: "The comments will not be used to solicit or promote business or custom or current commercial activity or illegal activity.",
    license_content_p7: "You hereby grant ChargeProb a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce, and edit your comments in any form, format, or media.",
    hyperlinks_title: "HYPERLINKS TO OUR CONTENT",
    hyperlinks_content_p1: "The following organizations may link to our website without prior written permission:",
    hyperlinks_nopermission_content_l1: "Government agencies;",
    hyperlinks_nopermission_content_l2: "Search engines;",
    hyperlinks_nopermission_content_l3: "News organizations;",
    hyperlinks_nopermission_content_l4: "Online directory distributors may link to our website in the same manner as they hyperlink to the websites of other publicly traded companies;",
    hyperlinks_nopermission_content_l5: "Accredited companies for the entire system, with the exception of recruiting non-profit organizations, shopping malls for charities, and fundraising groups for charities that are not allowed to hyperlink to our website.",
    hyperlinks_content_p2: "These organizations may link to our home page, publications or other website information, so long as the link: (a) is not misleading in any way; (b) does not falsely imply sponsorship, endorsement or endorsement of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.",
    hyperlinks_content_p3: "We may consider and approve other link requests from the following types of organizations:",
    hyperlinks_linkrequests_content_l1: "commonly known sources of information for consumers and/or businesses;",
    hyperlinks_linkrequests_content_l2: "dot.com community sites;",
    hyperlinks_linkrequests_content_l3: "associations or other groups representing charities;",
    hyperlinks_linkrequests_content_l4: "online directory distributors;",
    hyperlinks_linkrequests_content_l5: "internet portals;",
    hyperlinks_linkrequests_content_l6: "accountancy, law and consultancy firms;",
    hyperlinks_linkrequests_content_l7: "educational institutions and trade associations.",
    hyperlinks_content_p4: "We will approve link requests from these organizations if we determine that: (a) the link would not make us look favorable to ourselves or our accredited companies; (b) the organization has no negative data with us; (c) the benefit to us of the visibility of the hyperlink compensates for the absence of ChargeProb; and (d) the link is in the context of general resource information.",
    hyperlinks_content_p5: "These organizations may link to our home page as long as the link: (a) is not misleading in any way; (b) does not falsely imply sponsorship, endorsement of the linking party and its products or services; and (c) fits within the context of the linking party's site.",
    hyperlinks_content_p6: "If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must notify us by emailing ChargeProb. Please include your name, the name of your organization, contact details as well as the URL of your site, a list of all the URLs from which you would like to link to our website and a list of the URLs on our site that you would like to link to. clutch. Wait 2-3 weeks for a response.",
    hyperlinks_content_p7: "Approved organizations may hyperlink to our website as follows:",
    hyperlinks_contactus_content_l1: "By using our company name; or",
    hyperlinks_contactus_content_l2: "By using the uniform resource locator that is linked to; or",
    hyperlinks_contactus_content_l3: "Using any other description of our linked website, it must make sense within the context and format of the content on the linking party's site.",
    hyperlinks_content_p8: "The use of ChargeProb's logo or other artwork is not allowed for linking without a trademark license agreement.",
    liability_title: "LIABILITY FOR CONTENT",
    liability_content_p1: "We cannot be held responsible for any content that appears on your website. You agree to protect and defend us against all claims made on your website. No link(s) may appear on any website that may be construed as defamatory, obscene or criminal, or which infringes, otherwise violates or advocates the infringement or other violation of any rights of any third party.",
    rights_title: "RESERVATION OF RIGHTS",
    rights_content_p1: "We reserve the right to request that you remove all links or any particular link to our website. You agree to immediately remove any links to our website upon request. We also reserve the right to change these terms and conditions and the linking policy at any time. By continuously linking to our website, you agree to be bound by and abide by these linking terms.",
    linkremovals_title: "REMOVALS OF LINKS FROM OUR WEBSITE",
    linkremovals_content_p1: "If you find any link on our website that is objectionable for any reason, you may contact us at any time and let us know. We will consider requests to remove links, but we are under no obligation to respond to you directly.",
    linkremovals_content_p2: "We do not ensure that the information on this website is correct. We do not guarantee completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.",
    disclaimer_title: "DISCLAIMER",
    disclaimer_content_p1: "To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:",
    disclaimer_list_content_l1: "limit or exclude our or your liability for death or personal injury;",
    disclaimer_list_content_l2: "limitation or exclusion of our or your liability for fraud or fraudulent misrepresentation;",
    disclaimer_list_content_l3: "limit any of our or your liabilities in any way that is not permitted under applicable law;",
    disclaimer_list_content_l4: "exclude any of our or your liabilities that may not be excluded under applicable law.",
    disclaimer_content_p2: "The limitations and prohibitions of liability set forth in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) settle all liabilities arising under the disclaimer, including liabilities arising in contract, tort and for breach of statutory duty.",
    disclaimer_content_p3: "As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any kind.",

  },
  Dutch: {
    title: "ALGEMENE VOORWAARDEN",
    subtitle_content_p1: "Welkom bij www.chargeprob.com!",
    subtitle_content_p2: "Deze algemene voorwaarden omschrijven de regels en voorschriften voor het gebruik van de website van ChargeProb, gelegen op www.chargeprob.com.",
    subtitle_content_p3: "Door deze website te bezoeken, gaan we ervan uit dat je deze algemene voorwaarden accepteert. Blijf www.chargeprob.com niet gebruiken als je niet akkoord gaat met alle voorwaarden die op deze pagina worden vermeld.",
    cookies_title: "COOKIES",
    cookies_content_p1: "De website maakt gebruik van cookies om je online ervaring persoonlijker te maken. Door www.chargeprob.com te openen, stemde je ermee in om de vereiste cookies te gebruiken.",
    cookies_content_p2: "Een cookie is een tekstbestand dat door een web pagina server op je harde schijf wordt geplaatst. Cookies kunnen niet worden gebruikt om programma&#39;s uit te voeren of virussen op je computer te plaatsen. Cookies worden uniek aan jou toegewezen en kunnen alleen worden gelezen door een webserver in het domein dat de cookie aan je heeft uitgegeven.",
    cookies_content_p3: "We kunnen cookies gebruiken om informatie te verzamelen, op te slaan en bij te houden voor statistische of marketingdoeleinden om onze website te laten werken. Je hebt de mogelijkheid om optionele cookies te accepteren of om te weigeren. Er zijn enkele vereiste cookies die nodig zijn voor de werking van onze website. Voor deze cookies is je toestemming niet vereist, aangezien ze altijd werken. Hou er rekening mee dat door het accepteren van vereiste cookies, je ook cookies van derden accepteert, die kunnen worden gebruikt via door derden geleverde services als je dergelijke services op onze website gebruikt, bijvoorbeeld een video weergavevenster van derden dat geïntegreerd is op onze website.",
    license_title: "LICENTIE",
    license_content_p1: "Tenzij anders vermeld, bezit ChargeProb en/of zijn licentiegevers de intellectuele eigendomsrechten voor alle gegevens op www.chargeprob.com. Alle intellectuele eigendomsrechten zijn voorbehouden. Je hebt hier toegang toe vanaf www.chargeprob.com voor je eigen persoonlijk gebruik, onderworpen aan de beperkingen die in deze algemene voorwaarden zijn vastgelegd.",
    license_content_p2: "Je mag geen:",
    license_youmaynot_content_l1: "Materiaal van www.chargeprob.com kopiëren of opnieuw publiceren",
    license_youmaynot_content_l2: "Materiaal van www.chargeprob.com verkopen, verhuren of in sublicentie geven",
    license_youmaynot_content_l3: "Materiaal van www.chargeprob.com reproduceren, dupliceren of kopieren",
    license_youmaynot_content_l4: "Herdistribueren van inhoud van www.chargeprob.com",
    license_content_p3: "Deze overeenkomst begint op deze datum.",
    license_content_p4: "Delen van deze website bieden gebruikers de mogelijkheid om meningen en informatie op bepaalde delen van de website te plaatsen en uit te wisselen. ChargeProb filtert, bewerkt, publiceert of beoordeelt geen opmerkingen vóór hun aanwezigheid op de website. Reacties weerspiegelen geen standpunten en meningen van ChargeProb, zijn agenten en/of affiliate ondernemingen. Opmerkingen weerspiegelen de standpunten en meningen van de persoon die zijn opvattingen en meningen plaatsen. Voor zover toegestaan ​​door de toepasselijke wetgeving, is ChargeProb niet aansprakelijk voor de opmerkingen of enige aansprakelijkheid, schade of kosten veroorzaakt en/of geleden als gevolg van het gebruik van, en/of het plaatsen van, en/of de verschijning van de reacties op deze website.",
    license_content_p5: "ChargeProb behoudt zich het recht voor om alle opmerkingen te controleren en alle opmerkingen te verwijderen die als ongepast of beledigend kunnen worden beschouwd of die inbreuk maken op deze algemene voorwaarden.",
    license_content_p6: "Je garandeert en verklaart dat:",
    license_warrant_content_l1: "Je het recht hebt om de opmerkingen op onze website te plaatsen en beschikt over alle benodigde licenties en toestemmingen om dit te doen.",
    license_warrant_content_l2: "De opmerkingen maken geen inbreuk op enig intellectueel eigendomsrecht, inclusief maar niet beperkt tot auteursrechten, patenten of handelsmerken van een derde partij.",
    license_warrant_content_l3: "De opmerkingen bevatten geen lasterlijk, lasterlijk, beledigend, onfatsoenlijk of anderszins onwettig materiaal, wat een inbreuk op de privacy is.",
    license_warrant_content_l4: "De opmerkingen zullen niet worden gebruikt om zakelijke of aangepaste of huidige commerciële activiteiten of onwettige activiteiten te vragen of te promoten.",
    license_content_p7: "Hierbij verleen je ChargeProb een niet-exclusieve licentie voor het gebruiken, reproduceren, bewerken en autoriseren van anderen om je opmerkingen te gebruiken, reproduceren en bewerken in alle vormen, formaten, of media.",
    hyperlinks_title: "HYPERLINKS NAAR ONZE INHOUD",
    hyperlinks_content_p1: "De volgende organisaties kunnen naar onze website linken zonder voorafgaande schriftelijke toestemming:",
    hyperlinks_nopermission_content_l1: "Overheidsinstanties;",
    hyperlinks_nopermission_content_l2: "Zoekmachines;",
    hyperlinks_nopermission_content_l3: "Nieuwsorganisaties;",
    hyperlinks_nopermission_content_l4: "Online directory-distributeurs kunnen op dezelfde manier naar onze website linken als hyperlinks naar de websites van andere beursgenoteerde bedrijven;",
    hyperlinks_nopermission_content_l5: "Geaccrediteerde bedrijven voor het hele systeem, met uitzondering van het werven van non-profitorganisaties, winkelcentra voor goede doelen en fondsenwerving groepen voor liefdadigheidsinstellingen die geen hyperlink naar onze website mogen hebben.",
    hyperlinks_content_p2: "Deze organisaties kunnen linken naar onze startpagina, publicaties of andere website informatie, zolang de link: (a) op geen enkele manier misleidend is; (b) impliceert niet ten onrechte sponsoring, goedkeuring of goedkeuring van de verbindende partij en haar producten en/of diensten; en (c) past binnen de context van de site van de verbindende partij.",
    hyperlinks_content_p3: "We kunnen andere linkverzoeken van de volgende soorten organisaties in overweging nemen en goedkeuren:",
    hyperlinks_linkrequests_content_l1: "algemeen bekende informatiebronnen voor consumenten en/of bedrijven;",
    hyperlinks_linkrequests_content_l2: "dot.com-gemeenschap sites;",
    hyperlinks_linkrequests_content_l3: "verenigingen of andere groepen die goede doelen vertegenwoordigen;",
    hyperlinks_linkrequests_content_l4: "online directory-distributeurs;",
    hyperlinks_linkrequests_content_l5: "internetportalen;",
    hyperlinks_linkrequests_content_l6: "accountants-, advocaten- en adviesbureaus;",
    hyperlinks_linkrequests_content_l7: "onderwijsinstellingen en handelsverenigingen.",
    hyperlinks_content_p4: "We zullen linkverzoeken van deze organisaties goedkeuren als we besluiten dat: (a) de link ons er niet gunstig uit zou laten zien voor onszelf of voor onze geaccrediteerde bedrijven; (b) de organisatie geen negatieve gegevens bij ons heeft; (c) het voordeel voor ons van de zichtbaarheid van de hyperlink compenseert de afwezigheid van ChargeProb; en (d) de link staat in de context van algemene broninformatie.",
    hyperlinks_content_p5: "Deze organisaties kunnen naar onze homepage linken zolang de link: (a) op geen enkele manier misleidend is; (b) geen ten onrechte sponsoring impliceert, goedkeuring van de verbindende partij en haar producten of diensten heeft; en (c) past binnen de context van de site van de verbindende partij.",
    hyperlinks_content_p6: "Als je een van de organisaties bent die worden genoemd in paragraaf 2 hierboven, en geïnteresseerd bent in een link naar onze website, moet je ons hiervan op de hoogte stellen door een e-mail te sturen naar ChargeProb. Vermeld je naam, de naam van je organisatie, contactgegevens evenals de URL van je site, een lijst met alle URL's van waaruit je naar onze website wilt linken en een lijst met de URL's op onze site waarnaar je wilt verwijzen. koppeling. Wacht 2-3 weken op een reactie.",
    hyperlinks_content_p7: "Goedgekeurde organisaties kunnen als volgt een hyperlink naar onze website maken:",
    hyperlinks_contactus_content_l1: "Door gebruik te maken van onze bedrijfsnaam; of",
    hyperlinks_contactus_content_l2: "Door gebruik te maken van de uniforme resource locator waarnaar wordt gelinkt; of",
    hyperlinks_contactus_content_l3: "Het gebruik van een andere omschrijving van onze website waarnaar gelinkt wordt, het moet logisch zijn binnen de context en het formaat van de inhoud op de site van de koppelende partij.",
    hyperlinks_content_p8: "Het gebruik van het logo van ChargeProb of ander artwork is niet toegestaan voor het linken zonder een handelsmerk licentieovereenkomst.",
    liability_title: "AANSPRAKELIJKHEID VOOR INHOUD",
    liability_content_p1: "Wij kunnen niet verantwoordelijk worden gehouden voor enige inhoud die op je website verschijnt. Je stemt ermee in om ons te beschermen en te verdedigen tegen alle claims die op je website worden ingediend. Er mogen geen link(s) op een website verschijnen die kunnen worden geïnterpreteerd als lasterlijk, obsceen of crimineel, of die inbreuk maken, anderszins schendt of pleit voor de inbreuk of andere schending van rechten van derden.",
    rights_title: "VOORBEHOUD VAN RECHTEN",
    rights_content_p1: "We behouden ons het recht voor om te verzoeken dat je alle links of een bepaalde link naar onze website verwijdert. Je stemt ermee in om op verzoek alle links naar onze website onmiddellijk te verwijderen. We behouden ons ook het recht voor om deze algemene voorwaarden en het koppelingsbeleid op elk moment te wijzigen. Door continu naar onze website te linken, ga je ermee akkoord gebonden te zijn aan, en zich te houden aan deze voorwaarden voor linken.",
    linkremovals_title: "VERWIJDERING VAN LINKS VAN ONZE WEBSITE",
    linkremovals_content_p1: "Als je een link op onze website vindt die om welke reden dan ook aanstootgevend is, kun je op elk moment contact met ons opnemen en ons hiervan op de hoogte stellen. We zullen verzoeken om links te verwijderen in overweging nemen, maar we zijn niet verplicht om rechtstreeks op je te reageren",
    linkremovals_content_p2: "We zorgen er niet voor dat de informatie op deze website correct is. We garanderen geen volledigheid of nauwkeurigheid, noch beloven we ervoor te zorgen dat de website beschikbaar blijft of dat het materiaal op de website up-to-date wordt gehouden.",
    disclaimer_title: "DISCLAIMER",
    disclaimer_content_p1: "Voor zover maximaal toegestaan door de toepasselijke wetgeving, sluiten we alle verklaringen, garanties en voorwaarden met betrekking tot onze website en het gebruik van deze website uit. Niets in deze disclaimer zal:",
    disclaimer_list_content_l1: "onze of jouw aansprakelijkheid voor overlijden of persoonlijk letsel beperken of uitsluiten;",
    disclaimer_list_content_l2: "beperking of uitsluiting van onze of jouw aansprakelijkheid voor fraude of frauduleuze verkeerde voorstelling van zaken;",
    disclaimer_list_content_l3: "een van onze of jouw aansprakelijkheden beperken op een manier die niet is toegestaan onder de toepasselijke wetgeving;",
    disclaimer_list_content_l4: "sluit een van onze of jouw aansprakelijkheden uit die mogelijk niet worden uitgesloten onder de toepasselijke wetgeving.",
    disclaimer_content_p2: "De beperkingen en verbodsbepalingen van aansprakelijkheid uiteengezet in deze Sectie en elders in deze disclaimer: (a) zijn onderworpen aan de voorgaande paragraaf; en (b) alle aansprakelijkheden regelen die voortvloeien uit de disclaimer, inclusief aansprakelijkheden die voortvloeien uit een contract, onrechtmatige daad en voor schending van wettelijke plichten.",
    disclaimer_content_p3: "Zolang de website en de informatie en diensten op de website gratis worden aangeboden, zijn wij niet aansprakelijk voor verlies of schade van welke aard dan ook.",
  }
};


export const commonProblemsContent = {
  English: {
    p1: "With ChargeProb we make disruptions and incidents with public charging stations transparent. " +
      "When you report a problem via ChargeProb, we will forward it to the " +
      "Charge Point Operator (CPO) of the charging station. The CPO is then able to " +
      "failure with a status update. By making a report you help " +
      "quickly fix the problem, and you also alert the next EV driver which will " +
      "otherwise encounter the same problem. Below are some tips and info about " +
      "common problems you might have with public charging stations.",
    notWorkingAtAll: "The charging station does not work at all",
    notWorkingAtAll_p1: "This can have several causes. If the charging station does not respond at all, " +
      "one cause could be a power failure. There may also be a technical problem " +
      "with the charging station itself. In this case, make a report via ChargeProb " +
      "and forward it to the CPO, and inform other drivers about it. ",
    chargingDoesNotStart: "The charging session does not start",
    chargingDoesNotStart_p1: "This can have several causes. First try to plug again the charging cable. " +
      "It may happen that your charge card does not work because the charge point does not " +
      "recognize the card, or maybe your card has not yet been activated? " +
      "If you can, it is better to have more than one charge card with you, " +
      "so that another card can be used. If the charging session is still not starting, " +
      "then create a report in ChargeProb.",
    slowCharging: "Charging is very slow",
    slowCharging_p1: "This can have several causes. Think about battery level of your car or " +
      "for example temperature. The high current load on the electricity grid can also be the " +
      "cause. If several cars are charging in the same location, the " +
      "charging is distributed over several cars and therefore the charging speed is reduced. " +
      "You can report a slow charging station via ChargeProb.",
    chargeCardUnrecognized: "The charging station does not recognize the charging card?",
    chargeCardUnrecognized_p1: "The charge card may be defect, which means that the chip in the charge card is broken or " +
      "damaged. Try starting the charging session with a secondcard pass. Is the " +
      "second pass also not working? Then there is a good chance that the RFID reader in your " +
      "charge point is defect. Make a report via ChargeProb and we will forward it to " +
      "the charging station administrator.",
    cableStuck: "My charging cable is stuck?",
    cableStuck_p1: "The charging cable is stuck in the station. During charging, most stations lock " +
      "the cable to the car so that noone can unplug it during the charging. If your " +
      "charging session is finished then the car must unlock the charging cable automatically, " +
      "but sometimes this doesn't happen. Especially during frost, it can happen that the " +
      "automatic unlock fails. Usually it is possible to unlock the cable remotely and fix the problem.",
    damagedCharger: "The charging station is damaged",
    damagedCharger_p1: "It is possible that the charging station is working, but it can also look damaged " +
      "because, for example, a car has driven into it. At ChargeProb we are happy to receive " +
      "users notifications about this problem, so that we can inform the CPO about it and a " +
      "recovery action can be scheduled to repair the charging station.",
    blockedByNonEVcar: "Blocked by a non-EV car",
    blockedByNonEVcar_p1: "If the charging station parking spot is blocked by a non-EV car, this is " +
      "obviously very clumsy. If you make a report via ChargeProb, " +
      "we can forward this directly to parking management.",
    reportANewProblem: "Do you want to report a new problem?",
    reportANewProblem_button: "Make a report on ChargeProb!"
  },

  Dutch: {
    p1: "Met ChargeProb maken we storingen met publieke laadpalen transparant. " +
      "Zodra je een probleem hebt gemeld via ChargeProb zetten wij deze door naar de" +
      "Charge Point Operator (CPO) van de laadpaal. De CPO is vervolgens in staat de" +
      "storing van een status update te voorzien. Door een melding te maken help je om" +
      "het probleem snel te herstellen maar ook de volgende EV-rijder welke mogelijk" +
      "tegen hetzelfde probleem aanloopt. Onderstaand wat tips bij voorkomende" +
      "problemen mocht je tegen een probleem met laden aanlopen.",
    notWorkingAtAll: "Publieke Laadpaal doet helemaal niets",
    notWorkingAtAll_p1: "Dit kan verschillende oorzaken hebben. Reageert de laadpaal helemaal niet kan" +
      "de oorzaak hiervan een stroomstoring zijn. Ook kan er een technisch probleem" +
      "met de laadpaal zelf zijn. Maak in dit geval een melding via ChargeProb dan" +
      "informeer je ook de volgende EV-rijder. Wij zetten hem door naar de CPO.",
    chargingDoesNotStart: "De laadsessie wil niet starten",
    chargingDoesNotStart_p1: "Dit kan verschillende oorzaken hebben. Probeer eerst de laadkabel opnieuw aan" +
      "te sluiten. Het kan gebeuren dat de laadpas niet werkt, omdat de laadpaal de" +
      "laadpas niet herkent of bijvoorbeeld nog niet geactiveerd is. Het is dan ook" +
      "verstandig meerdere laadpassen in de auto te hebben, zodat een andere pas" +
      "gebruikt kan worden. Wil de laadsessie vervolgens nog niet starten maak dan een" +
      "melding via ChargeProb.",
    slowCharging: "Het laden gaat erg langzaam",
    slowCharging_p1: "Dit kan verschillende oorzaken hebben. Denk aan batterijniveau van je auto of" +
      "bijvoorbeeld temperatuur. Ook kan de belasting van het elektriciteitsnet een" +
      "oorzaak hebben. Indien er meerdere auto’s aan het laden zijn kan het zijn dat de" +
      "stroom over meerdere auto&#39;s verdeeld wordt en dus de laadsnelheid omlaag" +
      "gaat. Via ChargeProb kan je melding maken van een langzame laadpaal.",
    chargeCardUnrecognized: "De laadpaal herkend de laadpas niet?",
    chargeCardUnrecognized_p1: "De laadpas kan defect zijn, wat betekent dat de chip in de laadpas gebroken of" +
      "beschadigd is. Probeer de laadsessie te starten met een tweede pas. Werkt de" +
      "tweede pas ook niet? Dan is de kans groot dat de RFID reader (paslezer) in uw" +
      "laadpunt defect is. Maak een melding via ChargeProb dan zetten wij deze door" +
      "naar de beheerder.",
    cableStuck: "Mijn laadkabel komt niet los?",
    cableStuck_p1: "Je krijgt je laadkabel niet los. Tijdens het laden vergrendelen de meeste elektrische" +
      "auto’s zodat er geen onverlaat met je laadkabel aan de haal kan gaan. Als je de" +
      "laadsessie stopt, dan moet de auto de laadkabel automatisch ontgrendelen," +
      "maar heel soms gebeurt dat niet. Vooral bij vorst kan het voorkomen dat de" +
      "automatische ontgrendeling hapert. Er is eigenlijk altijd een back-up in de vorm" +
      "van een mechanische ontgrendeling.",
    damagedCharger: "De laadpaal is beschadigd",
    damagedCharger_p1: "Het kan zijn dat de laadpaal nog wel functioneert maar dat hij bijvoorbeeld" +
      "scheef staat doordat er een auto tegenaan is gereden. Ook in deze gevallen" +
      "ontvangen wij graag uw melding zodat we de CPO kunnen informeren dat er een" +
      "herstelactie kan worden ingepland.",
    blockedByNonEVcar: "Geblokkeerd bij niet EV auto",
    blockedByNonEVcar_p1: "Indien de publieke laadpaal wordt geblokkeerd door een niet EV auto is dit" +
      "natuurlijk erg onhandig. Geef deze melding ook door via ChargeProb in de" +
      "toekomst willen we deze rechtstreeks doorzetten naar parkeerbeheer.",
    reportANewProblem: "Wilt u een nieuw probleem melden?",
    reportANewProblem_button: "Maak een melding on ChargeProb!"
  }
};

export const aboutUsContent = {
  English: {
    cofounder: "Co-founder",
    p1: "We believe that as soon as there is a problem with a public charging station, " +
      "this should be known to the EV driver. We do this by linking the technical status of a " +
      "public charging station to reports from the EV driver.",
    p2: "We believe and strive towards digitalizing the process of reporting issues." +
      "We believe that there should be a central place for EV drivers to report a problem with a public charging station. " +
      "The Charge Point Operator (CPO) is then able to SOLVE the incident and communicate the status updates to the EV driver.",
    p3: "This results in more uptime and lower operational costs for the CPO, and higher satisfaction for the EV driver. ",
    ourStory: "Our story",
    ourStory_p1: "We know how difficult it is to rely on technical triggers whether " +
      "the on-site service or appliance functions properly. By combining these technical signals " +
      "with information from the users, we can create good insights into the " +
      "operational status of a public charging station. We believe that all problems " +
      "with public charging stations can be then handled completely digitally.",
    ourStory_p2: "We created ChargeProb to provide a solution to the biggest frustration" +
      "for an EV driver: a malfunctioning charging station. We do this by showing real-time " +
      "technical status and the problems reported by EV drivers. " +
      "We believe that in this way we can reduce operational costs and charging downtime.",
    daveDesc: "I have 8 years of experience in monitoring networks and services in the telecom sector, " +
      "and I also have extensive experience in managing national incidents. " +
      "I know how important it is to use data and customer signals to quickly detect and repair an incident.",
    gianmariaDesc: "I am passionate about programming and software engineering since school times. " +
      "I now have over 6 years of experience within the telecom sector, where I am " +
      "involved in creating a data and digitally driven operational organization.",
    cpFormula: "The ChargeProb Formula",
    cpFormula_transparent: "We make problems with public charging stations transparent. We do this" +
      "by providing insights into reported issues and real-time technical status" +
      "with the users.",
    cpFormula_digitalize: "We digitalize the process for handling failures on public charging stations." +
      "We share reports on social media and offer the CPO the opportunity to communicate digitally" +
      "with the users via one centralized app.",
    cpFormula_lessTime: "By making management information transparent for the CPO, we are able to " +
      "reduce the incidents detection time and charging stations downtime.",
    cpFormula_reports: "We ensure higher customer satisfaction even when there is" +
      "something wrong in the technology.",
    cpFormula_eco: "By facilitating the charging process for users, we contribute to the transition " +
      "to electric transport. This in line with the climate goals.",
    contactUs: "How can you contact us?",
    contactUs_p1: "It would be great to hear from you! We look forward to listen to your questions," +
      "ideas, feedback and any other interesting idea to develop together!",
  },
  Dutch: {
    cofounder: "Medeoprichter",
    p1: "Wij geloven erin dat zodra er een probleem is met een publieke laadpaal dit kenbaar moet zijn " +
      "voor de EV-rijder. Dit doen we door de technische status van een publieke laadpaal te koppelen " +
      "aan signalen van de EV-rijder.",
    p2: "Ons bestaan ligt in het vermogen om het storingsproces te digitaliseren. " +
      "Wij zijn van mening dat er voor de EV-rijder een centrale plek moet zijn om een probleem met een publieke laadpaal te melden. " +
      "De Charge Point Operator (CPO) is vervolgens in staat het incident te herstellen en de status met de EV-rijder te communiceren. ",
    p3: "Dit resulteert in meer uptime en lagere operationele kosten voor de CPO daarnaast een hogere tevredenheid bij de EV-rijder. ",
    ourStory: "Ons verhaal",
    ourStory_p1: "Wij weten uit eerste hand hoe moeilijk het is om vanuit technische triggers te monitoren of " +
      "de dienstverlening op locatie goed functioneert. Door deze technische signalen te " +
      "combineren met informatie vanuit de gebruikers krijg je een goed beeld van de " +
      "operationele status van een publieke laadpaal. Wij zijn van mening dat alle problemen " +
      "met publieke laadpalen volledig digitaal kunnen worden afgehandeld. ",
    ourStory_p2: "We hebben ChargeProb gecreëerd om een oplossing te bieden voor de grootste frustratie" +
      "van de EV-rijder namelijk een niet goed werkende laadpaal. Dit doen we door realtime de" +
      "technische status te laten zien en gemelde problemen door EV-rijders inzichtelijk te maken " +
      "voor andere gebruikers. Onze overtuiging is dat wij operationele kosten kunnen reduceren" +
      "en downtime kunnen verkorten.",
    daveDesc: "Ik heb 8 jaar ervaring in het bewaken van netwerken en diensten in de telecom " +
      "sector, daarnaast heb ik ruime ervaring in het besturen van landelijke incidenten. Ik " +
      "weet hoe belangrijk de inzet van data en klant signalen is om snel een probleem te detecteren en te herstellen.",
    gianmariaDesc: "Gianmaria is op jonge leeftijd in Italië gestart met programmeren. Hij" +
      "heeft inmiddels ruim 6 jaar ervaring binnen de telecom sector in het bouwen" +
      "van een digitaal gedreven operationele organisatie.",
    cpFormula: "De ChargeProb Formule",
    cpFormula_transparent: "Wij maken problemen met publieke laadpalen transparant. Dit doen we door" +
      "gemelde problemen inzichtelijk te maken en realtime technische status te" +
      "delen met de gebruikers.",
    cpFormula_digitalize: "Wij digitaliseren het storingsproces voor publieke laadpalen. " +
      "Delen meldingen op Social media en bieden de CPO de mogelijkheid digitaal te communiceren " +
      "met de gebruikers via één centrale plek.",
    cpFormula_lessTime: "Door management informatie inzichtelijk te maken voor de CPO zijn wij " +
      "in staat de detectietijd en downtime van een storing te reduceren. ",
    cpFormula_reports: "Wij zorgen voor hogere klanttevredenheid ook als er toch " +
      "even iets niet goed gaat in de techniek.",
    cpFormula_eco: "Door de gebruikersvriendelijkheid bij het laden van de EV-rijder " +
      "hoog te houden dragen we bij aan de transitie naar elektrisch vervoer. " +
      "Dit in navolging van de klimaatdoelen. ",
    contactUs: "Hoe kunt u contact met ons opnemen?",
    contactUs_p1: "Het zou geweldig zijn om van je te horen! Kortom, kunt u ons vertellen over uw vragen," +
      "ideeën, feedback of andere interessante ideeën waarmee we u kunnen helpen?",
  }
};

export const searchHereContent = {
  English: {
    searchHereButton: "Search chargers",
    getUserLocation: "Finding your position...", 
    loadMap: "Loading map...", 
    findingChargers: "Finding chargers...",
    zoomInButton: "Zoom in to search",
    zoomInTooltip: "Please zoom in to be able to search chargers",
    noChargersFoundTitle: "We coulnd't find any charging station in this area.",
    noChargersFoundBody: "Try zooming out or search in a different area."
  },
  Dutch: {
    searchHereButton: "Zoek laadpalen",
    getUserLocation: "Je positie zoeken...",
    loadMap: "Loading map...", 
    findingChargers: "Zoeken voor laadpalen...",
    zoomInButton: "a.u.b. inzoomen",
    zoomInTooltip: "a.u.b. inzoomen om laadpalen te zoeken",
    noChargersFoundTitle: "We konden geen laadpaal vinden in dit gebied.",
    noChargersFoundBody: "Probeer uit te zoomen of zoek in een ander gebied."
  }
};

export const navbarContent = {
  English: {
    signin: "Login",
    signout: "Logout",
    signedin: "Signed in",
    aboutChargeProb: "About ChargeProb",
    viewReports: "My reports",
    viewMyFavoriteChargers: "My chargers",
    viewMyProfile: "My profile",
    leaveFeedback: "Leave feedback",
  },
  Dutch: {
    signin: "Inloggen",
    signout: "Uitloggen",
    signedin: "Ingelogd",
    aboutChargeProb: "Over ChargeProb",
    viewReports: "Mijn meldingen",
    viewMyFavoriteChargers: "Favoriete laadpalen",
    viewMyProfile: "Mijn profiel",
    leaveFeedback: "Schrijf feedback",
  }
};

export const homePageFooterContent = {
  English: {
    aboutChargeProb: "About ChargeProb",
    privacyStatement: "Privacy Statement",
    termsAndConditions: "Terms and Conditions",
    map: "Map",
    cpos: "CPOs",
  },
  Dutch: {
    aboutChargeProb: "Over ChargeProb",
    privacyStatement: "Privacy Verklaring",
    termsAndConditions: "Algemene voorwaarden",
    map: "Map",
    cpos: "CPOs",
  }
};

export const mapContent = {
  English: {
    legendContent: '<b>Operational status:</b>' +
      '<li class="legendAvailable"><b> No problems </b></li>' +
      '<li class="legendWarning"><b> Warning </b></li>' +
      '<li class="legendUnavailable"><b> Unavailable </b></li>' +
      '<li class="legendUnknown"><b> Unknown </b></li>',
    legendContentTitle: "Operational status",
    legendContentAvailable: "Available",
    legendContentWarning: "Warning",
    legendContentUnavailable: "Unavailable",
    legendContentHalfAvailable_1: "Partially",
    legendContentHalfAvailable_2: "available",
    legendContentUnknown: "Unknown",
    legendContentAvailable_text1: "The charging station is currently working without problems, we see normal activity.",
    legendContentAvailable_text2: "No incident has been reported by the charge point operator or by other e-drivers.",
    legendContentWarning_text1: "An e-driver has reported a problem on this charging station",
    legendContentWarning_text2: "We have not yet received confirmation from the charge point operator that the charging station is not functioning.",
    legendContentWarning_text3: "It is also possible that the charging station is affected by a local power failure. By clicking on the charging station, you will find more details about the problem.",
    legendContentHalfAvailable_text1: "This indicates that part of the charging station is technically unavailable.",
    legendContentHalfAvailable_text2: "The charge point operator indicates that one or more of the sockets in the charging station are out of order. The charge point operator could provide a status update on the problem.",
    legendContentHalfAvailable_text3: "By clicking on the charging station, you will find more details about the incident.",
    legendContentUnavailable_text1: "This indicates that the charging station is technically unavailable.",
    legendContentUnavailable_text2: "The charge point operator indicates that the charging station is out of order. The charge point operator could provide a status update on the problem.",
    legendContentUnavailable_text3: "By clicking on the charging station, you will find more details about the incident.",
    legendContentUnknown_text1: "This means that unfortunately we don't have any insight into the operational status of the charger.",
    legendMissingCharger: "Charging station not on the map?",
  },
  Dutch: {
    legendContent: '<b>Operational status:</b>' +
      '<li class="legendAvailable"><b> Beschikbaar </b></li>' +
      '<li class="legendWarning"><b> storing gemeld </b></li>' +
      '<li class="legendUnavailable"><b> Onbeschikbaar </b></li>' +
      '<li class="legendUnknown"><b> Onbekend </b></li>',
    legendContentTitle: "Operational status",
    legendContentAvailable: "Beschikbaar",
    legendContentWarning: "Storing gemeld",
    legendContentUnavailable: "Onbeschikbaar",
    legendContentHalfAvailable_1: "Gedeeltelijk",
    legendContentHalfAvailable_2: "beschikbaar",
    legendContentUnknown: "Onbekend",
    legendContentAvailable_text1: "De laadpaal werkt op dit moment zonder problemen, we zien normale activiteit.",
    legendContentAvailable_text2: "Er is geen storing gemeld door de laadpaal beheerder of e-rijders.",
    legendContentWarning_text1: "Een e-rijder heeft een storing gemeld op deze laadpaal.",
    legendContentWarning_text2: "De laadpaal beheerder heeft nog niet bevestigd dat de laadpaal niet meer functioneert.",
    legendContentWarning_text3: "Ook kan het zijn dat de laadpaal betrokken is bij een lokale stroomstoring. Door op de laadpaal te klikken vind je meer details over de storing.",
    legendContentHalfAvailable_text1: "Dit geeft aan dat een deel van het laadpaal technisch onbeschikbaar is.",
    legendContentHalfAvailable_text2: "De laadpaal beheerder heeft bevestigd dat één of meerdere sockets in de laadpaal defect zijn.  De laadpaal beheerder heeft de mogelijkheid de storing van een status update te voorzien.",
    legendContentHalfAvailable_text3: "Door op de laadpaal te klikken vind je meer details over de storing.",
    legendContentUnavailable_text1: "Dit geeft aan dat de laadpaal technisch onbeschikbaar is.",
    legendContentUnavailable_text2: "De laadpaal beheerder heeft bevestigd dat de laadpaal buiten dienst is. De laadpaal beheerder heeft de mogelijkheid de storing van een status update te voorzien.",
    legendContentUnavailable_text3: "Door op de laadpaal te klikken vind je meer details over de storing.",
    legendContentUnknown_text1: "Dit betekent dat we helaas geen inzicht hebben in de operationele status van de laadpaal.",
    legendMissingCharger: "Laadpaal niet op de kaart?",
  },
}

export const buttonsContent = {
  English: {
    login: "Login",
    logout: "Logout",
    register: "Sign up",
    unsubscribe: "Unsubscribe",
    recoverPassword: "Recover password",
    sendActivationLink: "Send activation link",
    activateFail_sendNewLink_btn: "Click here to receive a new activation link",
    activateFail_sendingNewLink_btn: "Sending new link...",
    back: "Back",
    close: "Close",
    proceed: "Proceed",
    update: "Update",
    updating: "Updating...",
    or: "or",
    saveUserInfo: "Saving your info...",
    submit: "Submit",
    sendReport: "Send report",
    sendingReport: "Sending report...",
    sendReportAnonymous: "Send anonymous report",
    bringMeThere: "Show charger on map",
    showSockets: "Show all sockets",
    showChargerStatus: "Show charger status",
    showReportsOnCharger: "Show all reports on charger",
    removeFromFavoriteChargers: "Remove from favorites",
    createReportFromFavoriteChargers: "Report new incident",
    userChangePassword: "Change password",
    userAddPhoneNumber: "Add phone number",
    userChangePhoneNumber: "Change number",
    userRemovePhoneNumber: "Remove number",
    userAddCarType: "Add your car",
    userChangeCarType: "Change car",
    userRemoveCarType: "Remove car",
    createComment: "Leave a comment",
    createFirstComment: "Write the first comment !",
    postComment: "Post comment",
    postFeedback: "Post feedback",
    doNotShowAgain:"Do not show again",
    checkChargeProb: "Discover ChargeProb!",
  },
  Dutch: {
    login: "Inloggen",
    logout: "Uitloggen",
    register: "Aanmelden",
    unsubscribe: "Afmelden",
    sendActivationLink: "Nieuwe activeringslink verzenden",
    activateFail_sendNewLink_btn: "Klik hier om een nieuwe activatielink te ontvangen",
    activateFail_sendingNewLink_btn: "Nieuwe link verzenden...",
    recoverPassword: "Wachtwoord herstellen",
    back: "Terug",
    close: "Sluiten",
    proceed: "Doorgaan",
    update: "Update",
    updating: "Updating...",
    or: "of",
    saveUserInfo: "Je gegevens opslaan...",
    submit: "indienen",
    sendReport: "Stuur melding",
    sendingReport: "Melding sturen...",
    sendReportAnonymous: "Anoniem melding versturen",
    bringMeThere: "Toon laadpaal op kaart",
    showSockets: "Zie alle sockets",
    showChargerStatus: "Zie laadpaal status",
    showReportsOnCharger: "Zie alle meldingen",
    removeFromFavoriteChargers: "Verwijder van favorieten",
    createReportFromFavoriteChargers: "Meld een storing",
    userChangePassword: "Verander wachtwoord",
    userAddPhoneNumber: "Voeg telefoonnummer toe",
    userChangePhoneNumber: "Nummer wijzigen",
    userRemovePhoneNumber: "Verwijder nummer",
    userAddCarType: "Voeg auto toe",
    userChangeCarType: "Auto wijzigen",
    userRemoveCarType: "Verwijder auto",
    createComment: "Schrijf een reactie",
    createFirstComment: "Schrijf de eerste reactie!",
    postComment: "Plaats reactie",
    postFeedback: "Plaats feedback",
    doNotShowAgain:"Niet meer laten zien",
    checkChargeProb: "Ontdek ChargeProb!",
  }
}

export const loginToastContent = {
  English: {
    welcome: "Welcome!",
    toastMsgLogin: "Thanks for logging in!",
    toastMsgSignup: "Thank you for registering with us! We sent you an email with the instructions to activate your account.",
  },
  Dutch: {
    welcome: "Welcome!",
    toastMsgLogin: "Bedankt voor het inloggen!",
    toastMsgSignup: "Bedankt voor het registreren! We hebben je een e-mail gestuurd met instructies om je account te activeren.",
  }
}

export const welcomeToCPContent = {
  English: {
    title: "Problems with a public charging station?",
    p1: "Ever been at a broken charging station? Or having long calls when in need to report a problem?",
    p2: "Report your problem easily with ChargeProb and we make it visible and transparent to other e-drivers!",
    searchStation: "Find public charging stations near you and avoid malfunctioning stations.",
    checkStatus: "Check the status of a charging station.",
    reportProblem: "Report quickly a malfunction and immediately inform other e-drivers.",
    notifications: "Save your favorite charging stations and we keep you updated with their status!",
  },
  Dutch: {
    title: "Problemen met een publieke laadpaal?",
    p1: "Nooit meer bij een defecte laadpaal staan? Of lang in de wacht bij het telefonisch melden van een storing?",
    p2: "Meld je laadpaal storing eenvoudig via ChargeProb en wij maken dit direct zichtbaar voor alle e-rijders!",
    searchStation: "Zoek een laadpaal bij jou in de buurt en vermijd een defecte laadpaal.",
    checkStatus: "Check de laadpaal status.",
    reportProblem: "Meld eenvoudig een laadpaal probleem en informeer hiermee direct andere e-rijders.",
    notifications: "Zet een laadpaal in je favorieten en ChargeProb houdt je op de hoogte over de status!",
  }
}

export const shareOnSocialsContent = {
  English: {
    title: "Share a link to this charger",
    facebookText: "Check out this EV charging station in ChargeProb!",
    twitterTitle: "Check out this EV charging station in ChargeProb ",
    whatsappTitle: "Check out this EV charging station in ChargeProb ",
    telegramTitle: "Check out this EV charging station in ChargeProb ",
    linkedinTitle: "EV charging station",
    linkedinSummary: "Check out this EV charging station in ChargeProb ",
    emailSubject: "New EV charging station shared with you",
    emailBody: "Check out this EV charging station in ChargeProb",
  },
  Dutch: {
    title: "Deel een link naar deze laadpaal",
    facebookText: "Bekijk deze EV laadpaal in ChargeProb!",
    twitterTitle: "Bekijk deze EV laadpaal in ChargeProb ",
    whatsappTitle: "Bekijk deze EV laadpaal in ChargeProb ",
    telegramTitle: "Bekijk deze EV laadpaal in ChargeProb ",
    linkedinTitle: "EV laadpaal",
    linkedinSummary: "Bekijk deze EV laadpaal in ChargeProb ",
    emailSubject: "Nieuw EV laadpaal met je gedeeld",
    emailBody: "Bekijk deze EV laadpaal in ChargeProb",
  }
}

export const loginContent = {
  English: {
    loginTitle: "Login",
    loginReportTitle: "Submit your report",
    registerTitle: "Signup",
    changePasswordTitle: "Change password",
    goToLogin: "Already a user? ",
    goToLoginLink: " Sign in",
    goToSignup: "Not a member? ",
    goToSignupLink: " Sign up",
    stayAnonymous: "Don't want to login? ",
    stayAnonymousLink: " Continue without logging in",
    registerText: "Signup to stay updated on incidents!",
    loginError: "Email or password incorrect. Please provide valid credentials.",
    googleLoginError: "Sorry, we are having troubles contacting the Google services. Please try again later.",
    loginFavChargerTitle: "Login to stay updated!",
    loginFavCharger_p1: "Login to subscribe to this charging station and get realtime updates on its status!",
    loginToSendReportTitle: "Login first to stay updated!",
    loginToSendReport_p1: "If you first login, we will keep you updated on any notification and update on this charging station and on your report!",
    registerErrorGeneric: "Sorry, we are having some troubles registering your account at the moment.",
    registerErrorAlreadyExists: "An user with this email already exists. Please login or change email.",
    newUserEmailNotConfirmedError_title: "Thank you!",
    newUserEmailNotConfirmedError_p1: "We are happy to have you onboard!",
    newUserEmailNotConfirmedError_p2: "Please confirm your email address by clicking on the confirmation link we sent to your email.",
    emailNotConfirmedError_title: "Your account is not active!",
    emailNotConfirmedError_p1: "We noticed that your email address has not been verified yet.",
    emailNotConfirmedError_p2: "To report an incident on this charging station, please confirm your email address by clicking on the confirmation link we sent to your email.",
    emailError: "Please enter a valid email address.",
    phoneNumberError: "Please enter a valid phone number (+31612345678).",
    repeatPasswordError: "The passwords are different. Please enter the same password.",
    forgotPasswordLink: "Forgot password?",
    forgotPasswordTitle: "Recover your password",
    forgotPasswordInstructions: "Please enter your email to recover your password",
    passwordEmptyError: "Please set a password.",
    passwordLengthError: "The password must have more than 8 characters.",
    passwordCaseError: "The password must have both lowercase and uppercase characters.",
    passwordDigitError: "The password must have at least one digit.",
    passwordSpecialCharError: "The password must have at least one special character (#?!@$%^&*-).",
    passwordsAreDifferent: "The passwords are different. Please enter the same password.",
    passwordRequirements: "Password must be longer than 8 characters, and must contain at least one digit and one special character (#?!@$%^&*-).",
    changePasswordWrongOldPassword: "Your old passord is not correct. Please re-enter your old password and try again.",
    changePasswordWrongNewPassword: "Your new password is not valid. Please enter a valid new password.",
    changePasswordServerError: "Sorry, it is not possible to change your password now. Please try again later.",
    changePasswordSuccessTitle: "Password changed",
    changePasswordSuccess_p1: "Your password had been changed. You can now login with your new password.",
    changePasswordEnterEmail: "Please type your email.",
    changePasswordSendEmailSuccess_p1: "We sent you an email with the instructions to reset your password.",
    changePasswordSendEmailError: "Sorry, we are having some troubles processing your request. Please try again later.",
    changePasswordEmailNotValid: "The email you entered is not valid, or there isn't any user registered with this email. Please enter a valid email address or register a new account with this email address.",
    changePasswordLinkNotValid: "Your link is not valid anymore. Please request a new password reset link in the login page in https://www.chargeprob.com .",
    changePasswordSucccess_p1: "Your password has been successfully updated.",
    changePasswordSucccess_p2: " You can now login with your new password in ",
    sendNewActivationLinkTitle_success: "Thanks",

  },
  Dutch: {
    loginTitle: "Inloggen",
    loginReportTitle: "Dien uw melding in",
    registerTitle: "Aanmelden",
    changePasswordTitle: "Verander wachtwoord",
    goToLogin: "Al een gebruiker? ",
    goToLoginLink: " Inloggen",
    goToSignup: "Geen lid? ",
    goToSignupLink: " Aanmelden",
    stayAnonymous: "Wil je niet inloggen? ",
    stayAnonymousLink: " Ga verder zonder in te loggen",
    registerText: "Aanmelden om op de hoogte te blijven over storingen!",
    loginError: "Email of wachtwoord is incorrect. Graag juiste gegevens invoeren.",
    googleLoginError: "Sorry, we hebben problemen met het maken van contact met de Google-services. Probeer het later opnieuw a.u.b.",
    loginFavChargerTitle: "Inloggen om op de hoogte te blijven!",
    loginFavCharger_p1: "Inloggen om realtime updates te ontvangen over de laadpaal status!",
    loginToSendReportTitle: "Log eerst in om op de hoogte te blijven!",
    loginToSendReport_p1: "Als u inlogt, houden wij je op de hoogte van eventuele meldingen en updates over deze laadpaal en over jouw melding!",
    registerErrorGeneric: "Sorry, we ondervinden momenteel problemen bij het registreren van je account.",
    registerErrorAlreadyExists: "Er bestaat al een gebruiker met dit e-mailadres. Gelieve in te loggen of e-mail te wijzigen.",
    newUserEmailNotConfirmedError_title: "Thanks!",
    newUserEmailNotConfirmedError_p1: "We zijn blij je aan boord te hebben!",
    newUserEmailNotConfirmedError_p2: "A.u.b bevestig je e-mailadres door op de bevestigingslink te klikken die we naar je e-mail hebben gestuurd.",
    emailNotConfirmedError_title: "Je account is niet actief!",
    emailNotConfirmedError_p1: "We hebben gemerkt dat je e-mailadres nog niet is geverifieerd.",
    emailNotConfirmedError_p2: "Als u een probleem met deze laadpaal wilt melden, a.u.b bevestigt u je e-mailadres door op de bevestigingslink te klikken die we naar je e-mail hebben gestuurd.",
    emailError: "Gelieve een geldig e-mailadres in te geven.",
    phoneNumberError: "Voer een geldig telefoonnummer in (+31612345678) a.u.b.",
    repeatPasswordError: "De wachtwoorden zijn verschillend. Voer hetzelfde wachtwoord in.",
    forgotPasswordLink: "Wachtwoord vergeten?",
    forgotPasswordTitle: "Herstel je wachtwoord",
    forgotPasswordInstructions: "Voer je e-mailadres in om je wachtwoord te herstellen",
    passwordEmptyError: "Stel een wachtwoord in a.u.b.",
    passwordLengthError: "Het wachtwoord moet meer dan 8 tekens bevatten.",
    passwordCaseError: "Het wachtwoord moet zowel kleine letters als hoofdletters bevatten.",
    passwordDigitError: "Het wachtwoord moet uit minimaal één cijfer bestaan.",
    passwordSpecialCharError: "Het wachtwoord moet minstens één speciaal teken hebben (#?!@$%^&*-).",
    passwordsAreDifferent: "De wachtwoorden zijn verschillend. Voer hetzelfde wachtwoord in.",
    passwordRequirements: "Het wachtwoord moet langer zijn dan 8 tekens en moet minimaal één cijfer en één speciaal teken bevatten (#?!@$%^&*-).",
    changePasswordWrongOldPassword: "Je oude wachtwoord is niet correct. Voer je oude wachtwoord opnieuw in en probeer het opnieuw a.u.b.",
    changePasswordWrongNewPassword: "Je nieuwe wachtwoord is niet geldig. Voer een geldig nieuw wachtwoord in.",
    changePasswordServerError: "Het is helaas niet mogelijk om je wachtwoord nu te wijzigen. Probeer het later opnieuw a.u.b.",
    changePasswordSuccessTitle: "Wachtwoord veranderd",
    changePasswordSuccess_p1: "Je wachtwoord is gewijzigd. Je kunt nu inloggen met je nieuwe wachtwoord.",
    changePasswordEnterEmail: "Vul je e-mailadres in a.u.b.",
    changePasswordSendEmailSuccess_p1: "We hebben je een e-mail gestuurd met instructies om je wachtwoord opnieuw in te stellen.",
    changePasswordSendEmailError: "Sorry, we hebben wat problemen met het verwerken van je verzoek. Probeer het later opnieuw a.u.b.",
    changePasswordEmailNotValid: "Het e-mailadres dat je hebt ingevoerd is niet geldig, of er is geen gebruiker geregistreerd met dit e-mailadres. Voer een geldig e-mailadres in of registreer een nieuw account met dit e-mailadres",
    changePasswordLinkNotValid: "Je link is niet meer geldig. Vraag een nieuwe link voor het opnieuw instellen van het wachtwoord aan op de inlogpagina van https://www.chargeprob.com .",
    changePasswordSucccess_p1: "Je wachtwoord is succesvol geupdate.",
    changePasswordSucccess_p2: "Je kunt nu inloggen met je nieuwe wachtwoord in ",
  }
}

export const chargerStatusContent = {
  English: {
    available: "Available",
    unavailable: "UNAVAILABLE",
    warning: "WARNING",
    unknown: "Status Unknown",
    noIssuesReported: "No incidents reported",
    report: "incident reported",
    reports: "incidents reported",
    recentReport: "New incident reported recently!",
    reportDaysAgo: "days ago",
    availableExt: "No reports created on this charging station yet.",
    availableWarningExt: "There are no recent reports on this charging station, but some e-drivers reported problems in the past.",
    unavailableExt: "There are recent incidents reported on this charging station.",
    warningExt: "Other e-drivers have recently reported incidents with this charging station.",
    unknownExt: "Sorry, we don't have any status information about this charging station.",
    lastUpdated: "Last updated 3 mins ago",
    tellMeMore: "See most recent reports!"
  },
  Dutch: {
    available: "Beschikbaar",
    unavailable: "ONBESCHIKBAAR",
    warning: "STORING GEMELD",
    unknown: "Status Onbekend",
    noIssuesReported: "Geen storingen gemeld",
    report: "storing gemeld",
    reports: "storingen gemeld",
    recentReport: "Nieuwe recente melding!",
    reportDaysAgo: "dagen geleden",
    availableExt: "Geen storingen gemeld op deze laadpaal.",
    availableWarningExt: "Er zijn geen recente storingen gemeld op deze laadpaal, maar andere e-rijders hebben eerdere storingen gemeld.",
    unavailableExt: "Er is momenteel een storing op deze laadpaal.",
    warningExt: "Andere e-rijders hebben onlangs een storing gemeld.",
    unknownExt: "Sorry, helaas hebben we geen status informatie over deze laadpaal.",
    lastUpdated: "Laatste update 3 min geleden",
    tellMeMore: "Zie recente meldingen!"
  }
}

export const chargerSocketsContent = {
  English: {
    socket: "socket",
    sockets: "sockets",
    availableNow: " available now!",
    notAvailableNow: " available now",
    noEVSESonCharger_p1: "Sorry, we are having troubles retrieving the socket information for this charging station.",
    noEVSESonCharger_p2: "Please try again later.",
  },
  Dutch: {
    socket: "socket",
    sockets: "sockets",
    availableNow: " nu beschikbaar!",
    notAvailableNow: " nu beschikbaar",
    noEVSESonCharger_p1: "Sorry, we hebben problemen met het ophalen van de sockets voor deze laadpaal.",
    noEVSESonCharger_p2: "Probeer het later opnieuw a.u.b.",
  }
}

export const createCommentOnChargerContent = {
  English: {
    userExperience: "How is your experience with this charging station?",
    leaveComment: "Leave a comment that might help other e-drivers, or ask a question about this charging station!",
    emptyCommentErrorMsg: "Please leave a comment in the space above.",
  },
  Dutch: {
    userExperience: "Wat is jouw ervaring met deze laadpaal?",
    leaveComment: "Laat een reactie achter die andere e-rijders kan helpen, of stel hier een vraag over deze laadpaal!",
    emptyCommentErrorMsg: "Laat een reactie achter in de ruimte hierboven a.u.b.",
  }
}

export const leaveFeedbackContent = {
  English: {
    userExperience: "How is your experience with ChargeProb?",
    leaveFeedback: "We are happy to know about your experience with us! Your feedback not only helps us, it helps other users of ChargeProb. The feedback is anonymous, but if you like to be contacted you can leave your email address.",
    emptyFeedbackErrorMsg: "Please write some text in the space above.",
    contactEmail: "Leave your email below if you like to have a follow-up on your feedback.",
    contactEmailErrorMsg: "Please enter a valid email address.",
    success_p1: "Thank you for your feedback!",
    success_p2: "We appreciate you taking the time to leave a feedback.",
    success_p3: "This will help us improve ChargeProb for you and other e-drivers.",
    fail_p1: "Sorry, we are experiencing some problems at the moment and we are unable to post your feedback.",
    fail_p2: "Your feedback was not created. Please try again later.",
  },
  Dutch: {
    userExperience: "Wat is jouw ervaring met ChargeProb?",
    leaveFeedback: "We zijn blij om te weten over uw ervaring met ons! Uw feedback helpt niet alleen ons, het helpt ook andere gebruikers van ChargeProb. De feedback is anoniem, maar als u graag gecontacteerd wilt worden kunt u uw e-mailadres achterlaten.",
    emptyFeedbackErrorMsg: "Schrijf wat tekst in de ruimte hierboven a.u.b.",
    contactEmail: "Laat hieronder uw e-mailadres achter als u een vervolg op uw feedback wilt hebben.",
    contactEmailErrorMsg: "Gelieve een geldig e-mailadres in te vullen.",
    success_p1: "Bedankt voor jouw feedback!",
    success_p2: "We stellen het op prijs dat je de tijd heeft genomen om een feedback achter te laten.",
    success_p3: "Dit helpt ons om ChargeProb voor jou en andere e-rijders te verbeteren.",
    fail_p1: "Sorry, we ondervinden momenteel wat problemen en we kunnen je feedback niet plaatsen",
    fail_p2: "Je feedback is niet geplaatst. Probeer het later opnieuw a.u.b.",
  }
}

export const missingChargerContent = {
  English: {
    nothingSelectedErrorMsg: "Please choose one option.",
    noAddressErrorMsg: "Please tell us the closest addres to this charging station.",
    title: "A charging station is not shown on the map and it has some issues?",
    subtitle: "Please provide us with all information about the charging station. Most information can be found on the sticker on the charging station. We will forward your report to the charging station administrator.",
    newIncidentChargerNotWork: "Charger does not work",
    newIncidentSlowCharging: "Slow charging",
    newIncidentStartStop: "Charging does not start/stop",
    newIncidentOtherIssue: "Other issue",
    address: "Address",
    addressPlaceholder: "street, house number and city or postal code",
    cpo: "Charging station administrator",
    chargerId: "Is there a number or code printed on the charging station? If so, please write it here.",
    chargerNumber: "Charging station number/code",
    etraDetails: "More info about this charging station",
    missingChargerProblem: "There is an issue with this charger",
    uploadPicture: "Upload a picture of the charging station",
    uploadImage: "Upload photo",
    uploadImageUploaded: "Photo uploaded",
    removeImage: "Remove photo",
    takePhoto: "Take photo",
    startCameraError: "Sorry, something went wrong with starting your camera. Please try again later and check that you gave permissions to use your camera on this device.",
    confirmTitle: "Check if all the information about the missing charging station are correct.",
    confirmIssue: "Problem with this charging station",
    contactEmail: "Leave your email here so we can keep you informed",
    contactEmailErrorMsg: "Please enter a valid email address.",
    confirmedOkTitle: "Thanks!", 
    confirmedFailTitle: "Sorry, something went wrong", 
    confirmedOk_p1: "We correctly received your information about a charging station which is not shown in the map.",
    confirmedOk_p2: "Your information will be forwarded to the charging station administrator and, if possible, the charging station will be added to the map.",
    confirmedFail_p1: "We are having troubles processing your request.",  
    confirmedFail_p2: "Please try again later.",
    confirmedFailTooManyReq_p1: "You already sent too many notifications about missing charging stations today.",  
    confirmedFailTooManyReq_p2: "You can submit new missing charging stations tomorrow.",
  },
  Dutch: {
    nothingSelectedErrorMsg: "Kies één optie a.u.b.",
    noAddressErrorMsg: "Vertel ons alstublieft het adres dat het dichtst bij deze laadpaal ligt",
    title: "Laadpaal niet op de kaart maar toch een probleem?",
    subtitle: "Geef ons alle informatie door over de laadpaal. De meeste informatie vind u op de sticker van de laadpaal. Wij geven de melding door aan de beheerder.",
    newIncidentChargerNotWork: "Laadpaal werkt niet",
    newIncidentSlowCharging: "Laadsnelheid langzaam",
    newIncidentStartStop: "Laadproces start/stopt niet",
    newIncidentOtherIssue: "Ander probleem",
    address: "Adres",
    addressPlaceholder: "straat, huisnummer en stad of postcode",
    cpo: "Laadpaal beheerder",
    chargerId: "Staat er een nummer of code op de laadpaal? Zo ja, geef deze dan hier aan ons door a.u.b.",
    chargerNumber: "Laadpaal nummer/code",
    extraDetails: "Meer details over deze laadpaal",
    missingChargerProblem: "Er is een probleem met deze laadpaal",
    uploadPicture: "Upload een foto van de laadpaal",
    uploadImage: "Upload foto",
    uploadImageUploaded: "Foto geüpload",
    removeImage: "Foto verwijderen",
    takePhoto: "Foto maken",
    startCameraError: "Sorry, er is iets misgegaan bij het starten van je camera. Probeer het later opnieuw en controleer of je toestemming hebt gegeven om je camera op dit apparaat te gebruiken.",
    confirmTitle: "Controleer of alle gegevens over de ontbrekende laadpaal kloppen.",
    confirmIssue: "Probleem met deze laadpaal",
    contactEmail: "Laat hier je e-mail achter zodat we je op de hoogte kunnen houden",
    contactEmailErrorMsg: "Gelieve een geldig e-mailadres in te vullen.",
    confirmedOkTitle: "Dankjewel!", 
    confirmedFailTitle: "Sorry, er is iets fout gegaan.", 
    confirmedOk_p1: "Wij hebben de gegevens correct ontvangen.",
    confirmedOk_p2: "De gegevens  worden doorgegeven aan de beheerder van de laadpaal en indien mogelijk wordt de laadpaal toegevoegd aan de kaart.",
    confirmedFail_p1: "We hebben problemen met het verwerken van uw verzoek.",  
    confirmedFail_p2: "Probeer het later opnieuw a.u.b.",
    confirmedFailTooManyReq_p1: "Je hebt vandaag al te veel meldingen verzonden over ontbrekende laadpalen.",  
    confirmedFailTooManyReq_p2: "Nieuwe ontbrekende laadpalen kunt u morgen doorgeven.",
  }
}


export const reportIncidentContent = {
  English: {
    button: "Report new incident",
    nothingSelectedErrorMsg: "Please choose one option.",
    noExtraDetailsErrorMsg: "You selected 'Other issue', so please give us some more details on the problem you are experiencing with this charging station.",
    title: "Tell us more about the issue you are experiencing with this charging station!",
    newIncidentTitle: "What is the issue with this charging station?",
    newIncidentChargerNotWork: "Charger does not work",
    newIncidentSlowCharging: "Slow charging",
    newIncidentStartStop: "Charging does not start/stop",
    newIncidentOtherIssue: "Other issue",
    newIncidentOption0: "Select an issue",
    newIncidentOption1: "Charger does not work",
    newIncidentOption2: "No energy",
    newIncidentOption3: "Slow charging",
    newIncidentOption4: "Charging does not start",
    newIncidentOption5: "Charging does not stop",
    newIncidentOption6: "Damaged",
    newIncidentOption7: "Blocked by not-EV car",
    newIncidentOption8: "Other issue",
    affectedChargerAll: "Complete charger",
    affectedChargerLeft: "Left socket",
    affectedChargerRight: "Right socket",
    affectedChargerNotSure: "Not sure",
    affectedChargerUnknown: "Unknown socket name",
    newIncidentExtraDetails1: "Notes about the incident",
    newIncidentExtraDetails2: "Please give us some more details on the problem with this charging station.",
    uploadImage: "Upload photo",
    uploadImageUploaded: "Photo uploaded",
    removeImage: "Remove photo",
    takePhoto: "Take photo",
    startCameraError: "Sorry, something went wrong with starting your camera. Please try again later and check that you gave permissions to use your camera on this device.",
  },
  Dutch: {
    button: "Meld een storing",
    nothingSelectedErrorMsg: "Kies één optie a.u.b.",
    noExtraDetailsErrorMsg: "U kiest 'Andere storing', vertel ons meer over de storing welke je ervaart a.u.b.",
    title: "Vertel ons meer over de storing welke je ervaart.",
    newIncidentTitle: "Wat is het probleem met deze laadpaal?",
    newIncidentChargerNotWork: "Laadpaal werkt niet",
    newIncidentSlowCharging: "Laadsnelheid langzaam",
    newIncidentStartStop: "Laadproces start/stopt niet",
    newIncidentOtherIssue: "Ander probleem",
    newIncidentOption0: "Kies een type storing",
    newIncidentOption1: "Laadpaal werkt niet",
    newIncidentOption2: "Geen stroom aanwezig",
    newIncidentOption3: "Laadsnelheid langzaam",
    newIncidentOption4: "Laadproces start niet",
    newIncidentOption5: "Laadproces stopt niet",
    newIncidentOption6: "Beschadigd",
    newIncidentOption7: "Geblokkeerd door niet EV auto",
    newIncidentOption8: "Ander probleem",
    affectedChargerAll: "Complete laadpaal",
    affectedChargerLeft: "Linker socket",
    affectedChargerRight: "Rechter socket",
    affectedChargerNotSure: "Ik weet het niet",
    affectedChargerUnknown: "Onbekende socket naam",
    newIncidentExtraDetails1: "Omschrijving van de melding.",
    newIncidentExtraDetails2: "Geef ons meer details over het probleem met deze laadpaal.",
    uploadImage: "Upload foto",
    uploadImageUploaded: "Foto geüpload",
    removeImage: "Foto verwijderen",
    takePhoto: "Foto maken",
    startCameraError: "Sorry, er is iets misgegaan bij het starten van je camera. Probeer het later opnieuw en controleer of je toestemming hebt gegeven om je camera op dit apparaat te gebruiken.",
  }
}

export const confirmNewIncidentContent = {
  English: {
    title: "Confirm and send your report",
    mainText: "Is everything correct? If so click on Send Incident, otherwise go Back and fill in the correct information.",
    problem: "Problem",
    proceed: "If you proceed, the incident will be reported to the Charge Point Operator!",
  },
  Dutch: {
    title: "Bevestig en verzend je melding",
    mainText: "Klopt alles? Klik in dat geval op Stuur melding, ga anders terug en vul de juiste gegevens in.",
    problem: "Storing",
    proceed: "Als je doorgaat, wordt de storing gemeld aan de laadpaal beheerder en inzichtelijk gemaakt voor andere e-rijders!"
  }
}

export const incidentSentContent = {
  English: {
    success_p1: "Thank you for reporting this incident!",
    success_p2: "With this report you help other e-drivers which could experience the same problem.",
    success_p3: "Unfortunately the operator of this charging station is not yet onboarded on our platform. We will keep you informed by e-mail about the status of the charging station.",
    fail_p1: "Sorry, we are experiencing some problems at the moment.",
    fail_p2: "Your incident was not created. Please try again later.",
    fail_toomany_samecharger_p1: "You already reported an incident on this charging station recently.",
    fail_toomany_samecharger_p2: "Thank you for your previous report, the other drivers are now aware about a possible problem on this charger!",
    fail_toomany_sameday_p1: "You already reported too many incidents in the last day.",
    fail_toomany_sameday_p2: "Sorry, you can not create too many reports on the same day.",
  },
  Dutch: {
    success_p1: "Bedankt voor het melden van je storing!",
    success_p2: "Door deze melding help je andere e-rijders welke mogelijk hetzelfde probleem ervaren.",
    success_p3: "Helaas is de beheerder van deze laadpaal nog niet aangesloten op ons platform. Geen zorgen wij houden je gewoon op de hoogte per e-mail over de status van de laadpaal. ",
    fail_p1: "Sorry, we ondervinden momenteel wat problemen.",
    fail_p2: "Uw melding is niet gemaakt. Probeer het later opnieuw a.u.b.",
    fail_toomany_samecharger_p1: "U heeft onlangs reeds een storing gemeld op deze laadpaal.",
    fail_toomany_samecharger_p2: "Bedankt voor de eerder gemaakte melding, andere e-rijders zijn hierdoor ook op de hoogte van een mogelijk probleem!",
    fail_toomany_sameday_p1: "U heeft vandaag al te veel meldingen gemaakt.",
    fail_toomany_sameday_p2: "Sorry, u heeft het limiet van aantal meldingen op één dag bereikt.",
  }
}

export const commentSentContent = {
  English: {
    success_p1: "Thank you for your comment!",
    success_p2: "We appreciate you taking the time to leave a comment on this charging station.",
    success_p3: "This will help and inform other e-drivers.",
    fail_p1: "Sorry, we are experiencing some problems at the moment and we are unable to post your comment.",
    fail_p2: "Your comment was not created. Please try again later.",
    fail_toomany_samecharger_p1: "You already posted too many comments on this charging station today.",
    fail_toomany_samecharger_p2: "We like to keep the comments short to avoid having uninformative comments on a charging station.",
    fail_toomany_samecharger_p3: "If you still want to share something on this charging station, please try again tomorrow.",
    fail_toomany_sameday_p1: "You already posted too many comments today.",
    fail_toomany_sameday_p2: "We like to keep the comments short to avoid having uninformative comments on a charging station.",
    fail_toomany_sameday_p3: "If you still want to share something on this charging station, please try again tomorrow.",
  },
  Dutch: {
    success_p1: "Bedankt voor jouw reactie!",
    success_p2: "We stellen het op prijs dat je de tijd heeft genomen om een reactie over deze laadpaal achter te laten.",
    success_p3: "Dit zal andere e-rijders helpen en informeren.",
    fail_p1: "Sorry, we ondervinden momenteel wat problemen en we kunnen je reactie niet plaatsen.",
    fail_p2: "Sorry, we ondervinden momenteel wat problemen en we kunnen je reactie niet plaatsen.",
    fail_toomany_samecharger_p1: "Je hebt vandaag al meerdere reacties over deze laadpaal geplaatst.",
    fail_toomany_samecharger_p2: "We houden de reacties graag kort om te voorkomen dat we niet-informatieve reacties over een laadpaal hebben.",
    fail_toomany_samecharger_p3: "Als je toch iets wilt delen over deze laadpaal, probeer het dan morgen opnieuw a.u.b.",
    fail_toomany_sameday_p1: "Je hebt vandaag al meerdere reacties geplaatst.",
    fail_toomany_sameday_p2: "We houden de reacties graag kort om te voorkomen dat we niet-informatieve reacties over een laadpaal hebben.",
    fail_toomany_sameday_p3: "Als je toch iets wilt delen over deze laadpaal, probeer het dan morgen opnieuw a.u.b.",
  }
}

export const chargerDetailsContent = {
  English: {
    button: "More info",
    title: "Charger info",
    addFavoriteCharger: "Add to favorites",
    removeFavoriteCharger: "Remove from favorites",
  },
  Dutch: {
    button: "Meer info",
    title: "Laadpaal info",
    addFavoriteCharger: "Toevoegen aan favorieten",
    removeFavoriteCharger: "Verwijder van favorieten",
  }
}

export const offcanvasContent = {
  English: {
    chargerStatusOffcanvasTitle: "Charging station status",
    chargerStatusReportsOnCharger: "Most recent reports on this charging station",
    chargerStatusWarningText: "Another e-driver has reported a problem on this charging station, so it is possible that the station is not working properly.",
    chargerStatusUnavailableText: "The operator of this charging station has confirmed that the station it is out of order.",
    noReportsToShow: "No one reported any malfunction on this charging station recently.",
    userSpaceTitle: "My reports",
    userReportOpenReports: "Ongoing issues",
    userReportNoOpenReports: "You don't have any open report.",
    userReportClosedReports: "Resolved issues",
    userReportLoadingReports: "Loading your reports...",
    userReportLoadingReportsError_p1: "Sorry, we are having troubles loading your reports.",
    userReportLoadingReportsError_p2: "Please try again later.",
    userReportsUpdateFromOwner: "Last update from the charging station operator",
    userReportsUpdateFromOwnerStatus: "Incident status: ",
    userReportsNoUpdateFromOwner: "We didn't receive any update from the charging station operator.",
    userReportsClosedByOwner: "You confirmed that this problem has been resolved.",
    userReportsClosedByUser: "The author of this report confirmed that the problem has been resolved.",
    userReportsClosedByUsers: "Other e-drivers have reported that this problem has been resolved.",
    userReportsFeedbackWorking: " e-drivers say the problem is solved",
    userReportsFeedbackWorkingOne: " e-driver says the problem is solved",
    userReportsFeedbackNotWorking: " e-drivers say the problem is not solved",
    userReportsFeedbackNotWorkingOne: " e-driver says the problem is not solved",
    userReportIsIssueSolved: "Problem solved?",
    userReportIsIssueSolvedModalTitle: "Is the problem with this charging station solved?",
    userReportIsIssueSolvedConfirm: "Yes, problem solved!",
    userReportIsIssueSolvedNotConfirm: "No, problem persists.",
    userReportIsIssueSolvedUnknown: "I don't know.",
    userReportIsIssueSolvedConfirmationSolvedTitle: "Thank you!",
    userReportIsIssueSolvedConfirmationSolved_p1: "Thank you for reporting that the problem with this charging station has been resolved.",
    userReportIsIssueSolvedConfirmationSolved_p2: "We really appreaciate your help in creating a reliable charging network for everyone!",
    userReportIsIssueSolvedConfirmationNotSolvedTitle: "Thank you.",
    userReportIsIssueSolvedConfirmationNotSolved_p1: "Thank you for reporting that the problem with this charging station has not been resolved yet.",
    userReportIsIssueSolvedConfirmationNotSolved_p2: "We will update you if we receive any update from the charging station operator.",
    userReportIsIssueSolvedConfirmationErrorTitle: "Oops!",
    userReportIsIssueSolvedConfirmationError_p1: "Sorry, we are having some troubles processing your request. Please try again later.",
    userReportIsIssueSolvedConfirmationTooManyRequests_p1: "Sorry, you already gave your feedback too many times today. Please try again tomorrow.",
    chargerPopupIsIssueSolvedModalTitle: "Another e-driver reported a malfunction with this charging station. Is the problem solved?",
    userFavoriteChargersTitle: "My charging stations",
    userFavoriteChargersLoading: "Loading your favorite charging stations...",
    userFavoriteChargersNoChargers_p1: "You didn't save any charging station yet.",
    userFavoriteChargersNoChargers_p2: "By saving a charging station as your favorite, we will keep you updated on its status!",
    userFavoriteChargerError_p1: "Sorry, we are having troubles retrieving your favorite charging stations.",
    userFavoriteChargerError_p2: "Please try again later.",
    userFavoriteChargersNoReports: "No one reported an incident on this charging station yet",
    userFavoriteChargersOneReport: "There is 1 incident reported on this charging station",
    userFavoriteChargersMultipleReports_p1: "There are ",
    userFavoriteChargersMultipleReports_p2: " incidents reported on this charging station",
    userFavoriteChargersLastReport: "Last issue reported: ",
    userFavoriteChargersConfirmRemoveFromFavorites: "Are you sure you want to remove this charging station from your favorites?",
    userProfileTitle: "My profile",
    userProfileLoggedInAs: "Logged in as",
    userProfilePhonenumber: "Phone number",
    userProfileNoPhonenumberProvided: "No phone number provided. Add your phone number to receive updates via sms.",
    userProfilePhoneNumberError: "Please enter a valid phone number (+31612345678).",
    userProfileUpdatePhonenumberError: "Sorry, it was not possible to add your phone number. Please try again later.",
    userProfileCarType: "Car",
    userProfileCarTypeError: "Please enter a valid car, by giving the car manufacturer and the car model.",
    userProfileUpdateCarTypeError: "Sorry, it was not possible to add your car. Please try again later.",
    userProfileLanguage: "Language",
    userProfileUnsubscribeModalTitle: "Unsubscribe",
    userProfileUnsubscribeConfirm_p1: "Are you sure you want to unsubscribe?",
    userProfileUnsubscribeConfirm_p2: "You won't be able anymore to save favorite chargers, to report problems on chargers and to receive updates and notifications.",
    userProfileUnsubscribedConfirmedModalTitle: "Unsubscribed",
    userProfileUnsubscribedConfirmedModal_p1: "We are sorry to see you go.",
    userProfileUnsubscribedConfirmedModal_p2: "You can send us a feedback on your experience with ChargeProb at info@chargeprob.com .",
    userProfileUnsubscribeErrorTitle: "Sorry, something went wrong.",
    userProfileUnsubscribeError_p1: "Please try again later.",
    userProfileTotReports: " incidents reported",
    userProfileTotFavChargers: " favorite charging stations",
    useProfileNotification: "Email notifications",
    useProfileNotificationNotActive_p1: "You didn't activate your account yet.",
    useProfileNotificationNotActive_p2: "Please follow the instructions we sent you by email to activate your account, or click the button below to receive a new activation email.",
    userProfileEmailConsentChargerStatusUpdate: "Status updates on your favorite charging stations",
    userProfileEmailConsentCPOStatusUpdate: "Operator updates on your favorite charging stations",
    userProfileEmailConsentReportOnCharger: "New incidents reported on your favorite charging stations",
    userProfileEmailConsentUpdateError: "Sorry, we are having some troubles processing your request. Please try again later.",
    commentsOnChargerTitle: "Comments on this charging station",
    commentsOnChargerNoComments: "No one made a comment on this charging station yet.",
  },
  Dutch: {
    chargerStatusOffcanvasTitle: "Laadpaal status",
    chargerStatusReportsOnCharger: "Meest recente meldingen van e-rijders",
    chargerStatusWarningText: "Een andere e-rijder heeft een probleem gemeld aan deze laadpaal, dus het kan zijn dat het paal niet goed werkt.",
    chargerStatusUnavailableText: "De laadpaal operator heeft bevestigd dat het laadpaal buiten gebruik is.",
    noReportsToShow: "Niemand heeft onlangs een storing aan deze laadpaal gemeld.",
    userSpaceTitle: "Mijn meldingen",
    userReportOpenReports: "Lopende problemen",
    userReportNoOpenReports: "U heeft geen openstaand melding.",
    userReportClosedReports: "Opgeloste problemen",
    userReportLoadingReports: "Loading jouw meldingen...",
    userReportLoadingReportsError_p1: "Sorry, we ondervinden momenteel problemen met het ophalen van je meldingen.",
    userReportLoadingReportsError_p2: "Probeer het later opnieuw a.u.b.",
    userReportsUpdateFromOwner: "Laatste update van de laadpaal beheerder",
    userReportsUpdateFromOwnerStatus: "Storing status: ",
    userReportsNoUpdateFromOwner: "We hebben geen update ontvangen van de laadpaal beheerder.",
    userReportsClosedByOwner: "U heeft bevestigd dat dit probleem is opgelost.",
    userReportsClosedByUser: "De auteur van dit rapport bevestigde dat het probleem is opgelost.",
    userReportsClosedByUsers: "Andere e-rijders hebben gemeld dat dit probleem is opgelost.",
    userReportsFeedbackWorking: " e-rijders zeggen dat de storing opgelost is",
    userReportsFeedbackWorkingOne: " e-rijder zegt dat de storing opgelost is",
    userReportsFeedbackNotWorking: " e-rijders zeggen dat de storing niet opgelost is",
    userReportsFeedbackNotWorkingOne: " e-rijder zegt dat de storing niet opgelost is",
    userReportIsIssueSolved: "Storing opgelost?",
    userReportIsIssueSolvedModalTitle: "Is de storing met deze laadpaal opgelost?",
    userReportIsIssueSolvedConfirm: "Ja, storing is opgelost!",
    userReportIsIssueSolvedNotConfirm: "Nee, storing blijft bestaan.",
    userReportIsIssueSolvedUnknown: "Ik weet het niet.",
    userReportIsIssueSolvedConfirmationSolvedTitle: "Dankjewel!",
    userReportIsIssueSolvedConfirmationSolved_p1: "Bedankt voor het melden dat het probleem met deze laadpaal is opgelost.",
    userReportIsIssueSolvedConfirmationSolved_p2: "We stellen je hulp bij het creëren van een betrouwbaar laadnetwerk voor iedereen zeer op prijs!",
    userReportIsIssueSolvedConfirmationNotSolvedTitle: "Dankjewel.",
    userReportIsIssueSolvedConfirmationNotSolved_p1: "Bedankt voor het melden dat het probleem met deze laadpaal nog niet is opgelost.",
    userReportIsIssueSolvedConfirmationNotSolved_p2: "We zullen u op de hoogte houden als we een update ontvangen van de laadpaal beheerder.",
    userReportIsIssueSolvedConfirmationErrorTitle: "Oops!",
    userReportIsIssueSolvedConfirmationError_p1: "Sorry, er is iets fout gegaan. Probeer het later opnieuw a.u.b.",
    userReportIsIssueSolvedConfirmationTooManyRequests_p1: "Sorry, je hebt vandaag al te vaak feedback gegeven. Probeer het morgen opnieuw a.u.b.",
    chargerPopupIsIssueSolvedModalTitle: "Een andere e-rijder meldde een storing bij deze laadpaal. Is het probleem opgelost?",
    userFavoriteChargersTitle: "Mijn opgeslagen laadpalen",
    userFavoriteChargersLoading: "Loading je favoriete laadpalen...",
    userFavoriteChargersNoChargers_p1: "Je hebt nog geen favoriete laadpalen opgeslagen.",
    userFavoriteChargersNoChargers_p2: "Door de laadpaal in je favorieten te zetten houden we je op de hoogte over de status van de laadpaal.",
    userFavoriteChargerError_p1: "Sorry, we ondervinden momenteel problemen met het ophalen van je favoriete laadpalen.",
    userFavoriteChargerError_p2: "Probeer het later opnieuw a.u.b.",
    userFavoriteChargersNoReports: "Er zijn geen problemen gemeld op deze laadpaal",
    userFavoriteChargersOneReport: "Er is 1 probleem gemeld op deze laadpaal",
    userFavoriteChargersMultipleReports_p1: "Er zijn ",
    userFavoriteChargersMultipleReports_p2: " problemen gemeld op deze laadpaal",
    userFavoriteChargersLastReport: "Laatst gemelde probleem: ",
    userFavoriteChargersConfirmRemoveFromFavorites: "Weet je zeker dat je deze laadpaal uit je favorieten wilt verwijderen?",
    userProfileTitle: "Mijn profiel",
    userProfileLoggedInAs: "Ingelogd als",
    userProfilePhonenumber: "Telefoonnummer",
    userProfileNoPhonenumberProvided: "Geen telefoonnummer opgegeven.",
    userProfilePhoneNumberError: "Voer a.u.b. een geldig telefoonnummer in (+31612345678).",
    userProfileUpdatePhonenumberError: "Sorry, het was niet mogelijk om je telefoonnummer toe te voegen. Probeer het later opnieuw a.u.b.",
    userProfileCarType: "Auto",
    userProfileCarTypeError: "Vul a.u.b. een geldige auto in, door de autofabrikant en het automodel door te geven.",
    userProfileUpdateCarTypeError: "Sorry, het was niet mogelijk om je auto toe te voegen. Probeer het later opnieuw a.u.b.",
    userProfileLanguage: "Taal",
    userProfileUnsubscribeModalTitle: "Afmelden",
    userProfileUnsubscribeConfirm_p1: "Weet je zeker dat je wilt afmelden voor ChargeProb?",
    userProfileUnsubscribeConfirm_p2: "Je kunt geen favoriete laadpalen meer opslaan, problemen met laadpalen melden en updates of notificaties ontvangen.",
    userProfileUnsubscribedConfirmedModalTitle: "Afgemeld",
    userProfileUnsubscribedConfirmedModal_p1: "We vinden het jammer om jou te zien gaan.",
    userProfileUnsubscribedConfirmedModal_p2: "Je kunt ons feedback sturen over je ervaring met ChargeProb op info@chargeprob.com .",
    userProfileUnsubscribeErrorTitle: "Sorry, er is iets fout gegaan.",
    userProfileUnsubscribeError_p1: "Probeer het later opnieuw a.u.b.",
    userProfileTotReports: " problemen gemelden",
    userProfileTotFavChargers: " favoriete laadpalen",
    useProfileNotification: "Email notificaties",
    useProfileNotificationNotActive_p1: "Je hebt je account nog niet geactiveerd.",
    useProfileNotificationNotActive_p2: "Volg de instructies die we u per e-mail hebben gestuurd om uw account te activeren, of klik op de onderstaande knop om een nieuwe activeringsmail te ontvangen.",
    userProfileEmailConsentChargerStatusUpdate: "Status updates op je favoriete laadpalen",
    userProfileEmailConsentCPOStatusUpdate: "Beheerders updates op je favoriete laadpalen",
    userProfileEmailConsentReportOnCharger: "Nieuwe meldingen op je favoriete laadpalen",
    userProfileEmailConsentUpdateError: "Sorry, we hebben wat problemen met het verwerken van je verzoek. Probeer het later opnieuw a.u.b.",
    commentsOnChargerTitle: "Reacties over deze laadpaal",
    commentsOnChargerNoComments: "Er zijn nog geen reacties over deze laadpaal.",
  }
}

export const userLocationContent = {
  English: {
    alertLocationDisabledTitle: "Location is disabled",
    alertLocationDisabled_p1: "We need your permission to locate where you are.",
    alertLocationDisabled_p2: "Please turn on geolocation service on your phone and give location permissions to this website.",
    alertLocationUnknownTitle: "We are unable to get your current location",
    alertLocationUnknown_p1: "Sorry, we are having troubles in getting your current location.",
    alertLocationUnknown_p2: "Please go manually to your current location on the map.",
  },
  Dutch: {
    alertLocationDisabledTitle: "Locatie is uitgeschakeld",
    alertLocationDisabled_p1: "We hebben je toestemming nodig om te bepalen waar je zich bevindt.",
    alertLocationDisabled_p2: "Schakel de geolocatie service op je telefoon in en geef locatiemachtigingen aan deze website.",
    alertLocationUnknownTitle: "We kunnen je huidige locatie niet achterhalen",
    alertLocationUnknown_p1: "Sorry, we ondervinden problemen bij het verkrijgen van je huidige locatie",
    alertLocationUnknown_p2: "Ga handmatig naar je location op de kaart a.u.b.",
  }
}

export const energieOnderbrekingContent = {
  English: {
    warningPlannedMaint: "There is a planned energy maintenance in this area.",
    warningEnergyFailure: "There is an ongoing energy failure in this area.",
    planned: "Planned",
    incident: "Incident",
    from: "From",
    to: "To",
    expectedEnd: "Expected end",
    area: "Area",
    status: "Status",
    notes: "Notes"
  },
  Dutch: {
    warningPlannedMaint: "Er is een gepland energieonderhoud in dit gebied.",
    warningEnergyFailure: "Er is een voortdurende energiestoring in dit gebied.",
    planned: "Gepland",
    incident: "Incident",
    from: "Vanaf",
    to: "Tot",
    expectedEnd: "Verwacht einde",
    area: "Gebied",
    status: "Status",
    notes: "Notes"
  }
}