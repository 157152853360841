import React, { useContext } from 'react';
import MapNavbar from '../components/MapNavbar';
import '../App.css';
import Container from 'react-bootstrap/Container';
import { accountActivationContent } from '../components/internationalization/Translations';
import AuthContext from '../context/AuthContext';

function ActivateAccountSuccess() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? accountActivationContent.English :
        language === "NL" ? accountActivationContent.Dutch :
            accountActivationContent.English;

    return (
        <>
            <MapNavbar page="activateaccount_success" />
            <Container fluid className='accountactivation'>
                <br /><br /><br /><br />
                <h2>{content.activateSuccess_p1}</h2>
                <h2>&nbsp; {content.activateSuccess_p2} <a href='/'>ChargeProb</a></h2>
            </Container>
        </>
    )

}

export default ActivateAccountSuccess;