import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { homePageContent } from '../components/internationalization/Translations';
import LogoWhite from '../static/images/FINAL-ICONLOGO-ChargeProb-WHITE.png';


function GetInContact(props) {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? homePageContent.English :
        language === "NL" ? homePageContent.Dutch :
            homePageContent.English;

    return (
        <section className="u-clearfix u-container-align-center u-custom-color-2 u-section-contactus" id="carousel_7650">
            <div className="u-clearfix u-sheet u-valign-middle-xl u-sheet-1">
                <div className="u-clearfix u-layout-wrap u-layout-wrap-1">
                    <div className="u-gutter-0 u-layout">
                        <div className="u-layout-row">
                            <div className="u-align-left u-container-style u-layout-cell u-size-60 u-layout-cell-1">
                                <div className="u-container-layout u-container-layout-1">
                                    <h2 className="u-align-center u-custom-font u-text u-text-1">{content.contact}</h2>
                                    <img className="u-align-center u-image u-image-contain u-image-default u-image-1" src={LogoWhite} alt="" data-image-width="1258" data-image-height="1269" />
                                    <h6 className="u-align-center u-custom-font u-text u-text-2"><b>{content.contactText}</b></h6>
                                    {/* <p className="u-align-center u-custom-font u-text u-text-3">Bent u beheerder van laadpalen en wilt u graag uw operationele kosten verlagen? Neem dan <span style={{ fontWeight: "400" }}>contact </span>op met ChargeProb voor een demo. Bent u gebruiker van een laadpaal en heeft u feedback voor ons, wij horen het graag!</p> */}
                                    <div className="u-align-center u-social-icons u-spacing-20 u-social-icons-1">
                                        <a className="u-social-url" target="_blank" href="https://twitter.com/ChargeProb" title="Twitter CP"><span className="u-icon u-icon-circle u-social-icon u-social-twitter u-icon-1">
                                            <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-e140"></use></svg>
                                            <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-e140" className="u-svg-content"><path d="M92.2,38.2c0,0.8,0,1.6,0,2.3c0,24.3-18.6,52.4-52.6,52.4c-10.6,0.1-20.2-2.9-28.5-8.2 c1.4,0.2,2.9,0.2,4.4,0.2c8.7,0,16.7-2.9,23-7.9c-8.1-0.2-14.9-5.5-17.3-12.8c1.1,0.2,2.4,0.2,3.4,0.2c1.6,0,3.3-0.2,4.8-0.7 c-8.4-1.6-14.9-9.2-14.9-18c0-0.2,0-0.2,0-0.2c2.5,1.4,5.4,2.2,8.4,2.3c-5-3.3-8.3-8.9-8.3-15.4c0-3.4,1-6.5,2.5-9.2 c9.1,11.1,22.7,18.5,38,19.2c-0.2-1.4-0.4-2.8-0.4-4.3c0.1-10,8.3-18.2,18.5-18.2c5.4,0,10.1,2.2,13.5,5.7c4.3-0.8,8.1-2.3,11.7-4.5 c-1.4,4.3-4.3,7.9-8.1,10.1c3.7-0.4,7.3-1.4,10.6-2.9C98.9,32.3,95.7,35.5,92.2,38.2z"></path></svg>
                                        </span>
                                        </a>
                                        <a className="u-social-url" target="_blank" href="https://www.linkedin.com/company/chargeprob" title="Linkedin CP"><span className="u-icon u-icon-circle u-social-icon u-social-linkedin u-icon-2">
                                            <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-438b"></use></svg>
                                            <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-438b" className="u-svg-content"><path d="M33.8,96.8H14.5v-58h19.3V96.8z M24.1,30.9L24.1,30.9c-6.6,0-10.8-4.5-10.8-10.1c0-5.8,4.3-10.1,10.9-10.1 S34.9,15,35.1,20.8C35.1,26.4,30.8,30.9,24.1,30.9z M103.3,96.8H84.1v-31c0-7.8-2.7-13.1-9.8-13.1c-5.3,0-8.5,3.6-9.9,7.1 c-0.6,1.3-0.6,3-0.6,4.8V97H44.5c0,0,0.3-52.6,0-58h19.3v8.2c2.6-3.9,7.2-9.6,17.4-9.6c12.7,0,22.2,8.4,22.2,26.1V96.8z"></path></svg>
                                        </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default GetInContact;