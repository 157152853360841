import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { Button, Stack } from 'react-bootstrap';
import { chargerStatusContent } from '../internationalization/Translations';
import { energieOnderbrekingContent } from '../internationalization/Translations';


function ChargerStatus(props) {

    const content = props.language === "EN" ? chargerStatusContent.English :
        props.language === "NL" ? chargerStatusContent.Dutch :
            chargerStatusContent.English;

    const eoContent = props.language === "EN" ? energieOnderbrekingContent.English :
        props.language === "NL" ? energieOnderbrekingContent.Dutch :
            energieOnderbrekingContent.English;

    const markerInfo = props.markerInfo ? props.markerInfo : null;
    const lastReportDate = markerInfo["usersreports"] && markerInfo["usersreports"].length > 0 ?
        new Date(Date.parse(markerInfo.lastreporttime)) : null;

    const now = new Date();
    const lastReportDiffDays = lastReportDate ? Math.ceil((now - lastReportDate) / (1000 * 60 * 60 * 24)) : null;
    const lastReportDiffDaysText = lastReportDiffDays && lastReportDiffDays <= 3 ? content.recentReport
        : lastReportDiffDays && lastReportDiffDays > 3 ? lastReportDiffDays + " " + content.reportDaysAgo
            : null;

    const markerInfoStatus = props.markerInfo ? props.markerInfo.status : null;
    //const totReportsOnCharger = props.markerInfo ? props.markerInfo.usersreports ? props.markerInfo.usersreports.length : 0 : 0;
    const openReports = markerInfo && markerInfo.usersreports ? markerInfo.usersreports.filter(report => report.report_status !== "CLOSE") : null;
    const totOpenReportsOnCharger = openReports ? openReports.length : 0;
    const markerStatus = markerInfoStatus === "Available" || markerInfoStatus === "Partially available" ? "Available" :
        markerInfoStatus === "Unavailable" ? "Unavailable" :
            markerInfoStatus === "Warning" ? "Warning" :
                "Unknown";

    const markerStatus_translation = markerInfoStatus === "Available" || markerInfoStatus === "Partially available" ? content.noIssuesReported :
        markerInfoStatus === "Unavailable" ? content.unavailable :
            markerInfoStatus === "Warning" ? content.warning :
                content.unknown;

    const statusColor = markerStatus === "Available" || markerStatus === "Partially available" ? "#1dbf73" :
        markerStatus === "Unavailable" ? "#BF2D32" :
            markerStatus === "Warning" ? "#FFAE00" :
                "white";

    const report_s = totOpenReportsOnCharger === 1 ? content.report : content.reports;

    const hasEoDisruption = props.markerInfo && props.markerInfo.disruption ? true : false;

    const eoDisruption_translation = hasEoDisruption ?
        props.markerInfo.disruption.planned ? eoContent.warningPlannedMaint
            : eoContent.warningEnergyFailure
        : null;

    const eoStatusColor = hasEoDisruption ?
        markerStatus === "Unavailable" ? "#BF2D32" :
            "#FFAE00" : "white";

    const showChargerStatusDetails = () => {
        let back = "";
        props.showChargerStatusDetails(props.markerInfo, back);
    }

    const exclamationIcon = markerInfoStatus === "Unavailable" ?
        <FontAwesomeIcon icon={faCircleExclamation} style={{ 'fontSize': '1.8rem' }} />
        : markerInfoStatus === "Warning" ?
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ 'fontSize': '1.8rem' }} />
            : <FontAwesomeIcon icon={faThumbsUp} style={{ 'fontSize': '1.6rem' }} />;

    const divBoxClassname = markerInfoStatus === "Unavailable" ?
        "popup-element-div popup-element-div-bordered popup-element-div-red"
        : markerInfoStatus === "Warning" ?
            "popup-element-div popup-element-div-bordered popup-element-div-warning"
            : markerInfoStatus === "Available" || markerInfoStatus === "Partially available" ?
                "popup-element-div popup-element-div-green"
                : "popup-element-div popup-element-div-bordered popup-element-div-hidden";

    const rightArrowIcon = markerInfoStatus === "Available" || markerInfoStatus === "Partially available" ?
        <span className='rightArrowGreenIcon'></span>
        : markerInfoStatus === "Unavailable" ?
            <span className='rightArrowRedIcon'></span>
            : markerInfoStatus === "Warning" ?
                <span className='rightArrowWarningIcon'></span>
                : <span className='rightArrowWhiteIcon'></span>


    return (
        <div className={divBoxClassname} onClick={showChargerStatusDetails}>
            {hasEoDisruption ?
                <Stack direction='vertical' gap={2}>
                    <Stack direction='horizontal' gap={2} style={{ 'color': eoStatusColor }}>
                        <div>
                            <b><FontAwesomeIcon icon={faExclamationTriangle} style={{ 'fontSize': '1.8rem' }} /></b>
                        </div>
                        <div><b>{eoDisruption_translation}</b></div>
                    </Stack>
                    <div></div>
                </Stack>
                : null}
                <div>
            {totOpenReportsOnCharger === 0 && markerInfoStatus === "Unknown" ?
                <Stack direction='horizontal' gap={3}>
                    <div><b>{markerStatus_translation}</b></div>
                    <div className='ms-auto'>{rightArrowIcon}</div>
                </Stack>
                : markerInfoStatus === "Available" || markerInfoStatus === "Partially available" ?
                    <Stack direction='horizontal' gap={3}>
                        <div>
                            <b>{exclamationIcon}</b>
                        </div>
                        <div><b>{markerStatus_translation}</b></div>
                    </Stack>
                    : totOpenReportsOnCharger === 0 && markerInfoStatus === "Unavailable" ?
                    <Stack direction='horizontal' gap={3}>
                        <div>
                            <b>{exclamationIcon}</b>
                        </div>
                        <div><b>{markerStatus_translation}</b></div>
                        <div className='ms-auto'>{rightArrowIcon}</div>
                    </Stack>
                    :
                    <Stack direction='horizontal' gap={3}>
                        <div>
                            <b>{exclamationIcon}</b>
                        </div>
                        <Stack direction="vertical" gap={1}>
                            <div><b>{markerStatus_translation}</b></div>
                            <div>
                                <b>{totOpenReportsOnCharger} {report_s}</b>
                            </div>
                            <div>
                                {lastReportDiffDaysText}
                            </div>
                        </Stack>
                        <div className='ms-auto'>{rightArrowIcon}</div>
                    </Stack>
            }
            </div>
        </div >
    )

}

export default ChargerStatus;

/* 
            
            const markerStatusDetailInfo =  markerStatus === "Available" && totReportsOnCharger === 0 ? 
                                        content.availableExt :
                                    markerStatus === "Available" && totReportsOnCharger > 0 ? 
                                        content.availableWarningExt :
                                    markerStatus === "Unavailable" ? 
                                        content.unavailableExt :
                                    markerStatus === "Warning" ? 
                                        content.warningExt
                                        : 
                                        content.unknownExt
            <Card style={{border: '3px solid '+ statusColor}}>
                <Card.Header 
                    style={{'color': statusColor, 'padding': '0.2rem 1rem 0.2rem 1rem', 'fontSize': '0.7rem'}}>
                    <b>{markerStatus_translation} {exclamationIcon}</b>
                    {totReportsOnCharger === 1 ?
                    <div><b>{totReportsOnCharger} {content.report}</b></div>
                    :
                    <div><b>{totReportsOnCharger} {content.reports}</b></div>
                        }

                </Card.Header>
                
                <Card.Header 
                    style={{'color': statusColor, 'padding': '0.2rem 1rem 0.2rem 1rem', textAlign: 'center'}}>
                    <b>{markerStatus_translation}</b> {exclamationIcon}
                </Card.Header>
                <Card.Body>
                    {/* <Card.Title style={{'color': statusColor}}><h6><b>{markerStatus}</b> </h6></Card.Title> 
                    <Row className="ps-0" style={{'fontSize': '0.9em'}}>
                        <span>{markerStatusDetailInfo}</span>
                    </Row>
                    {markerStatus === "Unavailable" || markerStatus === "Warning" || totReportsOnCharger > 0 ?
                        <Button 
                            variant="secondary" 
                            size="xs" 
                            onClick={showChargerStatusDetails}
                            style={{'fontSize': '0.8em'}}
                            className="pt-1 mt-2" >
                                {content.tellMeMore}
                        </Button>
                    : null }
                </Card.Body> 
            </Card> */