import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';


function UserComment(props){
    const userComment = props.userComment;
    const comment_date = new Date(Date.parse(userComment.comment_datetime));
    const offset = comment_date.getTimezoneOffset()
    const commentDate = new Date(comment_date.getTime() - (offset*60*1000)).toISOString().split('T')[0]

    return(
        <Card border="dark" className="offcanvas-cards">
            <Card.Header as="h5" className='chargeprob-green' style={{'display': ' flex','justifyContent': 'flex-end'}}>
                {commentDate}
            </Card.Header>
            <Card.Body>
                <Stack direction="horizontal" gap={4}>
                    {userComment.reaction === "positive" ?
                    <Card.Text>
                        <span className='likeIcon'></span>
                    </Card.Text> 
                    : userComment.reaction === "negative" ?
                    <Card.Text>
                        <span className='dislikeIcon'></span>
                    </Card.Text>
                    : null } 
                    <Card.Text>
                        {userComment.comment}
                    </Card.Text>
                </Stack>
            </Card.Body>
        </Card>
    )
}

export default UserComment;