import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faBars } from '@fortawesome/free-solid-svg-icons';
import { navbarContent } from './internationalization/Translations';
import AuthContext from '../context/AuthContext';
import CPlogo from '../static/logos/CPlogo_white_green.png';
import LogoGreen from '../static/images/FINAL-ICONLOGO-ChargeProb-GREEN_800.png';
import LanguagePicker from './user_interaction/LanguagePicker';

function MapNavbar(props) {

  let { user, language } = useContext(AuthContext);
  const content = language === "EN" ? navbarContent.English :
    language === "NL" ? navbarContent.Dutch :
      navbarContent.English;

  const headerClassName = props.page && props.page === "homepage" ? 'py-0 map-navbar-lg' : 'py-0 map-navbar';

  const isUserLoggedIn = () => {
    if (user) return true;
    return false;
  }

  return (
    <Navbar variant="dark" sticky="top" className={headerClassName}>
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={CPlogo}
            width="140"
            height="29"
            className="d-inline-block align-top"
          />{' '}
          <img
            alt=""
            src={LogoGreen}
            width="0"
            height="0"
            className="d-inline-block align-top"
            style={{ display: 'none' }}
          />{' '}
        </Navbar.Brand>
        {props.page === "storingcpo" || props.page === "latestreports" ?
          <Navbar.Collapse className="justify-content-end"></Navbar.Collapse>
          : props.page === "map" ?
            <Navbar.Collapse className="justify-content-end">
              {isUserLoggedIn() ?
                <Stack direction='horizontal' gap={2} onClick={props.logoutUser} style={{ cursor: "pointer" }}>
                  <span className='userIcon-sm'></span>
                  <Navbar.Text>{content.signout}</Navbar.Text>
                </Stack>
                :
                <Stack direction='horizontal' gap={1} style={{ cursor: "pointer" }}>
                  <span className='userIcon-sm' onClick={props.openUserLoginModal}></span>
                  <Navbar.Text onClick={props.openUserLoginModal}>{content.signin}</Navbar.Text>
                  <div></div>
                  <div></div>
                  <LanguagePicker variant="dark" />
                </Stack>
              }
            </Navbar.Collapse>
            :
            <Navbar.Collapse className="justify-content-end">
              <LanguagePicker variant="dark" />
            </Navbar.Collapse>
        }
      </Container>
    </Navbar>
  )

}

export default MapNavbar;