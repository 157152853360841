import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { reportIncidentContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';
import AuthContext from '../../context/AuthContext';


function ModalNewIncidentInfo(props) {

    let { userProfile } = useContext(AuthContext);

    const content = props.language === "EN" ? reportIncidentContent.English :
        props.language === "NL" ? reportIncidentContent.Dutch :
            reportIncidentContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [incidentType, setIncidentType] = useState('');
    const [incidentTypeTranslation, setIncidentTypeTranslation] = useState('');
    const [affectedCharger, setAffectedCharger] = useState('Complete charger');
    const [incidentExtraInfo, setIncidentExtraInfo] = useState('');
    const [carModel, setCarModel] = useState('');
    const [reportImage, setReportImage] = useState(null);
    const [reportImagePreview, setReportImagePreview] = useState(null);
    const [reportPhoto, setReportPhoto] = useState(null);
    const [reportPhotoPreview, setReportPhotoPreview] = useState(null);
    const [startCapture, setStartCapture] = useState(false);
    const [startCaptureError, setStartCaptureError] = useState('');
    const [mediaStream, setMediaStream] = useState(null);
    const imageInputRef = useRef(null);
    const videoRef = useRef(null);

    const [noIncidentSelectedError, setNoIncidentSelectedError] = useState('');
    const [noChargerSelectedError, setNoChargerSelectedError] = useState('');
    const [noExtraDetailsError, setNoExtraDetailsError] = useState('');

    const nothingSelectedErrorMsg = content.nothingSelectedErrorMsg;
    const noExtraDetailsErrorMsg = content.noExtraDetailsErrorMsg;

    useEffect(() => {
        if (userProfile && userProfile.car_type) {
            setCarModel(userProfile.car_type);
        }
    }, [userProfile]);

    useEffect(() => {
        return () => {
            if (mediaStream) {
                handleStopCapture();
            }
        };
    }, [mediaStream]);

    const handleUploadImage = () => {
        imageInputRef.current.click();
    };

    const handleUploadedFile = (evt) => {
        const file = evt.target.files[0];
        if (file) {
            setReportImage(file);
            setReportImagePreview(URL.createObjectURL(file));
        }
    }

    const handleRemoveImage = () => {
        setReportImage(null);
        setReportImagePreview(null);
        setReportPhoto(null);
        setReportPhotoPreview(null);
        setStartCaptureError('');
    };

    // const selectIncidentOptions = (e) => {
    //     setIncidentType(e.value);
    //     setIncidentTypeTranslation(e.options[e.selectedIndex].text);
    // }

    const selectTypeOfIncident = (incidentTypeSelected, incidentTypeTranslationSelected) => {
        setIncidentType(incidentTypeSelected);
        setIncidentTypeTranslation(incidentTypeTranslationSelected);
    }

    const submitClicked = () => {
        setNoIncidentSelectedError('');
        setNoChargerSelectedError('');
        setStartCaptureError('');
        if (incidentType === '') setNoIncidentSelectedError(nothingSelectedErrorMsg);
        if (affectedCharger === '') setNoChargerSelectedError(nothingSelectedErrorMsg);
        if (incidentType === 'Other issue' && incidentExtraInfo.length < 5) setNoExtraDetailsError(noExtraDetailsErrorMsg);
        if (incidentType !== '' &&
            affectedCharger !== '' &&
            !(incidentType === 'Other issue' && incidentExtraInfo.length < 5)) {
            props.setIncidentType(incidentType);
            props.setIncidentTypeTranslation(incidentTypeTranslation);
            props.setAffectedCharger(affectedCharger);
            props.setIncidentExtraInfo(incidentExtraInfo);
            props.setCarModel(carModel);
            if (reportImage) {
                props.setReportImage(reportImage);
                props.setReportImagePreview(reportImagePreview);
            }
            else if (reportPhoto) {
                props.setReportImage(reportPhoto);
                props.setReportImagePreview(reportPhotoPreview);
            }
            else {
                props.setReportImage(null);
            }
            props.goToNextModal();
        }
    }

    const handleStartCapture = async () => {
        setStartCapture(true);
        setStartCaptureError('');
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: "environment" },
                audio: false,
            });
            setMediaStream(stream);
            videoRef.current.srcObject = stream;
            videoRef.current.play();
        } catch (error) {
            //console.error("Error starting camera:", error);
            setStartCaptureError(content.startCameraError);
            setStartCapture(false);
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop());
                setMediaStream(null);
            }
        }
    };

    const handleStopCapture = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
            setMediaStream(null);
        }
        setStartCapture(false);
        setStartCaptureError('');
    }

    const handleTakePhoto = () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas
            .getContext("2d")
            .drawImage(
                videoRef.current,
                0,
                0,
                videoRef.current.videoWidth,
                videoRef.current.videoHeight
            );

        const photoURL = canvas.toDataURL();
        setReportPhotoPreview(photoURL);
        canvas.toBlob(blob => {
            const fileName = `image-${Date.now()}.png`;
            const file = new File([blob], fileName, { type: "image/png" });
            setReportPhoto(file);
        });
        videoRef.current.pause();
        handleStopCapture();
    };

    const resetIncidentInfo = () => {
        setReportImage(null);
        setReportImagePreview(null);
        setReportPhoto(null);
        setReportPhotoPreview(null);
        setStartCaptureError('');
        setIncidentType('');
        setIncidentTypeTranslation('');
        setAffectedCharger('Complete charger');
        setIncidentExtraInfo('');
    }

    const handleClose = () => {
        handleStopCapture();
        resetIncidentInfo();
        props.resetIncidentInfo();
        props.handleClose();
    }

    return (
        <Modal show={props.show} onHide={handleClose} backdrop="static">
            <Modal.Header className="modalsBody" >
                <Modal.Title className='w-100 text-center'>{content.title}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            {startCapture ?
                <Modal.Body className="modalsBody">
                    <video ref={videoRef} className='report-takepicture' />
                    <Stack direction='horizontal' gap={2}>
                        <Button size="sm" className='chargeprob-button-outline-green' onClick={handleTakePhoto}>
                            {content.takePhoto}
                        </Button>
                        <Button size="sm" variant='outline-danger' onClick={handleStopCapture}>
                            {buttonscontent.close}
                        </Button>
                    </Stack>
                </Modal.Body>
                :
                <Modal.Body className="modalsBody">
                    <Form>
                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                            <h5>{content.newIncidentTitle}</h5>
                        </div>
                        {/* 
                        <Form.Group className="mb-3" controlId="reportIncident.ControlInput.incidentType">
                            <Form.Select aria-label="new incident type" 
                                onChange={evt => selectIncidentOptions(evt.target)}>
                                <option>{content.newIncidentOption0}</option>
                                <option value="Charger does not work">{content.newIncidentOption1}</option>
                                <option value="No energy">{content.newIncidentOption2}</option>
                                <option value="Slow charging">{content.newIncidentOption3}</option>
                                <option value="Charging does not start">{content.newIncidentOption4}</option>
                                <option value="Charging does not stop">{content.newIncidentOption5}</option>
                                <option value="Damaged">{content.newIncidentOption6}</option>
                                <option value="Blocked by not-EV car">{content.newIncidentOption7}</option>
                                <option value="Other issue">{content.newIncidentOption8}</option>
                            </Form.Select>
                        </Form.Group> */}
                        <br />
                        <Row style={{ fontSize: '0.8rem', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                            <Col xs={3} onClick={() => selectTypeOfIncident("Charger does not work", content.newIncidentChargerNotWork)}>
                                <Stack direction='vertical' gap={2}>
                                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        {incidentType === "Charger does not work" ?
                                            <span className="reportWarningGreenIcon"></span>
                                            : <span className="reportWarningIcon"></span>
                                        }
                                    </div>
                                    <div>
                                        {incidentType === "Charger does not work" ?
                                            <span className='chargeprob-darkgreen' style={{fontSize: '0.85rem'}}><b>{content.newIncidentChargerNotWork}</b></span>
                                            :
                                            content.newIncidentChargerNotWork
                                        }
                                    </div>
                                </Stack>
                            </Col>
                            <Col xs={3} onClick={() => selectTypeOfIncident("Slow charging", content.newIncidentSlowCharging)}>
                                <Stack direction='vertical' gap={2}>
                                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        {incidentType === "Slow charging" ?
                                            <span className="reportSlowChargingGreenIcon"></span>
                                            :
                                            <span className="reportSlowChargingIcon"></span>
                                        }
                                    </div>
                                    <div>
                                        {incidentType === "Slow charging" ?
                                            <span className='chargeprob-darkgreen' style={{fontSize: '0.85rem'}}><b>{content.newIncidentSlowCharging}</b></span>
                                            :
                                            content.newIncidentSlowCharging
                                        }
                                    </div>
                                </Stack>
                            </Col>
                            <Col xs={3} onClick={() => selectTypeOfIncident("Charging does not start", content.newIncidentStartStop)}>
                                <Stack direction='vertical' gap={2}>
                                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        {incidentType === "Charging does not start" ?
                                            <span className="reportStartStopGreenIcon"></span>
                                            :
                                            <span className="reportStartStopIcon"></span>
                                        }
                                    </div>
                                    <div>
                                        {incidentType === "Charging does not start" ?
                                            <span className='chargeprob-darkgreen' style={{fontSize: '0.85rem'}}><b>{content.newIncidentStartStop}</b></span>
                                            :
                                            content.newIncidentStartStop
                                        }
                                    </div>
                                </Stack>
                            </Col>
                            <Col xs={3} onClick={() => selectTypeOfIncident("Other issue", content.newIncidentOtherIssue)}>
                                <Stack direction='vertical' gap={2}>
                                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        {incidentType === "Other issue" ?
                                            <span className="reportOtherProblemGreenIcon"></span>
                                            :
                                            <span className="reportOtherProblemIcon"></span>
                                        }
                                    </div>
                                    <div>
                                        {incidentType === "Other issue" ?
                                            <span className='chargeprob-darkgreen' style={{fontSize: '0.85rem'}}><b>{content.newIncidentOtherIssue}</b></span>
                                            :
                                            content.newIncidentOtherIssue
                                        }
                                    </div>
                                </Stack>
                            </Col>
                        </Row>
                        <p className='red'><b>{noIncidentSelectedError}</b></p>
                        <br />
                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="reportIncident.ControlInput.affectedCharger">
                                    <Form.Label>
                                        Socket
                                    </Form.Label>
                                    {/* <Form.Select aria-label="new incident type"
                                onChange={ evt => setAffectedCharger(evt.target.value)}>
                                <option value="Complete charger">{content.affectedChargerAll}</option>
                                <option value="Left charger">{content.affectedChargerLeft}</option>
                                <option value="Right charger">{content.affectedChargerRight}</option>
                                <option value="Not sure">{content.affectedChargerUnknown}</option>
                            </Form.Select> */}
                                    <Form.Select aria-label="new incident type"
                                        onChange={evt => setAffectedCharger(evt.target.value)}>
                                        <option key="completecharger" value="Complete charger">{content.affectedChargerAll}</option>
                                        {props.markerInfo && props.markerInfo.evses && props.markerInfo.evses.map((evse, option_id) => {
                                            return (
                                                <option key={"optionevse" + option_id} value={evse.evse_name}>{evse.evse_name}</option>
                                            )
                                        })}
                                        <option key="notsureevse" value="Not sure">{content.affectedChargerNotSure}</option>
                                        <option key="unknownevse" value="Unknown evse">{content.affectedChargerUnknown}</option>
                                    </Form.Select>
                                </Form.Group>
                                <p className='red'><b>{noChargerSelectedError}</b></p>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="reportIncident.ControlInput.carModel">
                                    <Form.Label>
                                        Car model
                                    </Form.Label>
                                    <Form.Control value={carModel} onChange={evt => setCarModel(evt.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1" >
                            {incidentType === "Other issue" ?
                                <Form.Label>{content.newIncidentExtraDetails2}</Form.Label> :
                                <Form.Label>{content.newIncidentExtraDetails1}</Form.Label>}
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={incidentExtraInfo}
                                onChange={evt => setIncidentExtraInfo(evt.target.value)} />
                        </Form.Group>
                        <p className='red'><b>{noExtraDetailsError}</b></p>
                        {reportImage ?
                            <Form.Group>
                                <Stack direction='vertical' gap={1}>
                                    <div>
                                        <img src={reportImagePreview} alt="Picture preview" className='report-image-preview' />
                                    </div>
                                    <div>
                                        <Button size="sm" variant='outline-danger' onClick={handleRemoveImage}>
                                            {content.removeImage}
                                        </Button>
                                    </div>
                                </Stack>
                            </Form.Group>
                            :
                            <Form.Group>
                                {reportPhotoPreview ?
                                    <Stack direction='vertical' gap={1}>
                                        <div>
                                            <img src={reportPhotoPreview} alt="Picture preview" className='report-image-preview' />
                                        </div>
                                        <div>
                                            <Button size="sm" variant='outline-danger' onClick={handleRemoveImage}>
                                                {content.removeImage}
                                            </Button>
                                        </div>
                                    </Stack>
                                    :
                                    <div>
                                        <Stack direction='horizontal' gap={2}>
                                            <div>
                                                <Button size="sm" className='chargeprob-button-outline-green' onClick={handleUploadImage}>
                                                    {content.uploadImage}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={imageInputRef}
                                                    accept="image/jpeg,image/png"
                                                    style={{ display: "none" }}
                                                    onChange={handleUploadedFile}
                                                />
                                            </div>
                                            <div>
                                                <Button size="sm" className='chargeprob-button-outline-green' onClick={handleStartCapture}>
                                                    {content.takePhoto}
                                                </Button>
                                            </div>
                                        </Stack>
                                        <p className='red'><b>{startCaptureError}</b></p>
                                    </div>
                                }
                            </Form.Group>
                        }
                    </Form>
                    <Row>
                        <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                            <Stack direction='horizontal' gap={2}>
                                <Button variant="danger" onClick={handleClose}>
                                    {buttonscontent.close}
                                </Button>
                                <Button variant="success" onClick={() => submitClicked(props.markerInfo)}>
                                    {buttonscontent.proceed}
                                </Button>
                            </Stack>
                        </div>
                    </Row>
                </Modal.Body>
            }
        </Modal>
    )
}

export default ModalNewIncidentInfo;