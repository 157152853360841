import React from 'react';

function EVSEConnectorInfo(props){

    return(
        <div style={{'fontSize': '0.75rem', 'marginLeft': '16px'}}>
            {props.connector.format && props.connector.format.length > 1 ?
            <p style={{'marginBottom': '0'}}>Type: {props.connector.format}</p>
            : <p style={{'marginBottom': '0'}}>Type: UNKNOWN</p>
            }
            {props.connector.power_type && props.connector.power_type.length > 1 ?
            <p style={{'marginBottom': '0'}}>Power type: {props.connector.power_type}</p>
            : <p style={{'marginBottom': '0'}}>Power type: UNKNOWN</p>
            }
            {props.connector.max_power ?
            <p style={{'marginBottom': '0'}}>Max power: {props.connector.max_power}</p>
            : <p style={{'marginBottom': '0'}}>Max power: UNKNOWN</p>
            }
            {props.connector.voltage ?
            <p style={{'marginBottom': '0'}}>Voltage: {props.connector.voltage}</p>
            : <p style={{'marginBottom': '0'}}>Voltage: UNKNOWN</p>
            }
            {props.connector.amperage ?
            <p>Amperage: {props.connector.amperage}</p>
            : <p>Amperage: UNKNOWN</p>
            }
        </div>
    )
}

export default EVSEConnectorInfo;