import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { useFetchEnergieOnderbrekingDisruptions } from './hooks/useFetchEnergieOnderbrekingDisruptions';
import { useFetchMapboxDetails } from './hooks/useFetchMapboxDetails';
//import { useCookies } from 'react-cookie';
import { OpenStreetMapProvider } from 'leaflet-geosearch'; //, SearchControl, MapBoxProvider
import SearchHereOverlay from './components/map_components/SearchHereOverlay';
import ModalUserLoginOrRegister from './components/modals/ModalUserLoginOrRegister';
import MapNavbar from './components/MapNavbar';
import { API } from './api-service';
import Map from './components/Map';
import { MapBoxProvider } from 'leaflet-geosearch';
import './App.css';
import UserReportsOffcanvas from './components/user_interaction/UserReportsOffcanvas';
import UserFavoriteChargersOffcanvas from './components/user_interaction/UserFavoriteChargersOffcanvas';
import ChargerStatusInfoOffcanvas from './components/user_interaction/ChargerStatusInfoOffcanvas';
import UserProfileOffcanvas from './components/user_interaction/UserProfileOffcanvas';
import NoLocationPermissionsAlert from './components/map_components/NoLocationPermissionsAlert';
import { loginContent, cookiesConsentContent } from './components/internationalization/Translations';

import AuthContext from './context/AuthContext';
import EVSESStatusInfoOffcanvas from './components/user_interaction/EVSESStatusInfoOffcanvas';
import ChargerCommentsOffcanvas from './components/user_interaction/ChargerCommentsOffcanvas';
import ModalCreateCommentOnCharger from './components/modals/ModalCreateCommentOnCharger';
import ModalCommentOnChargerCreated from './components/modals/ModalCommentOnChargerCreated';
import ModalShowLegendInfo from './components/modals/ModalShowLegendInfo';
import { Helmet } from 'react-helmet';
import ModalUserFeedback from './components/modals/ModalUserFeedback';
import ModalUserFeedbackCreated from './components/modals/ModalUserFeedbackCreated';
import MapFooter from './components/MapFooter';
import SignedInToast from './components/toasts/SignedInToast';
import ModalWelcomeToCP from './components/modals/ModalWelcomeToCP';
import ModalShareOnSocials from './components/modals/ModalShareOnSocials';
import NoMarkersFoundToast from './components/toasts/NoMarkersFoundToast';
import ModalMissingChargerInfo from './components/modals/ModalMissingChargerInfo';
import ModalConfirmMissingCharger from './components/modals/ModalConfirmMissingCharger';
import ModalMissingChargerConfirmed from './components/modals/ModalMissingChargerConfirmed';

function App(props) {

  let { user, userProfile, userFavoriteChargers, authTokens, language, loginUser, loginUserWithGoogleToken, logoutUser, registerUser, getUserProfile, getUserFavoriteChargers, setUserFavoriteChargers } = useContext(AuthContext);

  const params = useParams();

  const logincontent = language === "EN" ? loginContent.English :
    language === "NL" ? loginContent.Dutch :
      loginContent.English;

  const cookiesconsentContent = language === "EN" ? cookiesConsentContent.English :
    language === "NL" ? cookiesConsentContent.Dutch :
      cookiesConsentContent.English;

  const [mapboxTokenAPI, loadingMapboxToken, errorMapboxToken] = useFetchMapboxDetails();
  const [mapboxToken, setMapboxToken] = useState(null);

  const [map, setMap] = useState(null);

  const [eoAPI, loadingEoData, errorEoData] = useFetchEnergieOnderbrekingDisruptions();
  const [eoData, setEoData] = useState(null);

  const [mapSearchBoxProvider, setMapSearchBoxProvider] = useState(null);
  const showSearch = useState(true);
  const [initialLatitude, setInitialLatitude] = useState(52.350571);
  const [initialLongitude, setInitialLongitude] = useState(4.871276);
  const [mapZoom, setMapZoom] = useState(11);
  const [markers, setMarkers] = useState([]);

  const [loadUserLocation, setLoadUserLocation] = useState(false);
  const [loadingUserLocation, setLoadingUserLocation] = useState(true);

  const [loadChargerFromURL, setLoadChargersFromURL] = useState(false);
  const [loadingChargers, setLoadingChargers] = useState(false);
  const [loadingUserReports, setLoadingUserReports] = useState(false);
  const [loadingUserReportsError, setLoadingUserReportsError] = useState(false);
  const [loadingUserFavChargers, setLoadingUserFavChargers] = useState(false);
  const [loadingUserFavChargersError, setLoadingUserFavChargersError] = useState(false);
  const [loadingEVSESStatus, setLoadingEVSESStatus] = useState(false);
  const [loadingEVSESStatusError, setLoadingEVSESStatusError] = useState(false);

  const [showSearchHere, setShowSearchHere] = useState(true);

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [showLoginToast, setShowLoginToast] = useState(false);
  const [showSignupToast, setShowSignupToast] = useState(false);
  const [showNoMarkersFoundToast, setShowNoMarkersFoundToast] = useState(false);
  const [showShareOptionsModal, setShowShareOptionsModal] = useState(false);

  const [showWelcomeToCPModal, setShowWelcomeToCPModal] = useState(false);

  const [showUserReportsOffcanvas, setShowUserReportsOffcanvas] = useState(false);
  const [showUserFavoriteChargersOffcanvas, setShowUserFavoriteChargersOffcanvas] = useState(false);
  const [showChargerStatusInfoOffcanvas, setShowChargerStatusInfoOffcanvas] = useState(false);
  const [showChargerStatusEVSESInfoOffcanvas, setShowChargerStatusEVSESInfoOffcanvas] = useState(false);
  const [showChargerCommentsOffcanvas, setShowChargerCommentsOffcanvas] = useState(false);
  const [showUserProfileOffcanvas, setShowUserProfileOffcanvas] = useState(false);

  const [showCreateCommentOnChargerModal, setShowCreateCommentOnChargerModal] = useState(false);
  const [showCommentOnChargerCreatedModal, setShowCommentOnChargerCreatedModal] = useState(false);
  const [commentNotCreatedReason, setCommentNotCreatedReason] = useState("");

  const [showLeaveFeedbackModal, setShowLeaveFeedbackModal] = useState(false);
  const [showFeedbackCreatedModal, setShowFeedbackCreatedModal] = useState(false);
  const [feedbackNotCreatedReason, setFeedbackNotCreatedReason] = useState(false);

  const [showUserLoginModal, setShowUserLoginModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userRepeatPassword, setUserRepeatPassword] = useState('');
  const [userLoginError, setUserLoginError] = useState('');
  const [userRegisterError, setUserRegisterError] = useState('');
  const loginError = logincontent.loginError;

  const [userReports, setUserReports] = useState(null);
  const [userReportsOnCharger, setUserReportsOnCharger] = useState(null);

  const [selectedCharger, setSelectedCharger] = useState(null);

  const [bringmethereLocation, setBringmethereLocation] = useState("");
  const [userLocationError, setUserLocationError] = useState("");
  const [showNoLocationAccessAlert, setShowNoLocationAccessAlert] = useState(false);

  const [showLegendInfo, setShowLegendInfo] = useState(false);
  const [showMissingChargerInfo, setShowMissingChargerInfo] = useState(false);
  const [showMissingChargerConfirm, setShowMissingChargerConfirm] = useState(false);
  const [showMissingChargerConfirmed, setShowMissingChargerConfirmed] = useState(false);
  const [postMissingChargerLoading, setPostMissingChargerLoading] = useState(false);
  const [missingChargerNotCreatedReason, setMissingChargerNotCreatedReason] = useState("");
  const [missingChargerFormData, setMissingChargerFormData] = useState({
    "incidentType": "",
    "incidentTypeTranslation": "",
    "chargerId": "",
    "address": "",
    "extraInfo": "",
    "cpo": "",
    "reportImage": null,
    "reportImagePreview": null
  });

  function isNumeric(value) {
    return /^\d+$/.test(value);
  }

  useEffect(() => {
    if (process.env.REACT_APP_MAP_PROVIDER === 'mapbox') {
      setMapboxToken(mapboxTokenAPI.access_token);
      const provider = new MapBoxProvider({
        params: {
          access_token: mapboxTokenAPI.access_token,
        },
      });
      setMapSearchBoxProvider(provider);
    }
    else {
      setMapboxToken(null);
      const provider = new OpenStreetMapProvider();
      setMapSearchBoxProvider(provider);
    }
  }, [mapboxTokenAPI]);

  useEffect(() => {
    if (params.id && isNumeric(params.id)) {
      setLoadUserLocation(false);
      openMapOnLocation(params.id);
    }
    else {
      setLoadUserLocation(true);
    }
  }, [params])

  useEffect(() => {
    setEoData(eoAPI);
  }, [eoAPI]);

  useEffect(() => {
    const shouldShowWelcomeToCPModal = !localStorage.getItem('doNotShowAgainWelcomeToCP');
    setShowWelcomeToCPModal(shouldShowWelcomeToCPModal);
  }, []);

  const resetState = () => {
    setShowUserLoginModal(false);
    setUserEmail('');
    setUserPhoneNumber('');
    setUserPassword('');
    setUserRepeatPassword('');
    setUserLoginError('');
    setUserRegisterError('');
    setUserReports(null);
    setBringmethereLocation("");
    setUserReportsOnCharger(null);
    setShowUserReportsOffcanvas(false);
    setShowChargerStatusInfoOffcanvas(false);
    setShowUserProfileOffcanvas(false);
    setShowUserFavoriteChargersOffcanvas(false);
    setShowLegendInfo(false);
    setShowMissingChargerInfo(false);
  }

  const isUserLoggedIn = () => {
    if (user) return true;
    return false;
  }

  const login = async () => {
    setUserLoginError("");
    const body = {
      'email': userEmail,
      'password': userPassword
    }
    const resp = await loginUser(body);
    if (resp === "OK") {
      setShowLoginToast(true);
      closeLoginModal();
    }
    else {
      setUserLoginError(loginError);
    }
  }

  const loginWithGoogleToken = async (googleToken) => {
    const resp = await loginUserWithGoogleToken(googleToken);
    if (resp === "OK") {
      setShowLoginToast(true);
      closeLoginModal();
      return "OK";
    }
    else {
      return "ERROR";
    }
  }

  const logout = () => {
    logoutUser();
    resetState();
  }

  const register = async () => {
    setUserRegisterError("");
    const body = {
      'username': userEmail,
      'email': userEmail,
      'password': userPassword
    }
    const bodyLogin = {
      'email': userEmail,
      'password': userPassword
    }
    const resp = await registerUser(body, bodyLogin);
    if (resp === "OK") {
      setShowSignupToast(true);
      closeLoginModal();
    }
    else {
      setUserRegisterError(resp);
    }
  }

  const matchMarkersWithEO = (newMarkers) => {
    const markersEo = [];
    const markersEoIds = [];
    let letters = /[a-z]/i;
    if (eoData) {
      newMarkers.forEach(function (marker, index) {
        let marker_pc6 = marker["postal_code"].replace(' ', '');
        let marker_pc4 = marker["postal_code"].replace(' ', '').slice(0, 4);
        eoData.forEach(function (disruption, index) {
          let pc = disruption["location_postalCode"];
          if (letters.test(pc)) {
            if (pc.includes(marker_pc6)) {
              let markerEo = { ...marker, disruption }
              markersEo.push(markerEo);
              markersEoIds.push(markerEo.id);
            }
          }
          else {
            if (pc.includes(marker_pc4)) {
              let markerEo = { ...marker, disruption }
              markersEo.push(markerEo);
              markersEoIds.push(markerEo.id);
            }
          }
        });
      });
      const markersNoEo = newMarkers.filter(item => !markersEoIds.includes(item.id));
      return [...markersNoEo, ...markersEo];
    }
    else {
      return newMarkers;
    }
  }

  const updateUserFavoriteChargers = async () => {
    setLoadingUserFavChargersError(false);
    setLoadingUserFavChargers(true);
    let resp = await getUserFavoriteChargers(authTokens.access);
    if (resp.status === 200) {
      let data = await resp.json();
      setUserFavoriteChargers(matchMarkersWithEO(data));
      setLoadingUserFavChargers(false);
      return "OK";
    }
    else {
      setUserFavoriteChargers(null);
      setLoadingUserFavChargersError(true);
      setLoadingUserFavChargers(false);
      return "Not possible to update user favorite chargers."
    }
  }

  const addOrRemoveFromFavoriteChargers = async (chargerInfo) => {
    const requestBody = { 'charger_id': chargerInfo.id }
    const resp = await API.addOrRemoveFromFavoriteChargers(requestBody, userProfile.id, authTokens.access);
    if (resp.status === 200) {
      let userprof = await getUserProfile(authTokens.access);
      await updateUserFavoriteChargers();
    } else {
      //console.log(data);
    }
  }

  const viewUserFavoriteChargers = async () => {
    openUserFavoriteChargersOffcanvas();
    await updateUserFavoriteChargers();
  }

  const searchHereClicked = async () => {
    //setNoResultsFound('');
    setLoadingChargers(true);
    const data = await API.getEnergyLocationsByZipcode(map.getBounds().getSouthWest(), map.getBounds().getNorthEast());
    if (!Array.isArray(data) || !data.length) {
      setShowNoMarkersFoundToast(true);
    } else {
      setMarkers(matchMarkersWithEO(data));
    }
    setLoadingChargers(false);
  }

  const searchOnLocationFound = async (mapObj) => {
    //setNoResultsFound('');
    setLoadingChargers(true);
    //wait a bit so that map is loaded and we can get the map bounds
    await new Promise(r => setTimeout(r, 1500));
    const data = await API.getEnergyLocationsByZipcode(mapObj.getBounds().getSouthWest(), mapObj.getBounds().getNorthEast());
    if (!Array.isArray(data) || !data.length) {
      setShowNoMarkersFoundToast(true);
    } else {
      setMarkers(matchMarkersWithEO(data));
    }
    setLoadingChargers(false);
  }

  const zoomToSearchClicked = async () => {
    setMapZoom(16);
    await new Promise(r => setTimeout(r, 500));
    await searchHereClicked();
  }

  const openMapOnLocation = async (chargerlocation_id) => {
    setLoadChargersFromURL(true);
    const data = await API.getEnergyLocationDetail(chargerlocation_id);
    const arrayData = [data];
    if (!Array.isArray(arrayData) || !arrayData.length) {
      //no results found, just load normal map
    } else {
      if (arrayData[0]["detail"]) {
        //no results found, just load normal map
      }
      else {
        setMarkers(matchMarkersWithEO(arrayData));
        setBringmethereLocation({
          'id': arrayData[0]["id"],
          'latitude': arrayData[0]["latitude"],
          'longitude': arrayData[0]["longitude"]
        });
      }
    }
    setLoadChargersFromURL(false);
  }

  const goToLocation = async (chargerlocation_id) => {
    const data = await API.getEnergyLocationDetail(chargerlocation_id);
    const arrayData = [data];
    if (!Array.isArray(arrayData) || !arrayData.length) {
      //no results found, just load normal map
    } else {
      if (arrayData[0]["detail"]) {
        //no results found, just load normal map
      }
      else {
        setMarkers(matchMarkersWithEO(arrayData));
      }
    }
  }

  const viewUserReports = async () => {
    setLoadingUserReportsError(false);
    setLoadingUserReports(true);
    openUserReportsOffcanvas();
    const resp = await API.getUserReports(authTokens.access);
    if (typeof resp !== 'undefined') {
      if (resp.length > 0) {
        setUserReports(resp);
      }
      else {
        setUserReports(null);
      }
    }
    else {
      setLoadingUserReportsError(true);
      //console.log("You didn't create any review yet!")
    }
    setLoadingUserReports(false);
  }

  const updateUserReportsState = (reportToUpdate) => {
    const reportToUpdateIndex = userReports.findIndex((report) => report.id === reportToUpdate.id);
    const newUserReports = [...userReports];
    newUserReports[reportToUpdateIndex] = reportToUpdate;
    setUserReports(newUserReports);
  }

  const confirmReportIsSolvedOrNot = async (userReport, feedback) => {
    const resp = await API.sendUserFeedbackOnOwnReport(userReport.id, feedback, authTokens.access);
    if (resp.status === 200 || resp.status === 204) {
      //Update the userReports state with the just updated userReport
      const updatedUserReport = await resp.json();
      updateUserReportsState(updatedUserReport);

      //refresh user profile
      getUserProfile(authTokens.access);

      //refresh chargers
      searchHereClicked();
      return "ok";
    }
    else {
      //refresh user profile
      getUserProfile(authTokens.access);
      return "error";
    }
  }

  const updateChargerEVSESstatus = async () => {
    setLoadingEVSESStatusError(false);
    setLoadingEVSESStatus(true);
    const data = await API.getEnergyLocationDetail(selectedCharger.id);
    if (typeof data !== 'undefined') {
      setSelectedCharger(data);
      const other_chargers = markers.filter(marker => {
        return marker.id !== selectedCharger.id;
      });
      setMarkers(matchMarkersWithEO([...other_chargers, data]));
    }
    else {
      setLoadingEVSESStatusError(true);
      //console.log("Sorry it is not possible to update the charger status now.")
    }
    setLoadingEVSESStatus(false);
  }

  const viewUserProfile = () => {
    openUserProfileOffcanvas();
  }

  const viewLeaveFeedbackModal = () => {
    openLeaveFeedbackModal();
  }

  const showChargerStatusDetails = (chargerlocation, back) => {
    if (back === "userFavoriteChargers") setShowChargerStatusInfoOffcanvas(true);
    else openChargerStatusInfoOffcanvas();
    setSelectedCharger(chargerlocation);
    let userreports = chargerlocation.usersreports;
    setUserReportsOnCharger(userreports.reverse());
  }

  const showSocketsDetails = (charger, back) => {
    setSelectedCharger(charger);
    if (back === "userFavoriteChargers") setShowChargerStatusEVSESInfoOffcanvas(true);
    else openChargerStatusEVSESInfoOffcanvas();
  }

  const showChargerEvsesStatusDetails = (charger) => {
    setSelectedCharger(charger);
    openChargerStatusEVSESInfoOffcanvas();
  }

  const showChargerComments = (charger) => {
    setSelectedCharger(charger);
    openChargerCommentsOffcanvas();
  }

  const createCommentOnCharger = async (charger, commentText, reaction) => {
    const requestBody =
    {
      'charger_id': charger.id,
      'comment': commentText,
      'reaction': reaction
    }
    const resp = await API.postNewComment(requestBody, authTokens.access);
    if (resp.status === 201) {
      setCommentNotCreatedReason("");
      closeCreateCommentOnChargerModal();
      //props.searchHereClicked();
    }
    else if (resp.status === 200) {
      let data = await resp.json();
      setCommentNotCreatedReason(data.error);
      closeCreateCommentOnChargerModal();
    }
    else {
      setCommentNotCreatedReason("Server issue");
      closeCreateCommentOnChargerModal();
    }
    setShowCommentOnChargerCreatedModal(true);

    //Update location so to retrieve new comment
    const newChargerDetail = await API.getEnergyLocationDetail(charger.id);
    const newChargerDetailData = [newChargerDetail];
    if (!Array.isArray(newChargerDetailData) || !newChargerDetailData.length) {
      //no results found, don't do anything
    } else {
      if (newChargerDetailData[0]["detail"]) {
        //no results found, don't do anything
      }
      else {
        setSelectedCharger(newChargerDetailData[0]);
        const other_chargers = markers.filter(marker => {
          return marker.id !== charger.id;
        });
        setMarkers(([...other_chargers, newChargerDetailData[0]]));
        //matchMarkersWithEO(newChargerDetailData);
      }
    }
  }

  const createUserFeedback = async (requestBody) => {
    const resp = await API.postFeedback(requestBody);
    if (resp.status === 201) {
      setFeedbackNotCreatedReason("");
      closeLeaveFeedbackModal();
    }
    else if (resp.status === 200) {
      let data = await resp.json();
      setFeedbackNotCreatedReason(data.error);
      closeLeaveFeedbackModal();
    }
    else {
      setFeedbackNotCreatedReason("Server issue");
      closeLeaveFeedbackModal();
    }
    setShowFeedbackCreatedModal(true);
  }

  const postMissingCharger = async (contactEmail) => {
    setPostMissingChargerLoading(true);
    const requestBody = new FormData();
    requestBody.append('charger_id', missingChargerFormData["chargerId"]);
    requestBody.append('address', missingChargerFormData["address"]);
    requestBody.append('cpo', missingChargerFormData["cpo"]);
    requestBody.append('extra_info', missingChargerFormData["extraInfo"]);
    requestBody.append('incident_type', missingChargerFormData["incidentType"]);
    requestBody.append('contact_email', contactEmail);
    if (missingChargerFormData["reportImage"]) {
      requestBody.append('picture', missingChargerFormData["reportImage"]);
    }
    const resp = await API.postMissingCharger(requestBody);

    if (resp.status === 201 || resp.status === 200) {
      setPostMissingChargerLoading(false);
      setMissingChargerNotCreatedReason("");
      goToMissingChargerConfirmedModal();
    }
    else if (resp.status === 429) {
      setPostMissingChargerLoading(false);
      setMissingChargerNotCreatedReason("Too many requests");
      goToMissingChargerConfirmedModal();
    }
    else {
      setMissingChargerNotCreatedReason("Server issue");
      setPostMissingChargerLoading(false);
      goToMissingChargerConfirmedModal();
    }
    setPostMissingChargerLoading(false);
  }

  const hideShowSearch = () => { setShowSearchHere(false); }

  const showShowSearch = () => { setShowSearchHere(true); }

  const openUserLoginModal = () => { hideShowSearch(); setShowUserLoginModal(true); }

  const closeLoginModal = () => { setShowUserLoginModal(false); }

  const closeCreateCommentOnChargerModal = () => { setShowCreateCommentOnChargerModal(false); }

  const closeCommentOnChargerModalCreated = () => { setShowCommentOnChargerCreatedModal(false); }

  const openLegendInfoModal = () => { hideShowSearch(); setShowLegendInfo(true); }

  const closeLegendInfoModal = () => { setShowLegendInfo(false); }

  const openMissingChargerModal = () => { hideShowSearch(); setShowMissingChargerInfo(true); }

  const closeMissingChargerModal = () => { setShowMissingChargerInfo(false); }

  const openMissingChargerConfirmModal = () => { hideShowSearch(); setShowMissingChargerConfirm(true); }

  const closeMissingChargerConfirmModal = () => { setShowMissingChargerConfirm(false); }

  const openMissingChargerConfirmedModal = () => { hideShowSearch(); setShowMissingChargerConfirmed(true); }

  const closeMissingChargerConfirmedModal = () => { setShowMissingChargerConfirmed(false); }

  const openLeaveFeedbackModal = () => { hideShowSearch(); setShowLeaveFeedbackModal(true); }

  const closeLeaveFeedbackModal = () => { setShowLeaveFeedbackModal(false); }

  const closeFeedbackCreatedModal = () => { setShowFeedbackCreatedModal(false); }

  const openUserReportsOffcanvas = () => {
    setShowChargerStatusInfoOffcanvas(false);
    setShowUserFavoriteChargersOffcanvas(false);
    setShowUserProfileOffcanvas(false);
    setShowChargerStatusEVSESInfoOffcanvas(false);
    setShowChargerCommentsOffcanvas(false);
    setShowUserReportsOffcanvas(true);
  }

  const closeUserReportsOffcanvas = () => { setShowUserReportsOffcanvas(false); }

  const openUserFavoriteChargersOffcanvas = () => {
    setShowUserReportsOffcanvas(false);
    setShowChargerStatusInfoOffcanvas(false);
    setShowUserProfileOffcanvas(false);
    setShowChargerStatusEVSESInfoOffcanvas(false);
    setShowChargerCommentsOffcanvas(false);
    setShowUserFavoriteChargersOffcanvas(true);
  }

  const closeUserFavoriteChargersOffcanvas = () => { setShowUserFavoriteChargersOffcanvas(false); }

  const openChargerStatusInfoOffcanvas = () => {
    setShowUserReportsOffcanvas(false);
    setShowUserFavoriteChargersOffcanvas(false);
    setShowUserProfileOffcanvas(false);
    setShowChargerStatusEVSESInfoOffcanvas(false);
    setShowChargerCommentsOffcanvas(false);
    setShowChargerStatusInfoOffcanvas(true);
  }

  const closeChargerStatusInfoOffcanvas = () => { setShowChargerStatusInfoOffcanvas(false); }

  const openChargerStatusEVSESInfoOffcanvas = () => {
    setShowUserReportsOffcanvas(false);
    setShowUserFavoriteChargersOffcanvas(false);
    setShowUserProfileOffcanvas(false);
    setShowChargerStatusInfoOffcanvas(false);
    setShowChargerCommentsOffcanvas(false);
    setShowChargerStatusEVSESInfoOffcanvas(true);
  }

  const closeChargerStatusEVSESInfoOffcanvas = () => { setShowChargerStatusEVSESInfoOffcanvas(false); }

  const openChargerCommentsOffcanvas = () => {
    setShowUserReportsOffcanvas(false);
    setShowUserFavoriteChargersOffcanvas(false);
    setShowUserProfileOffcanvas(false);
    setShowChargerStatusInfoOffcanvas(false);
    setShowChargerStatusEVSESInfoOffcanvas(false);
    setShowChargerCommentsOffcanvas(true);
  }

  const closeChargerCommentsOffcanvas = () => { setShowChargerCommentsOffcanvas(false); }

  const openUserProfileOffcanvas = () => {
    setShowUserReportsOffcanvas(false);
    setShowUserFavoriteChargersOffcanvas(false);
    setShowChargerStatusInfoOffcanvas(false);
    setShowChargerStatusEVSESInfoOffcanvas(false);
    setShowChargerCommentsOffcanvas(false);
    setShowUserProfileOffcanvas(true);
  }

  const closeUserProfileOffcanvas = () => { setShowUserProfileOffcanvas(false); }

  const showShareOptions = (charger) => {
    setSelectedCharger(charger);
    setShowShareOptionsModal(true);
  }

  const closeShareOptionsModal = () => {
    setShowShareOptionsModal(false);
  }

  const closeLoginToast = () => {
    setShowLoginToast(false);
  }

  const closeSignupToast = () => {
    setShowSignupToast(false);
  }

  const closeNoMarkersFoundToast = () => {
    setShowNoMarkersFoundToast(false);
  }

  const goToMissingChargerInfoModal = () => {
    closeMissingChargerConfirmModal();
    openMissingChargerModal();
  }

  const goToMissingChargerConfirmModal = () => {
    closeMissingChargerModal();
    openMissingChargerConfirmModal();
  }

  const goToMissingChargerConfirmedModal = () => {
    closeMissingChargerConfirmModal();
    openMissingChargerConfirmedModal();
  }

  return (
    <div className="App">
      <Helmet>
        <meta
          name="description"
          content="ChargeProb - Meld eenvoudig een laadpaal storing"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <title>ChargeProb - Meld eenvoudig een laadpaal storing</title>
      </Helmet>
      <MapNavbar
        page="map"
        openUserLoginModal={openUserLoginModal}
        logoutUser={logout} />
      <UserReportsOffcanvas
        language={language}
        show={showUserReportsOffcanvas}
        handleClose={closeUserReportsOffcanvas}
        userReports={userReports}
        loadingUserReports={loadingUserReports}
        loadingUserReportsError={loadingUserReportsError}
        setBringmethereLocation={setBringmethereLocation}
        showShareOptions={showShareOptions}
        confirmReportIsSolvedOrNot={confirmReportIsSolvedOrNot}
      />
      <UserFavoriteChargersOffcanvas
        language={language}
        show={showUserFavoriteChargersOffcanvas}
        handleClose={closeUserFavoriteChargersOffcanvas}
        userFavoriteChargers={userFavoriteChargers}
        loadingUserFavChargers={loadingUserFavChargers}
        loadingUserFavChargersError={loadingUserFavChargersError}
        setBringmethereLocation={setBringmethereLocation}
        showChargerStatusDetails={showChargerStatusDetails}
        showSocketsDetails={showSocketsDetails}
        addOrRemoveFromFavoriteChargers={addOrRemoveFromFavoriteChargers}
        showShareOptions={showShareOptions}
      />
      <ChargerStatusInfoOffcanvas
        language={language}
        show={showChargerStatusInfoOffcanvas}
        handleClose={closeChargerStatusInfoOffcanvas}
        selectedCharger={selectedCharger}
        userReportsOnCharger={userReportsOnCharger} />
      <EVSESStatusInfoOffcanvas
        language={language}
        show={showChargerStatusEVSESInfoOffcanvas}
        handleClose={closeChargerStatusEVSESInfoOffcanvas}
        selectedCharger={selectedCharger}
        updateChargerEVSESstatus={updateChargerEVSESstatus}
        loadingEVSESStatus={loadingEVSESStatus}
        loadingEVSESStatusError={loadingEVSESStatusError} />
      <ChargerCommentsOffcanvas
        language={language}
        show={showChargerCommentsOffcanvas}
        handleClose={closeChargerCommentsOffcanvas}
        selectedCharger={selectedCharger}
        setShowCreateCommentOnChargerModal={setShowCreateCommentOnChargerModal}
        openUserLoginModal={openUserLoginModal} />
      <UserProfileOffcanvas
        language={language}
        show={showUserProfileOffcanvas}
        handleClose={closeUserProfileOffcanvas}
        logoutUser={logout}
      />
      <div className="map">
        <Map
          setMap={setMap}
          mapSearchBoxProvider={mapSearchBoxProvider}
          mapboxToken={mapboxToken}
          latitude={initialLatitude}
          longitude={initialLongitude}
          mapZoom={mapZoom}
          setMapZoom={setMapZoom}
          language={language}
          markers={markers}
          showSearch={showSearch}
          setShowSearchHere={setShowSearchHere}
          searchHereClicked={searchHereClicked}
          searchOnLocationFound={searchOnLocationFound}
          popupIsOpen={popupIsOpen}
          setPopupIsOpen={setPopupIsOpen}
          showShowSearch={showShowSearch}
          hideShowSearch={hideShowSearch}
          setShowLoginToast={setShowLoginToast}
          setShowSignupToast={setShowSignupToast}
          bringmethereLocation={bringmethereLocation}
          goToLocation={goToLocation}
          showChargerStatusDetails={showChargerStatusDetails}
          showChargerEvsesStatusDetails={showChargerEvsesStatusDetails}
          showChargerComments={showChargerComments}
          addOrRemoveFromFavoriteChargers={addOrRemoveFromFavoriteChargers}
          setUserLocationError={setUserLocationError}
          setShowNoLocationAccessAlert={setShowNoLocationAccessAlert}
          openLegendInfoModal={openLegendInfoModal}
          openMissingChargerModal={openMissingChargerModal}
          openUserLoginModal={openUserLoginModal}
          loadUserLocation={loadUserLocation}
          setLoadingUserLocation={setLoadingUserLocation}
          showShareOptions={showShareOptions}
        />
        <NoLocationPermissionsAlert
          language={language}
          show={showNoLocationAccessAlert}
          setShow={setShowNoLocationAccessAlert}
          userLocationError={userLocationError} />
        <ModalUserLoginOrRegister
          title="Login"
          language={language}
          parent="home"
          show={showUserLoginModal}
          handleClose={closeLoginModal}
          userEmail={userEmail}
          setUserEmail={setUserEmail}
          userPhoneNumber={userPhoneNumber}
          setUserPhoneNumber={setUserPhoneNumber}
          userPassword={userPassword}
          setUserPassword={setUserPassword}
          userRepeatPassword={userRepeatPassword}
          setUserRepeatPassword={setUserRepeatPassword}
          loginUser={login}
          loginWithGoogleToken={loginWithGoogleToken}
          userLoginError={userLoginError}
          registerUser={register}
          userRegisterError={userRegisterError}
          setUserRegisterError={setUserRegisterError}
        />
        <ModalCreateCommentOnCharger
          language={language}
          show={showCreateCommentOnChargerModal}
          handleClose={closeCreateCommentOnChargerModal}
          selectedCharger={selectedCharger}
          createCommentOnCharger={createCommentOnCharger} />
        <ModalCommentOnChargerCreated
          language={language}
          show={showCommentOnChargerCreatedModal}
          handleClose={closeCommentOnChargerModalCreated}
          commentNotCreatedReason={commentNotCreatedReason} />
        <ModalShowLegendInfo
          language={language}
          show={showLegendInfo}
          handleClose={closeLegendInfoModal} />
        <ModalMissingChargerInfo
          language={language}
          show={showMissingChargerInfo}
          handleClose={closeMissingChargerModal}
          goToNextModal={goToMissingChargerConfirmModal}
          missingChargerFormData={missingChargerFormData}
          setMissingChargerFormData={setMissingChargerFormData} />
        <ModalConfirmMissingCharger
          language={language}
          show={showMissingChargerConfirm}
          handleClose={closeMissingChargerConfirmModal}
          handleBack={goToMissingChargerInfoModal}
          missingChargerFormData={missingChargerFormData}
          setMissingChargerFormData={setMissingChargerFormData}
          postMissingCharger={postMissingCharger}
          postMissingChargerLoading={postMissingChargerLoading} />
        <ModalMissingChargerConfirmed
          language={language}
          show={showMissingChargerConfirmed}
          handleClose={closeMissingChargerConfirmedModal}
          missingChargerNotCreatedReason={missingChargerNotCreatedReason} />
        <ModalUserFeedback
          language={language}
          show={showLeaveFeedbackModal}
          handleClose={closeLeaveFeedbackModal}
          createUserFeedback={createUserFeedback} />
        <ModalUserFeedbackCreated
          language={language}
          show={showFeedbackCreatedModal}
          handleClose={closeFeedbackCreatedModal}
          feedbackNotCreatedReason={feedbackNotCreatedReason} />
        <ModalWelcomeToCP
          language={language}
          show={showWelcomeToCPModal}
          setShow={setShowWelcomeToCPModal}
        />
        <SearchHereOverlay
          language={language}
          mapSearchBoxProvider={mapSearchBoxProvider}
          mapZoom={mapZoom}
          setMapZoom={setMapZoom}
          showSearchHere={showSearchHere}
          searchHereClicked={searchHereClicked}
          zoomToSearchClicked={zoomToSearchClicked}
          markers={markers}
          loadChargerFromURL={loadChargerFromURL}
          loadingChargers={loadingChargers}
          loadingUserLocation={loadingUserLocation}
          popupIsOpen={popupIsOpen}
          showUserLoginModal={showUserLoginModal}
          showUserReportsOffcanvas={showUserReportsOffcanvas}
          showUserFavoriteChargersOffcanvas={showUserFavoriteChargersOffcanvas} />
        <ModalShareOnSocials
          showShareOptionsModal={showShareOptionsModal}
          closeShareOptionsModal={closeShareOptionsModal}
          selectedCharger={selectedCharger}
          language={language}
        />
        <SignedInToast
          showLoginToast={showLoginToast}
          closeLoginToast={closeLoginToast}
          showSignupToast={showSignupToast}
          closeSignupToast={closeSignupToast}
          language={language} />
        <NoMarkersFoundToast
          showNoMarkersFoundToast={showNoMarkersFoundToast}
          closeNoMarkersFoundToast={closeNoMarkersFoundToast}
          language={language} />
        <CookieConsent
          location="bottom"
          buttonText={cookiesconsentContent.consentButtonText}
          cookieName="standardCookie"
          style={{ background: "#202832", zIndex: 9999 }}
          buttonStyle={{ background: "#1dbf73", color: "#202832", fontSize: "9px", margin: "7px 10px 7px 0px" }}
          contentStyle={{ margin: "7px 0px 7px 0px" }}
          expires={150} >
          <span style={{ fontSize: "10px", color: "#1dbf73" }}>{cookiesconsentContent.consentText}</span>
        </CookieConsent>
      </div>
      <MapFooter
        page="map"
        viewUserReports={viewUserReports}
        viewUserFavoriteChargers={viewUserFavoriteChargers}
        viewUserProfile={viewUserProfile}
        viewLeaveFeedbackModal={viewLeaveFeedbackModal} />
    </div>
  );
}

export default App;
