import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { leaveFeedbackContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';

function ModalUserFeedbackCreated(props) {

    const content = props.language === "EN" ? leaveFeedbackContent.English :
        props.language === "NL" ? leaveFeedbackContent.Dutch :
            leaveFeedbackContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const title = props.feedbackNotCreatedReason === "" ? content.success_p1 : "Oops!";

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{title}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            {props.feedbackNotCreatedReason === "" ?
                <Modal.Body className="modalsBody">
                    <p>{content.success_p2}</p>
                    <p>{content.success_p3}</p>
                    <Row>
                        <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                            <Button variant="success" onClick={props.handleClose}>
                                {buttonscontent.close}
                            </Button>
                        </div>
                    </Row>
                </Modal.Body>
                :
                <Modal.Body className="modalsBody">
                    <p>{content.fail_p1}</p>
                    <p>{content.fail_p2}</p>
                    <Row>
                        <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                            <Button variant="success" onClick={props.handleClose}>
                                {buttonscontent.close}
                            </Button>
                        </div>
                    </Row>
                </Modal.Body>
            }
        </Modal>
    )
}

export default ModalUserFeedbackCreated;