import React, { useState, useContext } from 'react';
import { API } from '../../api-service';
import Button from 'react-bootstrap/Button';
import ModalUserLoginOrRegister from '../modals/ModalUserLoginOrRegister';
import ModalConfirmNewIncident from '../modals/ModalConfirmNewIncident';
import ModalNewIncidentConfirmed from '../modals/ModalNewIncidentConfirmed';
import ModalNewIncidentInfo from '../modals/ModalNewIncidentInfo';
//import ModalAccountNotActive from '../modals/ModalAccountNotActive';
import ModalNewIncidentNotCreated from '../modals/ModalNewIncidentNotCreated';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { loginContent, reportIncidentContent } from '../internationalization/Translations';
import AuthContext from '../../context/AuthContext';

function ReportIncidentButton(props) {

    let { user, userProfile, getUserProfile, authTokens, loginUser, registerUser, loginUserWithGoogleToken } = useContext(AuthContext);
    const content = props.language === "EN" ? reportIncidentContent.English :
        props.language === "NL" ? reportIncidentContent.Dutch :
            reportIncidentContent.English;
    const logincontent = props.language === "EN" ? loginContent.English :
        props.language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const [incidentType, setIncidentType] = useState('');
    const [incidentTypeTranslation, setIncidentTypeTranslation] = useState('');
    const [affectedCharger, setAffectedCharger] = useState('');
    const [incidentExtraInfo, setIncidentExtraInfo] = useState('');
    const [carModel, setCarModel] = useState('');
    const [reportImage, setReportImage] = useState(null);
    const [reportImagePreview, setReportImagePreview] = useState(null);

    const [userEmail, setUserEmail] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userRepeatPassword, setUserRepeatPassword] = useState('');
    const [userLoginError, setUserLoginError] = useState('');
    const [userRegisterError, setUserRegisterError] = useState('');
    const loginError = logincontent.loginError;
    //const [newUserJustSignedup, setNewUserJustSignedup] = useState(false);

    const [onIncidentInfoModal, setOnIncidentInfoModal] = useState(false);
    const [onUserLoginModal, setOnUserLoginModal] = useState(false);
    const [onAccountNotActiveModal, setOnAccountNotActiveModal] = useState(false);
    const [onConfirmModal, setOnConfirmModal] = useState(false);
    const [onNewIncidentConfirmedModal, setOnNewIncidentConfirmedModal] = useState(false);
    const [onNewIncidentNotCreatedModal, setOnNewIncidentNotCreatedModal] = useState(false);

    const [incidentNotCreatedReason, setIncidentNotCreatedReason] = useState("");
    const [sendIncidentLoading, setSendIncidentLoading] = useState(false);

    const isUserLoggedIn = () => {
        if (user) return true;
        return false;
    }

    const isUserEmailConfirmed = () => {
        if (user && userProfile && userProfile.email_confirmed) return true;
        return false;
    }

    const checkUserIsLoggedIn = () => {
        if (isUserLoggedIn()) {
            goToConfirmModal();
            // if (isUserEmailConfirmed()) {
            //     goToOnIncidentInfoModal();
            // }
            // else {
            //     goToOnAccountNotActiveModal();
            // }
        }
        else {
            goToOnUserLoginModal();
        }
    }

    const closeAllModals = () => {
        setOnIncidentInfoModal(false);
        setOnUserLoginModal(false);
        setOnConfirmModal(false);
        setOnNewIncidentConfirmedModal(false);
        setOnNewIncidentNotCreatedModal(false);
        setOnAccountNotActiveModal(false);
        props.showShowSearch();
    }

    const goToOnUserLoginModal = () => {
        props.hideShowSearch();
        setOnAccountNotActiveModal(false);
        setOnConfirmModal(false);
        setOnNewIncidentConfirmedModal(false);
        setOnNewIncidentNotCreatedModal(false);
        setOnIncidentInfoModal(false);
        setOnUserLoginModal(true);
    }

    const goToOnAccountNotActiveModal = () => {
        props.hideShowSearch();
        setOnIncidentInfoModal(false);
        setOnUserLoginModal(false);
        setOnConfirmModal(false);
        setOnNewIncidentConfirmedModal(false);
        setOnNewIncidentNotCreatedModal(false);
        setOnAccountNotActiveModal(true);
    }

    const goToOnIncidentInfoModal = () => {
        props.hideShowSearch();
        setOnAccountNotActiveModal(false);
        setOnUserLoginModal(false);
        setOnConfirmModal(false);
        setOnNewIncidentConfirmedModal(false);
        setOnNewIncidentNotCreatedModal(false);
        setOnIncidentInfoModal(true);
    }

    const goToConfirmModal = () => {
        props.hideShowSearch();
        setOnIncidentInfoModal(false);
        setOnUserLoginModal(false);
        setOnNewIncidentConfirmedModal(false);
        setOnNewIncidentNotCreatedModal(false);
        setOnAccountNotActiveModal(false);
        setOnConfirmModal(true);
    }

    const goToNewIncidentConfirmedModal = () => {
        props.hideShowSearch();
        setOnIncidentInfoModal(false);
        setOnUserLoginModal(false);
        setOnConfirmModal(false);
        setOnNewIncidentNotCreatedModal(false);
        setOnAccountNotActiveModal(false);
        setOnNewIncidentConfirmedModal(true);
    }

    const goToNewIncidentNotCreatedModal = () => {
        props.hideShowSearch();
        setOnIncidentInfoModal(false);
        setOnUserLoginModal(false);
        setOnConfirmModal(false);
        setOnNewIncidentConfirmedModal(false);
        setOnAccountNotActiveModal(false);
        setOnNewIncidentNotCreatedModal(true);
    }

    const postNewIncident = async (markerInfo) => {
        setSendIncidentLoading(true);
        const requestBody = new FormData();
        requestBody.append('charger_id', markerInfo.id);
        requestBody.append('report', incidentType);
        requestBody.append('affected_charger', affectedCharger);
        requestBody.append('report_notes', incidentExtraInfo);
        requestBody.append('car_model', carModel);
        if(reportImage){
            requestBody.append('report_image', reportImage);
        }
        let resp = null;
        if(isUserLoggedIn() && authTokens && authTokens.access) { 
            resp = await API.postNewIncident(requestBody, authTokens.access);
        }
        else{
            resp = await API.postNewIncident(requestBody);
        }
        if (resp.status === 201) {
            setSendIncidentLoading(false);
            setIncidentNotCreatedReason("");
            goToNewIncidentConfirmedModal();
            props.searchHereClicked();
            //refresh userProfile and tot_reports
            if(isUserLoggedIn() && authTokens && authTokens.access) { 
                getUserProfile(authTokens.access);
            }
        }
        else if (resp.status === 200) {
            let data = await resp.json();
            setSendIncidentLoading(false);
            setIncidentNotCreatedReason(data.error);
            goToNewIncidentNotCreatedModal();
        }
        else if (resp.status === 429) {
            let data = await resp.json();
            setSendIncidentLoading(false);
            setIncidentNotCreatedReason("Too many requests");
            goToNewIncidentNotCreatedModal();
        }
        else {
            setIncidentNotCreatedReason("Server issue");
            setSendIncidentLoading(false);
            goToNewIncidentNotCreatedModal();
        }
    }

    const login = async () => {
        setUserLoginError("");
        const body = {
            'email': userEmail,
            'password': userPassword
        }
        const resp = await loginUser(body);
        if (resp === "OK") {
            props.setShowLoginToast(true);
            goToConfirmModal();
        }
        else {
            setUserLoginError(loginError);
        }
    }

    const loginWithGoogleToken = async (googleToken) => {
        setUserLoginError("");
        const resp = await loginUserWithGoogleToken(googleToken);
        if (resp === "OK") {
          props.setShowLoginToast(true);
          goToConfirmModal();
        }
        else {
            setUserLoginError(loginError);
        }
      }

    // const continueWithoutLogin = () => {
    //     goToConfirmModal();
    // }

    const register = async () => {
        //setNewUserJustSignedup(false);
        setUserRegisterError("");
        const body = {
            'username': userEmail,
            'email': userEmail,
            'password': userPassword
        }
        const bodyLogin = {
            'email': userEmail,
            'password': userPassword
        }
        const resp = await registerUser(body, bodyLogin);
        if (resp === "OK") {
            props.setShowSignupToast(true);
            //setNewUserJustSignedup(true);
            //goToOnAccountNotActiveModal();
            goToConfirmModal();
        }
        else {
            setUserRegisterError(resp);
        }
    }

    const resetIncidentInfo = () => {
        setIncidentType('');
        setIncidentTypeTranslation('');
        setAffectedCharger('Complete charger');
        setIncidentExtraInfo('');
        setCarModel('');
        setReportImage(null);
        setReportImagePreview(null);
    }

    return (
        <>
            <Button
                size="md"
                className='chargeprob-button-outline-warning reportIncident-button-glowing'
                style={{ padding: '0.4rem 0.6rem 0.2rem 0.6rem', fontSize: '1.1rem' }}
                onClick={() => goToOnIncidentInfoModal()}>
                <b>{content.button} &nbsp;
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: '1.4rem' }} />
                </b>
            </Button>
            <ModalUserLoginOrRegister
                // title="Login to send a new incident"
                language={props.language}
                parent="report"
                show={onUserLoginModal}
                handleClose={closeAllModals}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                userPhoneNumber={userPhoneNumber}
                setUserPhoneNumber={setUserPhoneNumber}
                userPassword={userPassword}
                setUserPassword={setUserPassword}
                userRepeatPassword={userRepeatPassword}
                setUserRepeatPassword={setUserRepeatPassword}
                loginUser={login}
                loginWithGoogleToken={loginWithGoogleToken}
                //continueWithoutLogin={continueWithoutLogin}
                userLoginError={userLoginError}
                registerUser={register}
                userRegisterError={userRegisterError}
                setUserRegisterError={setUserRegisterError}
                markerInfo={props.markerInfo}
                incidentType={incidentType}
                incidentTypeTranslation={incidentTypeTranslation}
                incidentExtraInfo={incidentExtraInfo}
                sendIncidentLoading={sendIncidentLoading}
                postNewIncident={postNewIncident}
            />
            {/* <ModalAccountNotActive
                show={onAccountNotActiveModal}
                handleClose={closeAllModals}
                //newUserJustSignedup={newUserJustSignedup}
                language={props.language}
            /> */}
            <ModalNewIncidentInfo
                show={onIncidentInfoModal}
                handleClose={closeAllModals}
                goToNextModal={checkUserIsLoggedIn}
                markerInfo={props.markerInfo}
                setIncidentType={setIncidentType}
                setIncidentTypeTranslation={setIncidentTypeTranslation}
                setAffectedCharger={setAffectedCharger}
                setIncidentExtraInfo={setIncidentExtraInfo}
                setCarModel={setCarModel}
                setReportImage={setReportImage}
                setReportImagePreview={setReportImagePreview}
                resetIncidentInfo={resetIncidentInfo}
                language={props.language}
            />
            <ModalConfirmNewIncident
                show={onConfirmModal}
                handleClose={closeAllModals}
                handleBack={goToOnIncidentInfoModal}
                markerInfo={props.markerInfo}
                userEmail={userEmail}
                userPhoneNumber={userPhoneNumber}
                incidentType={incidentType}
                incidentTypeTranslation={incidentTypeTranslation}
                affectedCharger={affectedCharger}
                incidentExtraInfo={incidentExtraInfo}
                carModel={carModel}
                reportImage={reportImage}
                reportImagePreview={reportImagePreview}
                postNewIncident={postNewIncident}
                sendIncidentLoading={sendIncidentLoading}
                resetIncidentInfo={resetIncidentInfo}
                language={props.language}
            />
            <ModalNewIncidentConfirmed
                show={onNewIncidentConfirmedModal}
                handleClose={closeAllModals}
                markerInfo={props.markerInfo}
                language={props.language}
            />
            <ModalNewIncidentNotCreated
                show={onNewIncidentNotCreatedModal}
                handleClose={closeAllModals}
                markerInfo={props.markerInfo}
                language={props.language}
                incidentNotCreatedReason={incidentNotCreatedReason}
            />
        </>
    )
}

export default ReportIncidentButton;

/*
<OverlayTrigger
    placement="top"
    overlay={<Tooltip id="reportincidenttooltip">Report a new incident</Tooltip>} >
    <Button 
        variant="danger" 
        size="md" 
        onClick={goToOnIncidentInfoModal}>
            Report new incident &nbsp;
        <FontAwesomeIcon icon={faExclamationTriangle} />
    </Button>
</OverlayTrigger> */