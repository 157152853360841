import React, { useContext } from 'react';
import MapNavbar from '../components/MapNavbar';
import '../App.css';
import Container from 'react-bootstrap/Container';
import { errorPageContent } from '../components/internationalization/Translations';
import AuthContext from '../context/AuthContext';

function ErrorPage() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? errorPageContent.English :
        language === "NL" ? errorPageContent.Dutch :
            errorPageContent.English;

    return (
        <>
            <MapNavbar page="errorpage" />
            <Container fluid className='errorpage'>
                <br /><br /><br /><br />
                <h2>{content.wrongPage}</h2>
                <h2>&nbsp; {content.goBackToCP} <a href='/'>ChargeProb</a></h2>
            </Container>
        </>
    )

}

export default ErrorPage;