import React, { useState, useContext } from 'react';
import '../stylesheets/nicepage_homepage.css';
import AuthContext from '../context/AuthContext';
import { homePageContent } from '../components/internationalization/Translations';
import ChargerStatusPhone from '../static/images/checkstatus.png';
import ReportProblemPhone from '../static/images/erijder2.png';
import ReceiveEmailPhone from '../static/images/erijder3.png';
import OverOns from '../static/images/overons.jpg';
import Conversation from '../static/images/conversation.png';
import ChargingStationAvailable from '../static/images/chargers/green-charger_64px.png';
import ChargingStationHalfAvailable from '../static/images/chargers/red-green-charger_64px.png';
import ChargingStationWarning from '../static/images/chargers/warning-charger_64px.png';
import ChargingStationUnavailable from '../static/images/chargers/red-charger_64px.png';
//import ChargingStationUnknown from '../static/images/chargers/unknown-charger_64px.png';
import CautionIcon from '../static/images/commonproblems_caution.png';
import SnailIcon from '../static/images/commonproblems_snail.png';
import SocketIcon from '../static/images/commonproblems_socket.png';
import NfcIcon from '../static/images/commonproblems_laadpas.png';
import PisaIcon from '../static/images/commonproblems_pisa.png';
import FuelIcon from '../static/images/commonproblems_fuel.png';
import MapNavbar from '../components/MapNavbar';
import GetInContact from './GetInContact';
import StatsCounter from './StatsCounter';
import HomePageFooter from './HomePageFooter';

function ERidersHomePage(props) {

  let { language } = useContext(AuthContext);
  const content = language === "EN" ? homePageContent.English :
    language === "NL" ? homePageContent.Dutch :
      homePageContent.English;

  const [showQuestion1, setShowQuestion1] = useState(false);
  const [showQuestion2, setShowQuestion2] = useState(false);
  const [showQuestion3, setShowQuestion3] = useState(false);
  const [showQuestion4, setShowQuestion4] = useState(false);
  const [showQuestion5, setShowQuestion5] = useState(false);
  const [showQuestion6, setShowQuestion6] = useState(false);


  return (
    <div className='homepagediv'>
      <MapNavbar page="homepage" />
      <div className='u-body u-xl-mode' data-lang="en">
        <section className="u-align-right u-clearfix u-image u-shading u-section-3" id="sec-2d1e" data-image-width="6000" data-image-height="4000">
          <div className="u-clearfix u-sheet u-sheet-1">
            <h1 className="u-align-left u-custom-font u-text u-text-body-alt-color u-text-1">{content.incidentOnCharger}<br />
              <br />
            </h1>
            <a href="/" className="u-active-black u-align-center-xs u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-custom-font u-hover-custom-color-1 u-radius-50 u-btn-1">{content.reportIncidentHere}!<br />
            </a>
          </div>
        </section>
        <StatsCounter />
        <section className="u-clearfix u-section-4" id="sec-4955">
          <div className="u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
            <h3 className="u-align-center u-custom-font u-text u-text-1">{content.reportIncidentEasy}!</h3>
            <h3 className="u-align-center u-custom-font u-text u-text-default-lg u-text-default-md u-text-default-xl u-text-2"> {content.reportIncidentEasy_text_1} <span className="u-text-custom-color-2">ChargeProb </span>{content.reportIncidentEasy_text_2}</h3>
            <div className="u-expanded-width u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-container-style u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <img alt="" className="u-expanded-width u-image u-image-default u-image-1" data-image-width="1946" data-image-height="2238" src={ChargerStatusPhone} />
                    <h3 className="u-align-center u-custom-font u-text u-text-default u-text-3">{content.reportIncidentEasy_chargerStatus}</h3>
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-4">{content.reportIncidentEasy_chargerStatusCheckStatus}</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <img alt="" className="u-expanded-width u-image u-image-default u-image-2" data-image-width="1946" data-image-height="2239" src={ReportProblemPhone} />
                    <h3 className="u-align-center u-custom-font u-text u-text-default u-text-5">{content.reportIncident}</h3>
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-6">{content.reportIncidentEasy_chargerStatusReportProblem_text}</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <img alt="" className="u-expanded-width u-image u-image-default u-image-3" data-image-width="1946" data-image-height="2239" src={ReceiveEmailPhone} />
                    <h3 className="u-align-center u-custom-font u-text u-text-default u-text-7">{content.reportIncidentEasy_chargerStatusStayUpdated}</h3>
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-8">{content.reportIncidentEasy_chargerStatusStayUpdated_text}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="u-align-center-lg u-align-center-md u-align-center-xl u-align-left-sm u-align-left-xs u-clearfix u-container-align-center u-grey-5 u-section-5" id="sec-32b3">
          <div className="u-clearfix u-sheet u-valign-middle-xs u-sheet-1">
            <h3 className="u-align-center u-custom-font u-text u-text-1">{content.chargerPossibileStatuses}</h3>
            <div className="u-expanded-width u-layout-grid u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-1">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h5 className="u-align-center u-custom-font u-text u-text-2">{content.chargerPossibileStatuses_available}</h5>
                    <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-1" src={ChargingStationAvailable} alt="" data-image-width="24" data-image-height="24" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-3">{content.chargerPossibileStatuses_available_text}</p>
                  </div>
                </div>
                <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-4">
                  <div className="u-container-layout u-similar-container u-container-layout-4">
                    <h5 className="u-align-center u-custom-font u-text u-text-8">{content.chargerPossibileStatuses_halfavailable}</h5>
                    <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-4" src={ChargingStationHalfAvailable} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-9">{content.chargerPossibileStatuses_halfavailable_text}</p>
                  </div>
                </div>
                <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-2">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h5 className="u-align-center u-custom-font u-text u-text-4">{content.chargerPossibileStatuses_warning}</h5>
                    <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-2" src={ChargingStationWarning} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-5">{content.chargerPossibileStatuses_warning_text}</p>
                  </div>
                </div>
                <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-3">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h5 className="u-align-center u-custom-font u-text u-text-6">{content.chargerPossibileStatuses_unavailable}</h5>
                    <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-3" src={ChargingStationUnavailable} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-7">{content.chargerPossibileStatuses_unavailable_text}</p>
                  </div>
                </div>
                {/* <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-4">
                  <div className="u-container-layout u-similar-container u-container-layout-4">
                    <h5 className="u-align-center u-custom-font u-text u-text-8">{content.chargerPossibileStatuses_unknown}</h5>
                    <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-4" src={ChargingStationUnknown} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-9">{content.chargerPossibileStatuses_unknown_text}</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="u-align-center u-clearfix u-section-6" id="sec-493b">
          <div className="u-align-left u-clearfix u-sheet u-sheet-1">
            <h3 className="u-align-center u-custom-font u-text u-text-1">{content.commonProblems}</h3>
            <div className="u-expanded-width u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-container-style u-grey-5 u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h4 className="u-align-center u-custom-font u-text u-text-2">{content.commonProblems_notWorking}</h4>
                    <img className="u-image u-image-contain u-image-default u-preserve-proportions u-image-1" src={CautionIcon} alt="" data-image-width="64" data-image-height="64" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-3">{content.commonProblems_notWorking_text}</p>
                  </div>
                </div>
                <div className="u-align-center u-container-style u-grey-5 u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h4 className="u-align-center u-custom-font u-text u-text-4">{content.commonProblems_slow}</h4>
                    <img className="u-image u-image-contain u-image-default u-preserve-proportions u-image-2" src={SnailIcon} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-5">{content.commonProblems_slow_text}</p>
                  </div>
                </div>
                <div className="u-container-style u-grey-5 u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h4 className="u-align-center u-custom-font u-text u-text-6">{content.commonProblems_cableStuck}</h4>
                    <img className="u-image u-image-contain u-image-default u-preserve-proportions u-image-3" src={SocketIcon} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-7">{content.commonProblems_cableStuck_text}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="u-align-center u-clearfix u-section-7" id="sec-53b9">
          <div className="u-align-left u-clearfix u-sheet u-sheet-1">
            <div className="u-expanded-width u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-container-style u-grey-5 u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h4 className="u-align-center u-custom-font u-text u-text-default u-text-1">{content.commonProblems_passNotWorking}</h4>
                    <img className="u-image u-image-contain u-image-default u-preserve-proportions u-image-1" src={NfcIcon} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-2">{content.commonProblems_passNotWorking_text}</p> 
                  </div>
                </div>
                <div className="u-align-center u-container-style u-grey-5 u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h4 className="u-align-center u-custom-font u-text u-text-default u-text-3">{content.commonProblems_chargerDamaged}</h4>
                    <img className="u-image u-image-contain u-image-default u-preserve-proportions u-image-2" src={PisaIcon} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-4">{content.commonProblems_chargerDamaged_text}</p>
                  </div>
                </div>
                <div className="u-container-style u-grey-5 u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h4 className="u-align-center u-custom-font u-text u-text-default u-text-5">{content.commonProblems_blocked}</h4>
                    <img className="u-image u-image-contain u-image-default u-preserve-proportions u-image-3" src={FuelIcon} alt="" data-image-width="150" data-image-height="150" />
                    <p className="u-align-center u-custom-font u-text u-text-body-color u-text-6">{content.commonProblems_blocked_text}<br /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-clearfix u-grey-5 u-section-8" id="sec-e1ca">
          <div className="u-clearfix u-sheet u-sheet-1">
            <div className="u-border-3 u-border-custom-color-2 u-hidden-xs u-shape u-shape-svg u-text-custom-color-1 u-shape-1">
              <svg className="u-svg-link" preserveAspectRatio="none" viewBox="0 0 160 160" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-db78"></use></svg>
              <svg className="u-svg-content" viewBox="-1.5 -1.5 163 163" x="0px" y="0px" id="svg-db78"><path d="M114.3,152.3l38-38C144.4,130.9,130.9,144.4,114.3,152.3z M117.1,9.1l-108,108c0.8,1.6,1.7,3.2,2.7,4.8l110-110
	C120.3,10.9,118.7,10,117.1,9.1z M97.5,2L2,97.5c0.4,2,1,4,1.5,5.9l99.9-99.9C101.5,2.9,99.5,2.4,97.5,2z M80,160c2,0,4-0.1,5.9-0.2
	l73.9-73.9c0.1-2,0.2-3.9,0.2-5.9c0-0.6,0-1.2,0-1.9L78.1,160C78.8,160,79.4,160,80,160z M34.9,146.1c1.5,1,3,2,4.6,2.9L149,39.5
	c-0.9-1.6-1.9-3.1-2.9-4.6L34.9,146.1z M132.7,19.8L19.8,132.7c1.2,1.3,2.3,2.6,3.6,3.9L136.6,23.4C135.3,22.2,134,21,132.7,19.8z
	 M59.6,157.4l97.8-97.8c-0.5-1.9-1.1-3.8-1.7-5.7L53.9,155.6C55.8,156.3,57.7,156.9,59.6,157.4z M7.6,45.9L45.9,7.6
	C29.1,15.5,15.5,29.1,7.6,45.9z M80,0c-2.6,0-5.1,0.1-7.6,0.4l-72,72C0.1,74.9,0,77.4,0,80c0,0.1,0,0.2,0,0.2L80.2,0
	C80.2,0,80.1,0,80,0z"></path></svg>
            </div>
            <div className="u-custom-color-2 u-shape u-shape-circle u-shape-2"></div>
            <img className="u-image u-image-round u-radius-20 u-image-1" src={OverOns} data-image-width="1280" data-image-height="853" />
            <div className="u-align-left u-container-style u-group u-radius-20 u-shape-round u-white u-group-1">
              <div className="u-container-layout u-container-layout-1">
                <h3 className="u-align-center u-custom-font u-text u-text-1">{content.aboutUs}</h3>
                <p className="u-align-center u-custom-font u-text u-text-body-color u-text-2">{content.aboutUs_text}</p>
                <a href="#" className="u-active-black u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-hover-black u-radius-50 u-btn-1">{content.readMore}</a>
              </div>
            </div>
          </div>
        </section>
        <section className="u-align-center u-clearfix u-white u-section-9" id="sec-82dd">
          <div className="u-clearfix u-sheet u-sheet-1">
            <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-1" src={Conversation} alt="" data-image-width="64" data-image-height="64" />
            <h3 className="u-align-center u-custom-font u-text u-text-1">{content.faq}</h3>
            <h5 className="u-align-left u-custom-font u-text u-text-2">{content.faq_reportIncident}</h5>
            <div className="u-accordion u-collapsed-by-default u-faq u-spacing-10 u-accordion-1">
              <div className="u-accordion-item" onClick={() => showQuestion1 ? setShowQuestion1(false) : setShowQuestion1(true)}>
                <a className="u-accordion-link u-active-custom-color-1 u-button-style u-custom-color-2 u-custom-font u-hover-custom-color-1 u-accordion-link-1" id="link-accordion-f600" aria-controls="accordion-f600" aria-selected="false">
                  <span className="u-accordion-link-text">{content.faq_reportIncident_q1}</span>
                  <span className="u-accordion-link-icon u-icon u-text-active-white u-text-hover-white u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 426.66667 426.66667" >
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-b6d8"></use>
                    </svg>
                    <svg className="u-svg-content" viewBox="0 0 426.66667 426.66667" id="svg-b6d8">
                      <path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"></path>
                    </svg>
                  </span>
                </a>
                {showQuestion1 ?
                  <div className="u-container-style u-expanded-width u-white u-accordion-pane-1" id="accordion-f600" aria-labelledby="link-accordion-f600">
                    <div className="u-container-layout u-container-layout-1">
                      <div className="fr-view u-clearfix u-rich-text u-text">
                        <p>
                          <span className="u-custom-font">
                            <span className="u-text-body-color">{content.faq_reportIncident_r1}</span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  : null}
              </div>
              <div className="u-accordion-item" onClick={() => showQuestion2 ? setShowQuestion2(false) : setShowQuestion2(true)}>
                <a className="u-accordion-link u-active-custom-color-1 u-button-style u-custom-color-2 u-custom-font u-hover-custom-color-1 u-accordion-link-2" id="link-accordion-854e" aria-controls="accordion-854e" aria-selected="false">
                  <span className="u-accordion-link-text">{content.faq_reportIncident_q2}</span><span className="u-accordion-link-icon u-icon u-text-active-white u-text-hover-white u-icon-2"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 426.66667 426.66667" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-4779"></use></svg><svg className="u-svg-content" viewBox="0 0 426.66667 426.66667" id="svg-4779"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"></path></svg></span>
                </a>
                {showQuestion2 ?
                <div className="u-container-style u-expanded-width u-white u-accordion-pane-2" id="accordion-854e" aria-labelledby="link-accordion-854e">
                  <div className="u-container-layout u-container-layout-2">
                    <div className="fr-view u-clearfix u-rich-text u-text">
                      <p>
                        <span className="u-custom-font" >
                          <span className="u-text-body-color">{content.faq_reportIncident_r2}</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                : null }
              </div>
              <div className="u-accordion-item" onClick={() => showQuestion3 ? setShowQuestion3(false) : setShowQuestion3(true)}>
                <a className="u-accordion-link u-active-custom-color-1 u-button-style u-custom-color-2 u-custom-font u-hover-custom-color-1 u-accordion-link-3" id="link-095d" aria-controls="095d" aria-selected="false">
                  <span className="u-accordion-link-text">{content.faq_reportIncident_q3}
                  </span><span className="u-accordion-link-icon u-icon u-text-active-white u-text-hover-white u-icon-3"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-2a78"></use></svg><svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-2a78"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
                </a>
                {showQuestion3 ?
                <div className="u-container-style u-expanded-width u-white u-accordion-pane-3" id="095d" aria-labelledby="link-095d">
                  <div className="u-container-layout u-container-layout-3">
                    <div className="fr-view u-clearfix u-rich-text u-text">
                      <p>
                        <span className="u-custom-font" >
                          <span className="u-text-body-color">{content.faq_reportIncident_r3}</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                : null }
              </div>
            </div>
          </div>
        </section>
        <section className="u-align-center u-clearfix u-white u-section-10" id="sec-4215">
          <div className="u-clearfix u-sheet u-sheet-1">
            <h5 className="u-align-left u-custom-font u-text u-text-1">{content.faq_notifications}</h5>
            <div className="u-accordion u-collapsed-by-default u-faq u-spacing-10 u-accordion-1">
              <div className="u-accordion-item" onClick={() => showQuestion4 ? setShowQuestion4(false) : setShowQuestion4(true)}>
                <a className="u-accordion-link u-active-custom-color-1 u-button-style u-custom-color-2 u-hover-custom-color-1 u-accordion-link-1" id="link-accordion-72f4" aria-controls="accordion-72f4" aria-selected="false">
                  <span className="u-accordion-link-text">{content.faq_notifications_q1}
                  </span><span className="u-accordion-link-icon u-icon u-text-active-white u-text-hover-white u-icon-1"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 426.66667 426.66667" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-b250"></use></svg><svg className="u-svg-content" viewBox="0 0 426.66667 426.66667" id="svg-b250"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"></path></svg></span>
                </a>
                {showQuestion4 ?
                <div className="u-container-style u-expanded-width u-white u-accordion-pane-1" id="accordion-72f4" aria-labelledby="link-accordion-72f4">
                  <div className="u-container-layout u-container-layout-1">
                    <div className="fr-view u-clearfix u-rich-text u-text">
                      <p>
                        <span className="u-custom-font" >
                          <span className="u-text-body-color">{content.faq_notifications_r1}</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                : null }
              </div>
              <div className="u-accordion-item" onClick={() => showQuestion5 ? setShowQuestion5(false) : setShowQuestion5(true)}>
                <a className="u-accordion-link u-active-custom-color-1 u-button-style u-custom-color-2 u-hover-custom-color-1 u-accordion-link-2" id="link-dacb" aria-controls="dacb" aria-selected="false">
                  <span className="u-accordion-link-text">{content.faq_notifications_q2}
                  </span><span className="u-accordion-link-icon u-icon u-text-active-white u-text-hover-white u-icon-2"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-95c7"></use></svg><svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-95c7"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
                </a>
                {showQuestion5 ?
                <div className="u-container-style u-expanded-width u-white u-accordion-pane-2" id="dacb" aria-labelledby="link-dacb">
                  <div className="u-container-layout u-container-layout-2">
                    <div className="fr-view u-clearfix u-rich-text u-text">
                      <p>
                        <span className="u-custom-font" >
                          <span className="u-text-body-color">{content.faq_notifications_r2}</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                : null }
              </div>
            </div>
          </div>
        </section>
        <section className="u-align-center u-clearfix u-white u-section-11" id="sec-6852">
          <div className="u-clearfix u-sheet u-sheet-1">
            <h5 className="u-align-left u-custom-font u-text u-text-1">{content.faq_account}</h5>
            <div className="u-accordion u-collapsed-by-default u-faq u-spacing-10 u-accordion-1">
              <div className="u-accordion-item" onClick={() => showQuestion6 ? setShowQuestion6(false) : setShowQuestion6(true)}>
                <a className="u-accordion-link u-active-custom-color-1 u-button-style u-custom-color-2 u-hover-custom-color-1 u-accordion-link-1" id="link-accordion-f600" aria-controls="accordion-f600" aria-selected="false">
                  <span className="u-accordion-link-text">{content.faq_account_q1}
                  </span><span className="u-accordion-link-icon u-icon u-text-active-white u-text-hover-white u-icon-1"><svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 426.66667 426.66667" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-b6d8"></use></svg><svg className="u-svg-content" viewBox="0 0 426.66667 426.66667" id="svg-b6d8"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"></path></svg></span>
                </a>
                {showQuestion6 ?
                <div className="u-container-style u-expanded-width u-white u-accordion-pane-1" id="accordion-f600" aria-labelledby="link-accordion-f600">
                  <div className="u-container-layout u-container-layout-1">
                    <div className="fr-view u-clearfix u-rich-text u-text">
                      <p>
                        <span className="u-custom-font" >
                          <span className="u-text-body-color">{content.faq_account_r1}</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                : null }
              </div>
            </div>
            <h3 className="u-align-center u-custom-font u-text u-text-3">{content.incident}?</h3>
            <a href="/" className="u-active-black u-align-center-xs u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-custom-font u-hover-custom-color-1 u-radius-50 u-btn-1">{content.reportIncidentHere}!<br />
            </a>
          </div>
        </section>
        <GetInContact />
        <HomePageFooter />
      </div>
    </div>
  )
}

export default ERidersHomePage;