import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import { leaveFeedbackContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { Utils } from '../../utils/utils';


function ModalUserFeedback(props) {

    const content = props.language === "EN" ? leaveFeedbackContent.English :
        props.language === "NL" ? leaveFeedbackContent.Dutch :
            leaveFeedbackContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [reaction, setReaction] = useState('neutral');
    const [likeIconClassname, setLikeIconClassname] = useState('likeIcon');
    const [dislikeIconClassname, setDisikeIconClassname] = useState('dislikeIcon');
    const [feedbackText, setFeedbackText] = useState('');
    const [contactEmail, setContactEmail] = useState(null);

    const [emptyFeedbackError, setEmptyFeedbackError] = useState(null);
    const [contactEmailError, setContactEmailError] = useState(null);

    const emptyFeedbackErrorMsg = content.emptyFeedbackErrorMsg;
    const contactEmailErrorMsg = content.contactEmailErrorMsg;

    const setPositiveReaction = () => {
        if (likeIconClassname === 'likeIcon-big') {
            setLikeIconClassname('likeIcon');
            setDisikeIconClassname('dislikeIcon');
        }
        else {
            setReaction('positive');
            setLikeIconClassname('likeIcon-big');
            setDisikeIconClassname('dislikeIcon-small');
        }
    }

    const setNegativeReaction = () => {
        if (dislikeIconClassname === 'dislikeIcon-big') {
            setLikeIconClassname('likeIcon');
            setDisikeIconClassname('dislikeIcon');
        }
        else {
            setReaction('negative');
            setLikeIconClassname('likeIcon-small');
            setDisikeIconClassname('dislikeIcon-big');
        }
    }

    const submitClicked = () => {
        setEmptyFeedbackError(null);
        setContactEmailError(null);
        if (feedbackText.length < 5) {
            setEmptyFeedbackError(emptyFeedbackErrorMsg);
        }
        else if (contactEmail && !Utils.emailValidation(contactEmail)) {
            setContactEmailError(contactEmailErrorMsg);
        }
        else {
            const requestBody = contactEmail ?
                { 'feedback': feedbackText, 'reaction': reaction, 'contact_email': contactEmail } :
                { 'feedback': feedbackText, 'reaction': reaction }

            props.createUserFeedback(requestBody);
            setLikeIconClassname('likeIcon');
            setDisikeIconClassname('dislikeIcon');
            setContactEmail(null);
            setFeedbackText('');
            setReaction('neutral');
        }
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody" >
                <Modal.Title className='w-100 text-center'>{content.userExperience}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <Form>
                    <Stack direction="horizontal" gap={4}>
                        <span className={likeIconClassname} style={{ 'pointer': 'cursor' }} onClick={() => setPositiveReaction()}></span>
                        <span className={dislikeIconClassname} style={{ 'pointer': 'cursor' }} onClick={() => setNegativeReaction()}></span>
                    </Stack>
                    <br />
                    <Form.Label>
                        {content.leaveFeedback}
                    </Form.Label>
                    <Form.Group
                        className="mb-3"
                        controlId="leavefeedback.ControlTextarea" >
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={evt => setFeedbackText(evt.target.value)} />
                        <p className='red'>{emptyFeedbackError}</p>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="leavefeedback.ControlInputEmail" >
                        <Form.Label>
                            {content.contactEmail}
                        </Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="name@example.com"
                            onChange={evt => setContactEmail(evt.target.value)}
                        />
                        <p className='red'>{contactEmailError}</p>
                    </Form.Group>
                </Form>
                <Row>
                    <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                        <Stack direction='horizontal' gap={2}>
                            <Button variant="danger" onClick={props.handleClose}>
                                {buttonscontent.close}
                            </Button>
                            <Button variant="success" onClick={() => submitClicked()}>
                                {buttonscontent.postFeedback}
                            </Button>
                        </Stack>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalUserFeedback;