import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { confirmNewIncidentContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';


function ModalConfirmNewIncident(props) {

    const content = props.language === "EN" ? confirmNewIncidentContent.English :
        props.language === "NL" ? confirmNewIncidentContent.Dutch :
            confirmNewIncidentContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const handleClose = () => {
        props.resetIncidentInfo();
        props.handleClose();
    }

    return (
        <Modal show={props.show} onHide={handleClose} backdrop="static">
            <Modal.Header className="modalsBody" >
                <Modal.Title className='w-100 text-center'>{content.title}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <div>
                    <Row className="justify-content-center">
                        <Col xs={{ span: '9' }}>
                            <div className="d-flex justify-content-center">
                                <h4><b>{props.incidentTypeTranslation}</b></h4>
                            </div>
                            {props.incidentExtraInfo && props.incidentExtraInfo !== "" ?
                                <div className="d-flex justify-content-center">
                                    <p style={{ fontSize: '0.8rem' }}><b>{props.incidentExtraInfo}</b></p>
                                </div>
                                : null}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={{ span: '10', offset: '1' }} md={{ span: '8', offset: '2' }}>
                            <Stack direction='horizontal' gap={2}>
                                <Col xs={{ span: '4' }}>
                                    <div className="d-grid gap-2">
                                        <Button variant="secondary" onClick={props.handleBack}>
                                            {buttonscontent.back}
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs={{ span: '8' }}>
                                    {/* <div className="d-grid gap-2">
                                        <Button className='chargeprob-button-green' onClick={() => props.continueWithoutLogin()}>
                                            {buttonscontent.sendReportAnonymous}
                                        </Button>
                                    </div> */}
                                    {props.sendIncidentLoading ?
                                        <Button className="chargeprob-button-green w-100">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="sendincidentloading"
                                                aria-hidden="true"
                                            />
                                            <span className="visually-hidden">Loading...</span>
                                            <b>{buttonscontent.sendingReport}</b>
                                        </Button>
                                        :
                                        <Button className="chargeprob-button-green sendReport-button-glowing w-100" onClick={() => props.postNewIncident(props.markerInfo)}>
                                            <b>{buttonscontent.sendReport}</b>
                                        </Button>
                                    }
                                </Col>
                            </Stack>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default ModalConfirmNewIncident;

/* OLD MODAL

<Modal.Body className="modalsBody">
                <p>{content.mainText}</p>
                <p><b>{content.proceed}</b></p>
                <br/>
                <p>{content.problem}: <b>{props.incidentType}</b></p>
                <p>Socket: <b>{props.affectedCharger}</b></p>
                <p>Car model: <b>{props.carModel}</b></p>
                <p>Notes: <b>{props.incidentExtraInfo}</b></p>
                {props.reportImagePreview ?
                    <img src={props.reportImagePreview} alt="Picture preview" className='report-image-preview' />
                    : null}
                <br />
                <br />
                <Row>
                    <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                        <Stack direction='horizontal' gap={2}>
                            <Button variant="danger" onClick={handleClose}>
                                {buttonscontent.close}
                            </Button>
                            <Button variant="secondary" onClick={props.handleBack}>
                                {buttonscontent.back}
                            </Button>
                            {props.sendIncidentLoading ?
                                <Button variant="success">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="sendincidentloading"
                                        aria-hidden="true"
                                    />
                                    <span className="visually-hidden">Loading...</span>
                                    {buttonscontent.sendingReport}
                                </Button>
                                :
                                <Button variant="success" onClick={() => props.postNewIncident(props.markerInfo)}>
                                    {buttonscontent.sendReport}
                                </Button>
                            }
                        </Stack>
                    </div>
                </Row>
            </Modal.Body>
            */