import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import CustomCloseButton from '../buttons/CustomCloseButton';


function ModalConfirmUnsubscribe(props) {

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{props.content.userProfileUnsubscribeModalTitle}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <p>{props.content.userProfileUnsubscribeConfirm_p1}</p>
                <p>{props.content.userProfileUnsubscribeConfirm_p2}</p>
                <Row>
                    <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                        <Stack direction='horizontal' gap={2}>
                            <Button variant="danger" onClick={props.handleClose}>
                                {props.buttonscontent.close}
                            </Button>
                            <Button variant="success" onClick={props.unsubscribe}>
                                {props.buttonscontent.unsubscribe}
                            </Button>
                        </Stack>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalConfirmUnsubscribe;