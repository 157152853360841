import React from 'react';
import Modal from 'react-bootstrap/Modal';
import GreenChargerIcon from '../../static/Icons/charging_green_24px.png';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { mapContent } from "../internationalization/Translations";
import { Stack } from 'react-bootstrap';

function ModalShowLegendInfo(props) {

    const legendContent = props.language === "EN" ? mapContent.English :
        props.language === "NL" ? mapContent.Dutch :
            mapContent.English;

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header className="modalsBody" >
                <Modal.Title className='w-100 text-center'>Charger operational status</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className='modalsBody legendInfoModal' style={{ 'fontSize': '0.8rem' }}>
                <Stack direction="horizontal" gap={1}>
                    <div><span className="legendAvailable"></span></div>
                    <div><b>{legendContent.legendContentAvailable}</b></div>
                </Stack>
                <p>{legendContent.legendContentAvailable_text1}</p>
                <p>{legendContent.legendContentAvailable_text2}</p>
                <br />
                <Stack direction="horizontal" gap={1}>
                    <div><span className="legendHalfAvailable"></span></div>
                    <div><b>{legendContent.legendContentHalfAvailable_1} {legendContent.legendContentHalfAvailable_2}</b></div>
                </Stack>
                <p>{legendContent.legendContentHalfAvailable_text1}</p>
                <p>{legendContent.legendContentHalfAvailable_text2}</p>
                <p>{legendContent.legendContentHalfAvailable_text3}</p>
                <br />
                <Stack direction="horizontal" gap={1}>
                    <div><span className="legendWarning"></span></div>
                    <div><b>{legendContent.legendContentWarning}</b></div>
                </Stack>
                <p>{legendContent.legendContentWarning_text1}</p>
                <p>{legendContent.legendContentWarning_text2}</p>
                <p>{legendContent.legendContentWarning_text3}</p>
                <br />
                <Stack direction="horizontal" gap={1}>
                    <div><span className="legendUnavailable"></span></div>
                    <div><b>{legendContent.legendContentUnavailable}</b></div>
                </Stack>
                <p>{legendContent.legendContentUnavailable_text1}</p>
                <p>{legendContent.legendContentUnavailable_text2}</p>
                <p>{legendContent.legendContentUnavailable_text3}</p>
                <br />
                <Stack direction="horizontal" gap={1}>
                    <div><span className="legendUnknown"></span></div>
                    <div><b>{legendContent.legendContentUnknown}</b></div>
                </Stack>
                <p>{legendContent.legendContentUnknown_text1}</p>
            </Modal.Body>
        </Modal>
    )
}

export default ModalShowLegendInfo;