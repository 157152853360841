import React, { useContext } from 'react';
import '../stylesheets/nicepage_homepage.css';
import AuthContext from '../context/AuthContext';
import { homePageContent } from '../components/internationalization/Translations';
import MapNavbar from '../components/MapNavbar';
import StatsCounter from './StatsCounter';
import GetInContact from './GetInContact';
import HomePageFooter from './HomePageFooter';

function AboutChargeProbHome(props) {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? homePageContent.English :
        language === "NL" ? homePageContent.Dutch :
            homePageContent.English;

    return (
        <div className='homepagediv'>
            <div className='u-body u-xl-mode' data-lang="en">
                <MapNavbar page="homepage" />
                <section className="u-clearfix u-section-1" id="sec-bc15">
                    <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                        <div className="u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout" >
                                <div className="u-layout-row" >
                                    <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-shading u-size-30 u-size-xs-60 u-image-1" src="" data-image-width="6000" data-image-height="4000">
                                        <div className="u-container-layout u-container-layout-1" src="">
                                            <h2 className="u-align-center u-custom-font u-text u-text-default u-text-1">{content.forEDrivers}</h2>
                                            <p className="u-align-center u-custom-font u-text u-text-body-alt-color u-text-2">{content.forEDriversImgText}<br />
                                            </p>
                                            <a href="/for-e-driver" className="u-align-center u-border-none u-btn u-button-style u-custom-color-2 u-custom-font u-hover-custom-color-1 u-btn-1">{content.moreInfo}</a>
                                        </div>
                                    </div>
                                    <div className="u-align-left u-container-style u-image u-layout-cell u-right-cell u-size-30 u-size-xs-60 u-image-2" data-image-width="5851" data-image-height="3906">
                                        <div className="u-container-layout u-container-layout-2">
                                            <h2 className="u-align-center u-custom-font u-text u-text-body-alt-color u-text-default u-text-3">{content.forCPOs}</h2>
                                            <p className="u-align-center u-custom-font u-text u-text-body-alt-color u-text-4">{content.forCPOsImgText}</p>
                                            <a href="/for-cpo" className="u-align-center u-border-none u-btn u-button-style u-custom-color-2 u-custom-font u-hover-custom-color-1 u-btn-2">{content.moreInfo}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <StatsCounter />
                <section className="u-align-center u-clearfix u-image u-shading u-section-2" src="" id="sec-392d" data-image-width="1062" data-image-height="684">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <h1 className="u-custom-font u-text u-text-1">{content.incidentOnCharger}</h1>
                        <a href="/" className="u-active-black u-align-center-xs u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-custom-font u-hover-custom-color-1 u-radius-50 u-btn-1">{content.reportIncident}!<br />
                        </a>
                    </div>
                </section>
                <GetInContact />
                <HomePageFooter />
            </div>

        </div>
    )
}

export default AboutChargeProbHome;