import {useState, useEffect} from 'react';
import { API } from '../api-service';

function useFetchHomePageStats() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect( () => {
        async function fetchGeneralStats() {
            setLoading(true);
            setError();
            const data = await API.getGeneralStats()
                .catch( error => setError(error))

            setData(data);
            setLoading(false);
        }

        fetchGeneralStats();
    }, []);
    
    return [data, loading, error]
}

export { useFetchHomePageStats };