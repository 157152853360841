import { Tooltip } from 'react-leaflet';
import '../../App.css';

function MarkerTooltip(props) {
  const markerInfo = props.markerInfo;
  const markerStatus = props.markerInfo.disruption ?
    props.markerInfo.status === "Unavailable" ?
      "Unavailable" : "Warning" : props.markerInfo.status;

  const availableEVSEs = markerInfo && markerInfo.evses ?
    markerInfo.evses.filter(evse => evse.status === "AVAILABLE")
    : null;

  const totAvailableEVSEs = availableEVSEs && availableEVSEs.length > 0 ? availableEVSEs.length : "0";

  const tooltipClassname = totAvailableEVSEs === "0" ? "charger-tooltip charger-tooltip-busy" : "charger-tooltip charger-tooltip-available";

  return (
    <>
      {props.mapZoom >= 16 ?
        markerStatus === "Available" || markerStatus === "Partially available" || markerStatus === "Warning" ?
          <Tooltip
            permanent={true}
            direction='top'
            offset={[-9, 4]}
            className={tooltipClassname}>
            <b>{totAvailableEVSEs}</b>
          </Tooltip>
          : null
        : null}
    </>
  )
}

export default MarkerTooltip;