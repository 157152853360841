import React, { useState, useEffect, createContext } from 'react';
import jwt_decode from "jwt-decode";
import { loginContent } from '../components/internationalization/Translations';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null);
    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    let [loading, setLoading] = useState(true);
    let [userProfile, setUserProfile] = useState(null);
    let [userFavoriteChargers, setUserFavoriteChargers] = useState(null);

    const [language, setLanguage] = useState('NL');
    const logincontent = language === "EN" ? loginContent.English :
        language === "NL" ? loginContent.Dutch :
            loginContent.English;

    let server_url = process.env.REACT_APP_SERVER_URL;

    let loginUserWithGoogleToken = async (google_token) => {
        const body = {
            "grant_type": "convert_token",
            "backend": "google-oauth2",
            "token": google_token
        }

        let response = await fetch(`${server_url}/api/evchargers/token/convert_token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        let data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data);
            let userData = jwt_decode(data.access);
            setUser(userData);
            localStorage.setItem('authTokens', JSON.stringify(data));
            getUserProfile(data.access);
            getUserFavoriteChargers(data.access);
            return "OK";
        }
        else {
            return "Invalid credentials";
        }
    }


    let loginUser = async (body) => {
        let response = await fetch(`${server_url}/api/evchargers/token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        let data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data);
            let userData = jwt_decode(data.access);
            setUser(userData);
            localStorage.setItem('authTokens', JSON.stringify(data));
            getUserProfile(data.access);
            getUserFavoriteChargers(data.access);
            return "OK";
        }
        else {
            return "Invalid credentials";
        }
    }

    let logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        setUserProfile(null);
        localStorage.removeItem('authTokens');
    }

    let registerUser = async (body, bodyLogin) => {
        let response = await fetch(`${server_url}/api/evchargers/register/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        let data = await response.json();

        if (response.status === 201) {
            return loginUser(bodyLogin);
        }
        else if (response.status === 400) {
            return logincontent.registerErrorAlreadyExists;
        }
        else {
            return logincontent.registerErrorGeneric;
        }
    }

    let unsubscribeUser = async () => {
        let response = await fetch(`${server_url}/api/evchargers/user/${user.user_id}/destroy/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        })

        let resp = await response;
        logoutUser();
        if (resp.status === 204) {
            return "OK"
        }
        else if (resp.status === 400) {
            return "User does not exist"
        }
        else {
            return "ERROR"
        }
    }

    let changeUserPassword = async (body) => {
        let response = await fetch(`${server_url}/api/evchargers/password/update/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
            body: JSON.stringify(body)
        })

        let data = await response.json();

        if (response.status === 200) {
            logoutUser();
            return "OK";
        }
        else if (response.status === 422) {
            return data.response;
        }
        else {
            return "Error changing password";
        }
    }

    let resetUserPassword = async (uid, user_token, body) => {
        let response = await fetch(`${server_url}/api/evchargers/password/restore/${uid}/${user_token}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })

        let data = await response.json();

        if (response.status === 200) {
            return "OK";
        }
        else if (response.status === 422) {
            return data.response;
        }
        else {
            return "Error changing password";
        }
    }

    let updateToken = async () => {
        if (user) {
            let response = await fetch(`${server_url}/api/evchargers/token/refresh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'refresh': authTokens.refresh })
            })

            let data = await response.json();

            if (response.status === 200) {
                setAuthTokens(data);
                setUser(jwt_decode(data.access));
                localStorage.setItem('authTokens', JSON.stringify(data));
                if (loading) {
                    getUserProfile(data.access);
                    getUserFavoriteChargers(data.access);
                }
                setLoading(false);
                return "OK";
            }
            else {
                setLoading(false);
                logoutUser();
            }
        }
        else {
            setLoading(false);
            logoutUser();
        }
    }

    let getUserProfile = async (token) => {
        let response = await fetch(`${server_url}/api/evchargers/userprofile/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })

        let data = await response.json();
        if (response.status === 200) {
            setUserProfile(data[0]);
            return "OK";
        }
        else {
            setUserProfile(null);
            return "ERROR loading user profile"
        }
    }

    let getUserFavoriteChargers = async (token) => {
        let response = await fetch(`${server_url}/api/evchargers/userprofile/favoritechargers/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })

        //let data = await response.json();
        return response
        // if(response.status === 200){
        //     setUserFavoriteChargers(data);
        //     return "OK";
        // }
        // else{
        //     setUserFavoriteChargers(null);
        //     return "ERROR loading user favorite chargers"
        // }
    }

    let contextData = {
        user: user,
        userProfile: userProfile,
        userFavoriteChargers: userFavoriteChargers,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        registerUser: registerUser,
        loginUserWithGoogleToken: loginUserWithGoogleToken,
        changeUserPassword: changeUserPassword,
        resetUserPassword: resetUserPassword,
        unsubscribeUser: unsubscribeUser,
        getUserProfile: getUserProfile,
        getUserFavoriteChargers: getUserFavoriteChargers,
        setUserFavoriteChargers: setUserFavoriteChargers,
        language: language,
        setLanguage: setLanguage
    }

    useEffect(() => {
        if (loading) {
            updateToken();
        }
        let updateTime = 1000 * 60 * 4;
        let interval = setInterval(() => {
            if (authTokens) {
                updateToken();
            }
        }, updateTime);

        return () => clearInterval(interval);
    }, [authTokens, loading])

    useEffect(() => {
        if (userProfile) setLanguage(userProfile.favorite_language);
    }, [userProfile])

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}