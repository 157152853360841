import React, { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import '../../App.css';
import { searchHereContent } from '../internationalization/Translations';


function SearchHereOverlay(props) {
    const content = props.language === "EN" ? searchHereContent.English :
        props.language === "NL" ? searchHereContent.Dutch :
            searchHereContent.English;

    const [searchHereTimesClicked, setSearchHereTimesClicked] = useState(0);

    const searchHereClicked = () => {
        const newSearchHereTimesClicked = searchHereTimesClicked + 1;
        setSearchHereTimesClicked(newSearchHereTimesClicked);
        props.searchHereClicked();
    }

    return (
        <>
            {props.showSearchHere && !props.popupIsOpen &&
                !props.showUserLoginModal && !props.showUserReportsOffcanvas &&
                !props.showUserFavoriteChargersOffcanvas ?
                <div className='searchOverlay'>
                    {props.loadChargerFromURL || !props.mapSearchBoxProvider ?
                        <Badge pill className='searchOverlay-btn'>
                            {content.loadMap}
                        </Badge>
                        :
                        props.loadingUserLocation ?
                            <Badge pill className='searchOverlay-btn'>
                                {content.getUserLocation}
                            </Badge>
                            :
                            props.mapZoom >= 16 ?
                                props.loadingChargers ?
                                    <Badge pill className='searchOverlay-btn'>
                                        {content.findingChargers}
                                    </Badge>
                                    :
                                    props.markers && props.markers.length === 0 ?
                                        <Badge pill type="button" className='searchOverlay-btn searchHere-badge-pulsing'
                                            onClick={() => searchHereClicked()}>
                                            {content.searchHereButton}
                                        </Badge>
                                        : <Badge pill type="button" className='searchOverlay-btn'
                                            onClick={() => searchHereClicked()}>
                                            {content.searchHereButton}
                                        </Badge>
                                :
                                <Badge pill type="button" className='searchOverlay-btn searchHere-badge-pulsing'
                                    onClick={props.zoomToSearchClicked}>
                                    {content.zoomInButton}
                                </Badge>
                    }
                </div>
                :
                null}
        </>
    )

}

export default SearchHereOverlay;

{/* 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
<OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="zoomchargers">{content.zoomInTooltip}</Tooltip>} >
    <Badge pill type="button" className='searchOverlay-btn'>
        {content.zoomInButton}
    </Badge>
</OverlayTrigger> */}