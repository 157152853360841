import React, { useState, useContext } from 'react';
import { API } from '../api-service';
import MapNavbar from '../components/MapNavbar';
import '../App.css';
import Container from 'react-bootstrap/Container';
import { accountActivationContent, buttonsContent } from '../components/internationalization/Translations';
import AuthContext from '../context/AuthContext';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { Utils } from '../utils/utils';


function ActivateAccountFail() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? accountActivationContent.English :
        language === "NL" ? accountActivationContent.Dutch :
            accountActivationContent.English;

    const buttonscontent = language === "EN" ? buttonsContent.English :
        language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [userEmail, setUserEmail] = useState(false);
    const [userEmailError, setUserEmailError] = useState('');
    const [linkSent, setLinkSent] = useState(false);
    const [linkSentError, setLinkSentError] = useState(false);
    const [sendingLink, setSendingLink] = useState(false);

    const sendNewActivationLink = async () => {
        setUserEmailError('');
        if (!Utils.emailValidation(userEmail)) {
            setUserEmailError(content.activateFail_emailError);
        }
        else {
            setSendingLink(true);
            setLinkSent(false);
            setLinkSentError(false);
            const resp = await API.sendNewActivationLink({ 'email': userEmail });
            if (resp.status === 200) {
                setLinkSent(true);
                setLinkSentError(false);
            } else {
                setLinkSent(true);
                setLinkSentError(true);
            }
            setSendingLink(false);
        }
    }

    return (
        <>
            <MapNavbar page="activateaccount_fail" />
            {linkSent ?
                linkSentError ?
                    <Container fluid className='accountactivation'>
                        <br /><br /><br /><br />
                        <Row>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                                <h2>{content.activateFail_newLinkSent_error_p1}</h2>
                                <h2>{content.activateFail_newLinkSent_error_p2}</h2>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container fluid className='accountactivation'>
                        <br /><br /><br /><br />
                        <Row>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                                <h2>{content.activateSuccess_newLinkSent_p1}</h2>
                                <h2>{content.activateSuccess_newLinkSent_p2}</h2>
                                <h2>{content.activateSuccess_newLinkSent_p3}</h2>
                            </Col>
                        </Row>
                    </Container>
                : <Container fluid className='accountactivation'>
                    <br /><br /><br /><br />
                    <Row>
                        <Col xs={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                            <h2>{content.activateFail_p1}</h2>
                            <br />
                            <h2>{content.activateFail_p2}</h2>
                            <br /><br /><br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                            {sendingLink ?
                                <Button className='chargeprob-button-green'>
                                    {buttonscontent.activateFail_sendingNewLink_btn}
                                </Button>
                                :
                                <Form>
                                    <Form.Group className="mb-3" controlId="confirmemail.ControlInputEmail" >
                                        <Form.Control
                                            type="email"
                                            placeholder="name@example.com"
                                            style={{ 'color': 'white' }}
                                            autoFocus
                                            onChange={evt => setUserEmail(evt.target.value)}
                                        />
                                        <p className='red' style={{ fontSize: '1rem' }}><b>{userEmailError}</b></p>
                                        <Button
                                            className='chargeprob-button-green'
                                            onClick={() => sendNewActivationLink()}>
                                            {buttonscontent.activateFail_sendNewLink_btn}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            }
                        </Col>
                    </Row>

                </Container>
            }
        </>
    )

}

export default ActivateAccountFail;