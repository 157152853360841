import React, { useContext } from 'react';
import '../../stylesheets/storing_cpo.css';
import AuthContext from '../../context/AuthContext';
import { storingCPOPageContent } from '../../components/internationalization/Translations';
import MapNavbar from '../../components/MapNavbar';
import HomePageFooter from '../HomePageFooter';
import GetInContact from '../GetInContact';
import HeaderStoring from './HeaderStoring';

function CpoStoringHome() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? storingCPOPageContent.English :
        language === "NL" ? storingCPOPageContent.Dutch :
            storingCPOPageContent.English;

    return (
        <div className='homepagediv'>
            <MapNavbar page="storingcpo" />
            <div className='storingcpo-u-body storingcpo-u-xl-mode' data-lang="en">
                <HeaderStoring />
                <section className="storingcpo-u-clearfix storingcpohome-u-section-2" id="sec-00a3">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <div className="fr-view storingcpo-u-align-left storingcpo-u-clearfix storingcpo-u-rich-text storingcpo-u-text storingcpohome-u-text-1">
                            <p id="isPasted">
                                <span className="storingcpo-u-custom-font" style={{ fontWeight: "700", fontSize: "1.875rem" }}>Contactgegevens laadpaal beheerders</span>
                            </p>
                            <p id="isPasted">
                                <span className="storingcpo-u-custom-font" >Indien u problemen ondervindt bij het melden van een storing, kunt u doorgaans de contactinformatie van de laadpaalbeheerders vinden op het laadstation zelf. Mocht deze informatie niet beschikbaar zijn, dan bieden wij hieronder een overzicht van de belangrijkste openbare laadnetwerken in Nederland.&nbsp;</span>
                            </p>
                            <p>
                                <br />
                            </p>
                        </div>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-grey-5 storingcpohome-u-section-3" id="sec-ce81">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-1">
                            <b><a href="/storing/cpo/allego" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">Allego</a></b>
                            </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2">Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/allego" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Allego</a>:<br />Website:&nbsp;<a href="https://www.allego.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2">Allego Website</a>
                            <br />Telefoonnummer: 0880333033<br />Storingspagina website:&nbsp;<a rel="nofollow" href="https://www.allego.eu/nl-nl/zakelijk/support-en-contact" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Storingspagina website</a>
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpohome-u-section-4" id="sec-809f">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpohome-u-text storingcpohome-u-text-1">
                            <b> <a href="/storing/cpo/evnetnl" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">EVnetNL</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/evnetnl" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">EVnetNL</a>:<br />Website: <a href="https://www.evbox.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2"> EVnet Website</a>
                            <br />Telefoonnummer: 08003522365<br /> Storingspagina website: nvt
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-grey-5 storingcpohome-u-section-5" id="sec-51c3">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-1">
                            <b> <a href="/storing/cpo/shellrecharge" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">NewMotion / Shell Recharge</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/shellrecharge" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Shell Recharge</a>:<br />Website: <a href="https://newmotion.com/nl_NL/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2">NewMotion Website</a>
                            <br />Telefoonnummer: 0880109500<br /> Storingspagina website: nvt<br />
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpohome-u-section-6" id="sec-b357">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-text storingcpohome-u-text-1">
                            <b> <a href="/storing/cpo/equans" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">Equans</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/equans" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Equans</a>:<br />Website: <a href="https://www.evbox.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2"> Equans Website </a>
                            <br />Telefoonnummer:
                            +31(0)883692900 <br /> Storingspagina website: nvt
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-grey-5 storingcpohome-u-section-7" id="sec-58d0">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-1"> 
                        <b> <a href="/storing/cpo/ionity" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">Ionity</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/ionity" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Ionity</a>:<br />Website: <a href="https://newmotion.com/nl_NL/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2"> Ionity Website </a>
                            <br />Telefoonnummer: 0207082896<br /> Storingspagina website: <a rel="nofollow" href="https://ionity.eu/en/contact-service" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Storingspagina website</a><br />
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpohome-u-section-8" id="sec-0c95">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-text storingcpohome-u-text-1">
                        <b><a href="/storing/cpo/fastned" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">Fastned</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/fastned" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Fastned</a>:<br />Website: <a href="https://fastned.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2">Fastned Website</a>
                            <br />Telefoonnummer: 0207155316<br /> Storingspagina website: nvt<br />
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-grey-5 storingcpohome-u-section-9" id="sec-eae1">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-1">
                            <b><a href="/storing/cpo/enecoemobility" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">Eneco eMobility</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/enecoemobility" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Eneco</a>:<br />Website: <a href="https://eneco-emobility.com/nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2">Eneco eMobility Website</a>
                            <br />Telefoonnummer: 0881707700<br /> Storingspagina website:&nbsp;<a rel="nofollow" href="https://www.eneco-emobility.com/thuis/kennis-en-tips/storing-laadpaal" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Storingspagina website</a><br />
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpohome-u-section-10" id="sec-7f2a">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-text storingcpohome-u-text-1">
                            <b><a href="/storing/cpo/greenflux" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">GreenFlux</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/greenflux" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">GreenFlux</a>:<br />Website: <a href="https://www.greenflux.com/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2">GreenFlux Website</a>
                            <br />Telefoonnummer: 0886050700<br /> Storingspagina website: nvt<br />
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-grey-5 storingcpohome-u-section-11" id="sec-d444">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-1">
                        <b><a href="/storing/cpo/ecotap" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">Ecotap</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/ecotap" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Ecotap</a>:<br />Website: <a href="https://www.movenience.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2"> EcoTap Website </a>
                            <br />Telefoonnummer: <b></b> 31(0)411745020&nbsp;&nbsp;<br /> Storingspagina website: nvt<br />
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpohome-u-section-10" id="sec-14b3">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpohome-u-sheet-1">
                        <h3 className="storingcpo-u-text storingcpohome-u-text-1">
                        <b><a href="/storing/cpo/vattenfall" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpohome-u-btn-1">Vattenfall</a></b>
                        </h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpohome-u-text-2"> Snel hulp bij het verhelpen van een probleem met een laadpaal van <a href="/storing/cpo/vattenfall" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-link storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Vattenfall</a>:<br />Website: <a href="https://www.vattenfall.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-2">Vattenfall Website</a>
                            <br />Telefoonnummer: 0883637991<br /> Storingspagina website:<a rel="nofollow" href="https://incharge.vattenfall.nl/veelgestelde-vragen/storing-laadpaal-oplossen" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpohome-u-btn-1">Storingspagina website</a>
                            <br />
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-align-center storingcpo-u-clearfix storingcpo-u-image storingcpo-u-shading storingcpo-u-section-4" src="" data-image-width="1072" data-image-height="678" id="sec-fee5">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <a href="/" className="storingcpo-u-active-black storingcpo-u-border-none storingcpo-u-btn storingcpo-u-btn-round storingcpo-u-button-style storingcpo-u-custom-color-2 storingcpo-u-custom-font storingcpo-u-hover-custom-color-1 storingcpo-u-radius-50 storingcpo-u-btn-1">{content.reportProblemButton}<br />
                        </a>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-5" id="sec-ba43">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpo-u-sheet-1">
                        <div className="fr-view storingcpo-u-align-left storingcpo-u-clearfix storingcpo-u-rich-text storingcpo-u-text storingcpo-u-text-1">
                            <p id="isPasted">
                                <span className="storingcpo-u-custom-font">Voor de meest actuele informatie en telefoonnummers voor storingsmeldingen, verwijzen we je naar de websites van de specifieke laadpaalbeheerders. Wij zorgen ervoor dat de informatie regelmatig wordt gecontroleert en bijgewerkt om onze bezoekers zo accuraat en nuttig mogelijk te informeren. <br />
                                </span>
                            </p>
                        </div>
                    </div>
                </section>

                <GetInContact />
                <HomePageFooter />
            </div >
        </div >
    )
}

export default CpoStoringHome;