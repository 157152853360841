import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { missingChargerContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';

function ModalMissingChargerConfirmed(props) {

    const content = props.language === "EN" ? missingChargerContent.English :
        props.language === "NL" ? missingChargerContent.Dutch :
            missingChargerContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody" >
                {props.missingChargerNotCreatedReason === "" ?
                    <Modal.Title className='w-100 text-center'>{content.confirmedOkTitle}</Modal.Title>
                    :
                    <Modal.Title className='w-100 text-center'>{content.confirmedFailTitle}</Modal.Title>
                }
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                {props.missingChargerNotCreatedReason === "" ?
                    <div>
                        <p>{content.confirmedOk_p1}</p>
                        <p>{content.confirmedOk_p2}</p>
                    </div>
                    : props.missingChargerNotCreatedReason.indexOf("Too many requests") !== -1 ?
                    <div>
                        <p>{content.confirmedFailTooManyReq_p1}</p>
                        <p>{content.confirmedFailTooManyReq_p2}</p>
                    </div>
                    :
                    <div>
                        <p>{content.confirmedFail_p1}</p>
                        <p>{content.confirmedFail_p2}</p>
                    </div>
                }
                <Row>
                    <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                        <Button variant="success" onClick={props.handleClose}>
                            {buttonscontent.close}
                        </Button>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalMissingChargerConfirmed;