import React from 'react';

function CustomCloseButton(props){

    return(
        <button 
            type="button" 
            className='offcanvas-closebutton' 
            aria-label='Close' 
            onClick={() => props.handleClose()}
        >
        </button>
    )
}

export default CustomCloseButton;