import {useState, useEffect} from 'react';
import { API } from '../api-service';

function useFetchEnergieOnderbrekingDisruptions() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect( () => {
        async function fetchLocations() {
            setLoading(true);
            setError();
            const data = await API.getEOdisruptions()
                .catch( error => setError(error))

            setData(data);
            setLoading(false);
        }

        fetchLocations();
    }, []);
    
    return [data, loading, error]
}

export { useFetchEnergieOnderbrekingDisruptions };