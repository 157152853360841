import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import CustomCloseButton from '../buttons/CustomCloseButton';
import { loginToastContent } from '../internationalization/Translations';


function SignedInToast(props) {

    const content = props.language === "EN" ? loginToastContent.English :
        props.language === "NL" ? loginToastContent.Dutch :
            loginToastContent.English;

    return (
        <ToastContainer className="p-2 loginToast" position='bottom-center'>
            <Toast onClose={props.closeLoginToast} show={props.showLoginToast} delay={3000} autohide>
                <Toast.Header closeButton={false} style={{ color: 'white' }}>
                    <strong className="mx-auto">{content.welcome}</strong>
                    <CustomCloseButton handleClose={props.closeLoginToast} />
                </Toast.Header>
                <Toast.Body>{content.toastMsgLogin}</Toast.Body>
            </Toast>
            <Toast onClose={props.closeSignupToast} show={props.showSignupToast} delay={3000} >
                <Toast.Header closeButton={false} style={{ color: 'white' }}>
                    <strong className="mx-auto">{content.welcome}</strong>
                    <CustomCloseButton handleClose={props.closeSignupToast} />
                </Toast.Header>
                <Toast.Body>{content.toastMsgSignup}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default SignedInToast;