import React, { useContext } from 'react';
import '../../stylesheets/storing_cpo.css';
import AuthContext from '../../context/AuthContext';
import { storingCPOPageContent } from '../../components/internationalization/Translations';

function HeaderStoring() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? storingCPOPageContent.English :
        language === "NL" ? storingCPOPageContent.Dutch :
            storingCPOPageContent.English;

    return (
            <div className='storingcpo-u-body storingcpo-u-xl-mode' data-lang="en">
                <section className="storingcpo-u-align-center storingcpo-u-clearfix storingcpo-u-custom-color-1 storingcpo-u-section-1" id="sec-f4ca">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <div className="custom-expanded storingcpo-u-align-left storingcpo-u-container-style storingcpo-u-group storingcpo-u-shape-rectangle storingcpo-u-group-1">
                            <div className="storingcpo-u-container-layout storingcpo-u-container-layout-1">
                                <h1 className="storingcpo-u-align-center storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-default storingcpo-u-title storingcpo-u-text-1">{content.headerTitle}</h1>
                                <p className="storingcpo-u-align-center storingcpo-u-custom-font storingcpo-u-large-text storingcpo-u-text storingcpo-u-text-variant storingcpo-u-text-2">{content.headerSubTitle}</p>
                                <a href="/" className="storingcpo-u-active-black storingcpo-u-border-none storingcpo-u-btn storingcpo-u-btn-round storingcpo-u-button-style storingcpo-u-custom-color-2 storingcpo-u-custom-font storingcpo-u-hover-custom-color-1 storingcpo-u-radius-50 storingcpo-u-btn-1">{content.reportProblemButton}<br />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
    )
}

export default HeaderStoring;