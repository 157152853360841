import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import '../../App.css';
import MarkerPopup from './MarkerPopup';
import MarkerTooltip from './MarkerTooltip';
import ModalProblemSolvedOrNot from '../modals/ModalProblemSolvedOrNot';
import ModalUserReportFeedbackSent from '../modals/ModalUserReportFeedbackSent';


function getRedChargerMarker() {
  return L.icon({
    iconUrl: require('../../static/Icons/red-charger_32px.png'),
    iconSize: [32, 32], // size of the icon
    popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
    //shadowSize:   [50, 64], // size of the shadow
    //iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62],  // the same for the shadow
  })
}

function getGreenChargerMarker() {
  return L.icon({
    iconUrl: require('../../static/Icons/green-charger_32px.png'),
    iconSize: [32, 32], // size of the icon
    popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
    //shadowSize:   [50, 64], // size of the shadow
    //iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62],  // the same for the shadow
  })
}

function getYellowChargerMarker() {
  return L.icon({
    iconUrl: require('../../static/Icons/warning-charger_32px.png'),
    iconSize: [32, 32], // size of the icon
    popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
    //shadowSize:   [50, 64], // size of the shadow
    //iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62],  // the same for the shadow
  })
}

function getUnknwownChargerMarker() {
  return L.icon({
    iconUrl: require('../../static/Icons/unknown-charger_32px.png'),
    iconSize: [32, 32], // size of the icon
    popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
    //shadowSize:   [50, 64], // size of the shadow
    //iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62],  // the same for the shadow
  })
}

function getRedGreenChargerMarker() {
  return L.icon({
    iconUrl: require('../../static/Icons/red-green-charger_32px.png'),
    iconSize: [32, 32], // size of the icon
    popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
    //shadowSize:   [50, 64], // size of the shadow
    //iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62],  // the same for the shadow
  })
}

function ChargerMarker(props) {
  const markerInfo = props.markerInfo;
  const markerStatus = props.markerInfo.disruption ?
    props.markerInfo.status === "Unavailable" ?
      "Unavailable" : "Warning" : props.markerInfo.status;

  const [showProblemSolvedOrNotModal, setShowProblemSolvedOrNotModal] = useState(false);
  const [showModalReportFeedbackSent, setShowModalReportFeedbackSent] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [feedbackResult, setFeedbackResult] = useState(null);
  const [popupIsOpen, setPopupIsOpen] = useState(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (popupIsOpen) {
      timeoutRef.current = setTimeout(() => {
        goToProblemSolvedOrNotModal();
      }, 2000);
    }
    else{
      closeModalReportFeedbackSent();
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [popupIsOpen])

  const closeModalProblemSolvedOrNot = () => {
    setShowProblemSolvedOrNotModal(false);
  }

  const goToProblemSolvedOrNotModal = () => {
    if (markerStatus && markerStatus === "Warning" && markerInfo.status === "Warning" && popupIsOpen && !feedbackGiven) {
      setShowProblemSolvedOrNotModal(true);
    }
  }

  const openModalReportFeedbackSent = (report) => {
    setShowModalReportFeedbackSent(true);
  }

  const closeModalReportFeedbackSent = () => {
    setShowModalReportFeedbackSent(false);
  }

  return (
    <Marker
      position={[markerInfo.latitude, markerInfo.longitude]}
      icon={
        markerStatus === 'Available' ? getGreenChargerMarker() :
          markerStatus === 'Partially available' ? getRedGreenChargerMarker() :
            markerStatus === 'Unavailable' ? getRedChargerMarker() :
              markerStatus === 'Warning' ? getYellowChargerMarker() :
                getUnknwownChargerMarker()}
      eventHandlers={{
        click: (e) => {
          props.setShowSearchHere(false);
        },
        popupopen: (e) => {
          setPopupIsOpen(true);
          props.setPopupIsOpen(true);
        },
        popupclose: (e) => {
          setPopupIsOpen(false);
          props.setPopupIsOpen(false);
          closeModalProblemSolvedOrNot();
        }
      }}>
      <MarkerTooltip
        key={"marker-tooltip-" + markerInfo.id}
        markerInfo={markerInfo}
        mapZoom={props.mapZoom}
      />
      <MarkerPopup
        key={"marker-popup-" + markerInfo.id}
        markerInfo={markerInfo}
        showChargerStatusDetails={props.showChargerStatusDetails}
        showChargerEvsesStatusDetails={props.showChargerEvsesStatusDetails}
        showChargerComments={props.showChargerComments}
        showShowSearch={props.showShowSearch}
        hideShowSearch={props.hideShowSearch}
        setShowLoginToast={props.setShowLoginToast}
        setShowSignupToast={props.setShowSignupToast}
        language={props.language}
        addOrRemoveFromFavoriteChargers={props.addOrRemoveFromFavoriteChargers}
        searchHereClicked={props.searchHereClicked}
        openUserLoginModal={props.openUserLoginModal}
        showShareOptions={props.showShareOptions}
      />
      {showProblemSolvedOrNotModal ?
        <ModalProblemSolvedOrNot
          show={showProblemSolvedOrNotModal}
          handleClose={closeModalProblemSolvedOrNot}
          markerInfo={markerInfo}
          language={props.language}
          searchHereClicked={props.searchHereClicked}
          setFeedbackResult={setFeedbackResult}
          setFeedbackGiven={setFeedbackGiven}
          openModalReportFeedbackSent={openModalReportFeedbackSent}
          closeModalProblemSolvedOrNot={closeModalProblemSolvedOrNot} />
        : null}
      {showModalReportFeedbackSent ?
        <ModalUserReportFeedbackSent
          show={showModalReportFeedbackSent}
          handleClose={closeModalReportFeedbackSent}
          language={props.language}
          feedbackResult={feedbackResult} />
        : null}
    </Marker>
  )
}

export default ChargerMarker;