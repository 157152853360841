import React, { useContext } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import { homePageFooterContent } from '../components/internationalization/Translations';


function HomePageFooter(props) {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? homePageFooterContent.English :
        language === "NL" ? homePageFooterContent.Dutch :
        homePageFooterContent.English;

    return (
        <footer className="u-align-center u-clearfix u-footer u-grey-80 u-footer" style={{ fontSize: '0.8rem' }}>
            <div className="u-clearfix u-sheet u-sheet-1">
                <Row className='mt-2 pt-2 pb-2'>
                    <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                        <Stack direction='horizontal' gap={2} style={{ display: 'inline-flex' }}>
                            <div><a href='/privacystatement' className='white-text'>{content.privacyStatement}</a></div>
                            <div> | </div>
                            <div><a href='/termsandconditions' className='white-text'>{content.termsAndConditions}</a></div>
                            <div> | </div>
                            <div><a href='/map' className='white-text'>{content.map}</a></div>
                            <div> | </div>
                            <div><a href='/aboutchargeprob' className='white-text'>{content.aboutChargeProb}</a></div>
                            <div> | </div>
                            <div><a href='/storing/cpo' className='white-text'>{content.cpos}</a></div>
                        </Stack>
                    </Col>
                </Row>
            </div>
        </footer>
    )
}

export default HomePageFooter;