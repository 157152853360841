import React, { useContext } from 'react';
import '../../stylesheets/storing_cpo.css';
import AuthContext from '../../context/AuthContext';
import { storingCPOPageContent } from '../../components/internationalization/Translations';
// import LogoWhite from '../static/images/FINAL-ICONLOGO-ChargeProb-WHITE.png';
import MapNavbar from '../../components/MapNavbar';
import HomePageFooter from '../HomePageFooter';
import GetInContact from '../GetInContact';
import HeaderStoring from './HeaderStoring';

function VattenfallStoring() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? storingCPOPageContent.English :
        language === "NL" ? storingCPOPageContent.Dutch :
            storingCPOPageContent.English;

    return (
        <div className='homepagediv'>
            <MapNavbar page="storingcpo" />
            <div className='storingcpo-u-body storingcpo-u-xl-mode' data-lang="en">
                <HeaderStoring />
                <section className="storingcpo-u-clearfix storingcpo-u-section-2" id="sec-6d24">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <h3 className="storingcpo-u-text storingcpo-u-text-1"><b>Vattenfall</b></h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpo}</p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoWebsite}: <a href="https://www.vattenfall.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpo-u-btn-1">Vattenfall Website</a></p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoPhonenumber}: 0883637991</p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoStoringWebsite}: <a rel="nofollow" href="https://incharge.vattenfall.nl/veelgestelde-vragen/storing-laadpaal-oplossen" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpo-u-btn-2">{content.infoCpoStoringWebsite}</a></p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-3" id="sec-5429">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-default storingcpo-u-text-1">
                            <b>"Over Vattenfall inCharge: Toonaangevend in Elektrisch Laden"</b>
                            <br />Vattenfall is de trotse eigenaar van het
                            inCharge-laadnetwerk. Met 9.000 oplaadstations is inCharge een van de snelst
                            groeiende laadnetwerken van Europa. Vattenfall is vastberaden om de overgang
                            naar elektrisch rijden te ondersteunen en een betrouwbare infrastructuur te
                            bieden voor elektrische voertuigen. <br />
                            <br /><b>Hulp
                                bij Het Loskoppelen van Uw Laadkabel bij Vattenfall inCharge Laadpalen</b>
                            <br />Na een laadsessie bij een Vattenfall inCharge laadpaal
                            kan het voorkomen dat de stekker niet op de gebruikelijke manier kan worden
                            losgekoppeld. Hier zijn enkele tips die kunnen helpen: <br />
                            <br />1. Probeer
                            de autoportieren te sluiten en vervolgens onmiddellijk weer te openen. Dit
                            kan soms de stekker vrijgeven.<br /> 2. Als het
                            gewicht van de laadkabel het loskoppelen bemoeilijkt, ondersteun dan de
                            stekker en ontgrendel de deuren met de sleutel. Voor sommige
                            Tesla-modellen moet de stekker ook vanuit het menu in de auto worden
                            losgekoppeld.<br /> 3. Op
                            bepaalde laadstations kunt u het laadstation resetten door uw laadpas
                            gedurende 30 seconden voor de lezer te houden. Uw laadkabel wordt dan
                            automatisch vrijgegeven.<br />
                            <br /> Indien deze oplossingen niet werken, raden wij aan
                            contact op te nemen met de operator van het oplaadpunt voor verdere
                            assistentie. <br />
                            <br /><b>Als
                                Het Oplaadpunt van Vattenfall inCharge Niet Reageert</b>
                            <br />Als het oplaadpunt van Vattenfall inCharge op geen
                            enkele manier reageert en er geen controlelampje brandt, kan er mogelijk een
                            storing in de stroomtoevoer zijn. In dat geval adviseren wij u contact op te
                            nemen met de beheerder van het laadstation voor verdere ondersteuning. <br />
                            <br /><b>Problemen
                                met het Starten van de Vattenfall inCharge Laadpaal na Aanbieden van Uw
                                Laadpas?</b>
                            <br />Als uw laadsessie bij een Vattenfall inCharge laadpaal
                            niet start nadat u uw laadpas heeft aangeboden, kunnen verschillende factoren
                            hiervoor verantwoordelijk zijn:&nbsp;<br />
                            <br /> - Controleer
                            of uw laadpas is geactiveerd voor gebruik bij openbare laadsessies.
                            Sommige laadpassen moeten eerst worden geactiveerd, en dit proces kan tot
                            24 uur duren.<br /> - Verifieer
                            of het specifieke laadpunt openbaar toegankelijk is. Niet alle laadpalen
                            zijn beschikbaar voor publiek gebruik. U kunt dit controleren door het
                            adres in te voeren in de Laadpaal App van Last Mile Solutions (Android /
                            iOS).<br /> - Soms kan
                            de kaartlezer van het laadpunt een andere pas detecteren als u uw hele
                            portemonnee bij de kaartlezer houdt. Probeer opnieuw een laadsessie te
                            starten nadat u uw laadpas uit uw portemonnee heeft gehaald.<br /> - Als uw
                            laadpas geblokkeerd lijkt te zijn, kunt u dit controleren door contact op
                            te nemen met de uitgever van uw laadpas.<br />
                            <br /><b>Contactgegevens
                                Vattenfall inCharge</b>
                            <br />
                            <br />Indien u toch nog hulp nodig heeft, kunt u doorgaans
                            de contactgegevens van de operator vinden op het laadstation zelf. Mocht dat
                            niet het geval zijn, kunt u contact opnemen via de onderstaande gegevens: <br /> Website: <a href="https://www.vattenfall.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpo-u-btn-1">Vattenfall Website</a>
                            <br />Telefoonnummer:0883637991<br />
                            <br />Vattenfall inCharge staat klaar om u te helpen en
                            ervoor te zorgen dat uw elektrische voertuig altijd klaar is om de weg op te
                            gaan.<br />
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-align-center storingcpo-u-clearfix storingcpo-u-image storingcpo-u-shading storingcpo-u-section-4" src="" data-image-width="1072" data-image-height="678" id="sec-fee5">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <a href="/" className="storingcpo-u-active-black storingcpo-u-border-none storingcpo-u-btn storingcpo-u-btn-round storingcpo-u-button-style storingcpo-u-custom-color-2 storingcpo-u-custom-font storingcpo-u-hover-custom-color-1 storingcpo-u-radius-50 storingcpo-u-btn-1">{content.reportProblemButton}<br />
                        </a>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-5" id="sec-ba43">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpo-u-sheet-1">
                        <div className="fr-view storingcpo-u-align-left storingcpo-u-clearfix storingcpo-u-rich-text storingcpo-u-text storingcpo-u-text-1">
                            <p id="isPasted">
                                <span className="storingcpo-u-custom-font">Voor de meest actuele informatie en telefoonnummers voor storingsmeldingen, verwijzen we je naar de websites van de specifieke laadpaalbeheerders. Wij zorgen ervoor dat de informatie regelmatig wordt gecontroleert en bijgewerkt om onze bezoekers zo accuraat en nuttig mogelijk te informeren. <br />
                                </span>
                            </p>
                        </div>
                    </div>
                </section>

                <GetInContact />
                <HomePageFooter />
            </div>
        </div>
    )
}

export default VattenfallStoring;