import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { incidentSentContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';

function ModalNewIncidentNotCreated(props) {

    const content = props.language === "EN" ? incidentSentContent.English :
        props.language === "NL" ? incidentSentContent.Dutch :
            incidentSentContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>Oops!</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                {props.incidentNotCreatedReason.indexOf("on this charger") !== -1 ?
                    <>
                        <p>{content.fail_toomany_samecharger_p1}</p>
                        <p>{content.fail_toomany_samecharger_p2}</p>
                    </>
                    : props.incidentNotCreatedReason.indexOf("in the last 24 hours") !== -1 ?
                        <>
                            <p>{content.fail_toomany_sameday_p1}</p>
                            <p>{content.fail_toomany_sameday_p2}</p>
                        </>
                        : props.incidentNotCreatedReason.indexOf("Too many requests") !== -1 ?
                            <>
                                <p>{content.fail_toomany_sameday_p1}</p>
                                <p>{content.fail_toomany_sameday_p2}</p>
                            </>
                            :
                            <>
                                <p>{content.fail_p1}</p>
                                <p>{content.fail_p2}</p>
                            </>
                }
                <Row>
                    <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                        <Button variant="secondary" onClick={props.handleClose}>
                            {buttonscontent.close}
                        </Button>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalNewIncidentNotCreated;