import React, { useContext, useState } from 'react';
import { Popup } from 'react-leaflet';
import Stack from 'react-bootstrap/Stack';
import ReportIncidentButton from '../buttons/ReportIncidentButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { chargerDetailsContent } from '../internationalization/Translations';
import AuthContext from '../../context/AuthContext';
import ChargerStatus from './ChargerStatus';
import ChargerEvses from './ChargerEvses';
import ChargerComments from './ChargerComments';
import ModalShowMarkerDetails from '../modals/ModalShowMarkerDetails';

function MarkerPopup(props) {
    let { user, userProfile } = useContext(AuthContext);
    const markerInfo = props.markerInfo;

    const content = props.language === "EN" ? chargerDetailsContent.English :
        props.language === "NL" ? chargerDetailsContent.Dutch :
            chargerDetailsContent.English;

    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const is_fast_charger = props.markerInfo && props.markerInfo.is_fast_charger ? props.markerInfo.is_fast_charger : false;

    const favoriteClicked = () => {
        userProfile ? props.addOrRemoveFromFavoriteChargers(markerInfo) : props.openUserLoginModal();
    }

    const goToShowDetailsModal = () => {
        setShowDetailsModal(true);
    }
    const closeDetailsModal = () => {
        setShowDetailsModal(false);
    }  

    return (
        <Popup autoClose='true'>
            <Stack direction="horizontal">
                <div className='text-ellipsis' style={{ cursor: "pointer" }} onClick={() => goToShowDetailsModal()}>
                    <h5 style={{ 'fontSize': '0.9rem', 'color': 'white' }}><b>{markerInfo.address}</b></h5>
                </div>
                <div className="ms-auto" style={{ marginRight: '4px' }}>
                    <Stack direction='horizontal' gap={3}>
                        <h5 style={{ 'fontSize': '1.6rem', cursor: 'pointer' }} onClick={() => props.showShareOptions(markerInfo)}>
                            <FontAwesomeIcon icon={faShareNodes} style={{ 'color': 'white' }} />
                        </h5>
                        {userProfile && userProfile.favoritechargers && userProfile.favoritechargers.indexOf(markerInfo.id) >= 0 ?
                            <h5 style={{ 'fontSize': '1.6rem', cursor: 'pointer' }} onClick={favoriteClicked}>
                                <FontAwesomeIcon icon={faHeartSolid} style={{ 'color': '#1dbf73' }} />
                            </h5>
                            :
                            //userProfile && userProfile.favoritechargers && userProfile.favoritechargers.indexOf(markerInfo.id) === -1 ?
                            <h5 style={{ 'fontSize': '1.6rem', cursor: 'pointer' }} onClick={favoriteClicked}>
                                <FontAwesomeIcon icon={faHeart} style={{ 'color': 'white' }} />
                            </h5>
                            //: null 
                        }
                    </Stack>
                </div>
            </Stack>
            <div style={{ cursor: "pointer" }} onClick={() => goToShowDetailsModal()}>
                <h6 style={{ 'fontSize': '0.7rem', 'color': 'white' }}>
                    <b>{markerInfo.cpo_name}&nbsp;</b>
                </h6>
                {is_fast_charger ?
                    <h6 className='chargeprob-green' style={{ 'fontSize': '0.9em' }}>
                        <b>Fast charger</b>
                    </h6>
                    : null
                }
            </div>
            <br />
            <div className="d-grid gap-3">
                <ChargerStatus
                    markerInfo={markerInfo}
                    showChargerStatusDetails={props.showChargerStatusDetails}
                    language={props.language} />
                <ChargerEvses
                    markerInfo={markerInfo}
                    showChargerEvsesStatusDetails={props.showChargerEvsesStatusDetails}
                    language={props.language} />
                <ChargerComments
                    markerInfo={markerInfo}
                    showChargerComments={props.showChargerComments}
                    language={props.language} />
                {/* <Stack direction="horizontal" gap={2} style={{ 'width': 'auto' }}>
                    <div> */}
                <ReportIncidentButton
                    markerInfo={markerInfo}
                    reportIncident={props.reportIncident}
                    showShowSearch={props.showShowSearch}
                    hideShowSearch={props.hideShowSearch}
                    setShowLoginToast={props.setShowLoginToast}
                    setShowSignupToast={props.setShowSignupToast}
                    language={props.language}
                    searchHereClicked={props.searchHereClicked} />
                {/* <div >
                        <EnergyMarkerDetailsButton
                            markerInfo={markerInfo}
                            language={props.language} />
                    </div> */}
                {/* </Stack> */}
            </div>
            <ModalShowMarkerDetails
                show={showDetailsModal}
                handleClose={closeDetailsModal}
                markerInfo={markerInfo}
                content={content}
            />
        </Popup>
    )
}

export default MarkerPopup;
