import React from 'react';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { energieOnderbrekingContent } from '../internationalization/Translations';


function EODisruptionInfo(props) {

    const content = props.language === "EN" ? energieOnderbrekingContent.English :
        props.language === "NL" ? energieOnderbrekingContent.Dutch :
            energieOnderbrekingContent.English;

    const disruption = props.selectedCharger.disruption;

    const eoDisruption_translation = disruption.planned ? content.warningPlannedMaint
        : content.warningEnergyFailure;

    const disruptionStart = disruption.disruption_start_time ? disruption.disruption_start_time
        : "Unknown";

    const disruptionEnd = disruption.disruption_end_time ? disruption.disruption_end_time
        : "Unknown";

    const disruption_area = disruption.location_postalCode ? disruption.location_postalCode.replace(/;/g, ' - ')
        : "Unknown";

    const disruption_status = disruption.status ? disruption.status
        : "Unknown";

    const disruption_message = disruption.message ? disruption.message
        : "Unknown";

    const disruption_city = disruption.location_city ? disruption.location_city
        : "Unknown";

    return (
        <div>

            <Card border="dark" className="offcanvas-cards">
                <Card.Body>
                    <h5 style={{ 'textAlign': 'center' }} className="warning-text">
                        <b>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ 'fontSize': '1.6rem' }} />
                            &nbsp;&nbsp;&nbsp;&nbsp;WARNING&nbsp;&nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ 'fontSize': '1.6rem' }} />
                        </b>
                    </h5>
                    <h6 style={{ 'textAlign': 'center' }} className="warning-text"><b>{eoDisruption_translation}</b></h6>
                    <br />
                    <Stack direction="vertical" gap={1} style={{ 'fontSize': '0.85rem' }}>
                        <Stack direction="horizontal" gap={2}>
                            <span className="plannedmaintenanceIcon"></span>
                            {disruption.planned ?
                            <b>{content.planned}</b>
                            : <b>{content.incident}</b> }
                        </Stack>
                        <div>
                        {content.from}: {disruptionStart}
                        </div>
                        {disruption.planned ?
                        <div>
                        {content.to}: {disruptionEnd}
                        </div>
                        : <div>
                        {content.expectedEnd}: {disruptionEnd}
                        </div>
                        }
                    </Stack>
                    <br />
                    <Stack direction="vertical" gap={1} style={{ 'fontSize': '0.85rem' }}>
                        <Stack direction="horizontal" gap={2}>
                            <span className="progressIcon"></span>
                            <b>{content.status}</b>
                        </Stack>
                        <div>
                        {disruption_status}
                        </div>
                    </Stack>
                    <br />
                    <Stack direction="vertical" gap={1} style={{ 'fontSize': '0.85rem' }}>
                        <Stack direction="horizontal" gap={2}>
                            <span className="disruptionAreaIcon"></span>
                            <b>{content.area}: {disruption_city}</b>
                        </Stack>
                        <div>
                            {disruption_area}
                        </div>
                    </Stack>
                    <br />
                    <Stack direction="vertical" gap={1} style={{ 'fontSize': '0.85rem' }}>
                        <Stack direction="horizontal" gap={2}>
                            <span className="infoIcon"></span>
                            <b>{content.notes}</b>
                        </Stack>
                        <div>
                        {disruption_message}
                        </div>
                    </Stack>
                </Card.Body>
            </Card>
        </div>
    )

}

export default EODisruptionInfo;