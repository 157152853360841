import {useState, useEffect} from 'react';
import { API } from '../api-service';

function useFetchMapboxDetails() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect( () => {
        async function fetchMapboxDetails() {
            if(process.env.REACT_APP_MAP_PROVIDER === 'mapbox'){
                setLoading(true);
                setError();
                const data = await API.getMapboxDetails()
                    .catch( error => setError(error))

                setData(data);
                setLoading(false);
            }
        }

        fetchMapboxDetails();
    }, []);
    
    return [data, loading, error]
}

export { useFetchMapboxDetails };