import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { offcanvasContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faPen, faShareNodes, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

function UserReport(props) {
    const userReport = props.userReport;
    const evcharger_details = userReport.evchargerdetails && userReport.evchargerdetails[0] ?
        userReport.evchargerdetails[0] : props.selectedCharger;

    //const report_date = new Date(Date.parse(userReport.report_start_time)).toLocaleString();
    const cpo_update_date = userReport && userReport.cpo_update_time_str ? userReport.cpo_update_time_str : null;

    const report_status_time = userReport ?
        userReport.report_status === "OPEN" ? userReport.report_start_time_str
            : userReport.report_status === "IN PROGRESS" ? userReport.report_first_update_time_str
                : userReport.report_status === "CLOSE" ? userReport.report_stop_time_str
                    : null
        : null;

    const imageSrc = userReport.report_image_base64 ? `data:image/jpeg;base64,${userReport.report_image_base64}` : null;

    const content = props.language === "EN" ? offcanvasContent.English :
        props.language === "NL" ? offcanvasContent.Dutch :
            offcanvasContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const bringmethere_clicked = (charger_id, charger) => {
        props.setBringmethereLocation({
            'id': charger_id,
            'latitude': charger["latitude"],
            'longitude': charger["longitude"]
        });
    }

    return (
        <Card border="dark" className="offcanvas-cards">
            <Card.Header as="h5">
                <Stack direction='horizontal' gap={3}>
                    <div>
                        {evcharger_details["address"] + ","}
                        <br />
                        {evcharger_details["postal_code"] + " " + evcharger_details["city"]}
                    </div>
                    <div className="ms-auto">
                        <h5 style={{ 'fontSize': '1.6rem', cursor: 'pointer' }} onClick={() => props.showShareOptions(evcharger_details)}>
                            <FontAwesomeIcon icon={faShareNodes} style={{ 'color': 'white' }} />
                        </h5>
                    </div>
                </Stack>
            </Card.Header>
            <Card.Body>
                <Card.Title>{userReport.report_start_time_str}</Card.Title>
                <Card.Text>
                    <FontAwesomeIcon icon={faCircleExclamation} style={{ 'color': '#BF2D32' }} />
                    &nbsp;&nbsp;Problem: &nbsp;
                    {userReport.affected_charger && userReport.affected_charger !== "" && userReport.affected_charger !== "Not sure" ?
                        <span><b>{userReport.report}</b> ({userReport.affected_charger})</span>
                        : <b>{userReport.report}</b>}
                </Card.Text>
                {/* {userReport.car_model && userReport.car_model !== "Unknown" ?
                    <Card.Text>
                        Car model: {userReport.car_model}
                    </Card.Text> : null} */}
                {userReport.report_notes && userReport.report_notes !== "" ?
                    <Card.Text>
                        Notes: {userReport.report_notes}
                    </Card.Text> : null}
                {userReport.report_image ?
                    <div style={{ cursor: 'pointer' }}>
                        <img src={imageSrc} alt="Incident picture" className='report-image-thumbnail'
                            onClick={() => props.openModalReportImage(userReport)} />
                    </div>
                    : null}
                <hr className='chargeprob-darkgreen' />
                <Card.Text>
                    {/* <span style={{ fontSize: "0.7rem" }}>{report_status_time}</span>
                    <br /> */}
                    {content.userReportsUpdateFromOwnerStatus} <b>{userReport.report_status}</b>
                </Card.Text>
                {userReport.report_status === "CLOSE" ?
                    userReport.report_close_actor === "reporter" ?
                        props.source === "userreports" ?
                            <Card.Text>
                                <FontAwesomeIcon icon={faThumbsUp} /> &nbsp; {content.userReportsClosedByOwner}
                            </Card.Text>
                            : <Card.Text>
                                <FontAwesomeIcon icon={faThumbsUp} /> &nbsp; {content.userReportsClosedByUser}
                            </Card.Text>
                        : userReport.report_close_actor === "other_users" ?
                            <Card.Text>
                                <FontAwesomeIcon icon={faThumbsUp} /> &nbsp; {content.userReportsClosedByUsers}
                            </Card.Text>
                            : null
                    : null
                }
                {userReport.cpo_update && evcharger_details.cpo_update !== "" ?
                    <Card.Text>
                        <FontAwesomeIcon icon={faPen} /> &nbsp; {content.userReportsUpdateFromOwner}:
                        &nbsp; <b>{userReport.cpo_update}</b>
                        {cpo_update_date ?
                            <>
                                <br />
                                <span style={{ 'fontSize': '0.7rem' }}>
                                    {cpo_update_date}
                                </span>
                            </>
                            : null
                        }
                    </Card.Text>
                    : userReport.report_status === "CLOSE" &&
                        (userReport.report_close_actor === "reporter" || userReport.report_close_actor === "other_users") ?
                        null
                        :
                        <Card.Text>
                            <FontAwesomeIcon icon={faPen} /> &nbsp; {content.userReportsNoUpdateFromOwner}
                        </Card.Text>
                }
                <Card.Text style={{ fontSize: '0.8rem' }}>
                    {userReport.tot_users_confirmations_repaired && userReport.tot_users_confirmations_repaired > 1 ?
                        <>
                            <FontAwesomeIcon icon={faThumbsUp} className='chargeprob-green' /> {userReport.tot_users_confirmations_repaired} {content.userReportsFeedbackWorking}
                            <br />
                        </>
                        : userReport.tot_users_confirmations_repaired && userReport.tot_users_confirmations_repaired === 1 ?
                            <>
                                <FontAwesomeIcon icon={faThumbsUp} className='chargeprob-green' /> {userReport.tot_users_confirmations_repaired} {content.userReportsFeedbackWorkingOne}
                                <br />
                            </>
                            : null}
                    {userReport.tot_users_confirmations_not_working && userReport.tot_users_confirmations_not_working > 1 ?
                        <>
                            <FontAwesomeIcon icon={faThumbsDown} style={{ color: 'red' }} /> {userReport.tot_users_confirmations_not_working} {content.userReportsFeedbackNotWorking}
                        </>
                        : userReport.tot_users_confirmations_not_working && userReport.tot_users_confirmations_not_working === 1 ?
                            <>
                                <FontAwesomeIcon icon={faThumbsDown} style={{ color: 'red' }} /> {userReport.tot_users_confirmations_not_working} {content.userReportsFeedbackNotWorkingOne}
                            </>
                            : null}
                </Card.Text>
                {props.source === "userreports" && userReport.report_status !== "CLOSE" ?
                    <Card.Text>
                        <Button className="chargeprob-button-green offcanvasButtons" onClick={() => props.openModalConfirmReportIsSolved(userReport)}>
                            <b>{content.userReportIsIssueSolved}</b>
                        </Button>
                    </Card.Text>
                    : null}
                {props.source === "userreports" ?
                    <>
                        <hr className='chargeprob-darkgreen' />
                        <Button variant="outline-warning" className='offcanvasButtons' onClick={() => bringmethere_clicked(userReport.evcharger, evcharger_details)}>{buttonscontent.bringMeThere}</Button>
                    </>
                    : null}
            </Card.Body>
        </Card>
        // <Card border="dark" className="offcanvas-cards">
        //     <Card.Header as="h5" className='chargeprob-green'>
        //         {evcharger_details["address"] + ","} 
        //         <br /> 
        //         {evcharger_details["postal_code"] + " " + evcharger_details["city"]}</Card.Header>
        //     <Card.Body>
        //         <Card.Title>{report_start_time_str}</Card.Title>
        //         <Card.Text>
        //         <FontAwesomeIcon icon={faCircleExclamation} style={{'color': '#BF2D32'}}/>
        //             &nbsp;&nbsp; Problem: <b>{userReport.report}</b>
        //         </Card.Text>
        //         {userReport.car_model && userReport.car_model !== "Unknown" ? 
        //         <Card.Text>
        //             Car model: {userReport.car_model}
        //         </Card.Text> : null }
        //         {userReport.report_notes && userReport.report_notes !== "" ? 
        //         <Card.Text>
        //             Notes: {userReport.report_notes}
        //         </Card.Text>  : null }
        //         <hr className='chargeprob-green'/>
        //         {evcharger_details.status_notes && evcharger_details.status_notes !== "" ?
        //         <Card.Text>
        //             <FontAwesomeIcon icon={faPen}/> &nbsp;&nbsp;
        //             {content.userReportsUpdateFromOwner}: {evcharger_details.status_notes}
        //         </Card.Text> 
        //         :
        //         <Card.Text>
        //             <FontAwesomeIcon icon={faPen}/> &nbsp;&nbsp;
        //             {content.userReportsNoUpdateFromOwner}
        //         </Card.Text>
        //         }
        //         {props.source === "userreports" ?
        //             <Button variant="outline-warning" className='offcanvasButtons' onClick={() => bringmethere_clicked(userReport.evcharger, evcharger_details)}>{buttonscontent.bringMeThere}</Button>
        //         : null }
        //     </Card.Body>
        // </Card>
    )
}

export default UserReport;