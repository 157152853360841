import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Stack from 'react-bootstrap/Stack';
import { offcanvasContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';
import UserReport from './UserReport';
import ChargerOperStatusInfo from './ChargerOperStatusInfo';
import EODisruptionInfo from './EODisruptionInfo';
import ModalReportImage from '../modals/ModalReportImage';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function ChargerStatusInfoOffcanvas(props) {

    const content = props.language === "EN" ? offcanvasContent.English :
        props.language === "NL" ? offcanvasContent.Dutch :
            offcanvasContent.English;

    const [showModalReportImage, setShowModalReportImage] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);

    const [showOpenReports, setShowOpenReports] = useState(true);
    const [showClosedReports, setShowClosedReports] = useState(false);

    const openModalReportImage = (report) => {
        setSelectedReport(report);
        setShowModalReportImage(true);
    }

    const closeModalReportImage = () => {
        setShowModalReportImage(false);
        setSelectedReport(null);
    }

    const toggleOpenReports = () => {
        setShowOpenReports(prevShow => !prevShow);
    }

    const toggleClosedReports = () => {
        setShowClosedReports(prevShow => !prevShow);
    }

    const openReports = props.userReportsOnCharger ? props.userReportsOnCharger.filter(report => report.report_status !== "CLOSE") : null;
    const closedReports = props.userReportsOnCharger ? props.userReportsOnCharger.filter(report => report.report_status === "CLOSE") : null;

    return (
        <>
            <Offcanvas show={props.show} onHide={props.handleClose} scroll={true} backdrop={true} className="offCanvasChargerStatusInfo chargeprob-background-darkgreen">
                <Offcanvas.Header className='chargeprob-green chargeprob-background-darkgreen'>
                    <Offcanvas.Title>{content.chargerStatusOffcanvasTitle}</Offcanvas.Title>
                    <CustomCloseButton handleClose={props.handleClose} />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {props.selectedCharger && props.selectedCharger.disruption ?
                        <>
                            <EODisruptionInfo
                                selectedCharger={props.selectedCharger}
                                language={props.language} />
                            <hr className='chargeprob-green' />
                        </>
                        : null}
                    <br />
                    <ChargerOperStatusInfo
                        selectedCharger={props.selectedCharger}
                        content={content} />
                    <br /><br />

                    {openReports && openReports.length > 0 ?
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }} onClick={toggleOpenReports}>
                                <h4 className='chargeprob-green text-center'>{content.userReportOpenReports}</h4>
                                {showOpenReports ?
                                    <h4><FontAwesomeIcon icon={faChevronDown} className='chargeprob-green' /></h4>
                                    :
                                    <h4><FontAwesomeIcon icon={faChevronUp} className='chargeprob-green' /></h4>
                                }
                            </div>
                            <hr className='chargeprob-green' />
                            <br />
                        </div>
                        : null
                    }

                    {showOpenReports ?
                        <Stack direction='vertical' gap={4}>
                            {openReports && openReports.map((openReportOnCharger) => {
                                return (
                                    <div style={{ display: 'flex', justifyContent: 'center' }} key={openReportOnCharger.id}>
                                        <UserReport
                                            source="chargerstatusinfo"
                                            userReport={openReportOnCharger}
                                            selectedCharger={props.selectedCharger}
                                            language={props.language}
                                            openModalReportImage={openModalReportImage} />
                                        <br />
                                    </div>
                                )
                            })}
                        </Stack>
                        : null}

                    {closedReports && closedReports.length > 0 ?
                        <div>
                            <br />
                            <br />
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }} onClick={toggleClosedReports}>
                                <h4 className='chargeprob-green text-center'>{content.userReportClosedReports}</h4>
                                {showClosedReports ?
                                    <h4><FontAwesomeIcon icon={faChevronDown} className='chargeprob-green' /></h4>
                                    :
                                    <h4><FontAwesomeIcon icon={faChevronUp} className='chargeprob-green' /></h4>
                                }
                            </div>
                            <hr className='chargeprob-green' />
                            <br />
                        </div>
                        : null}


                    {showClosedReports ?
                        <Stack direction='vertical' gap={4}>
                            {closedReports && closedReports.map((closedReportOnCharger) => {
                                return (
                                    <div style={{ display: 'flex', justifyContent: 'center' }} key={closedReportOnCharger.id}>
                                        <UserReport
                                            source="chargerstatusinfo"
                                            userReport={closedReportOnCharger}
                                            selectedCharger={props.selectedCharger}
                                            language={props.language}
                                            openModalReportImage={openModalReportImage} />
                                        <br />
                                    </div>
                                )
                            })}
                        </Stack>
                        : null}

                    <br /><br /><br /><br />
                </Offcanvas.Body>
            </Offcanvas>
            {showModalReportImage ?
                <ModalReportImage
                    show={showModalReportImage}
                    handleClose={closeModalReportImage}
                    report={selectedReport}
                    charger={props.selectedCharger}
                    language={props.language}
                />
                : null}
        </>
    )
}

export default ChargerStatusInfoOffcanvas;