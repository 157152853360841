export class Utils {

    static passwordValidation(pwd, pwd_errors) {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const userPassword = pwd;
        const passwordLength = userPassword.length;
        const digitsPassword = digitsRegExp.test(userPassword);
        const specialCharPassword = specialCharRegExp.test(userPassword);
        const minLengthPassword = minLengthRegExp.test(userPassword);
        let errMsg = "";
        if (passwordLength === 0) {
            errMsg = pwd_errors.passwordEmptyError;
        } else if (!minLengthPassword) {
            errMsg = pwd_errors.passwordLengthError;
        } else if (!digitsPassword) {
            errMsg = pwd_errors.passwordDigitError;
        } else if (!specialCharPassword) {
            errMsg = pwd_errors.passwordSpecialCharError;
        } else {
            errMsg = "";
        }

        // const uppercasePassword = uppercaseRegExp.test(userPassword);
        // const lowercasePassword = lowercaseRegExp.test(userPassword);
        // else if (!uppercasePassword) {
        //     errMsg = pwd_errors.passwordCaseError;
        // } else if (!lowercasePassword) {
        //     errMsg = pwd_errors.passwordCaseError;
        // } 

        return errMsg;
    }

    static repeatPasswordValidation(pwd, pwd_repeat) {
        if (!pwd || !pwd_repeat || pwd !== pwd_repeat) {
            return false;
        }
        return true;
    }

    static emailValidation = (userEmail) => {
        const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(!userEmail || regex.test(userEmail) === false){
            return false;
        }
        return true;
    }

    static phoneNumberValidation = (userPhoneNumber) => {
        const regex = /^[+][0-9]{2}[0-9]{8,11}$/;
        if(!userPhoneNumber || regex.test(userPhoneNumber) === false){
            return false;
        }
        return true;
    }
}