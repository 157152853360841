import React from 'react';
import { chargerSocketsContent } from '../internationalization/Translations';
import { Stack, Button, Row, Col } from 'react-bootstrap';


function ChargerComments(props) {

    const content = props.language === "EN" ? chargerSocketsContent.English :
        props.language === "NL" ? chargerSocketsContent.Dutch :
            chargerSocketsContent.English

    const markerInfo = props.markerInfo ? props.markerInfo : null;
    const totComments = props.markerInfo ? props.markerInfo.totcomments ? props.markerInfo.totcomments : 0 : 0;
    const commentsText = totComments === 1 ? "1 comment" : totComments + " comments";

    let positiveComments = totComments > 0 &&
        props.markerInfo.userscomments.filter(function (el) {
            return el.reaction === "positive";
        });

    let totPositiveComments = positiveComments && positiveComments.length ? positiveComments.length : 0;

    let negativeComments = totComments > 0 &&
        props.markerInfo.userscomments.filter(function (el) {
            return el.reaction === "negative";
        });

    let totNegativeComments = negativeComments && negativeComments.length ? negativeComments.length : 0;

    return (
        <div className='popup-element-div popup-element-div-bordered popup-element-div-white'
            onClick={() => props.showChargerComments(markerInfo)}>
            <Stack direction='horizontal' gap={3}>
                <div><span className='commentsIcon'></span></div>
                <Stack direction='vertical' gap={1}>
                    <div><b>{commentsText}</b></div>
                    <Stack direction='horizontal' gap={2}>
                        {totPositiveComments > 0 ?
                            <Stack direction='horizontal' gap={2}>
                                <div style={{ 'fontSize': '0.8rem' }}>{totPositiveComments}</div>
                                <span className='likeIcon-xs'></span>
                            </Stack>
                            : null}
                        {totNegativeComments > 0 ?
                            <Stack direction='horizontal' gap={2}>
                                <div style={{ 'color': '#ff0013', 'fontSize': '0.8rem' }}>{totNegativeComments}</div>
                                <span className='dislikeIcon-xs'></span>
                            </Stack>
                            : null}
                    </Stack>
                </Stack>
                <div><span className='rightArrowWhiteIcon' ></span></div>
            </Stack>
        </div>
    )

}

export default ChargerComments;
