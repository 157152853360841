import { useEffect } from "react";
import { useMap } from 'react-leaflet';

function BringMeThere(props) {
    const map = useMap();
    const bringmethere_lat = props.bringmethereLocation ? props.bringmethereLocation["latitude"] : "";
    const bringmethere_lng = props.bringmethereLocation ? props.bringmethereLocation["longitude"] : "";
  
    useEffect( () => {
      if(bringmethere_lat !== ""){
        map.closePopup();
        map.setView([bringmethere_lat, bringmethere_lng], 18);
        props.goToLocation(props.bringmethereLocation.id);
      }
    }, [bringmethere_lat, bringmethere_lng])
  
    return null;
}

export default BringMeThere;