import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { missingChargerContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';


function ModalMissingChargerInfo(props) {

    const content = props.language === "EN" ? missingChargerContent.English :
        props.language === "NL" ? missingChargerContent.Dutch :
            missingChargerContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [incidentType, setIncidentType] = useState('');
    const [incidentTypeTranslation, setIncidentTypeTranslation] = useState('');
    const [chargerId, setChargerId] = useState('');
    const [extraInfo, setExtraInfo] = useState('');
    const [address, setAddress] = useState('');
    const [cpo, setCpo] = useState('');
    const [missingChargerProblem, setMissingChargerProblem] = useState(true);
    const [reportImage, setReportImage] = useState(null);
    const [reportImagePreview, setReportImagePreview] = useState(null);
    const [reportPhoto, setReportPhoto] = useState(null);
    const [reportPhotoPreview, setReportPhotoPreview] = useState(null);
    const [startCapture, setStartCapture] = useState(false);
    const [startCaptureError, setStartCaptureError] = useState('');
    const [mediaStream, setMediaStream] = useState(null);
    const imageInputRef = useRef(null);
    const videoRef = useRef(null);

    const [noIncidentSelectedError, setNoIncidentSelectedError] = useState('');
    const [noAddressError, setNoAddressError] = useState('');
    const nothingSelectedErrorMsg = content.nothingSelectedErrorMsg;
    const noAddressErrorMsg = content.noAddressErrorMsg;

    useEffect(() => {
        setReportImage(props.missingChargerFormData["reportImage"]);
        setReportImagePreview(props.missingChargerFormData["reportImagePreview"]);
        setIncidentType(props.missingChargerFormData["incidentType"]);
        setIncidentTypeTranslation(props.missingChargerFormData["incidentTypeTranslation"]);
        setChargerId(props.missingChargerFormData["chargerId"]);
        setAddress(props.missingChargerFormData["address"]);
        setCpo(props.missingChargerFormData["cpo"]);
        setExtraInfo(props.missingChargerFormData["extraInfo"]);
    }, [props.missingChargerFormData]);

    useEffect(() => {
        return () => {
            if (mediaStream) {
                handleStopCapture();
            }
        };
    }, [mediaStream]);

    const handleMissingChargerProblemChange = (event) => {
        setMissingChargerProblem(event.target.checked);
    };

    const handleUploadImage = () => {
        imageInputRef.current.click();
    };

    const handleUploadedFile = (evt) => {
        const file = evt.target.files[0];
        if (file) {
            setReportImage(file);
            setReportImagePreview(URL.createObjectURL(file));
        }
    }

    const handleRemoveImage = () => {
        setReportImage(null);
        setReportImagePreview(null);
        setReportPhoto(null);
        setReportPhotoPreview(null);
        setStartCaptureError('');
    };

    const selectTypeOfIncident = (incidentTypeSelected, incidentTypeTranslationSelected) => {
        setIncidentType(incidentTypeSelected);
        setIncidentTypeTranslation(incidentTypeTranslationSelected);
    }

    const submitClicked = () => {
        setNoIncidentSelectedError('');
        setStartCaptureError('');
        if (missingChargerProblem && incidentType === '') setNoIncidentSelectedError(nothingSelectedErrorMsg);
        if (address === '') setNoAddressError(noAddressErrorMsg);
        if (address !== '' && (!missingChargerProblem || incidentType !== '')) {
            props.setMissingChargerFormData((prevState) => ({
                ...prevState,
                "incidentType": incidentType,
                "incidentTypeTranslation": incidentTypeTranslation,
                "chargerId": chargerId,
                "address": address,
                "extraInfo": extraInfo,
                "cpo": cpo,
                "reportImage": null,
                "reportImagePreview": null
            }));
            if (reportImage) {
                props.setMissingChargerFormData((prevState) => ({
                    ...prevState,
                    "reportImage": reportImage,
                    "reportImagePreview": reportImagePreview
                }));
            }
            else if (reportPhoto) {
                props.setMissingChargerFormData((prevState) => ({
                    ...prevState,
                    "reportImage": reportPhoto,
                    "reportImagePreview": reportPhotoPreview
                }));
            }
            else {
                //pass
            }
            props.goToNextModal();
        }
    }

    const handleStartCapture = async () => {
        setStartCapture(true);
        setStartCaptureError('');
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: "environment" },
                audio: false,
            });
            setMediaStream(stream);
            videoRef.current.srcObject = stream;
            videoRef.current.play();
        } catch (error) {
            //console.error("Error starting camera:", error);
            setStartCaptureError(content.startCameraError);
            setStartCapture(false);
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop());
                setMediaStream(null);
            }
        }
    };

    const handleStopCapture = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
            setMediaStream(null);
        }
        setStartCapture(false);
        setStartCaptureError('');
    }

    const handleTakePhoto = () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas
            .getContext("2d")
            .drawImage(
                videoRef.current,
                0,
                0,
                videoRef.current.videoWidth,
                videoRef.current.videoHeight
            );

        const photoURL = canvas.toDataURL();
        setReportPhotoPreview(photoURL);
        canvas.toBlob(blob => {
            const fileName = `image-${Date.now()}.png`;
            const file = new File([blob], fileName, { type: "image/png" });
            setReportPhoto(file);
        });
        videoRef.current.pause();
        handleStopCapture();
    };

    const resetIncidentInfo = () => {
        setReportImage(null);
        setReportImagePreview(null);
        setReportPhoto(null);
        setReportPhotoPreview(null);
        setStartCaptureError('');
        setIncidentType('');
        setIncidentTypeTranslation('');
        setChargerId('');
        setAddress('');
        setCpo('');
        setExtraInfo('');
        setMissingChargerProblem(false);
    }

    const handleClose = () => {
        handleStopCapture();
        resetIncidentInfo();
        //props.resetIncidentInfo();
        props.handleClose();
    }

    return (
        <Modal show={props.show} onHide={handleClose} backdrop="static">
            <Modal.Header className="modalsBody" >
                <Modal.Title className='w-100 text-center'>{content.title}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            {startCapture ?
                <Modal.Body className="modalsBody">
                    <video ref={videoRef} className='report-takepicture' />
                    <Stack direction='horizontal' gap={2}>
                        <Button size="sm" className='chargeprob-button-outline-green' onClick={handleTakePhoto}>
                            {content.takePhoto}
                        </Button>
                        <Button size="sm" variant='outline-danger' onClick={handleStopCapture}>
                            {buttonscontent.close}
                        </Button>
                    </Stack>
                </Modal.Body>
                :
                <Modal.Body className="modalsBody">
                    <Form>
                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                            <h6>{content.subtitle}</h6>
                        </div>
                        <br />
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="reportIncident.ControlInput.address">
                                    <Form.Label>
                                        *{content.address}
                                    </Form.Label>
                                    <Form.Control placeholder={content.addressPlaceholder} value={address} onChange={evt => setAddress(evt.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId="reportIncident.ControlInput.cpo">
                                    <Form.Label>
                                        {content.cpo}
                                    </Form.Label>
                                    <Form.Control value={cpo} onChange={evt => setCpo(evt.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <p className='red'><b>{noAddressError}</b></p>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3" controlId="reportIncident.ControlInput.chargerid">
                                    <Form.Label>
                                        {content.chargerId}
                                    </Form.Label>
                                    <Form.Control value={chargerId} onChange={evt => setChargerId(evt.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="me-auto form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="missingChargerProblem"
                                        checked={missingChargerProblem}
                                        onChange={handleMissingChargerProblemChange}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="missingChargerProblem">
                                        {content.missingChargerProblem}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        {missingChargerProblem ?
                            <Row style={{ fontSize: '0.8rem', display: 'flex', justifyContent: 'center', textAlign: 'center' }} className='mt-2'>
                                <Col xs={3} onClick={() => selectTypeOfIncident("Charger does not work", content.newIncidentChargerNotWork)}>
                                    <Stack direction='vertical' gap={2}>
                                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            {incidentType === "Charger does not work" ?
                                                <span className="reportWarningGreenIcon"></span>
                                                : <span className="reportWarningIcon"></span>
                                            }
                                        </div>
                                        <div>
                                            {incidentType === "Charger does not work" ?
                                                <span className='chargeprob-darkgreen' style={{ fontSize: '0.85rem' }}><b>{content.newIncidentChargerNotWork}</b></span>
                                                :
                                                content.newIncidentChargerNotWork
                                            }
                                        </div>
                                    </Stack>
                                </Col>
                                <Col xs={3} onClick={() => selectTypeOfIncident("Slow charging", content.newIncidentSlowCharging)}>
                                    <Stack direction='vertical' gap={2}>
                                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            {incidentType === "Slow charging" ?
                                                <span className="reportSlowChargingGreenIcon"></span>
                                                :
                                                <span className="reportSlowChargingIcon"></span>
                                            }
                                        </div>
                                        <div>
                                            {incidentType === "Slow charging" ?
                                                <span className='chargeprob-darkgreen' style={{ fontSize: '0.85rem' }}><b>{content.newIncidentSlowCharging}</b></span>
                                                :
                                                content.newIncidentSlowCharging
                                            }
                                        </div>
                                    </Stack>
                                </Col>
                                <Col xs={3} onClick={() => selectTypeOfIncident("Charging does not start", content.newIncidentStartStop)}>
                                    <Stack direction='vertical' gap={2}>
                                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            {incidentType === "Charging does not start" ?
                                                <span className="reportStartStopGreenIcon"></span>
                                                :
                                                <span className="reportStartStopIcon"></span>
                                            }
                                        </div>
                                        <div>
                                            {incidentType === "Charging does not start" ?
                                                <span className='chargeprob-darkgreen' style={{ fontSize: '0.85rem' }}><b>{content.newIncidentStartStop}</b></span>
                                                :
                                                content.newIncidentStartStop
                                            }
                                        </div>
                                    </Stack>
                                </Col>
                                <Col xs={3} onClick={() => selectTypeOfIncident("Other issue", content.newIncidentOtherIssue)}>
                                    <Stack direction='vertical' gap={2}>
                                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                            {incidentType === "Other issue" ?
                                                <span className="reportOtherProblemGreenIcon"></span>
                                                :
                                                <span className="reportOtherProblemIcon"></span>
                                            }
                                        </div>
                                        <div>
                                            {incidentType === "Other issue" ?
                                                <span className='chargeprob-darkgreen' style={{ fontSize: '0.85rem' }}><b>{content.newIncidentOtherIssue}</b></span>
                                                :
                                                content.newIncidentOtherIssue
                                            }
                                        </div>
                                    </Stack>
                                </Col>
                                <p className='red'><b>{noIncidentSelectedError}</b></p>
                            </Row>
                            : null}
                        <Row className='mt-3'>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1" >
                                <Form.Label>{content.extraDetails}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={extraInfo}
                                    onChange={evt => setExtraInfo(evt.target.value)} />
                            </Form.Group>
                        </Row>
                        {reportImage ?
                            <Form.Group>
                                <Stack direction='vertical' gap={1}>
                                    <div>
                                        <img src={reportImagePreview} alt="Picture preview" className='report-image-preview' />
                                    </div>
                                    <div>
                                        <Button size="sm" variant='outline-danger' onClick={handleRemoveImage}>
                                            {content.removeImage}
                                        </Button>
                                    </div>
                                </Stack>
                            </Form.Group>
                            :
                            <Form.Group>
                                {reportPhotoPreview ?
                                    <Stack direction='vertical' gap={1}>
                                        <div>
                                            <img src={reportPhotoPreview} alt="Picture preview" className='report-image-preview' />
                                        </div>
                                        <div>
                                            <Button size="sm" variant='outline-danger' onClick={handleRemoveImage}>
                                                {content.removeImage}
                                            </Button>
                                        </div>
                                    </Stack>
                                    :
                                    <div>
                                        <Form.Label>
                                            {content.uploadPicture}
                                        </Form.Label>
                                        <Stack direction='horizontal' gap={2}>
                                            <div>
                                                <Button size="sm" className='chargeprob-button-outline-green' onClick={handleUploadImage}>
                                                    {content.uploadImage}
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={imageInputRef}
                                                    accept="image/jpeg,image/png"
                                                    style={{ display: "none" }}
                                                    onChange={handleUploadedFile}
                                                />
                                            </div>
                                            <div>
                                                <Button size="sm" className='chargeprob-button-outline-green' onClick={handleStartCapture}>
                                                    {content.takePhoto}
                                                </Button>
                                            </div>
                                        </Stack>
                                        <p className='red'><b>{startCaptureError}</b></p>
                                    </div>
                                }
                            </Form.Group>
                        }
                    </Form>
                    <Row>
                        <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                            <Stack direction='horizontal' gap={2}>
                                <Button variant="danger" onClick={handleClose}>
                                    {buttonscontent.close}
                                </Button>
                                <Button variant="success" onClick={() => submitClicked()}>
                                    {buttonscontent.proceed}
                                </Button>
                            </Stack>
                        </div>
                    </Row>
                </Modal.Body>
            }
        </Modal>
    )
}

export default ModalMissingChargerInfo;