import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import AuthContext from '../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function UserProfilePhonenumber(props) {

    let { userProfile } = useContext(AuthContext);
    const buttonscontent = props.buttonscontent;
    const content = props.content;
    return (
        <>
            <h6 className='chargeprob-green'><FontAwesomeIcon icon={faPhone} size="lg" /> {content.userProfilePhonenumber}</h6>
            {userProfile && userProfile.phonenumber ?
                <>
                    <p>{userProfile.phonenumber}</p>
                    {props.showUserChangePhonenumber ?
                        <Stack direction="vertical" gap={1}>
                            <Col xs={10} md={7}>
                                <Form>
                                    <Form.Group className="mb-0" controlId="userprofile.ControlInputChangePhoneNumber" >
                                        <Form.Control
                                            type="tel"
                                            size="sm"
                                            className='offcanvasButtons'
                                            style={{color: "white"}}
                                            placeholder={userProfile.phonenumber}
                                            onChange={evt => props.setUserPhonenumber(evt.target.value)}
                                        />
                                        <p className='red'>{props.userPhonenumberError}</p>
                                    </Form.Group>
                                </Form>
                            </Col>
                            {props.userUpdatePhonenumberLoading ?
                                <Button size="sm" variant="success" className='offcanvasButtons'>
                                    {buttonscontent.saveUserInfo}
                                </Button>
                                :
                                <Stack direction="horizontal" gap={1}>
                                    <Button size="sm" variant="success" className='offcanvasButtons offcanvasButtonsLessPadding'
                                        onClick={() => props.checkPhonenumber("update")}>
                                        {buttonscontent.userChangePhoneNumber}
                                    </Button>
                                    <Button size="sm" variant="success" className='offcanvasButtons offcanvasButtonsLessPadding'
                                        onClick={() => props.checkPhonenumber("delete")}>
                                        {buttonscontent.userRemovePhoneNumber}
                                    </Button>
                                    <Button size="sm" variant="danger" className='offcanvasButtons'
                                        onClick={() => props.setShowUserChangePhonenumber(false)}>
                                        {buttonscontent.close}
                                    </Button>
                                </Stack>
                            }
                        </Stack>
                        :
                        <>
                            <Button
                                variant="outline-warning" size="sm" className='offcanvasButtons' onClick={() => props.showAndResetUserChangePhonenumber(true)}>
                                {buttonscontent.userChangePhoneNumber}
                            </Button>
                            <p>{props.userUpdatePhonenumberError}</p>
                        </>
                    }
                </>
                :
                <>
                    <p>{content.userProfileNoPhonenumberProvided}</p>
                    {props.showUserAddPhonenumber ?
                        <Stack direction="vertical" gap={1}>
                            <Col xs={8}>
                                <Form>
                                    <Form.Group className="mb-0" controlId="userprofile.ControlInputAddPhoneNumber" >
                                        <Form.Control
                                            type="tel"
                                            size="sm"
                                            className='offcanvasButtons'
                                            style={{color: "white"}}
                                            placeholder="phone number (e.g.: +31612345678)"
                                            onChange={evt => props.setUserPhonenumber(evt.target.value)}
                                        />
                                        <p className='red'>{props.userPhonenumberError}</p>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Stack direction="horizontal" gap={1}>
                                <Button size="sm" variant="success" className='offcanvasButtons'
                                    onClick={() => props.checkPhonenumber("add")}>
                                    {buttonscontent.userAddPhoneNumber}
                                </Button>
                                <Button size="sm" variant="danger" className='offcanvasButtons'
                                    onClick={() => props.setShowUserAddPhonenumber(false)}>
                                    {buttonscontent.close}
                                </Button>
                            </Stack>
                        </Stack>
                        :
                        <>
                            <Button
                                variant="outline-warning" size="sm" className='offcanvasButtons' onClick={() => props.showAndResetUserAddPhonenumber(true)}>
                                {buttonscontent.userAddPhoneNumber}
                            </Button>
                            <p>{props.userUpdatePhonenumberError}</p>
                        </>
                    }
                </>
            }
        </>
    )
}

export default UserProfilePhonenumber;