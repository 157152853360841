import React, { useState, useEffect, useContext } from 'react';
import '../stylesheets/nicepage_homepage.css';
import AuthContext from '../context/AuthContext';
import { homePageContent } from '../components/internationalization/Translations';
import { useFetchHomePageStats } from '../hooks/useFetchHomePageStats';
import CountUp from 'react-countup';

function StatsCounter(props) {

    const [stats, loadingStats, errorStats] = useFetchHomePageStats();
    let { language } = useContext(AuthContext);
    const content = language === "EN" ? homePageContent.English :
        language === "NL" ? homePageContent.Dutch :
            homePageContent.English;

    return (
        <section className="u-align-center u-clearfix u-grey-5 u-section-statscounter" id="sec-f4ca">
            <div className="u-clearfix u-sheet u-sheet-1">
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-container-layout-1">
                                {loadingStats ?
                                    <h1>Loading...</h1>
                                    : stats ?
                                        <h1 className="u-align-center u-text u-title u-text-1" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000">
                                            <CountUp
                                                start={0}
                                                end={stats.unavailable_chargers}
                                                delay={0}
                                                enableScrollSpy={true}
                                                duration={1.5}>
                                                {({ countUpRef }) => (
                                                    <div>
                                                        <span ref={countUpRef} />
                                                    </div>
                                                )}
                                            </CountUp>
                                        </h1>
                                        : <h1>Loading...</h1>
                                }
                                <p className="u-align-center u-custom-font u-text u-text-body-color u-text-2"> {content.unavailableChargersOverview}</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-container-layout-2">
                                {stats ?
                                    <h1 className="u-align-center u-text u-title u-text-3" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000"><CountUp
                                        start={0}
                                        end={stats.user_reports}
                                        delay={0}
                                        enableScrollSpy={true}
                                        duration={1.5}>
                                        {({ countUpRef }) => (
                                            <div>
                                                <span ref={countUpRef} />
                                            </div>
                                        )}
                                    </CountUp></h1>
                                    : <h1>Loading...</h1>
                                }
                                <p className="u-align-center u-custom-font u-text u-text-body-color u-text-4"> {content.problemsReportedOverview}</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-container-layout-3">
                                {stats ?
                                    <h1 className="u-align-center u-text u-title u-text-5" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000"><CountUp
                                        start={0}
                                        end={stats.cpo_updates}
                                        delay={0}
                                        enableScrollSpy={true}
                                        duration={1.5}>
                                        {({ countUpRef }) => (
                                            <div>
                                                <span ref={countUpRef} />
                                            </div>
                                        )}
                                    </CountUp></h1>
                                    : <h1>Loading...</h1>
                                }
                                <p className="u-align-center u-custom-font u-text u-text-body-color u-text-6"> {content.updatesFromCPOOverview}</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-container-layout-4">
                                {stats ?
                                    <h1 className="u-align-center u-text u-title u-text-7" data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000"><CountUp
                                        start={0}
                                        end={stats.available_chargers}
                                        delay={0}
                                        enableScrollSpy={true}
                                        duration={1.5}>
                                        {({ countUpRef }) => (
                                            <div>
                                                <span ref={countUpRef} />
                                            </div>
                                        )}
                                    </CountUp></h1>
                                    : <h1>Loading...</h1>
                                }
                                <p className="u-align-center u-custom-font u-text u-text-body-color u-text-8"> {content.availableChargersOverview}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StatsCounter;