import { useState, useEffect } from "react";
import { useMap } from 'react-leaflet';


function UserLocation(props){

  const map = useMap();
  const [loading, setLoading] = useState(true);

  const updateLocation = () => {
    if ("geolocation" in navigator) {
      //var location_timeout = setTimeout(10000);
      //navigator.geolocation.watchPosition(function(position) {
      navigator.geolocation.getCurrentPosition(function(position) {
        //clearTimeout(location_timeout);
        props.setUserLatLng([position.coords.latitude, position.coords.longitude]);
        props.setUserLocationActive(true);
        props.setUserLocationError("");

      },
      function(error) {
        if(error.code === 1){
          props.setUserLatLng(null);
          props.setUserLocationError("No permission");
        }
        else{
          props.setUserLatLng(null);
          props.setUserLocationError("Can't get location");
        }
        //console.log("error");
        props.setUserLocationActive(false);
        //clearTimeout(location_timeout);
      }, {enableHighAccuracy:true});
    } else {
      props.setUserLocationActive(false);
      props.setUserLatLng(null);
      props.setUserLocationError("Can't get location");
      //clearTimeout(location_timeout);
    }
  }

  useEffect(()=>{
    if(loading){
      props.setLoadingUserLocation(true);
      updateLocation();
      setLoading(false);
      props.setLoadingUserLocation(false);
    }
    let updateTime = 1000 * 5;
    let interval = setInterval(()=>{
      updateLocation();
    }, updateTime);

    return ()=> clearInterval(interval);
  }, [map])

  return null;
}

export default UserLocation; 

/* Get location using leaflet
function UserLocation(props){
    const map = useMap();
    let [loading, setLoading] = useState(true);
  
    const updateLocation = () => {
      console.log("update location");
      map.locate()
        .on('locationfound', function(e){
          if(loading){
            setLoading(false);
          }
          props.setUserLatLng(e.latlng);
          //props.setUserLocationActive(true);
      })
      .on('locationerror', function(e){
        props.setUserLatLng(null);
        //props.setUserLocationActive(false);
      });
    }
  
    useEffect(()=>{
      if(loading){
        updateLocation();
      }
      let updateTime = 1000 * 10;
      let interval = setInterval(()=>{
        updateLocation();
      }, updateTime);
  
      return ()=> clearInterval(interval);
  }, [map, loading])
}

export default UserLocation; */
