import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { offcanvasContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';

function ModalUserReportFeedbackSent(props) {

    const content = props.language === "EN" ? offcanvasContent.English :
        props.language === "NL" ? offcanvasContent.Dutch :
            offcanvasContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const title = props.feedbackResult === "solved" ? content.userReportIsIssueSolvedConfirmationSolvedTitle
        : props.feedbackResult === "not solved" ? content.userReportIsIssueSolvedConfirmationNotSolvedTitle
            : content.userReportIsIssueSolvedConfirmationErrorTitle;

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{title}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            {props.feedbackResult === "solved" ?
                <Modal.Body className="modalsBody text-center">
                    <p>{content.userReportIsIssueSolvedConfirmationSolved_p1}</p>
                    <p>{content.userReportIsIssueSolvedConfirmationSolved_p2}</p>
                    <Row>
                        <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                            <Button className='chargeprob-button-green' onClick={props.handleClose}>
                                {buttonscontent.close}
                            </Button>
                        </div>
                    </Row>
                </Modal.Body>
                : props.feedbackResult === "not solved" ?
                    <Modal.Body className="modalsBody text-center">
                        <p>{content.userReportIsIssueSolvedConfirmationNotSolved_p1}</p>
                        <p>{content.userReportIsIssueSolvedConfirmationNotSolved_p2}</p>
                        <Row>
                            <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                <Button className='chargeprob-button-green' onClick={props.handleClose}>
                                    {buttonscontent.close}
                                </Button>
                            </div>
                        </Row>
                    </Modal.Body>
                    : props.feedbackResult === "too many requests" ?
                        <Modal.Body className="modalsBody text-center">
                            <p>{content.userReportIsIssueSolvedConfirmationTooManyRequests_p1}</p>
                            <Row>
                                <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                    <Button className='chargeprob-button-green' onClick={props.handleClose}>
                                        {buttonscontent.close}
                                    </Button>
                                </div>
                            </Row>
                        </Modal.Body>

                        : <Modal.Body className="modalsBody text-center">
                            <p>{content.userReportIsIssueSolvedConfirmationError_p1}</p>
                            <Row>
                                <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                    <Button className='chargeprob-button-green' onClick={props.handleClose}>
                                        {buttonscontent.close}
                                    </Button>
                                </div>
                            </Row>
                        </Modal.Body>
            }
        </Modal>
    )
}

export default ModalUserReportFeedbackSent;