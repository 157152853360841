import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import CustomCloseButton from '../buttons/CustomCloseButton';


function ModalUnsubscribedConfirmation(props) {

    return (
        <>
            {props.unsubscribedSuccess !== "" ?
                <Modal show={props.show} onHide={props.handleClose} backdrop="static">
                    <Modal.Header className="modalsBody">
                        {props.unsubscribedSuccess === "OK" ?
                            <Modal.Title className='w-100 text-center'>{props.content.userProfileUnsubscribedConfirmedModalTitle}</Modal.Title>
                            :
                            <Modal.Title className='w-100 text-center'>{props.content.userProfileUnsubscribeErrorTitle}</Modal.Title>
                        }
                        <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
                    </Modal.Header>
                    <Modal.Body className="modalsBody">
                        {props.unsubscribedSuccess === "OK" ?
                            <>
                                <p>{props.content.userProfileUnsubscribedConfirmedModal_p1}</p>
                                <p>{props.content.userProfileUnsubscribedConfirmedModal_p2}</p>
                            </>
                            :
                            <>
                                <p>{props.content.userProfileUnsubscribeError_p1}</p>
                            </>
                        }
                        <Row>
                            <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                <Button variant="danger" onClick={props.handleClose}>
                                    {props.buttonscontent.close}
                                </Button>
                            </div>
                        </Row>
                    </Modal.Body>
                </Modal>
                : null}
        </>
    )
}

export default ModalUnsubscribedConfirmation;