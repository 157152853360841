import React, { useContext } from 'react';
import '../../stylesheets/storing_cpo.css';
import AuthContext from '../../context/AuthContext';
import { storingCPOPageContent } from '../../components/internationalization/Translations';
import MapNavbar from '../../components/MapNavbar';
import HomePageFooter from '../HomePageFooter';
import GetInContact from '../GetInContact';
import HeaderStoring from './HeaderStoring';

function AllegoStoring() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? storingCPOPageContent.English :
        language === "NL" ? storingCPOPageContent.Dutch :
            storingCPOPageContent.English;

    return (
        <div className='homepagediv'>
            <MapNavbar page="storingcpo" />
            <div className='storingcpo-u-body storingcpo-u-xl-mode' data-lang="en">
                <HeaderStoring />
                <section className="storingcpo-u-clearfix storingcpo-u-section-2" id="sec-6d24">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <h3 className="storingcpo-u-text storingcpo-u-text-1"><b>Allego</b></h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpo}</p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoWebsite}: <a href="https://www.allego.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpo-u-btn-1">Allego Website</a></p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoPhonenumber}: 0880333033</p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoStoringWebsite}: <a rel="nofollow" href="https://www.allego.eu/nl-nl/zakelijk/support-en-contact" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpo-u-btn-2">{content.infoCpoStoringWebsite}</a></p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-3" id="sec-5429">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-default storingcpo-u-text-1">
                            <b>"Over Allego: Betrouwbare Laadinfrastructuur
                                voor Elektrische Voertuigen"</b>
                            <br />Allego, opgericht in Nederland in 2013 als onderdeel
                            van netbeheerder Alliander, heeft zich ontwikkeld tot een internationale speler
                            in de wereld van laadinfrastructuur. Met een netwerk van meer dan 28.000
                            operationele oplaadpunten verspreid over heel Europa en hun eigen innovatieve
                            platform, de EV-Cloud, heeft Allego een duidelijke missie: elektrisch rijden
                            gemakkelijker en toegankelijker maken.<br />
                            <br /><b>Hulp
                                bij Het Loskoppelen van Uw Laadkabel bij Allego Laadpalen</b>
                            <br />Soms kan het na een laadsessie bij een Allego laadpaal
                            lastig zijn om de laadkabel op de normale manier los te koppelen. In deze
                            gevallen kunnen enkele eenvoudige stappen helpen: <br />
                            <br />Probeer
                            de autoportieren af te sluiten en onmiddellijk weer te openen. Dit kan
                            soms de stekker vrijgeven.<br /> Als het
                            gewicht van de laadkabel de vrijgave bemoeilijkt, ondersteun dan de
                            stekker en ontgrendel de deuren met de sleutel. Voor Tesla-voertuigen kan
                            het loskoppelen ook vanuit het menu in de auto worden uitgevoerd.<br /> Op
                            sommige laadstations is het mogelijk om het laadstation te resetten door
                            uw laadpas gedurende 30 seconden voor de lezer te houden. Hierdoor wordt
                            uw laadkabel automatisch vrijgegeven.<br />
                            <br />Als geen van deze oplossingen werkt, neem dan contact
                            op met de operator van het oplaadpunt.<br />
                            <br /><b>Wanneer
                                het Oplaadpunt van Allego Geen Reactie Geeft</b>
                            <br />
                            <br />Indien het oplaadpunt van Allego helemaal niet
                            reageert en er geen controlelampje brandt, kan dit wijzen op een stroomstoring.
                            In dit geval raden we aan contact op te nemen met de beheerder van het
                            laadstation voor assistentie.<br />
                            <br /><b>Als
                                Uw Laadsessie Niet Start na het Aanbieden van Uw Laadpas</b>
                            <br />
                            <br />Als uw laadsessie bij een Allego laadpaal niet start
                            na het aanbieden van uw laadpas, kunnen verschillende redenen hieraan ten
                            grondslag liggen: <br />
                            <br />- Controleer
                            of uw laadpas is geactiveerd voor gebruik bij publieke oplaadpunten.
                            Sommige laadpassen moeten geactiveerd worden en dit kan tot 24 uur duren.<br />- Verifieer
                            of het specifieke laadpunt openbaar toegankelijk is. Niet alle laadpalen
                            zijn beschikbaar voor publiek gebruik.<br />- Probeer
                            de laadsessie opnieuw te starten zonder andere kaarten of voorwerpen in de
                            buurt van de kaartlezer te houden.<br />- Controleer
                            of uw laadpas niet geblokkeerd is door de uitgever van de pas.<br />
                            <br />
                            <br /><b>Contactgegevens
                                voor Hulp bij Allego</b>
                            <br />
                            <br />Als u toch nog hulp nodig heeft, kunt u de
                            contactgegevens van de operator vaak vinden op het laadstation zelf. Als dat
                            niet het geval is, kunt u contact opnemen via de onderstaande gegevens: <br />
                            <br /> Website:<a href="https://www.allego.nl/" target="_new" className="u-active-none u-border-none storingcpo-u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1">Allego Website</a>
                            <br />Telefoonnummer: 0880333033&nbsp;<br />
                            <br />Allego staat klaar om te helpen en ervoor te zorgen
                            dat uw elektrische voertuig blijft laden en u zorgeloos kunt genieten van uw
                            reis.
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-align-center storingcpo-u-clearfix storingcpo-u-image storingcpo-u-shading storingcpo-u-section-4" src="" data-image-width="1072" data-image-height="678" id="sec-fee5">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <a href="/" className="storingcpo-u-active-black storingcpo-u-border-none storingcpo-u-btn storingcpo-u-btn-round storingcpo-u-button-style storingcpo-u-custom-color-2 storingcpo-u-custom-font storingcpo-u-hover-custom-color-1 storingcpo-u-radius-50 storingcpo-u-btn-1">{content.reportProblemButton}<br />
                        </a>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-5" id="sec-ba43">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpo-u-sheet-1">
                        <div className="fr-view storingcpo-u-align-left storingcpo-u-clearfix storingcpo-u-rich-text storingcpo-u-text storingcpo-u-text-1">
                            <p id="isPasted">
                                <span className="storingcpo-u-custom-font">Voor de meest actuele informatie en telefoonnummers voor storingsmeldingen, verwijzen we je naar de websites van de specifieke laadpaalbeheerders. Wij zorgen ervoor dat de informatie regelmatig wordt gecontroleert en bijgewerkt om onze bezoekers zo accuraat en nuttig mogelijk te informeren. <br />
                                </span>
                            </p>
                        </div>
                    </div>
                </section>

                <GetInContact />
                <HomePageFooter />
            </div >
        </div >
    )
}

export default AllegoStoring;