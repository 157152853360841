import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import AuthContext from '../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthEurope } from '@fortawesome/free-solid-svg-icons';
import LanguagePicker from './LanguagePicker';

function UserProfileLanguage(props) {

    let {userProfile, language} = useContext(AuthContext);
    //const buttonscontent = props.buttonscontent;
    const content = props.content;

    return (
        <>
        {userProfile ? //&& userProfile.favorite_language ?
        <>
            <h6 className='chargeprob-green'><FontAwesomeIcon icon={faEarthEurope} size="lg" /> {content.userProfileLanguage}</h6>
            <br />
            <Stack direction='horizontal' gap={1} > 
                <Col xs={5} md={4} lg={4}>
                    <LanguagePicker variant="dark" />
                </Col>
                {language !== userProfile.favorite_language ?
                <Col xs={5} md={4} lg={4}>
                    <Button
                        variant="outline-warning" size="sm" 
                        onClick={() => props.updateFavoriteLanguage()}>
                        Update
                    </Button>
                </Col>
                : null }
            </Stack>
        </>
        : null }
        </>
    )
}

export default UserProfileLanguage;