import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import EVSEStatusInfo from './EVSEStatusInfo';
import { chargerSocketsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';

function EVSESStatusInfoOffcanvas(props) {

    const content = props.language === "EN" ? chargerSocketsContent.English :
        props.language === "NL" ? chargerSocketsContent.Dutch :
            chargerSocketsContent.English;

    return (
        <Offcanvas show={props.show} onHide={props.handleClose} scroll={true} backdrop={true} className="offCanvasChargerStatusInfo chargeprob-background-darkgreen">
            <Offcanvas.Header className='chargeprob-green chargeprob-background-darkgreen'>
                <Offcanvas.Title>Charger Info</Offcanvas.Title>
                <CustomCloseButton handleClose={props.handleClose} />
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Stack direction="horizontal" gap={2} >
                    <div><span className='socketIcon chargeprob-green'></span></div>
                    <div className='chargeprob-green' style={{ 'fontSize': '1.1rem' }}><b>Sockets</b></div>
                </Stack>
                <br />
                {props.selectedCharger && props.selectedCharger.evses && props.selectedCharger.evses.length > 0 ?
                    props.loadingEVSESStatus ? 
                    <Button variant="outline-warning">
                        Updating status...
                    </Button>
                    :
                    <Button
                        variant="outline-warning"
                        onClick={() => props.updateChargerEVSESstatus()}>
                        Update sockets status
                    </Button>
                    :
                    <p className='chargeprob-green'>{content.noEVSESonCharger_p1}<br /><br />{content.noEVSESonCharger_p2}</p>
                }
                <br /><br />
                {props.loadingEVSESStatusError ? <p className='chargeprob-green'>{content.noEVSESonCharger_p1}<br /><br />{content.noEVSESonCharger_p2}</p> : null}
                {props.selectedCharger && props.selectedCharger.evses && props.selectedCharger.evses.map((evse) => {
                    return (
                        <React.Fragment key={evse.id}>
                            <EVSEStatusInfo
                                evse={evse}
                                content={content} />
                            <br />
                        </React.Fragment>
                    )
                })}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default EVSESStatusInfoOffcanvas;