import React, { useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { offcanvasContent, buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';
import UserComment from './UserComment';
import AuthContext from '../../context/AuthContext';

function ChargerCommentsOffcanvas(props){

    let {user} = useContext(AuthContext);

    const content = props.language === "EN" ? offcanvasContent.English :
                    props.language === "NL" ? offcanvasContent.Dutch :
                    offcanvasContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
                    props.language === "NL" ? buttonsContent.Dutch :
                    buttonsContent.English;

    const userCommentsOnCharger = props.selectedCharger && props.selectedCharger.userscomments && 
                                    props.selectedCharger.userscomments.length > 0 ? 
                                    props.selectedCharger.userscomments : null;

    const isUserLoggedIn = () => {
        if (user) return true;
        return false;
    }

    const checkIfUserIsLoggedIn = () => {
        if(isUserLoggedIn()){
            props.setShowCreateCommentOnChargerModal(true);
        }
        else{
            props.openUserLoginModal();
        }
    }
        
    return(
        <Offcanvas show={props.show} onHide={props.handleClose} scroll={true} backdrop={true} 
                className="offCanvasChargerStatusInfo chargeprob-background-darkgreen">
            <Offcanvas.Header className='chargeprob-green chargeprob-background-darkgreen'>
                <Offcanvas.Title>{content.commentsOnChargerTitle}</Offcanvas.Title>
                <CustomCloseButton handleClose={props.handleClose} />
            </Offcanvas.Header>
            <Offcanvas.Body>
                { userCommentsOnCharger && userCommentsOnCharger.length > 0 ? 
                    <div style={{'display': ' flex','justifyContent': 'flex-end'}}>
                        <Button  
                            variant="outline-warning"  
                            onClick={() => checkIfUserIsLoggedIn()}>
                            {buttonscontent.createComment}
                        </Button> 
                    </div>
                : 
                    <div>
                        <p className='chargeprob-green'>{content.commentsOnChargerNoComments}</p>
                        <br />
                        <div style={{'display': ' flex','justifyContent': 'flex-end'}}>
                            <Button  
                                variant="outline-warning"  
                                onClick={() => checkIfUserIsLoggedIn()}>
                                {buttonscontent.createFirstComment}
                            </Button> 
                        </div>   
                    </div> }
                    <br />
                { userCommentsOnCharger && userCommentsOnCharger.length > 0 && userCommentsOnCharger.map((userComment) => {
                    return (
                        <React.Fragment key={userComment.id}>
                            <UserComment
                                userComment={userComment}
                                content={content} />
                            <br />
                        </React.Fragment>
                    )
                })}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ChargerCommentsOffcanvas;