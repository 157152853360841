import React, { useContext } from 'react';
import '../../stylesheets/storing_cpo.css';
import AuthContext from '../../context/AuthContext';
import { storingCPOPageContent } from '../../components/internationalization/Translations';
import MapNavbar from '../../components/MapNavbar';
import HomePageFooter from '../HomePageFooter';
import GetInContact from '../GetInContact';
import HeaderStoring from './HeaderStoring';

function ShellRechargeStoring() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? storingCPOPageContent.English :
        language === "NL" ? storingCPOPageContent.Dutch :
            storingCPOPageContent.English;

    return (
        <div className='homepagediv'>
            <MapNavbar page="storingcpo" />
            <div className='storingcpo-u-body storingcpo-u-xl-mode' data-lang="en">
                <HeaderStoring />
                <section className="storingcpo-u-clearfix storingcpo-u-section-2" id="sec-6d24">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <h3 className="storingcpo-u-text storingcpo-u-text-1"><b>NewMotion Shell Recharge</b></h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpo}</p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoWebsite}: <a href="https://newmotion.com/nl_NL/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpo-u-btn-1">NewMotion Shell Recharge Website</a></p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoPhonenumber}: 0880109500</p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoStoringWebsite}: nvt</p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-3" id="sec-5429">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-default storingcpo-u-text-1">
                            <b>"Over Shell ReCharge (voorheen NewMotion):
                                Vooruitstrevende Elektrische Mobiliteit"</b>
                            <br />
                            <br />NewMotion gaat tegenwoordig door het leven als Shell
                            ReCharge. Op termijn zal de naam NewMotion naar verwachting verdwijnen. In
                            Nederland is Shell de grootste en vaak ook de meest bekende aanbieder van
                            brandstoffen. Ze zetten zich krachtig in voor de elektrische revolutie. <br />
                            <br /><b>Hulp
                                bij Het Loskoppelen van Uw Laadkabel bij Shell ReCharge (voorheen NewMotion)</b>
                            <br />
                            <br />Na een laadsessie bij een Shell ReCharge (voorheen
                            NewMotion) laadpaal kan het soms lastig zijn om de laadkabel op de
                            gebruikelijke manier los te koppelen. Hier zijn enkele stappen die kunnen
                            helpen: <br />
                            <br />1. Probeer
                            de autoportieren te sluiten en vervolgens onmiddellijk weer te openen. Dit
                            kan soms de stekker vrijgeven.<br />2. Als het
                            gewicht van de laadkabel het loskoppelen bemoeilijkt, ondersteun dan de
                            stekker en ontgrendel de deuren met de sleutel. Voor Tesla-voertuigen kan
                            de stekker ook vanuit het menu in de auto worden losgekoppeld.<br />3. Op
                            sommige laadstations kunt u het laadstation resetten door uw laadpas
                            gedurende 30 seconden voor de lezer te houden. Dit zal uw laadkabel
                            automatisch vrijgeven.<br />
                            <br /> Indien deze oplossingen niet werken, raden we aan
                            contact op te nemen met de operator van het oplaadpunt. <br />
                            <br /><b>Wanneer
                                het Oplaadpunt van Shell ReCharge (voorheen NewMotion) Niet Reageert</b>
                            <br />
                            <br />Als het oplaadpunt van Shell ReCharge (voorheen
                            NewMotion) op geen enkele wijze reageert en er geen controlelampje brandt, kan
                            er mogelijk sprake zijn van een stroomstoring. In dat geval adviseren wij u
                            contact op te nemen met de beheerder van het laadstation voor verdere
                            assistentie.&nbsp;<br />
                            <br /><b>Start
                                de Shell ReCharge (voorheen NewMotion) Laadpaal Niet na het Aanbieden van Uw
                                Laadpas?</b>
                            <br />
                            <br />Indien uw laadsessie bij een Shell ReCharge (voorheen
                            NewMotion) laadpaal niet start na het aanbieden van uw laadpas, kunnen
                            verschillende factoren hiervoor verantwoordelijk zijn:&nbsp;<br />- Controleer
                            of uw laadpas is geactiveerd voor gebruik bij publieke laadsessies.
                            Sommige laadpassen moeten geactiveerd worden en dit kan tot 24 uur duren.<br />- Verifieer
                            of het specifieke laadpunt openbaar toegankelijk is. Niet alle laadpalen
                            zijn beschikbaar voor publiek gebruik. U kunt dit controleren door het
                            adres in te voeren in de Laadpaal App van Last Mile Solutions (Android /
                            iOS).<br />- Soms kan
                            de kaartlezer van het laadpunt een andere pas detecteren als u uw hele
                            portemonnee bij de kaartlezer houdt. Probeer opnieuw een laadsessie te
                            starten nadat u uw laadpas uit uw portemonnee heeft gehaald.<br />- Als uw
                            laadpas geblokkeerd lijkt te zijn, kunt u dit controleren bij de uitgever
                            van de pas.<br />
                            <br /><b>Contactgegevens
                                Shell ReCharge (voorheen NewMotion)</b>
                            <br />
                            <br />Indien u toch nog hulp nodig heeft, kunt u doorgaans
                            de contactgegevens van de operator vinden op het laadstation zelf. Als dat niet
                            het geval is, kunt u contact opnemen via de onderstaande gegevens: <br /> Website: <a href="https://newmotion.com/nl_NL/" target="_new" className="u-active-none u-border-none storingcpo-u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1">NewMotion Website</a>
                            <br />Telefoonnummer: 0880109500<br />
                            <br />Shell ReCharge (voorheen NewMotion) staat klaar om te
                            helpen en ervoor te zorgen dat uw elektrische voertuig blijft laden, zodat u
                            zorgeloos van uw rit kunt genieten.
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-align-center storingcpo-u-clearfix storingcpo-u-image storingcpo-u-shading storingcpo-u-section-4" src="" data-image-width="1072" data-image-height="678" id="sec-fee5">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <a href="/" className="storingcpo-u-active-black storingcpo-u-border-none storingcpo-u-btn storingcpo-u-btn-round storingcpo-u-button-style storingcpo-u-custom-color-2 storingcpo-u-custom-font storingcpo-u-hover-custom-color-1 storingcpo-u-radius-50 storingcpo-u-btn-1">{content.reportProblemButton}<br />
                        </a>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-5" id="sec-ba43">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpo-u-sheet-1">
                        <div className="fr-view storingcpo-u-align-left storingcpo-u-clearfix storingcpo-u-rich-text storingcpo-u-text storingcpo-u-text-1">
                            <p id="isPasted">
                                <span className="storingcpo-u-custom-font">Voor de meest actuele informatie en telefoonnummers voor storingsmeldingen, verwijzen we je naar de websites van de specifieke laadpaalbeheerders. Wij zorgen ervoor dat de informatie regelmatig wordt gecontroleert en bijgewerkt om onze bezoekers zo accuraat en nuttig mogelijk te informeren. <br />
                                </span>
                            </p>
                        </div>
                    </div>
                </section>

                <GetInContact />
                <HomePageFooter />
            </div >
        </div >
    )
}

export default ShellRechargeStoring;