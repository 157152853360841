import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { welcomeToCPContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import LanguagePicker from '../user_interaction/LanguagePicker';

function ModalWelcomeToCP(props) {

    const content = props.language === "EN" ? welcomeToCPContent.English :
        props.language === "NL" ? welcomeToCPContent.Dutch :
            welcomeToCPContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    const handleCheckboxChange = (event) => {
        setDoNotShowAgain(event.target.checked);
    };

    const handleCloseModal = () => {
        if (doNotShowAgain) {
            localStorage.setItem('doNotShowAgainWelcomeToCP', true);
        }
        props.setShow(false);
    };

    return (
        <Modal show={props.show} onHide={handleCloseModal} backdrop="static">
            <Modal.Body className="modalsBody" style={{ fontSize: '0.8rem' }}>
                <h3 className='text-center'>{content.title}</h3>
                <br />
                <h6 className='text-center'>{content.p1}</h6>
                <h6 className='text-center'>{content.p2}</h6>
                <br />
                <Row>
                    <Col xs={2}>
                        <span className="checkStatusIcon"></span>
                    </Col>
                    <Col className="d-flex align-items-center" xs={{ span: 9, offset: 1 }} md={{ span: 10, offset: 0 }}>
                        <b><h6>{content.checkStatus}</h6></b>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={2}>
                        <span className="searchChargerIcon"></span>
                    </Col>
                    <Col className="d-flex align-items-center" xs={{ span: 9, offset: 1 }} md={{ span: 10, offset: 0 }}>
                        <b><h6>{content.searchStation}</h6></b>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={2}>
                        <span className="reportProblemIcon"></span>
                    </Col>
                    <Col className="d-flex align-items-center" xs={{ span: 9, offset: 1 }} md={{ span: 10, offset: 0 }}>
                        <b><h6>{content.reportProblem}</h6></b>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={2}>
                        <span className="notificationIcon"></span>
                    </Col>
                    <Col className="d-flex align-items-center" xs={{ span: 9, offset: 1 }} md={{ span: 10, offset: 0 }}>
                        <b><h6>{content.notifications}</h6></b>
                    </Col>
                </Row>
                <br />
                <Row>
                    <div className="d-flex">
                        <div className="me-auto form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="doNotShowAgain"
                                checked={doNotShowAgain}
                                onChange={handleCheckboxChange}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="doNotShowAgain">
                                {buttonscontent.doNotShowAgain}
                            </label>
                        </div>
                        <div>
                            <LanguagePicker variant="light" />
                        </div>
                    </div>
                </Row>
                <br />
                <div className='d-flex'>
                    <div className='ms-auto'>
                        <Button className='chargeprob-button-green' onClick={handleCloseModal}>
                            {buttonscontent.checkChargeProb}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer className="chargeprob-background-darkgreen">
                <Button variant="success" size='sm' onClick={handleCloseModal}>
                    {buttonscontent.close}
                </Button>
            </Modal.Footer> */}
        </Modal>
    )
}

export default ModalWelcomeToCP;