import React, { useState, useEffect, useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import AuthContext from '../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function UserProfileNotification(props) {

    let { userProfile } = useContext(AuthContext);
    const buttonscontent = props.buttonscontent;
    const content = props.content;

    const [notificationChargerStatusChange, setNotificationChargerStatusChange] = useState(userProfile.email_consent_status_update);
    const [initialNotificationChargerStatusChange, setInitialNotificationChargerStatusChange] = useState(userProfile.email_consent_status_update);

    const [notificationCPOUpdate, setNotificationCPOUpdate] = useState(userProfile.email_consent_cpo_status_update);
    const [initialNotificationCPOUpdate, setInitialNotificationCPOUpdate] = useState(userProfile.email_consent_cpo_status_update);

    const [notificationReportOnFavCharger, setNotificationReportOnFavCharger] = useState(userProfile.email_consent_report_on_charger);
    const [initialNotificationReportOnFavCharger, setInitialNotificationReportOnFavCharger] = useState(userProfile.email_consent_report_on_charger);

    const [updating, setUpdating] = useState(false);
    const [updateEmailConsentError, setUpdateEmailConsentError] = useState("");

    const updateInitialConsent = () => {
        setInitialNotificationChargerStatusChange(notificationChargerStatusChange);
        setInitialNotificationCPOUpdate(notificationCPOUpdate);
        setInitialNotificationReportOnFavCharger(notificationReportOnFavCharger);
    }

    const resetInitialConsent = () => {
        setNotificationChargerStatusChange(initialNotificationChargerStatusChange);
        setNotificationCPOUpdate(initialNotificationCPOUpdate);
        setNotificationReportOnFavCharger(initialNotificationReportOnFavCharger);
    }

    const updateNotificationConsent = async () => {
        setUpdating(true);
        setUpdateEmailConsentError("");
        const resp = await props.updateNotificationConsent(
            {
                "email_consent_status_update": notificationChargerStatusChange,
                "email_consent_cpo_status_update": notificationCPOUpdate,
                "email_consent_report_on_charger": notificationReportOnFavCharger
            });

        if (resp === "OK") {
            updateInitialConsent();
            setUpdating(false);
        }
        else {
            setUpdateEmailConsentError(content.userProfileEmailConsentUpdateError);
            setUpdating(false);
        }
    }

    const abortUpdate = () => {
        resetInitialConsent();
        setUpdateEmailConsentError("");
        setUpdating(false);
    }

    return (
        <>
            <h6 className='chargeprob-green'><FontAwesomeIcon icon={faEnvelope} size="lg" /> {content.useProfileNotification}</h6>
            {userProfile && userProfile.email_confirmed ?
                <div>
                    {notificationChargerStatusChange ?
                        <>
                            <p style={{ marginBottom: "0.2em" }}>{content.userProfileEmailConsentChargerStatusUpdate}</p>
                            <ButtonGroup aria-label="Charger status change">
                                <Button size="sm" className='chargeprob-button-green'>
                                    On
                                </Button>
                                <Button size="sm" variant="outline-danger"
                                    onClick={() => setNotificationChargerStatusChange(false)}>
                                    Off
                                </Button>
                            </ButtonGroup></>
                        :
                        <>
                            <p style={{ marginBottom: "0.2em" }}>{content.userProfileEmailConsentChargerStatusUpdate}</p>
                            <ButtonGroup aria-label="Charger status change">
                                <Button size="sm" className='chargeprob-button-outline-green'
                                    onClick={() => setNotificationChargerStatusChange(true)}>
                                    On
                                </Button>
                                <Button size="sm" variant="danger">
                                    Off
                                </Button>
                            </ButtonGroup>
                        </>
                    }
                    <br /><br />
                    {notificationCPOUpdate ?
                        <>
                            <p style={{ marginBottom: "0.2em" }}>{content.userProfileEmailConsentCPOStatusUpdate}</p>
                            <ButtonGroup aria-label="Charger status change">
                                <Button size="sm" className='chargeprob-button-green'>
                                    On
                                </Button>
                                <Button size="sm" variant="outline-danger"
                                    onClick={() => setNotificationCPOUpdate(false)}>
                                    Off
                                </Button>
                            </ButtonGroup></>
                        :
                        <>
                            <p style={{ marginBottom: "0.2em" }}>{content.userProfileEmailConsentCPOStatusUpdate}</p>
                            <ButtonGroup aria-label="Charger status change">
                                <Button size="sm" className='chargeprob-button-outline-green'
                                    onClick={() => setNotificationCPOUpdate(true)}>
                                    On
                                </Button>
                                <Button size="sm" variant="danger">
                                    Off
                                </Button>
                            </ButtonGroup>
                        </>
                    }
                    <br /><br />
                    {notificationReportOnFavCharger ?
                        <>
                            <p style={{ marginBottom: "0.2em" }}>{content.userProfileEmailConsentReportOnCharger}</p>
                            <ButtonGroup aria-label="Charger status change">
                                <Button size="sm" className='chargeprob-button-green'>
                                    On
                                </Button>
                                <Button size="sm" variant="outline-danger"
                                    onClick={() => setNotificationReportOnFavCharger(false)}>
                                    Off
                                </Button>
                            </ButtonGroup></>
                        :
                        <>
                            <p style={{ marginBottom: "0.2em" }}>{content.userProfileEmailConsentReportOnCharger}</p>
                            <ButtonGroup aria-label="Charger status change">
                                <Button size="sm" className='chargeprob-button-outline-green'
                                    onClick={() => setNotificationReportOnFavCharger(true)}>
                                    On
                                </Button>
                                <Button size="sm" variant="danger">
                                    Off
                                </Button>
                            </ButtonGroup>
                        </>
                    }
                    {notificationChargerStatusChange !== initialNotificationChargerStatusChange ||
                        notificationCPOUpdate !== initialNotificationCPOUpdate ||
                        notificationReportOnFavCharger !== initialNotificationReportOnFavCharger ?
                        <Col xs={7} md={4} lg={4}>
                            <br />
                            {updating ?
                                <Button variant="warning" size="sm">
                                    {buttonscontent.updating}
                                </Button>
                                :
                                <Stack direction="horizontal" gap={2}>
                                    <Button
                                        variant="outline-warning" size="sm"
                                        onClick={() => updateNotificationConsent()}>
                                        {buttonscontent.update}
                                    </Button>
                                    <Button
                                        variant="outline-danger" size="sm"
                                        onClick={() => abortUpdate()}>
                                        {buttonscontent.close}
                                    </Button>
                                </Stack>
                            }
                        </Col>
                        : null}
                    <p className='red'> {updateEmailConsentError} </p>
                </div>
                :
                <>
                    <p>{content.useProfileNotificationNotActive_p1}</p>
                    <p>{content.useProfileNotificationNotActive_p2}</p>
                    <Button
                        variant="outline-warning"
                        size="sm"
                        className='offcanvasButtons'
                        onClick={props.goTosendNewActivationLinkModal}>
                        {buttonscontent.sendActivationLink}
                    </Button>
                </>
            }
        </>
    )
}

export default UserProfileNotification;