import React, { useState, useEffect, useContext } from 'react';
import '../stylesheets/nicepage_homepage.css';
import AuthContext from '../context/AuthContext';
import { homePageContent } from '../components/internationalization/Translations';
import LogoWhite from '../static/images/FINAL-ICONLOGO-ChargeProb-WHITE.png';
import ChargingStationAvailable from '../static/images/chargers/green-charger_64px.png';
import ChargingStationHalfAvailable from '../static/images/chargers/red-green-charger_64px.png';
import ChargingStationWarning from '../static/images/chargers/warning-charger_64px.png';
import ChargingStationUnavailable from '../static/images/chargers/red-charger_64px.png';
//import ChargingStationUnknown from '../static/images/chargers/unknown-charger_64px.png';
import OverOns from '../static/images/overons.jpg';
import ProactiveComm from '../static/images/proactivecomm.png';
import ReactiveComm from '../static/images/reactivecomm.png';
import Automation from '../static/images/autopower.png';
import CheckStatus from '../static/images/checkstatus.png';
import MapNavbar from '../components/MapNavbar';
import StatsCounter from './StatsCounter';
import GetInContact from './GetInContact';
import { Utils } from '../utils/utils';
import { API } from '../api-service';
import HomePageFooter from './HomePageFooter';

function CPOHomePage() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? homePageContent.English :
        language === "NL" ? homePageContent.Dutch :
            homePageContent.English;

    const [contactName, setContactName] = useState('');
    const [contactCompany, setContactCompany] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');

    const [contactEmailError, setContactEmailError] = useState('');
    const [sendContactDetailsSuccess, setSendContactDetailsSuccess] = useState('');
    const [sendContactDetailsError, setSendContactDetailsError] = useState('');

    const checkContactDetails = async () => {
        setContactEmailError('');
        setSendContactDetailsError('');

        if (!Utils.emailValidation(contactEmail)) {
            setContactEmailError(content.contactEmailError);
        }
        else {
            const resp = await API.submitContactDetails({
                'name': contactName,
                'company': contactCompany,
                'email': contactEmail,
                'phone': contactPhone
            });
            if (resp.status === 200) {
                setSendContactDetailsSuccess(content.sendContactDetailsSuccess);
                setContactName('');
                setContactCompany('');
                setContactEmail('');
                setContactPhone('');
            }
            else {
                setSendContactDetailsError(content.sendContactDetailsError);
                setContactName('');
                setContactCompany('');
                setContactEmail('');
                setContactPhone('');
            }
        }
    }

    const resetState = () => {
        setContactName('');
        setContactCompany('');
        setContactEmail('');
        setContactPhone('');
        setContactEmailError('');
        setSendContactDetailsSuccess('');
        setSendContactDetailsError('');
    }

    useEffect(() => {
        resetState();
    }, []);

    return (
        <div className='homepagediv'>
            <MapNavbar page="homepage" />
            <div className='u-body u-xl-mode' data-lang="en">
                <section className="u-align-right u-clearfix u-image u-shading u-section-12" id="sec-409e" data-image-width="6000" data-image-height="4000">
                    <h1 className="u-align-left u-custom-font u-text u-text-1">{content.cpo_title_1}<br />{content.cpo_title_2}
                    </h1>
                    <img className="u-align-center u-image u-image-contain u-image-default u-image-1" src={LogoWhite} alt="" data-image-width="1258" data-image-height="1269" />
                </section>
                <StatsCounter />
                <section className="u-clearfix u-section-13" id="sec-6791">
                    <div className="u-clearfix u-sheet u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-sheet-1">
                        <h3 className="u-align-center u-custom-font u-text u-text-1">{content.cpo_digitalizeproccess}</h3>
                        <h3 className="u-align-center u-custom-font u-text u-text-2"> {content.cpo_digitalizeproccess_text_1} <span className="u-text-custom-color-2">ChargeProb</span> {content.cpo_digitalizeproccess_text_2}
                        </h3>
                        <div className="u-expanded-width u-list u-list-1">
                            <div className="u-repeater u-repeater-1">
                                <div className="u-container-style u-grey-5 u-list-item u-radius-20 u-repeater-item u-shape-round">
                                    <div className="u-container-layout u-similar-container u-container-layout-1">
                                        <h3 className="u-align-center u-custom-font u-text u-text-default u-text-3">{content.cpo_proactive}</h3>
                                        <img alt="" className="u-align-center u-image u-image-default u-image-1" data-image-width="490" data-image-height="562" src={ProactiveComm} />
                                        <p className="u-align-center u-custom-font u-text u-text-body-color u-text-4">{content.cpo_proactive_text}<br /></p>
                                    </div>
                                </div>
                                <div className="u-container-style u-grey-5 u-list-item u-radius-20 u-repeater-item u-shape-round">
                                    <div className="u-container-layout u-similar-container u-container-layout-2">
                                        <h3 className="u-align-center u-custom-font u-text u-text-default u-text-5">{content.cpo_reactive}</h3>
                                        <img alt="" className="u-align-center u-image u-image-default u-image-2" data-image-width="499" data-image-height="619" src={ReactiveComm} />
                                        <p className="u-align-center u-custom-font u-text u-text-body-color u-text-6"> {content.cpo_reactive_text}<br /><br /></p>
                                    </div>
                                </div>
                                <div className="u-container-style u-grey-5 u-list-item u-radius-20 u-repeater-item u-shape-round">
                                    <div className="u-container-layout u-similar-container u-container-layout-3">
                                        <h3 className="u-align-center u-custom-font u-text u-text-default u-text-7">{content.cpo_automation}</h3>
                                        <img alt="" className="u-align-center u-image u-image-default u-image-3" data-image-width="543" data-image-height="664" src={Automation} />
                                        <p className="u-align-center u-custom-font u-text u-text-body-color u-text-8"> {content.cpo_automation_text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-align-left u-clearfix u-image u-shading u-section-14" data-image-width="708" data-image-height="456" id="sec-dc16">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <h1 className="u-custom-font u-text u-text-default u-title u-text-1">{content.cpo_central_control}</h1>
                        <p className="u-custom-font u-large-text u-text u-text-variant u-text-2">{content.cpo_central_control_text}<br /></p>
                    </div>
                </section>
                <section className="u-align-center u-clearfix u-grey-5 u-section-15" id="sec-7e7f">
                    <div className="u-clearfix u-gutter-0 u-layout-wrap u-layout-wrap-1">
                        <div className="u-layout">
                            <div className="u-layout-row">
                                <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-24-lg u-size-24-xl u-size-60-md u-size-60-sm u-size-60-xs u-white u-layout-cell-1">
                                    <div className="u-container-layout u-container-layout-1">
                                        <h2 className="u-align-center u-text u-text-1">{content.cpo_demo}?</h2>
                                        <p className="u-align-center u-custom-font u-large-text u-text u-text-variant u-text-2">{content.cpo_demo_text_1} <span className="u-text-custom-color-2">ChargeProb </span>{content.cpo_demo_text_2}</p>
                                        <div className="u-expanded-width u-form u-form-1">
                                            <div className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form" style={{ padding: "0px" }} source="email" name="form">
                                                <div className="u-form-group u-form-name">
                                                    <input type="text" placeholder={content.cpo_placeholder_name} id="name-f0d0" name="Naam"
                                                        className="u-border-2 u-border-custom-color-2 u-input u-input-rectangle u-radius-7 u-text-black u-white u-input-1"
                                                        required={true}
                                                        onChange={evt => setContactName(evt.target.value)} />
                                                </div>
                                                <div className="u-form-group u-form-name u-form-group-2">
                                                    <input type="text" placeholder={content.cpo_placeholder_company} id="name-3c4e" name="name-1"
                                                        className="u-border-2 u-border-custom-color-2 u-input u-input-rectangle u-radius-7 u-text-black u-white u-input-2"
                                                        required=""
                                                        onChange={evt => setContactCompany(evt.target.value)} />
                                                </div>
                                                <div className="u-form-email u-form-group">
                                                    <input type="email" placeholder={content.cpo_placeholder_email} id="email-f0d0" name="email"
                                                        className="u-border-2 u-border-custom-color-2 u-input u-input-rectangle u-radius-7 u-text-black u-white u-input-3"
                                                        required={true}
                                                        onChange={evt => setContactEmail(evt.target.value)} />
                                                </div>
                                                <div className="u-form-group u-form-phone u-form-group-4">
                                                    <input type="tel" pattern="\+?\d{0,3}[\s\(\-]?([0-9]{2,3})[\s\)\-]?([\s\-]?)([0-9]{3})[\s\-]?([0-9]{2})[\s\-]?([0-9]{2})"
                                                        placeholder={content.cpo_placeholder_phone} id="phone-fbbe" name="phone"
                                                        className="u-border-2 u-border-custom-color-2 u-input u-input-rectangle u-radius-7 u-text-black u-white u-input-4"
                                                        required=""
                                                        onChange={evt => setContactPhone(evt.target.value)} />
                                                </div>
                                                <div className="u-align-left u-form-group u-form-submit">
                                                    {/* <a href="#" className="u-active-custom-color-1 u-border-none u-btn u-btn-round u-btn-submit u-button-style u-custom-color-2 u-hover-custom-color-1 u-radius-7 u-btn-1">Submit</a> */}
                                                    <button className="u-active-custom-color-1 u-border-none u-btn u-btn-round u-btn-submit u-button-style u-custom-color-2 u-hover-custom-color-1 u-radius-7 u-btn-1"
                                                        onClick={() => checkContactDetails()}>
                                                        {content.submit}
                                                    </button>
                                                </div>
                                                <p className='red' style={{marginLeft: "20px"}}>{contactEmailError}</p>
                                                <p className='red' style={{marginLeft: "20px"}}>{sendContactDetailsError}</p>
                                                <p className='chargeprob-green' style={{marginLeft: "20px"}}>{sendContactDetailsSuccess}</p>
                                                {/* <div className="u-form-send-message u-form-send-success"> Thank you! Your message has been sent. </div>
                                                <div className="u-form-send-error u-form-send-message"> Unable to send your message. Please fix errors then try again. </div>
                                                <input type="hidden" value="" name="recaptchaResponse" />
                                                <input type="hidden" name="formServices" value="2e80465ea46e15463f4983ab149e9230" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-36-lg u-size-36-xl u-size-60-md u-size-60-sm u-size-60-xs u-layout-cell-2">
                                    <div className="u-container-layout u-valign-middle u-container-layout-2">
                                        <img className="u-image u-image-1" src={CheckStatus} data-image-width="1946" data-image-height="2238" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-align-center u-clearfix u-container-align-center-sm u-container-align-center-xs u-section-16" id="sec-ae6e">
                    <div className="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-sheet-1">
                        <div className="u-clearfix u-expanded-width-lg u-expanded-width-md u-expanded-width-xl u-gutter-0 u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout" >
                                <div className="u-layout-row" >
                                    <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-30 u-size-xs-60 u-image-1" data-image-width="1536" data-image-height="2048">
                                        <div className="u-container-layout u-container-layout-1" ></div>
                                    </div>
                                    <div className="u-align-left u-container-style u-custom-color-2 u-layout-cell u-right-cell u-size-30 u-size-xs-60 u-layout-cell-2">
                                        <div className="u-container-layout u-container-layout-2">
                                            <img className="u-align-center-sm u-align-center-xs u-align-left-lg u-align-left-md u-align-left-xl u-image u-image-contain u-image-default u-image-2" src={LogoWhite} alt="" data-image-width="1258" data-image-height="1269" />
                                            <h2 className="u-align-center-sm u-align-center-xs u-custom-font u-text u-text-default u-text-1">Future of Charging</h2>
                                            <p className="u-align-center-sm u-align-center-xs u-custom-font u-text u-text-2">{content.cpo_foc_text}</p>
                                            <a href="https://futureofcharging.com/wp-content/uploads/2022/12/Thema-data.pdf" className="u-align-center-sm u-align-center-xs u-border-2 u-border-white u-btn u-btn-rectangle u-button-style u-none u-btn-1" target="_blank">{content.learnMore}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-align-center-lg u-align-center-md u-align-center-xl u-align-left-sm u-align-left-xs u-clearfix u-container-align-center u-grey-5 u-section-5" id="sec-32b3">
                    <div className="u-clearfix u-sheet u-valign-middle-xs u-sheet-1">
                        <h3 className="u-align-center u-custom-font u-text u-text-1">{content.chargerPossibileStatuses}</h3>
                        <div className="u-expanded-width u-layout-grid u-list u-list-1">
                            <div className="u-repeater u-repeater-1">
                                <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-1">
                                    <div className="u-container-layout u-similar-container u-container-layout-1">
                                        <h5 className="u-align-center u-custom-font u-text u-text-2">{content.chargerPossibileStatuses_available}</h5>
                                        <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-1" src={ChargingStationAvailable} alt="" data-image-width="24" data-image-height="24" />
                                        <p className="u-align-center u-custom-font u-text u-text-body-color u-text-3"> {content.chargerPossibileStatuses_available_text}</p>
                                    </div>
                                </div>
                                <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-1">
                                    <div className="u-container-layout u-similar-container u-container-layout-1">
                                        <h5 className="u-align-center u-custom-font u-text u-text-2">{content.chargerPossibileStatuses_halfavailable}</h5>
                                        <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-1" src={ChargingStationHalfAvailable} alt="" data-image-width="24" data-image-height="24" />
                                        <p className="u-align-center u-custom-font u-text u-text-body-color u-text-3"> {content.chargerPossibileStatuses_halfavailable_text}</p>
                                    </div>
                                </div>
                                <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-2">
                                    <div className="u-container-layout u-similar-container u-container-layout-2">
                                        <h5 className="u-align-center u-custom-font u-text u-text-4">{content.chargerPossibileStatuses_warning}</h5>
                                        <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-2" src={ChargingStationWarning} alt="" data-image-width="150" data-image-height="150" />
                                        <p className="u-align-center u-custom-font u-text u-text-body-color u-text-5"> {content.chargerPossibileStatuses_warning_text}</p>
                                    </div>
                                </div>
                                <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-3">
                                    <div className="u-container-layout u-similar-container u-container-layout-3">
                                        <h5 className="u-align-center u-custom-font u-text u-text-6">{content.chargerPossibileStatuses_unavailable}</h5>
                                        <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-3" src={ChargingStationUnavailable} alt="" data-image-width="150" data-image-height="150" />
                                        <p className="u-align-center u-custom-font u-text u-text-body-color u-text-7">{content.chargerPossibileStatuses_unavailable_text}</p>
                                    </div>
                                </div>
                                {/* <div className="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-4">
                                    <div className="u-container-layout u-similar-container u-container-layout-4">
                                        <h5 className="u-align-center u-custom-font u-text u-text-8">{content.chargerPossibileStatuses_unknown}</h5>
                                        <img className="u-align-center u-image u-image-default u-preserve-proportions u-image-4" src={ChargingStationUnknown} alt="" data-image-width="150" data-image-height="150" />
                                        <p className="u-align-center u-custom-font u-text u-text-body-color u-text-9">{content.chargerPossibileStatuses_unknown_text}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-clearfix u-section-8" id="sec-e1ca">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-border-3 u-border-custom-color-2 u-hidden-xs u-shape u-shape-svg u-text-custom-color-1 u-shape-1">
                            <svg className="u-svg-link" preserveAspectRatio="none" viewBox="0 0 160 160" ><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-db78"></use></svg>
                            <svg className="u-svg-content" viewBox="-1.5 -1.5 163 163" x="0px" y="0px" id="svg-db78"><path d="M114.3,152.3l38-38C144.4,130.9,130.9,144.4,114.3,152.3z M117.1,9.1l-108,108c0.8,1.6,1.7,3.2,2.7,4.8l110-110
	C120.3,10.9,118.7,10,117.1,9.1z M97.5,2L2,97.5c0.4,2,1,4,1.5,5.9l99.9-99.9C101.5,2.9,99.5,2.4,97.5,2z M80,160c2,0,4-0.1,5.9-0.2
	l73.9-73.9c0.1-2,0.2-3.9,0.2-5.9c0-0.6,0-1.2,0-1.9L78.1,160C78.8,160,79.4,160,80,160z M34.9,146.1c1.5,1,3,2,4.6,2.9L149,39.5
	c-0.9-1.6-1.9-3.1-2.9-4.6L34.9,146.1z M132.7,19.8L19.8,132.7c1.2,1.3,2.3,2.6,3.6,3.9L136.6,23.4C135.3,22.2,134,21,132.7,19.8z
	 M59.6,157.4l97.8-97.8c-0.5-1.9-1.1-3.8-1.7-5.7L53.9,155.6C55.8,156.3,57.7,156.9,59.6,157.4z M7.6,45.9L45.9,7.6
	C29.1,15.5,15.5,29.1,7.6,45.9z M80,0c-2.6,0-5.1,0.1-7.6,0.4l-72,72C0.1,74.9,0,77.4,0,80c0,0.1,0,0.2,0,0.2L80.2,0
	C80.2,0,80.1,0,80,0z"></path></svg>
                        </div>
                        <div className="u-custom-color-2 u-shape u-shape-circle u-shape-2"></div>
                        <img className="u-image u-image-round u-radius-20 u-image-1" src={OverOns} data-image-width="1280" data-image-height="853" />
                        <div className="u-align-left u-container-style u-group u-radius-20 u-shape-round u-white u-group-1 u-grey-5">
                            <div className="u-container-layout u-container-layout-1">
                                <h3 className="u-align-center u-custom-font u-text u-text-1">{content.aboutUs}</h3>
                                <p className="u-align-center u-custom-font u-text u-text-body-color u-text-2">{content.aboutUs_text}</p>
                                <a href="#" className="u-active-black u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-hover-black u-radius-50 u-btn-1">{content.readMore}</a>
                            </div>
                        </div>
                    </div>
                </section>
                <GetInContact />
                <HomePageFooter />
            </div>
        </div>
    )
}

export default CPOHomePage;