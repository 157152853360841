import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { loginContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';

function ModalUserForgotPassword(props) {

    const content = props.language === "EN" ? loginContent.English :
        props.language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static">
            <Modal.Header className="modalsBody">
                <Modal.Title className='w-100 text-center'>{content.forgotPasswordTitle}</Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className="modalsBody">
                <Form>
                    <Form.Group className="mb-3" controlId="userlogin.ControlInputEmail" >
                        <Form.Label>
                            {content.forgotPasswordInstructions}
                        </Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="name@example.com"
                            autoFocus
                            onChange={evt => props.setUserEmail(evt.target.value)}
                        />
                        <p className='red'>{props.userEmailError}</p>
                    </Form.Group>
                </Form>
                <Row>
                    <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                        <Stack direction='horizontal' gap={2}>
                            <Button variant="secondary" onClick={() => props.goToLoginView()}>
                                {buttonscontent.back}
                            </Button>
                            <Button variant="success" onClick={() => props.checkRestorePasswordCredentials()}>
                                {buttonscontent.recoverPassword}
                            </Button>
                        </Stack>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalUserForgotPassword;
