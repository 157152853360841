import React, { useEffect, useState } from 'react';
import ModalUserLogin from './ModalUserLogin';
import ModalUserRegister from './ModalUserRegister';
import ModalUserForgotPassword from './ModalUserForgotPassword';
import { Utils } from '../../utils/utils';
import { loginContent } from '../internationalization/Translations';
import { API } from '../../api-service';
import ModalResetPasswordEmailSent from './ModalResetPasswordEmailSent';

function ModalUserLoginOrRegister(props) {

    const content = props.language === "EN" ? loginContent.English :
        props.language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const [onLoginView, setOnLoginView] = useState(true);
    const [onSignupView, setOnSignupView] = useState(false);
    const [onForgotPasswordView, setOnForgotPasswordView] = useState(false);
    const [onForgotPasswordEmailSentView, setOnForgotPasswordEmailSentView] = useState(false);
    const [userEmailError, setUserEmailError] = useState('');
    const [userPhoneNumberError, setUserPhoneNumberError] = useState('');
    const [userPasswordError, setUserPasswordError] = useState('');
    const [userRepeatPasswordError, setUserRepeatPasswordError] = useState('');

    const emailError = content.emailError;

    useEffect(() => {
        resetErrors();
        resetState();
    }, [props.show])

    const checkLoginCredentials = () => {
        setUserEmailError('');
        if (Utils.emailValidation(props.userEmail)) {
            props.loginUser();
        }
        else {
            setUserEmailError(emailError);
        }
    }

    const checkSignupCredentials = () => {
        setUserEmailError('');
        setUserPhoneNumberError('');
        setUserPasswordError('');
        setUserRepeatPasswordError('');
        props.setUserRegisterError('');
        const pwd_validation = Utils.passwordValidation(props.userPassword, content);
        const repeat_pwd_validation = Utils.repeatPasswordValidation(props.userPassword, props.userRepeatPassword);

        if (!Utils.emailValidation(props.userEmail)) {
            setUserEmailError(emailError);
        }
        else if (pwd_validation !== "") {
            setUserPasswordError(pwd_validation);
        }
        else if (!repeat_pwd_validation) {
            setUserRepeatPasswordError(repeat_pwd_validation);
        }
        else {
            props.registerUser();
        }
    }

    const checkRestorePasswordCredentials = async() => {
        setUserEmailError('');
        setUserPhoneNumberError('');
        setUserPasswordError('');
        setUserRepeatPasswordError('');
        props.setUserRegisterError('');

        if (!Utils.emailValidation(props.userEmail)) {
            setUserEmailError(emailError);
        }
        else {
            const restore_pwd_resp = await API.sendRestorePasswordEmail({ 'email': props.userEmail })
            if (restore_pwd_resp.status === 200) {
                goToResetPasswordEmailSentModal();
            }
            else {
                setUserEmailError(emailError);
            }
        }
    }

    const resetErrors = () => {
        setUserEmailError('');
        setUserPhoneNumberError('');
        setUserPasswordError('');
        setUserRepeatPasswordError('');
    }

    const resetState = () => {
        setOnLoginView(false);
        setOnForgotPasswordView(false);
        setOnForgotPasswordEmailSentView(false);
        setOnSignupView(false);
    }

    const goToSignupView = () => {
        resetErrors();
        setOnLoginView(false);
        setOnForgotPasswordView(false);
        setOnForgotPasswordEmailSentView(false);
        setOnSignupView(true);
    }

    const goToLoginView = () => {
        resetErrors();
        setOnSignupView(false);
        setOnForgotPasswordView(false);
        setOnForgotPasswordEmailSentView(false);
        setOnLoginView(true);
    }

    const goToForgotPasswordView = () => {
        resetErrors();
        setOnSignupView(false);
        setOnLoginView(false);
        setOnForgotPasswordView(true);
        setOnForgotPasswordEmailSentView(false);
    }

    const goToResetPasswordEmailSentModal = () => {
        resetErrors();
        setOnSignupView(false);
        setOnLoginView(false);
        setOnForgotPasswordView(false);
        setOnForgotPasswordEmailSentView(true);
    }

    return (
        <>
            {onSignupView ?
                <ModalUserRegister
                    language={props.language}
                    show={props.show}
                    handleClose={props.handleClose}
                    userRegisterError={props.userRegisterError}
                    setUserEmail={props.setUserEmail}
                    userEmailError={userEmailError}
                    userPasswordError={userPasswordError}
                    setUserPhoneNumber={props.setUserPhoneNumber}
                    userPhoneNumberError={userPhoneNumberError}
                    setUserPassword={props.setUserPassword}
                    setUserRepeatPassword={props.setUserRepeatPassword}
                    userRepeatPasswordError={userRepeatPasswordError}
                    checkSignupCredentials={checkSignupCredentials}
                    goToLoginView={goToLoginView} />
                : onForgotPasswordView ?
                    <ModalUserForgotPassword
                        language={props.language}
                        show={props.show}
                        handleClose={props.handleClose}
                        setUserEmail={props.setUserEmail}
                        userEmailError={userEmailError}
                        goToLoginView={goToLoginView}
                        checkRestorePasswordCredentials={checkRestorePasswordCredentials} />
                    : onForgotPasswordEmailSentView ?
                        <ModalResetPasswordEmailSent
                            language={props.language}
                            show={props.show}
                            handleClose={props.handleClose}
                        />
                        :
                        <ModalUserLogin
                            language={props.language}
                            parent={props.parent}
                            show={props.show}
                            handleClose={props.handleClose}
                            userLoginError={props.userLoginError}
                            setUserEmail={props.setUserEmail}
                            userEmailError={userEmailError}
                            setUserPassword={props.setUserPassword}
                            checkLoginCredentials={checkLoginCredentials}
                            //continueWithoutLogin={props.continueWithoutLogin}
                            loginWithGoogleToken={props.loginWithGoogleToken}
                            goToSignupView={goToSignupView}
                            goToForgotPasswordView={goToForgotPasswordView}
                            markerInfo={props.markerInfo}
                            incidentType={props.incidentType}
                            incidentTypeTranslation={props.incidentTypeTranslation}
                            incidentExtraInfo={props.incidentExtraInfo}
                            sendIncidentLoading={props.sendIncidentLoading}
                            postNewIncident={props.postNewIncident} />
            }
        </>
    )
}

export default ModalUserLoginOrRegister;

/*
<Button variant="secondary" onClick={props.handleClose}>
    Close
</Button>
*/
