import Alert from 'react-bootstrap/Alert';
import { userLocationContent } from '../internationalization/Translations';

function NoLocationPermissionsAlert(props) {

    const content = props.language === "EN" ? userLocationContent.English :
                    props.language === "NL" ? userLocationContent.Dutch :
                    userLocationContent.English

    if(props.show){
        return (
            props.userLocationError === "No permission" ? 
            <Alert variant="light" className='noLocationPermissionsAlert noLocationPermissionsAlertResp'
                onClose={() => props.setShow(false)} dismissible>
                <Alert.Heading>{content.alertLocationDisabledTitle}</Alert.Heading>
                <p>{content.alertLocationDisabled_p1}</p>
                <p className="mb-0">{content.alertLocationDisabled_p2}</p>
            </Alert>
            : props.userLocationError === "Can't get location" ?
            <Alert variant="light" className='noLocationPermissionsAlert noLocationPermissionsAlertResp'
                onClose={() => props.setShow(false)} dismissible>
                <Alert.Heading>Location is disabled</Alert.Heading>
                <p>{content.alertLocationUnknown_p1}</p>
                <p className="mb-0">{content.alertLocationUnknown_p2}</p>
            </Alert>
            : null
    ) }
    else {return null } 
}

export default NoLocationPermissionsAlert;