import React, { useContext } from 'react';
import '../../stylesheets/storing_cpo.css';
import AuthContext from '../../context/AuthContext';
import { storingCPOPageContent } from '../../components/internationalization/Translations';
import MapNavbar from '../../components/MapNavbar';
import HomePageFooter from '../HomePageFooter';
import GetInContact from '../GetInContact';
import HeaderStoring from './HeaderStoring';

function EvnetNLStoring() {

    let { language } = useContext(AuthContext);
    const content = language === "EN" ? storingCPOPageContent.English :
        language === "NL" ? storingCPOPageContent.Dutch :
            storingCPOPageContent.English;

    return (
        <div className='homepagediv'>
            <MapNavbar page="storingcpo" />
            <div className='storingcpo-u-body storingcpo-u-xl-mode' data-lang="en">
                <HeaderStoring />
                <section className="storingcpo-u-clearfix storingcpo-u-section-2" id="sec-6d24">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <h3 className="storingcpo-u-text storingcpo-u-text-1"><b>EVnetNL</b></h3>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpo}</p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoWebsite}: <a href="https://www.evbox.nl/" target="_new" className="storingcpo-u-active-none storingcpo-u-border-none storingcpo-u-btn storingcpo-u-button-style storingcpo-u-hover-none storingcpo-u-none storingcpo-u-text-palette-1-base storingcpo-u-btn-1">EVnetNL Website</a></p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoPhonenumber}: 08003522365</p>
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-2">{content.infoCpoStoringWebsite}: nvt</p>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-3" id="sec-5429">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <p className="storingcpo-u-custom-font storingcpo-u-text storingcpo-u-text-default storingcpo-u-text-1">
                            <b>"Over EVnetNL: Eenvoudige Oplossingen voor
                                Laadpaalproblemen"</b>
                            <br />
                            <br />Het laadnetwerk van EVnetNL is nu onderdeel geworden
                            van ParknCharge. Bij het gebruik van een EVnetNL laadpaal kan het soms
                            voorkomen dat de laadkabel na een laadsessie niet op de gebruikelijke manier
                            losgekoppeld kan worden. In zulke gevallen kunnen enkele stappen helpen: <br />
                            <br />1. Probeer
                            de autoportieren te sluiten en vervolgens direct weer te openen. Dit kan
                            soms de stekker vrijgeven.<br /> 2. Als het
                            gewicht van de laadkabel het loskoppelen bemoeilijkt, ondersteun dan de
                            stekker en ontgrendel de deuren met de sleutel. Voor Tesla-voertuigen kan
                            de stekker ook vanuit het menu in de auto losgekoppeld worden.<br />3. Op
                            sommige laadstations is het mogelijk om het laadstation te resetten door
                            uw laadpas gedurende 30 seconden voor de lezer te houden. Dit zal uw
                            laadkabel automatisch vrijgeven.<br />
                            <br />Indien deze oplossingen niet werken, raden we aan
                            contact op te nemen met de operator van het oplaadpunt.<br />
                            <br />
                            <br /><b>Wanneer
                                het Oplaadpunt van EVnetNL Niet Reageert</b>
                            <br />
                            <br />Als het oplaadpunt van EVnetNL in geen enkele vorm
                            reageert en er geen controlelampje brandt, kan er mogelijk een stroomstoring
                            zijn. In dat geval adviseren wij u contact op te nemen met de beheerder van het
                            laadstation voor verdere assistentie.<br />
                            <br />
                            <br /><b>Start
                                de EVnetNL Laadpaal Niet Na het Aanbieden van Uw Laadpas?</b>
                            <br />
                            <br />Als uw laadsessie bij een EVnetNL laadpaal niet start
                            nadat u uw laadpas heeft aangeboden, kunnen meerdere factoren hieraan ten
                            grondslag liggen: <br />
                            <br />- Controleer
                            of uw laadpas is geactiveerd voor gebruik bij publieke laadsessies.
                            Sommige laadpassen moeten geactiveerd worden en dit kan tot 24 uur duren.<br /> - Verifieer
                            of het betreffende laadpunt openbaar toegankelijk is. Niet alle laadpalen
                            zijn beschikbaar voor publiek gebruik. U kunt dit controleren door het
                            adres in te voeren in de Laadpaal App van Last Mile Solutions (Android /
                            iOS).<br /> - Het kan
                            voorkomen dat de kaartlezer van het laadpunt een andere pas detecteert als
                            u uw hele portemonnee bij de kaartlezer houdt. Probeer opnieuw een
                            laadsessie te starten nadat u uw laadpas uit uw portemonnee heeft gehaald.<br /> - Als uw
                            laadpas geblokkeerd lijkt te zijn, kunt u dit controleren bij de uitgever
                            van de pas.<br />
                            <br /><b>Contactgegevens
                                voor Hulp bij EVnetNL</b>
                            <br />
                            <br />Indien u nog steeds hulp nodig heeft, kunt u doorgaans
                            de contactgegevens van de operator vinden op het laadstation zelf. Als dat niet
                            het geval is, kunt u contact opnemen via de onderstaande gegevens:&nbsp;<br />
                            <br /> Website: <a href="https://www.evbox.nl/" target="_new" className="u-active-none u-border-none storingcpo-u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1"> EVnet Website</a>
                            <br />Telefoonnummer: 08003522365<br />
                            <br />&nbsp;EVnetNL staat klaar om te helpen en ervoor te zorgen
                            dat uw elektrische voertuig blijft laden, zodat u zorgeloos van uw rit kunt
                            genieten.
                        </p>
                    </div>
                </section>
                <section className="storingcpo-u-align-center storingcpo-u-clearfix storingcpo-u-image storingcpo-u-shading storingcpo-u-section-4" src="" data-image-width="1072" data-image-height="678" id="sec-fee5">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-sheet-1">
                        <a href="/" className="storingcpo-u-active-black storingcpo-u-border-none storingcpo-u-btn storingcpo-u-btn-round storingcpo-u-button-style storingcpo-u-custom-color-2 storingcpo-u-custom-font storingcpo-u-hover-custom-color-1 storingcpo-u-radius-50 storingcpo-u-btn-1">{content.reportProblemButton}<br />
                        </a>
                    </div>
                </section>
                <section className="storingcpo-u-clearfix storingcpo-u-section-5" id="sec-ba43">
                    <div className="storingcpo-u-clearfix storingcpo-u-sheet storingcpo-u-valign-middle storingcpo-u-sheet-1">
                        <div className="fr-view storingcpo-u-align-left storingcpo-u-clearfix storingcpo-u-rich-text storingcpo-u-text storingcpo-u-text-1">
                            <p id="isPasted">
                                <span className="storingcpo-u-custom-font">Voor de meest actuele informatie en telefoonnummers voor storingsmeldingen, verwijzen we je naar de websites van de specifieke laadpaalbeheerders. Wij zorgen ervoor dat de informatie regelmatig wordt gecontroleert en bijgewerkt om onze bezoekers zo accuraat en nuttig mogelijk te informeren. <br />
                                </span>
                            </p>
                        </div>
                    </div>
                </section>

                <GetInContact />
                <HomePageFooter />
            </div >
        </div >
    )
}

export default EvnetNLStoring;