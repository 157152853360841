import { Marker } from 'react-leaflet';
import L from 'leaflet';

function UserLocationMarker(props) {
    return(
      <Marker 
          /* position={[props.userLatLng.lat, props.userLatLng.lng]}  */
          position={props.userLatLng} 
          icon={L.icon({iconUrl: require('../../static/Icons/userlocation_24px.png')})} >
      </Marker>
    )
  }

export default UserLocationMarker;