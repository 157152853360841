import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import UserReport from './UserReport';
import { offcanvasContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';
import ModalReportImage from '../modals/ModalReportImage';
import ModalUserReportConfirmSolved from '../modals/ModalUserReportConfirmSolved';
import ModalUserReportFeedbackSent from '../modals/ModalUserReportFeedbackSent';
import { Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function UserReportsOffcanvas(props) {

    const content = props.language === "EN" ? offcanvasContent.English :
        props.language === "NL" ? offcanvasContent.Dutch :
            offcanvasContent.English;

    const [showModalReportImage, setShowModalReportImage] = useState(false);
    const [showModalConfirmReportIsSolved, setShowModalConfirmReportIsSolved] = useState(false);
    const [showModalReportFeedbackSent, setShowModalReportFeedbackSent] = useState(false);

    const [sendingReportFeedback, setSendingReportFeedback] = useState(false);
    const [feedbackResult, setFeedbackResult] = useState(null);

    const [selectedReport, setSelectedReport] = useState(null);

    const [showOpenReports, setShowOpenReports] = useState(true);
    const [showClosedReports, setShowClosedReports] = useState(false);

    const openModalReportImage = (report) => {
        setSelectedReport(report);
        setShowModalReportImage(true);
    }

    const closeModalReportImage = () => {
        setShowModalReportImage(false);
        setSelectedReport(null);
    }

    const openModalConfirmReportIsSolved = (report) => {
        setSelectedReport(report);
        setShowModalConfirmReportIsSolved(true);
    }

    const closeModalConfirmReportIsSolved = () => {
        setShowModalConfirmReportIsSolved(false);
        setSelectedReport(null);
    }

    const openModalReportFeedbackSent = (report) => {
        setShowModalReportFeedbackSent(true);
    }

    const closeModalReportFeedbackSent = () => {
        setShowModalReportFeedbackSent(false);
    }

    const confirmReportIsSolved = async (report) => {
        setSendingReportFeedback(true);
        const resp = await props.confirmReportIsSolvedOrNot(report, "solved");
        if (resp === "ok") {
            setFeedbackResult("solved");
        }
        else {
            setFeedbackResult("error");
        }
        closeModalConfirmReportIsSolved();
        setSendingReportFeedback(false);
        openModalReportFeedbackSent();
    }

    const confirmReportIsNotSolved = async(report) => {
        setSendingReportFeedback(true);
        const resp = await props.confirmReportIsSolvedOrNot(report, "not solved");
        if (resp === "ok") {
            setFeedbackResult("not solved");
        }
        else {
            setFeedbackResult("error");
        }
        closeModalConfirmReportIsSolved();
        setSendingReportFeedback(false);
        openModalReportFeedbackSent();
    }

    const toggleOpenReports = () => {
        setShowOpenReports(prevShow => !prevShow);
    }

    const toggleClosedReports = () => {
        setShowClosedReports(prevShow => !prevShow);
    }

    // TODO: Use useMemo here?
    const openReports = props.userReports ? props.userReports.filter(report => report.report_status !== "CLOSE") : null;
    const closedReports = props.userReports ? props.userReports.filter(report => report.report_status === "CLOSE") : null;

    return (
        <>
            <Offcanvas show={props.show} onHide={props.handleClose} scroll={true} backdrop={true} className="offCanvasUserReports chargeprob-background-darkgreen" >
                <Offcanvas.Header className='chargeprob-green chargeprob-background-darkgreen'>
                    <Offcanvas.Title>{content.userSpaceTitle}</Offcanvas.Title>
                    <CustomCloseButton handleClose={props.handleClose} />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {/* <h5>My reports</h5> */}
                    <br />
                    {props.loadingUserReports ? <p className='chargeprob-green'>{content.userReportLoadingReports} </p> : null}
                    {props.loadingUserReportsError ? <p className='chargeprob-green'>{content.userReportLoadingReportsError_p1}<br /><br />{content.userReportLoadingReportsError_p2}</p> : null}
                    {openReports && openReports.length > 0 ?
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }} onClick={toggleOpenReports}>
                                <h4 className='chargeprob-green text-center'>{content.userReportOpenReports}</h4>
                                {showOpenReports ?
                                    <h4><FontAwesomeIcon icon={faChevronDown} className='chargeprob-green' /></h4>
                                    :
                                    <h4><FontAwesomeIcon icon={faChevronUp} className='chargeprob-green' /></h4>
                                }
                            </div>
                            <hr className='chargeprob-green' />
                            <br />
                        </div>
                        :
                        <div>
                            <h5 className='white-text text-center'>{content.userReportNoOpenReports}</h5>
                            <br />
                        </div>
                    }
                    {showOpenReports ?
                        <Stack direction='vertical' gap={4}>
                            {openReports && openReports.map((openReport) => {
                                return (
                                    <div style={{ display: 'flex', justifyContent: 'center' }} key={openReport.id}>
                                        <UserReport
                                            source="userreports"
                                            userReport={openReport}
                                            language={props.language}
                                            setBringmethereLocation={props.setBringmethereLocation}
                                            openModalReportImage={openModalReportImage}
                                            showShareOptions={props.showShareOptions}
                                            openModalConfirmReportIsSolved={openModalConfirmReportIsSolved}
                                        />
                                    </div>
                                )
                            })}
                        </Stack>
                        : null}

                    {openReports && openReports.length > 0 ?
                        <div>
                            <br />
                            <br />
                            <br />
                            <h6 className='horizontal-line chargeprob-green'>o</h6>
                            <br />
                            <br />
                            <br />
                        </div>
                        : null
                    }

                    {closedReports && closedReports.length > 0 ?
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }} onClick={toggleClosedReports}>
                                <h4 className='chargeprob-green text-center'>{content.userReportClosedReports}</h4>
                                {showClosedReports ?
                                    <h4><FontAwesomeIcon icon={faChevronDown} className='chargeprob-green' /></h4>
                                    :
                                    <h4><FontAwesomeIcon icon={faChevronUp} className='chargeprob-green' /></h4>
                                }
                            </div>
                            <hr className='chargeprob-green' />
                            <br />
                        </div>
                        : null}

                    {showClosedReports ?
                        <Stack direction='vertical' gap={4}>
                            {closedReports && closedReports.map((closedReport) => {
                                return (
                                    <div style={{ display: 'flex', justifyContent: 'center' }} key={closedReport.id}>
                                        <UserReport
                                            source="userreports"
                                            userReport={closedReport}
                                            language={props.language}
                                            setBringmethereLocation={props.setBringmethereLocation}
                                            openModalReportImage={openModalReportImage}
                                            showShareOptions={props.showShareOptions}
                                            openModalConfirmReportIsSolved={openModalConfirmReportIsSolved}
                                        />
                                    </div>
                                )
                            })}
                        </Stack>
                        : null}

                </Offcanvas.Body>
            </Offcanvas>
            {showModalReportImage ?
                <ModalReportImage
                    show={showModalReportImage}
                    handleClose={closeModalReportImage}
                    report={selectedReport}
                    language={props.language}
                />
                : null}
            {showModalConfirmReportIsSolved ?
                <ModalUserReportConfirmSolved
                    show={showModalConfirmReportIsSolved}
                    handleClose={closeModalConfirmReportIsSolved}
                    report={selectedReport}
                    content={content}
                    confirmReportIsSolved={confirmReportIsSolved}
                    confirmReportIsNotSolved={confirmReportIsNotSolved}
                    sendingReportFeedback={sendingReportFeedback}
                />
                : null}
            {showModalReportFeedbackSent ?
                <ModalUserReportFeedbackSent
                    show={showModalReportFeedbackSent}
                    handleClose={closeModalReportFeedbackSent}
                    language={props.language}
                    feedbackResult={feedbackResult}
                />
                : null}
        </>
    )
}

export default UserReportsOffcanvas;