import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import GreenChargerIcon from '../../static/images/chargers/charger_green_24px.png';
import CustomCloseButton from '../buttons/CustomCloseButton';
/*import Image from 'react-bootstrap/Image'; 
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons' */

function ModalShowMarkerDetails(props) {

    const markerInfo = props.markerInfo;

    return (
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="modalShowMarkerDetails" >
            <Modal.Header className="chargeprob-green chargeprob-background-darkgreen" >
                <Modal.Title className='w-100 text-center'>
                    <img src={GreenChargerIcon} alt={"logo"} /> <b>{markerInfo.name}</b>
                </Modal.Title>
                <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
            </Modal.Header>
            <Modal.Body className='modalsBody'>
                <p>Owner: <b>{markerInfo.owner_name}</b></p>
                <p>Operator: <b>{markerInfo.operator_name}</b></p>
                {markerInfo.suboperator_name && markerInfo.suboperator_name !== "" && markerInfo.suboperator_name !== markerInfo.operator_name ?
                    <p>Suboperator: <b>{markerInfo.suboperator_name}</b></p>
                    : null}
                <p>Address: <b>{markerInfo.address}, {markerInfo.postal_code}, {markerInfo.city}</b></p>
                {/* <FontAwesomeIcon icon={faCreditCard} size="lg" />&nbsp;
                    {markerInfo.Capability_Credit_Card === 'Y' ?  
                        <FontAwesomeIcon icon={faCheck} size="lg" style={{'color': 'green'}} /> : 
                        <FontAwesomeIcon icon={faXmark} size="lg" style={{'color': 'red'}} />} */}
                {/* <p>Pay with credit card: <b>{markerInfo.Capability_Credit_Card === 'Y' ? 'Yes' : 'Not possible'}</b></p>
                <p>RFID reader: <b>{markerInfo.capability_rfid_reader === 'Y' ? 'Yes' : 'Not possible'}</b></p>
                <p>Can be reserved: <b>{markerInfo.capability_reservable === 'Y' ? 'Yes' : 'Not possible'}</b></p>
                <p>Remote start-stop enabled: <b>{markerInfo.capability_reservable === 'Y' ? 'Yes' : 'No'}</b></p> */}
            </Modal.Body>
        </Modal>
    )
}

export default ModalShowMarkerDetails;

{/*  OLD VERSION WITH COMPANY PICTURE
<Modal show={props.show} onHide={props.handleClose} dialogClassName={dialogclassname} >
             <Container className="modalShowMarkerDetailsContainer"
                fluid >
                <Row className="modalShowMarkerDetailsImg"
                        style={{backgroundImage: `url('/images/${markerDetails.Operator}_chargermodal.png')`}}>
                    <Col xs={{span: 5, offset: 7 }} style={{backgroundColor: "#ECE3B1"}}>
                        <Row>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <Container>
                                        <Row className="justify-content-xs-center">
                                            <Col>
                                                <Image 
                                                    src={`../../logos/${markerDetails.Operator}_logo.svg`}
                                                    alt={`${markerDetails.Operator} logo`}
                                                    className='companyLogoModalHeader' 
                                                    fluid />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Title>
                            </Modal.Header>
                        </Row>
                        <Row>
                            <Col className="modalsBody">
                                <Row  style={{paddingTop: "2vh"}}>
                                    <p>Location name: <b>{markerDetails.Location_name}</b></p>
                                    <p>Owner: <b>{markerDetails.Owner}</b></p>
                                    <p>Operator: <b>{markerDetails.Operator}</b></p>
                                    <p>Address: <b>{markerDetails.Address}, {markerDetails.Postal_Code}, {markerDetails.City}</b></p>
                                </Row>
                                <Row>
                                    <h5>Charger features</h5>
                                    <p>Pay with credit card: <b>{markerDetails.Capability_Credit_Card === 'Y' ? 'Yes' : 'Not possible'}</b></p>
                                    <p>RFID reader: <b>{markerDetails.capability_rfid_reader === 'Y' ? 'Yes' : 'Not possible'}</b></p>
                                    <p>Can be reserved: <b>{markerDetails.capability_reservable === 'Y' ? 'Yes' : 'Not possible'}</b></p>
                                    <p>Remote start-stop enabled: <b>{markerDetails.capability_reservable === 'Y' ? 'Yes' : 'No'}</b></p>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Modal.Footer className="modalsHeader">
                                <p>Need support? Contact us!</p>
                                    {markerDetails.Support_phone ?
                                    <OverlayTrigger 
                                        trigger='click'
                                        placement="top"
                                        overlay={<Tooltip id="modalShowMarkerDetailsSupport">{markerDetails.Support_phone}</Tooltip>}
                                        rootClose >
                                        <span>
                                            <FontAwesomeIcon icon={faPhone} /> 
                                        </span> 
                                    </OverlayTrigger> : null}
                                    {markerDetails.Support_Email ? 
                                    <OverlayTrigger 
                                        trigger='click'
                                        placement="top"
                                        overlay={<Tooltip id="modalShowMarkerDetailsSupportEmail">{markerDetails.Support_Email}</Tooltip>}
                                        rootClose >
                                        <span>
                                            <FontAwesomeIcon icon={faAt} /> 
                                        </span> 
                                    </OverlayTrigger> : null}
                                    {markerDetails.Support_Facebook ?
                                        <a href={markerDetails.Support_Facebook} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faFacebook} /> 
                                        </a> : null}
                                    {markerDetails.Support_Twitter ?
                                        <a href={markerDetails.Support_Twitter} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faTwitter} /> 
                                        </a> : null }
                            </Modal.Footer>
            </Row> 
            </Col>
            </Row>
            </Container>
        </Modal> */}