import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { createCommentOnChargerContent } from '../internationalization/Translations';
import { buttonsContent } from '../internationalization/Translations';
import CustomCloseButton from '../buttons/CustomCloseButton';


function ModalCreateCommentOnCharger(props) {

    const content = props.language === "EN" ? createCommentOnChargerContent.English :
        props.language === "NL" ? createCommentOnChargerContent.Dutch :
            createCommentOnChargerContent.English;

    const buttonscontent = props.language === "EN" ? buttonsContent.English :
        props.language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [reaction, setReaction] = useState('neutral');
    const [likeIconClassname, setLikeIconClassname] = useState('likeIcon');
    const [dislikeIconClassname, setDisikeIconClassname] = useState('dislikeIcon');
    const [commentText, setCommentText] = useState('');

    const [emptyCommentError, setEmptyCommentError] = useState(null);

    const emptyCommentErrorMsg = content.emptyCommentErrorMsg;

    const setPositiveReaction = () => {
        if (likeIconClassname === 'likeIcon-big') {
            setLikeIconClassname('likeIcon');
            setDisikeIconClassname('dislikeIcon');
        }
        else {
            setReaction('positive');
            setLikeIconClassname('likeIcon-big');
            setDisikeIconClassname('dislikeIcon-small');
        }
    }

    const setNegativeReaction = () => {
        if (dislikeIconClassname === 'dislikeIcon-big') {
            setLikeIconClassname('likeIcon');
            setDisikeIconClassname('dislikeIcon');
        }
        else {
            setReaction('negative');
            setLikeIconClassname('likeIcon-small');
            setDisikeIconClassname('dislikeIcon-big');
        }
    }

    const submitClicked = () => {
        if (commentText.length < 5) {
            setEmptyCommentError(emptyCommentErrorMsg);
        }
        else {
            setEmptyCommentError(null);
            setLikeIconClassname('likeIcon');
            setDisikeIconClassname('dislikeIcon');
            props.createCommentOnCharger(props.selectedCharger, commentText, reaction);
        }
    }

    return (
        <>
            {props.selectedCharger ?
                <Modal show={props.show} onHide={props.handleClose} backdrop="static">
                    <Modal.Header className="modalsBody">
                        <Modal.Title className='w-100 text-center'>{props.selectedCharger.address}</Modal.Title>
                        <CustomCloseButton handleClose={props.handleClose}></CustomCloseButton>
                    </Modal.Header>
                    <Modal.Body className="modalsBody">
                        <Form>
                            <Form.Label>
                                {content.userExperience}
                            </Form.Label>
                            <Stack direction="horizontal" gap={4}>
                                <span className={likeIconClassname} style={{ 'pointer': 'cursor' }} onClick={() => setPositiveReaction()}></span>
                                <span className={dislikeIconClassname} style={{ 'pointer': 'cursor' }} onClick={() => setNegativeReaction()}></span>
                            </Stack>
                            <br />
                            <Form.Label>
                                {content.leaveComment}
                            </Form.Label>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1" >
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={evt => setCommentText(evt.target.value)} />
                            </Form.Group>
                            <p className='red'>{emptyCommentError}</p>
                        </Form>
                        <Row>
                            <div style={{ 'display': ' flex', 'justifyContent': 'flex-end' }}>
                                <Stack direction='horizontal' gap={2}>
                                    <Button variant="danger" onClick={props.handleClose}>
                                        {buttonscontent.close}
                                    </Button>
                                    <Button variant="success" onClick={() => submitClicked()}>
                                        {buttonscontent.postComment}
                                    </Button>
                                </Stack>
                            </div>
                        </Row>
                    </Modal.Body>
                </Modal>
                : null}
        </>
    )
}

export default ModalCreateCommentOnCharger;