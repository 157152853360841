import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MapNavbar from '../components/MapNavbar';
import '../App.css';
import Container from 'react-bootstrap/Container';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { loginContent } from '../components/internationalization/Translations';
import { buttonsContent } from '../components/internationalization/Translations';
import AuthContext from '../context/AuthContext';
import { Utils } from '../utils/utils';


function ResetPassword() {

    let { language, resetUserPassword } = useContext(AuthContext);
    const { uid, user_token } = useParams();
    const navigate = useNavigate();

    const logincontent = language === "EN" ? loginContent.English :
        language === "NL" ? loginContent.Dutch :
            loginContent.English;

    const buttonscontent = language === "EN" ? buttonsContent.English :
        language === "NL" ? buttonsContent.Dutch :
            buttonsContent.English;

    const [userEmail, setUserEmail] = useState('');
    const [userNewPassword, setUserNewPassword] = useState('');
    const [userRepeatPassword, setUserRepeatPassword] = useState('');

    const [userEmailError, setUserEmailError] = useState('');
    const [userNewPasswordError, setUserNewPasswordError] = useState('');
    const [userRepeatPasswordError, setUserRepeatPasswordError] = useState('');

    const checkChangePasswordCredentials = async () => {
        setUserEmailError('');
        setUserNewPasswordError('');
        setUserRepeatPasswordError('');
        const pwd_validation = Utils.passwordValidation(userNewPassword, logincontent);
        const repeat_pwd_validation = Utils.repeatPasswordValidation(userNewPassword, userRepeatPassword);

        if (!Utils.emailValidation(userEmail)) {
            setUserEmailError(logincontent.emailError);
        }
        else if (pwd_validation !== "") {
            setUserNewPasswordError(pwd_validation);
        }
        else if (!repeat_pwd_validation) {
            setUserRepeatPasswordError(logincontent.passwordsAreDifferent);
        }
        else {
            const resp = await resetUserPassword(uid, user_token,
                {
                    'email': userEmail,
                    'new_password': userNewPassword,
                    'repeat_password': userRepeatPassword,
                }
            );

            if (resp === "OK") {
                navigate("/user/password/reset/success");
            }
            else if (resp === "user does not exist") {
                setUserEmailError(logincontent.changePasswordEmailNotValid);
            }
            else if (resp === "link expired") {
                setUserNewPasswordError(logincontent.changePasswordLinkNotValid);
            }
            else if (resp === "old password is not correct") {
                setUserNewPasswordError(logincontent.changePasswordWrongOldPassword);
            }
            else if (resp === "passwords are different") {
                setUserNewPasswordError(logincontent.changePasswordWrongNewPassword);
            }
            else {
                setUserNewPasswordError(logincontent.changePasswordServerError);
            }
        }
    }


    return (
        <>
            <MapNavbar page="errorpage" />
            <Container fluid className='errorpage'>
                <br /><br /><br /><br />
                <Row>
                    <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
                        <Form>
                            <Form.Group className="mb-3" controlId="resetPassword.ControlInputEmail" >
                                <Form.Label>
                                    {logincontent.changePasswordEnterEmail}
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    autoFocus
                                    onChange={evt => setUserEmail(evt.target.value)}
                                />
                                <p className='red'>{userEmailError}</p>
                                <Form.Group className="mb-0" controlId="resetPassword.ControlInputNewPassword" >
                                    <Form.Control
                                        type="password"
                                        placeholder='New password'
                                        onChange={evt => setUserNewPassword(evt.target.value)}
                                    />
                                    <p style={{ 'fontSize': '0.7rem' }}>{logincontent.passwordRequirements}</p>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="resetPassword.ControlInputRepeatPassword" >
                                    <Form.Control
                                        type="password"
                                        placeholder='Repeat new password'
                                        onChange={evt => setUserRepeatPassword(evt.target.value)}
                                    />
                                    <p className='red'>{userNewPasswordError}</p>
                                    <p className='red'>{userRepeatPasswordError}</p>
                                </Form.Group>
                            </Form.Group>
                        </Form>
                        <Button variant="success" onClick={() => checkChangePasswordCredentials()}>
                            {buttonscontent.userChangePassword}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default ResetPassword;